import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getDistrictData, getPoliceStationData, postBulkData } from "../../../../API";
import { getFirStates } from "../../../../API/panelApis";
import ContentLoader from "../../../../Components/ContentLoader";
import NoRecord from "../../../../Components/NoRecord";
import useGeneralStore from "../../../../store/generalStore";
import SelectDropdown from "../../../../ui/forms/SelectDropdown";

export default function FileMissingInfo({ show, setShow, missingInfoDetails = [], detailsLoading = true, reload = () => {} }) {
    const showAlert = useGeneralStore((state) => state.open_alert);

    const [districtMissingInfo, setDistrictMissingInfo] = useState({});
    const [policeStationMissingInfo, setPoliceStationMissingInfo] = useState({});

    const [states, setStates] = useState([]);

    const closeModal = () => {
        setShow(false);
        setDistrictMissingInfo({});
        setPoliceStationMissingInfo({});
    };

    const updateMissingInfo = async () => {
        if (!missingInfoDetails?.length) return;
        // update missing info
        const data = missingInfoDetails?.map((el) => ({
            complaint_id: el?.complaint_id,
            district: districtMissingInfo?.[el?.complaint_id] || null,
            police_station_name: policeStationMissingInfo?.[el?.complaint_id] || null,
        }));

        const res = await postBulkData(data);

        if (res) showAlert({ variant: "success", msg: res?.message });
        else showAlert({ variant: "error", msg: "Error in updating complaints data." });

        reload();
        closeModal();
    };

    const getStates = async () => {
        const res = await getFirStates();
        if (res) setStates(res?.data);
        else showAlert({ variant: "danger", msg: "Unable to fetch states" });
    };

    useEffect(() => {
        getStates();
    }, []);

    const getStateId = useCallback(
        (state_name) => {
            const upperCaseStateName = state_name?.toUpperCase();

            if (upperCaseStateName === "JAMMU AND KASHMIR") return 87;
            if (upperCaseStateName === "ANDAMAN AND NICOBAR") return 73;
            if (upperCaseStateName === "DADRA AND NAGAR HAVELI") return 80;
            if (upperCaseStateName === "DAMAN AND DIU") return 81;
            return states?.find((el) => el?.state_name?.toUpperCase() === upperCaseStateName)?.state_id;
        },
        [states]
    );

    return (
        <Modal show={show} dialogClassName="modal-large" aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header>
                <Modal.Title id="example-custom-modal-styling-title">Fill Missing Info</Modal.Title>
            </Modal.Header>
            <Modal.Body className="tab-m-body" style={{ minHeight: "30rem" }}>
                <div className="pt-4 pb-3 upload-batch-popup">
                    {!detailsLoading && !!missingInfoDetails?.length && (
                        <div className="missing-info-table">
                            <table style={{ borderTop: "0" }} className="table table-hover table-small mb-5 mt-0">
                                <thead className="thead-light">
                                    <tr>
                                        <th style={{ zIndex: "2" }} scope="col" className="text-start">
                                            No.
                                        </th>
                                        <th style={{ zIndex: "2" }} scope="col" className="text-start">
                                            Name of Accused
                                        </th>
                                        <th style={{ zIndex: "2" }} scope="col" className="text-start">
                                            State [V]
                                        </th>
                                        <th style={{ zIndex: "2" }} scope="col" className="text-start">
                                            City
                                        </th>
                                        <th style={{ zIndex: "2" }} scope="col" className="text-start">
                                            Pincode
                                        </th>
                                        <th style={{ zIndex: "2" }} scope="col" className="text-start">
                                            District
                                        </th>
                                        <th style={{ zIndex: "2" }} scope="col" className="text-start">
                                            Police station
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {missingInfoDetails?.map((item, index) => (
                                        <tr key={index}>
                                            <td scope="row" valign="middle" className="py-3 ps-3 text-start">
                                                {index + 1}
                                            </td>
                                            <td valign="middle" className="py-3 ps-3 text-start">
                                                {item?.customer_name}
                                            </td>
                                            <td valign="middle" className="py-3 ps-3 text-start">
                                                {item?.state}
                                            </td>
                                            <td valign="middle" className="py-3 ps-3 text-start">
                                                {item?.city}
                                            </td>
                                            <td valign="middle" className="py-3 ps-3 text-start">
                                                {item?.zip_code}
                                            </td>
                                            <MapDistrictAndPoliceStation
                                                existingDistrict={item?.district}
                                                district={districtMissingInfo?.[item?.complaint_id]}
                                                handleDistrict={(val) => {
                                                    setDistrictMissingInfo((prev) => ({ ...prev, [item?.complaint_id]: val }));
                                                }}
                                                handlePoliceStation={(val) => setPoliceStationMissingInfo((prev) => ({ ...prev, [item?.complaint_id]: val }))}
                                                policeStation={policeStationMissingInfo?.[item?.complaint_id]}
                                                existingPoliceStation={item?.police_station_name}
                                                stateId={getStateId(item?.state)}
                                            />
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {!!detailsLoading && <ContentLoader />}
                    {!detailsLoading && !missingInfoDetails?.length && (
                        <div style={{ marginTop: "-10rem" }}>
                            <NoRecord />
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal()}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => updateMissingInfo()}>
                    Update Info
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function MapDistrictAndPoliceStation({
    stateId = "",
    district = "",
    existingDistrict = "",
    handleDistrict = () => {},
    policeStation = "",
    existingPoliceStation = "",
    handlePoliceStation = () => {},
}) {
    const showAlert = useGeneralStore((state) => state.open_alert);

    const [districts, setDistricts] = useState([]);
    const [policeStations, setPoliceStations] = useState([]);

    const getDistricts = async () => {
        const res = await getDistrictData(stateId);
        setDistricts(res?.data?.map((el) => ({ label: el?.district_name, value: el?.district_name, district_id: el?.district_id })));
    };

    const getPoliceStations = async () => {
        const district_id = districts?.find((el) => el?.value === district)?.district_id;
        if (!district_id) return;

        const res = await getPoliceStationData(district_id);
        if (!res?.data?.length) showAlert({ variant: "warning", msg: "No Police Station Found" });
        setPoliceStations(res?.data?.map((el) => ({ label: el?.police_station_name, value: el?.police_station_name })));
    };

    useEffect(() => {
        if (!!stateId) getDistricts();
    }, [stateId]);

    useEffect(() => {
        if (!!districts?.length && !!existingDistrict) {
            if (!!districts?.filter((el) => el?.value === existingDistrict?.toUpperCase())?.length) handleDistrict(existingDistrict?.toUpperCase());
        }
    }, [districts, existingDistrict]);

    useEffect(() => {
        if (!!district) getPoliceStations();
    }, [district]);

    useEffect(() => {
        if (!!policeStations?.length && !!existingPoliceStation) {
            if (!!policeStations?.filter((el) => el?.value === existingPoliceStation?.toUpperCase())?.length)
                handlePoliceStation(existingPoliceStation?.toUpperCase());
        }
    }, [policeStations, existingPoliceStation]);

    return (
        <>
            <td valign="middle" className="py-3 ps-3 text-start">
                <SelectDropdown placeholder="Select District" value={district} onChange={(val) => handleDistrict(val)} options={districts} />
            </td>
            <td valign="middle" className="py-3 ps-3 text-start">
                <SelectDropdown
                    placeholder="Select Police station"
                    disabled={!district}
                    value={policeStation}
                    onChange={(val) => handlePoliceStation(val)}
                    options={policeStations}
                />
            </td>
        </>
    );
}
