import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { getClientProducts, getDailySettlementData } from "../../API/adminApis";
import NoRecord from "../../Components/NoRecord";
import { commaFormat, compactFormat } from "../../Functions/formatNumber";
import useAuthStore from "../../store/authStore";
import SelectDropdown from "../../ui/forms/SelectDropdown";
import Loader from "../../ui/Loader";
import { showToast } from "../../utils/handleToast";
import FixedHeader from "../Components/FixedHeader";
import ViewDetails from "../Components/settlementReport/ViewDetails";
import getBaseUrl from "../../apis/getBaseUrl";

function DailySettlementReport() {
    const userData = useAuthStore((state) => state.userData);
    const [products, setProducts] = useState([]);

    const [product, setProduct] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [showDetails, setShowDetails] = useState(false);
    const [selected, setSelected] = useState(null);

    const getProducts = async () => {
        if (!userData?.client_id) return;
        const res = await getClientProducts({ client_id: userData?.client_id });
        if (!!res?.length) setProducts(res?.map((el) => ({ label: el, value: el })));
    };

    useEffect(() => {
        getProducts();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const res = await getDailySettlementData({
            client_id: userData?.client_id,
            product_name: product,
            confirmed_cases: true,
        });

        if (!!res) setData(res?.data);
        else {
            showToast({ variant: "danger", message: "Failed to fetch data" });
            setData([]);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [product]);

    return (
        <div>
            <FixedHeader>
                <div className="row w-100">
                    <div className="col-3">
                        <SelectDropdown placeholder="Choose Product" options={products} value={product} onChange={setProduct} />
                    </div>

                    <div className="col-3"></div>

                    <div className="col-3 d-flex align-items-center justify-content-end pe-0">{/* <UploadLetter /> */}</div>
                </div>
            </FixedHeader>

            <div>
                {!loading && !!data?.length && (
                    <table style={{ borderTop: "0" }} className="table m-0">
                        <thead>
                            <tr>
                                <th className="text-left align-top px-3 py-2">Date</th>
                                <th className="text-center align-top px-3 py-2">Settlements Confirmed</th>
                                <th className="text-center align-top px-3 py-2">Emails for Approval</th>
                                <th className="text-center align-top px-3 py-2">Letters Received</th>
                                <th className="text-center align-top px-3 py-2">Payments Received</th>
                                <th />
                            </tr>
                        </thead>

                        <tbody>
                            {data?.map((item, index) => (
                                <tr key={index}>
                                    <td className="text-left align-middle px-3 py-2">{!!item?.date ? format(new Date(item?.date), "do MMM") : "..."}</td>
                                    <td className="text-center align-middle px-3 py-2">
                                        {item?.confirmed_cases} {`(Rs. ${compactFormat(item?.settlement_raised_value)})`}
                                    </td>
                                    <td className="text-center align-middle px-3 py-2">{!!item?.email_for_approval ? "Sent" : "Not Sent"}</td>
                                    <td className="text-center align-middle px-3 py-2">{item?.letter_received}</td>
                                    <td className="text-center align-middle px-3 py-2">{item?.payment_received}</td>

                                    <td className="align-middle px-3 py-2">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="px-3 py-2 d-flex align-items-center gap-1">
                                                    Actions
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            setSelected(item);
                                                            setShowDetails(true);
                                                        }}
                                                    >
                                                        View Details
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        href={`${getBaseUrl().baseUrl}/dashboard/api/download_daily_settlement_data/?date=${
                                                            item?.date
                                                        }&&client_id=${userData?.client_id}&&product_name=${product}&&confirmed_cases=true`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Download Report
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {loading && <Loader />}

                {!loading && !data?.length && <NoRecord />}
            </div>

            <ViewDetails setShow={setShowDetails} show={showDetails} details={selected} setDetails={setSelected} product={product} />
        </div>
    );
}

export default DailySettlementReport;
