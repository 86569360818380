import React, { useEffect, useState } from "react";
import classes from "./ProfileForm.module.css";
import logo from "../../../Static/RawImages/logo.png";
import Steps from "../../../ui/Steps";
import useStep from "../../../hooks/useStep";
import useForm from "../../../hooks/useForm";
import { showToast } from "../../../utils/handleToast";
import { getAgentLanguages, logout } from "../../../API";
import MultiSelectDropDown from "../../../ui/forms/MultiSelectDropDown";
import { putAdminAgents } from "../../../API/adminApis";
import useAuthStore from "../../../store/authStore";
import { useHistory } from "react-router-dom";
import { HiArrowRightOnRectangle } from "react-icons/hi2";
import { addMedia } from "../../../apis";

const stepValues = {
  YOUR_DETAILS: "your_details",
  REFERENCE_DETAILS: "reference_details",
  SALARY_TRANSFER: "salary_transfer",
};

function ProfileForm() {
  const userData = useAuthStore((state) => state.userData);
  const removeUser = useAuthStore((state) => state.removeUser);
  const updateProfileCompleted = useAuthStore((state) => state.updateProfileCompleted);

  const { activeStep, setActiveStep, steps, updateStep, resetSteps } = useStep([
    { title: "Your Details", completed: false, key: stepValues.YOUR_DETAILS },
    { title: "Reference", completed: false, key: stepValues.REFERENCE_DETAILS },
    { title: "Salary Transfer", completed: false, key: stepValues.SALARY_TRANSFER },
  ]);

  const [languages, setLanguages] = useState([]);

  const [uploadingFile, setUploadingFile] = useState(false);
  const [loading, setLoading] = useState(false);

  const [details, handleDetails, resetDetails] = useForm({
    languages: [],
    contact_number: "",
    permanent_address: "",
    adhaar_number: "",
    adhaar_file: "",
    pan_number: "",

    father_name: "",
    mother_name: "",
    parent_number: "",

    bank_ifsc: "",
    bank_account_number: "",
  });

  const history = useHistory();

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const getLanguages = async () => {
    let res = await getAgentLanguages();
    if (res) setLanguages(res.map(({ language_name }) => ({ label: capitalize(language_name), value: language_name })));
    else setLanguages([]);
  };

  useEffect(() => {
    getLanguages();
    resetDetails();
    resetSteps();
  }, []);

  useEffect(() => {
    handleDetails("contact_number", userData?.mobile);
  }, [userData?.mobile]);

  const markAsCompleted = () => {
    showToast({ variant: "warning", message: "Process Discountinued for now" });
    updateProfileCompleted(true);
    history.push("/agent/calls/new/imp-leads");
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (e?.target?.files[0]?.size > 1e7) {
      e.target.value = "";
      showToast({ variant: "danger", msg: "File size should be lesser than 10 MBs!" });
      return;
    }
    if (!file) return;

    setUploadingFile(true);

    const formData = new FormData();
    formData.append("file", file);

    const res = await addMedia(formData);

    if (res?.success) {
      handleDetails("adhaar_file", res.data?.data);
    } else {
      showToast({ variant: "danger", message: res?.data?.message ?? "Failed to upload." });
      // markAsCompleted();
    }

    setUploadingFile(false);
  };

  // validations
  const validateData = (type = []) => {
    if (type.includes("languages") && !details.languages?.length) {
      showToast({ variant: "warning", message: "Please select language" });
      return false;
    }

    if (type.includes("contact_number") && (!details.contact_number || details.contact_number.length !== 10 || isNaN(details.contact_number))) {
      showToast({ variant: "warning", message: "Please enter a valid contact number" });
      return false;
    }

    if (type.includes("permanent_address") && !details.permanent_address) {
      showToast({ variant: "warning", message: "Please enter permanent address" });
      return false;
    }

    if (type.includes("adhaar_number") && (!details.adhaar_number || details.adhaar_number.length !== 12 || isNaN(details.adhaar_number))) {
      showToast({ variant: "warning", message: "Please enter a valid aadhar number" });
      return false;
    }

    if (type.includes("pan_number") && (!details.pan_number || details.pan_number.length !== 10)) {
      showToast({ variant: "warning", message: "Please enter a valid PAN number" });
      return false;
    }

    if (type.includes("adhaar_file") && !details.adhaar_file) {
      showToast({ variant: "warning", message: "Please upload Aadhar Card." });
      return false;
    }

    if (type.includes("father_name") && !details.father_name) {
      showToast({ variant: "warning", message: "Please enter a Father's Name" });
      return false;
    }

    if (type.includes("mother_name") && !details.mother_name) {
      showToast({ variant: "warning", message: "Please enter a Mother's Name" });
      return false;
    }

    if (type.includes("parent_number") && (!details.parent_number || details.parent_number.length !== 10 || isNaN(details.parent_number))) {
      showToast({ variant: "warning", message: "Please enter a valid parent's number" });
      return false;
    }

    if (type.includes("bank_ifsc") && (!details.bank_ifsc || details.bank_ifsc.length !== 11)) {
      showToast({ variant: "warning", message: "Please enter a valid bank account IFSC code" });
      return false;
    }

    if (type.includes("bank_account_number") && !details.bank_account_number) {
      showToast({ variant: "warning", message: "Please enter bank account number" });
      return false;
    }

    return true;
  };

  const validateDetails = () => {
    if (!validateData(["languages", "contact_number", "permanent_address", "adhaar_number", "pan_number", "adhaar_file"])) return;
    //
    updateStep({ stepKey: stepValues.YOUR_DETAILS, completed: true });
    setActiveStep(stepValues.REFERENCE_DETAILS);
  };

  const validateReference = () => {
    if (!validateData(["father_name", "mother_name", "parent_number"])) return;
    //
    updateStep({ stepKey: stepValues.REFERENCE_DETAILS, completed: true });
    setActiveStep(stepValues.SALARY_TRANSFER);
  };

  const onSubmit = async () => {
    if (!validateData(["languages", "contact_number", "permanent_address", "adhaar_number", "pan_number", "adhaar_file"])) {
      setActiveStep(stepValues.YOUR_DETAILS);
      return;
    }

    if (!validateData(["father_name", "mother_name", "parent_number"])) {
      setActiveStep(stepValues.REFERENCE_DETAILS);
      return;
    }

    if (!validateData(["bank_ifsc", "bank_account_number"])) return;

    setLoading(true);

    const res = await putAdminAgents({
      user_id: userData?.user_id,

      languages: details?.languages,
      contact_number: details?.contact_number,
      permanent_address: details?.permanent_address,
      adhaar_file: details?.adhaar_file,
      adhaar_number: details?.adhaar_number,
      pan_number: details?.pan_number,
      father_name: details?.father_name,
      mother_name: details?.mother_name,
      parent_number: details?.parent_number,
      bank_ifsc: details?.bank_ifsc,
      bank_account_number: details?.bank_account_number,
    });

    if (res) {
      updateStep({ stepKey: stepValues.SALARY_TRANSFER, completed: true });
      showToast({ variant: "success", message: "Data updated successfully" });
      markAsCompleted();
    } else {
      showToast({ variant: "danger", message: "Something went wrong. Try again later..." });
      // markAsCompleted();
    }

    setLoading(false);
  };

  const logoutfxn = async () => {
    await logout();
    removeUser();
  };

  return (
    <div className={classes.main_wrapper}>
      <div className={classes.logo}>
        <img src={logo} alt="logo" />
      </div>

      <button onClick={logoutfxn} className={classes.logout_btn}>
        <HiArrowRightOnRectangle style={{ fontSize: "1.3rem" }} /> Logout
      </button>

      <div className={classes.container}>
        <div className={classes.title}>
          <h1>Complete the Registration Process</h1>
        </div>
        {/* Steps */}

        <div className="py-4 w-100" style={{ overflow: "hidden" }}>
          <Steps activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} />
        </div>

        {/* Content */}
        <div className={classes.content_section}>
          {activeStep === stepValues.YOUR_DETAILS && (
            <>
              <div className="row">
                <div className="col-6 mb-4">
                  <label className={classes.label}>Languages</label>
                  <MultiSelectDropDown options={languages} value={details.languages} onChange={(val) => handleDetails("languages", val)} />
                </div>

                <div className="col-6 mb-4">
                  <label className={classes.label}>Contact No.</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0" }} className="input-group-text py-2">
                        +91
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="XXXX XXX XXX"
                      className="form-control py-2"
                      maxLength={10}
                      value={details.contact_number}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) return;
                        handleDetails("contact_number", e.target.value?.trim());
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-4 w-100">
                <label className={classes.label}>Permanent Address</label>
                <textarea
                  placeholder="Type your permanent address here..."
                  className="form-control"
                  rows={5}
                  value={details.permanent_address}
                  onChange={(e) => handleDetails("permanent_address", e.target.value)}
                />
              </div>

              <div className="row">
                <div className="col-6 mb-4">
                  <label className={classes.label}>Aadhar Number</label>
                  <input
                    type="text"
                    placeholder="XXXX-XXXX-XXXX"
                    maxLength={12}
                    className="form-control"
                    value={details.adhaar_number}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;
                      handleDetails("adhaar_number", e.target.value?.trim());
                    }}
                  />
                </div>

                <div className="col-6 mb-4">
                  <label className={classes.label}>PAN Card No.</label>
                  <input
                    type="text"
                    placeholder="PAN Card No."
                    maxLength={10}
                    className="form-control"
                    style={{ textTransform: "uppercase" }}
                    value={details.pan_number}
                    onChange={(e) => {
                      handleDetails("pan_number", e.target.value?.trim());
                    }}
                  />
                </div>

                <div className="col-12 mb-4">
                  <label className={classes.label}>Upload Aadhar Card</label>
                  <input type="file" className="form-control" disabled={uploadingFile} onChange={handleFileUpload} />
                  {uploadingFile && (
                    <p className="text-muted text-end">
                      <small>
                        <em>Uploading...</em>
                      </small>
                    </p>
                  )}
                </div>
              </div>
            </>
          )}

          {activeStep === stepValues.REFERENCE_DETAILS && (
            <>
              <div className="row justify-content-center">
                <div className="col-10 mb-3">
                  <label className={classes.label}>Father's Name</label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    value={details.father_name}
                    onChange={(e) => handleDetails("father_name", e.target.value)}
                  />
                </div>

                <div className="col-10 mb-3">
                  <label className={classes.label}>Mother's Name</label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    value={details.mother_name}
                    onChange={(e) => handleDetails("mother_name", e.target.value)}
                  />
                </div>

                <div className="col-10 mb-3">
                  <label className={classes.label}>Parent's Number</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0" }} className="input-group-text py-2">
                        +91
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="XXXX XXX XXX"
                      className="form-control py-2"
                      maxLength={10}
                      value={details.parent_number}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) return;
                        handleDetails("parent_number", e.target.value?.trim());
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {activeStep === stepValues.SALARY_TRANSFER && (
            <>
              <div className="row justify-content-center">
                <div className="col-10 mb-3">
                  <label className={classes.label}>Bank Account IFSC</label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    style={{ textTransform: "uppercase" }}
                    value={details.bank_ifsc}
                    maxLength={11}
                    onChange={(e) => handleDetails("bank_ifsc", e.target.value)}
                  />
                </div>

                <div className="col-10 mb-3">
                  <label className={classes.label}>Bank Account Number</label>
                  <input
                    type="text"
                    placeholder="Type here"
                    className="form-control"
                    style={{ textTransform: "uppercase" }}
                    value={details.bank_account_number}
                    onChange={(e) => handleDetails("bank_account_number", e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {/* Actions */}
        <div className={classes.footer}>
          {activeStep === stepValues.YOUR_DETAILS && (
            <>
              <button disabled={uploadingFile} onClick={validateDetails} className={classes.action_btn}>
                Next - Reference
              </button>
            </>
          )}

          {activeStep === stepValues.REFERENCE_DETAILS && (
            <>
              <button disabled={uploadingFile} onClick={() => setActiveStep(stepValues.YOUR_DETAILS)} className={classes.action_btn}>
                Prev - Details
              </button>

              <button disabled={uploadingFile} onClick={validateReference} className={classes.action_btn}>
                Next - Salary Transfer
              </button>
            </>
          )}

          {activeStep === stepValues.SALARY_TRANSFER && (
            <>
              <button disabled={uploadingFile} onClick={() => setActiveStep(stepValues.REFERENCE_DETAILS)} className={classes.action_btn}>
                Prev - Reference
              </button>

              <button disabled={uploadingFile || loading} onClick={onSubmit} className={classes.action_btn}>
                {loading ? "Submitting..." : "Submit"}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileForm;
