import { settlementProcess } from "../data";
import useBorrowerInfo from "../store";
import AddAlternateContact from "./add-alternate-contact";
import CallAlternateNumber from "./call-alternate-nos";
import FirCopy from "./fir-copy";
import LegalNotice from "./legal-notice";
import PaymentLink from "./payment-link";
import SendPaymentFollowup from "./setl-process/send-payment-followup";
import SendReminderToLender from "./setl-process/send-reminder-lender";
import SendVerificationMsg from "./setl-process/send-verification-msg";

export default function OtherActions() {
    const data = useBorrowerInfo((state) => state.data);

    return (
        <div>
            <p className="mb-3 fw-bold tx-16 text-black">Other Actions</p>

            <div className="row gy-2">
                <PaymentLink />

                <FirCopy />

                <LegalNotice />

                <CallAlternateNumber />

                <AddAlternateContact />

                {/* Settlement Processes */}
                {data?.settlement_intention === settlementProcess.INTENTION_RECEIVED && <SendVerificationMsg />}
                {data?.settlement_intention === settlementProcess.CONFIRMATION_RECEIVED && <SendReminderToLender />}
                {data?.settlement_intention === settlementProcess.LETTER_RECEIVED && <SendPaymentFollowup />}
            </div>
        </div>
    );
}
