import React from "react";
import { emailIcon, postalIcon, smsIcon, whatsappIcon } from "../icons";

export default function DeliveryStatusTable({
    data,
    setTrackingId,
    setIsPendingDocuments,
    communicationModes = [],
    setEmailReply,
    setWhatsappResponseMobile = () => {},
}) {
    const inLowerCaseCommunicationMode = communicationModes.map((mode) => mode?.toString()?.toLowerCase());

    // Email
    const getEmailStatus = () => {
        if (["Delivered", "Opened", "Responded", "Delivery", "Replied"].includes(data?.email_delivered_status)) return "Delivered";

        if (!data.email_delivered) return "Not Sent";

        return "Not Delivered";
    };

    const getEmailResponse = () => {
        let text = data?.email_delivered_status || "";

        if (text === "Replied") text = "Response Received";

        if (!!data.email_responded || text === "Replied")
            return (
                <span style={{ cursor: "pointer" }} className="text-primary" onClick={() => setEmailReply(data?.email)}>
                    {text}
                </span>
            );
        return text;

        // if (data.email_delivered_status === "Delivered")
        //     if (data.email_responded === true)
        //         return (
        //             <span style={{ cursor: "pointer" }} className="text-primary" onClick={() => setEmailReply(data?.email)}>
        //                 Response Received
        //             </span>
        //         );
        //     else return "Not Opened";
    };

    // Whatsapp
    const getWhatsappStatus = () => {
        if (!data?.whatsapp_delivered) return "Not Sent";
        if (data.whatsapp_delivered_status === "Invalid Phone Number") return "Not Delivered";
        else return "Delivered";
    };

    const getWhatsappResponse = () => {
        if (!data?.whatsapp_delivered) return "--";
        if (data.whatsapp_delivered_status === "Invalid Phone Number" || data.whatsapp_delivered_status === "Failed") return "Not on Whatsapp";
        if (data.whatsapp_delivered_status === "Sent") return "Not Read";
        if (data.whatsapp_delivered_status === "Replied")
            return (
                <span style={{ cursor: "pointer" }} className="text-primary" onClick={() => setWhatsappResponseMobile(data?.mobile)}>
                    Response Received
                </span>
            );
        return data?.whatsapp_delivered_status;
    };

    // Sms
    const getSmsStatus = () => {
        if (!data?.sms_delivered) return "Not Sent";
        if (["Delivered", "Clicked"]?.includes(data?.sms_delivered_status)) return "Delivered";
        return "Not Delivered";
    };
    const getSmsResponse = () => {
        if (!!data?.sms_delivered && ["Delivered", "Clicked"]?.includes(data?.sms_delivered_status)) return data?.sms_clicked;
        return "--";
    };

    return (
        <>
            {(!!inLowerCaseCommunicationMode?.length && (
                <table className="table table-bordered table-striped w-100">
                    <tbody>
                        {/* Postal Mode */}
                        {inLowerCaseCommunicationMode.includes("postal") && (
                            <tr>
                                <td className="text-center">{postalIcon}</td>
                                <td>
                                    {data.postal_tracking_id ? (data.postal_delivered_status ? data.postal_delivered_status : "Dispatched") : "Not Dispatched"}
                                    <br />
                                    <span
                                        className="text-primary"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            // setTrackingId(data?.postal_tracking_id);
                                            // setIsPendingDocuments(true);
                                        }}
                                    >
                                        {data.postal_tracking_id ? data.postal_tracking_id : ""}
                                    </span>
                                </td>
                                <td>{data?.postal_delivery_failed_reason || "--"}</td>
                            </tr>
                        )}

                        {/* SMS Mode */}
                        {inLowerCaseCommunicationMode.includes("sms") && (
                            <tr>
                                <td className="text-center">{smsIcon}</td>
                                <td>{getSmsStatus()}</td>
                                <td>{getSmsResponse()}</td>
                            </tr>
                        )}

                        {/* Email Mode */}
                        {inLowerCaseCommunicationMode.includes("email") && (
                            <tr>
                                <td className="text-center">{emailIcon}</td>
                                <td>{getEmailStatus()}</td>
                                <td>{getEmailResponse()}</td>
                            </tr>
                        )}

                        {/* Whatsapp Mode */}
                        {inLowerCaseCommunicationMode.includes("whatsapp") && (
                            <tr>
                                <td className="text-center">{whatsappIcon}</td>
                                <td>{getWhatsappStatus()}</td>
                                <td>{getWhatsappResponse()}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )) || <p className="pt-2 text-center text-muted">No Communication Modes Found</p>}
        </>
    );
}
