import React from "react";
import Select from "react-select";

// Sorting by disabled
const sortByProperty =
    (property, asc = true) =>
    (a, b) => {
        let propertyA = a[property];
        let propertyB = b[property];

        if (propertyA > propertyB) return asc ? 1 : -1;
        else if (propertyA < propertyB) return asc ? -1 : 1;
        return 0;
    };

export default function SelectDropdown({ name = "", placeholder = "", disabled = false, options, value, onChange, isClearable = true }) {
    return (
        <Select
            options={options?.sort(sortByProperty("disabled"))}
            value={options?.find((el) => el?.value?.toString() === value?.toString()) || ""}
            isDisabled={disabled}
            isOptionDisabled={(option) => option?.disabled}
            name={name}
            isClearable={isClearable}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    padding: "0.2rem 0",
                    paddingLeft: "10px",
                    border: "1px solid #dddddd !important",
                    borderRadius: "6px",
                    backgroundColor: state.isDisabled ? "#f1f1f1" : "#fff",
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#E0E7E9" : state.isFocused ? "#f4f6f8" : "white",
                    color: !state.isSelected && state.isDisabled ? "#aaa" : "#555",
                    zIndex: "999",
                }),
            }}
            onChange={(val) => (!!name ? onChange(name, !!val?.value ? val?.value : "") : onChange(!!val?.value ? val?.value : ""))}
            placeholder={placeholder}
        />
    );
}
