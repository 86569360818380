import { format } from "date-fns";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { getViewDailySettlementData, updateDailySettlementData } from "../../../API";
import NoRecord from "../../../Components/NoRecord";
import { compactFormat } from "../../../Functions/formatNumber";
import { isEmpty } from "../../../Functions/isEmpty";
import useAuthStore from "../../../store/authStore";
import Loader from "../../../ui/Loader";
import { showToast } from "../../../utils/handleToast";

const formatter = new Intl.ListFormat("en", { style: "short", type: "conjunction" });

export default function ViewDetails({ show, setShow, details, setDetails, product = null }) {
    const userData = useAuthStore((state) => state.userData);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setShow(false);
        setDetails(null);
    };

    const fetchData = async () => {
        if (!details?.date) return;

        setLoading(true);
        const res = await getViewDailySettlementData({
            date: details?.date,
            client_id: userData?.client_id,
            confirmed_cases: true,
            product_name: product,
        });

        if (res) {
            setData(res?.data);
        } else showToast({ variant: "danger", message: "Failed to fetch Details" });

        setLoading(false);
    };

    useEffect(() => {
        if (!!show && !!details) fetchData();
    }, [details, show]);

    const updateRejectedStatus = async (id, letter_rejected) => {
        if (!id) {
            showToast({ variant: "warning", message: "Id Not Found" });
            return;
        }
        const res = await updateDailySettlementData({ letter_rejected }, id);

        if (res) {
            showToast({ variant: "success", message: res?.message });
            fetchData();
        } else showToast({ variant: "danger", message: "Action Failed" });
    };

    return (
        <Modal size="xl" show={show}>
            <Modal.Header>
                <Modal.Title>Settlement Confirmations Received - {!!details?.date ? format(new Date(details?.date), "do MMM") : "..."}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: "400px" }} className="pb-4 pt-3">
                {!loading && !!data?.length && (
                    <>
                        <div style={{ minHeight: "400px" }} className="d-flex flex-column gap-4 h-100">
                            <table style={{ borderTop: "0" }} className="table m-0">
                                <thead>
                                    <tr>
                                        <th className="text-left align-middle px-3 py-2">#</th>
                                        <th className="text-left align-middle px-3 py-2">LAN</th>
                                        <th className="text-left align-middle px-3 py-2">SETL Amount</th>
                                        <th className="text-center align-middle px-3 py-2">Confirmation Received</th>
                                        <th className="text-center align-middle px-3 py-2">Letter Received</th>
                                        <th className="text-center align-middle px-3 py-2">Payment Status</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        const confirmationReceived = [];
                                        if (!!item?.customer__customer_notice__agree_to_settlement_email) confirmationReceived.push("Email");
                                        if (!!item?.customer__customer_notice__agree_to_settlement_whatsapp) confirmationReceived.push("WhatsApp");
                                        if (!!item?.admin_approved) confirmationReceived.push("Admin");

                                        return (
                                            <tr key={index}>
                                                <td className="text-left align-middle px-3 py-2">{index + 1}</td>
                                                <td className="text-left align-middle px-3 py-2">{item?.customer__loan_account_no || "..."}</td>
                                                <td className="text-left align-middle px-3 py-2" style={{ whiteSpace: "nowrap" }}>
                                                    Rs. {compactFormat(item?.customer__pre_approved_sattlement_amount || 0)}{" "}
                                                    {!isEmpty(item?.number_of_emi) && `(${item?.number_of_emi} Instl.)`}
                                                </td>
                                                <td className="text-center align-middle px-3 py-2">
                                                    {!!confirmationReceived?.length ? (
                                                        formatter.format(confirmationReceived)
                                                    ) : (
                                                        <span className="text-muted">Not Received</span>
                                                    )}
                                                </td>
                                                <td className="text-center align-middle px-3 py-2">
                                                    {!!item?.customer__settlement_letter_sent ? "Yes" : "No"}
                                                </td>
                                                <td className="text-center align-middle px-3 py-2">{!!item?.payment_amount ? "Yes" : "No"}</td>
                                                <td className="align-middle px-3 py-2">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                variant="secondary"
                                                                id="dropdown-basic"
                                                                className="px-3 py-2 d-flex align-items-center gap-1"
                                                            >
                                                                Actions
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => updateRejectedStatus(item?.id, !item?.letter_rejected)}>
                                                                    {!item?.letter_rejected ? "Mark as Rejected" : "Unmark as Rejected"}
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}

                {loading && <Loader />}

                {!loading && !data?.length && <NoRecord />}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
