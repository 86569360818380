import React, { useRef, useState } from "react";
import classes from "./messageInput.module.css";
import { IoSend } from "react-icons/io5";

export default function MessageInput({ onSendMessage, loading }) {
  const [message, setMessage] = useState("");
  const inputRef = useRef(null);

  const onSend = () => {
    if (!message) return;
    onSendMessage({ msg: message, file: null });
    setMessage("");

    // Focus the textarea after sending
    inputRef.current.focus();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <>
      <textarea
        ref={inputRef}
        className={classes.message_input}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Type a message"
      />

      <button className={classes.send_btn} onClick={onSend} disabled={!message || loading}>
        <IoSend />
      </button>
    </>
  );
}
