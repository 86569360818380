// variants => green, grey, blue, red, info
// icon => email, whatsapp, sms, ivr, agent

const variants = {
    GREEN: "green",
    GREY: "grey",
    BLUE: "blue",
    RED: "red",
    INFO: "info",
};

export const findCommunicationHistory = ({ icon = "", variant = "" }) => {
    if (icon === "email") {
        if (variant === variants.INFO) return "Not Sent Yet";
        if (variant === variants.RED) return "Invalid Email";
        if (variant === variants.GREY) return "Delivered";
        if (variant === variants.BLUE) return "Opened";
        if (variant === variants.GREEN) return "Responded";

        return "Email";
    }

    if (icon === "whatsapp") {
        if (variant === variants.INFO) return "Not Sent Yet";
        if (variant === variants.RED) return "Not On WhatsApp";
        if (variant === variants.GREY) return "Delivered";
        if (variant === variants.BLUE) return "Read";
        if (variant === variants.GREEN) return "Responded";

        return "Whatsapp";
    }

    if (icon === "sms") {
        if (variant === variants.INFO) return "Not Sent Yet";
        if (variant === variants.RED) return "Not Delivered";
        if (variant === variants.GREY) return "Delivered";
        if (variant === variants.BLUE) return "Link Clicked";

        return "SMS";
    }

    if (icon === "ivr") {
        if (variant === variants.INFO) return "Not Sent Yet";
        if (variant === variants.RED) return "WN/SW";
        if (variant === variants.GREY) return "RNR";
        if (variant === variants.BLUE) return "Answered";
        if (variant === variants.GREEN) return "Connected";

        return "IVR";
    }

    if (icon === "agent") {
        if (variant === variants.INFO) return "Not Connected";
        if (variant === variants.RED) return "WN/SW/RTP";
        if (variant === variants.GREY) return "RNR";
        if (variant === variants.BLUE) return "Connected";
        if (variant === variants.GREEN) return "PTP/SETL/SUR";

        return "Dispositions";
    }
};
