import { useEffect, useRef } from "react";
import { createContext, useContext } from "react";
import BrowserInteractionTime from "browser-interaction-time";
import { updateAgentPanelTime } from "../API/agentApis";
import { format } from "date-fns";
import useAuthStore from "../store/authStore";
import useAgent from "../hooks/useAgent";

const AGENT_PANEL_TIME_LOCAL_KEY = "agent_panel_time_tracker";
const AGENT_PANEL_TIME_LOCAL_TIMESTAMP = "agent_panel_time_tracker_timestamp";
const TOTAL_AGENT_PANEL_TIME_TODAY = "total_agent_panel_time_today";

const agentContext = createContext();

export const useAgentContext = () => useContext(agentContext);

export function AgentContextProvider({ children }) {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

    useAgent();

    const browserInteractionTime = useRef(null);

    const getTodaysPanelKey = () => format(new Date(), "dd_MM_yyyy");
    const parsePanelTimeLocal = (prev_time) => Number(!!prev_time ? JSON.parse(prev_time)?.[getTodaysPanelKey()] : 0);

    async function updatePanelTime(panel_time) {
        try {
            // total time updation -> local storage
            let prev_total_panel_time = localStorage.getItem(TOTAL_AGENT_PANEL_TIME_TODAY);
            localStorage.setItem(
                TOTAL_AGENT_PANEL_TIME_TODAY,
                JSON.stringify({
                    [getTodaysPanelKey()]: panel_time + parsePanelTimeLocal(prev_total_panel_time),
                })
            );

            const res = await updateAgentPanelTime({ panel_time, idle_time: null });
            // setPanelTime(!!res?.panel_time ? Number(res?.panel_time) : 0);
        } catch (err) {
            console.log(err);
        }
    }

    async function triggerPanelTimeTracker() {
        let prevPanelTrackerTimeStamp = new Date();
        let currentPanelTrackerTimeStamp = new Date();

        // check for item in localstorage
        const localStoragePanelTrackerTimeStamp = localStorage.getItem(AGENT_PANEL_TIME_LOCAL_TIMESTAMP);
        if (!!localStoragePanelTrackerTimeStamp) prevPanelTrackerTimeStamp = new Date(JSON.parse(localStoragePanelTrackerTimeStamp));
        else localStorage.setItem(AGENT_PANEL_TIME_LOCAL_TIMESTAMP, JSON.stringify(currentPanelTrackerTimeStamp));

        // API update logic => every 5 mins
        let FIVE_MINUTES = 5 * 60 * 1000; // 5min * 60sec * 1000msec
        let time_tracked = parseInt(browserInteractionTime.current.getTimeInMilliseconds() / 1000);

        // total panel time updation -> state
        // let prev_total_panel_time = localStorage.getItem(TOTAL_AGENT_PANEL_TIME_TODAY);
        // setPanelTime(time_tracked + parsePanelTimeLocal(prev_total_panel_time));

        if (currentPanelTrackerTimeStamp.getTime() - prevPanelTrackerTimeStamp.getTime() >= FIVE_MINUTES) {
            await updatePanelTime(time_tracked);
            localStorage.setItem(AGENT_PANEL_TIME_LOCAL_KEY, "0");
            browserInteractionTime.current.reset();
            browserInteractionTime.current.startTimer();
            localStorage.setItem(AGENT_PANEL_TIME_LOCAL_TIMESTAMP, JSON.stringify(currentPanelTrackerTimeStamp));
        } else {
            localStorage.setItem(AGENT_PANEL_TIME_LOCAL_KEY, time_tracked);
        }
    }

    async function startPanelTimeTracker() {
        localStorage.setItem(AGENT_PANEL_TIME_LOCAL_TIMESTAMP, JSON.stringify(new Date()));

        // total panel time -> initialization
        // let prev_total_panel_time = localStorage.getItem(TOTAL_AGENT_PANEL_TIME_TODAY);
        // setPanelTime(parsePanelTimeLocal(prev_total_panel_time));

        const localPanelTrackerTime = localStorage.getItem(AGENT_PANEL_TIME_LOCAL_KEY);
        if (!!localPanelTrackerTime) await updatePanelTime(parseInt(localPanelTrackerTime));

        if (!!browserInteractionTime.current) browserInteractionTime.current?.destroy();

        browserInteractionTime.current = new BrowserInteractionTime({
            timeIntervalEllapsedCallbacks: [],
            absoluteTimeEllapsedCallbacks: [],
            browserTabInactiveCallbacks: [],
            browserTabActiveCallbacks: [],
            idleTimeoutMs: 600000, // 10 mins = 10m * 60s * 1000ms
            checkCallbacksIntervalMs: 5000, // every 5 sec updated in localStorage
        });

        browserInteractionTime.current.startTimer();

        const interval_callback = {
            multiplier: (time) => time,
            timeInMilliseconds: 1000,
            callback: () => triggerPanelTimeTracker(),
        };
        browserInteractionTime.current.addTimeIntervalEllapsedCallback(interval_callback);
    }

    async function triggerPanelTimeTrackerLogout() {
        let time_tracked = parseInt(browserInteractionTime.current.getTimeInMilliseconds() / 1000);
        await updatePanelTime(time_tracked);
        localStorage.setItem(AGENT_PANEL_TIME_LOCAL_KEY, "0");
        browserInteractionTime.current.destroy();
    }

    useEffect(() => {
        isAuthenticated && startPanelTimeTracker();
        if (!isAuthenticated) triggerPanelTimeTrackerLogout();
    }, [isAuthenticated]);

    const values = {};

    return <agentContext.Provider value={values}>{children}</agentContext.Provider>;
}
