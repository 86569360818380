import { Fragment, useEffect, useState } from "react";
import AnalysisCard from "./analysis-card";
import { FaScaleBalanced } from "react-icons/fa6";
import { PiScalesLight } from "react-icons/pi";
import { Offcanvas } from "react-bootstrap";
import { format } from "date-fns";
import { getBorrowerReport, preLitigationConciliationIntentionHistory, preLitigationDownloadConciliationHearing } from "../../../apis";
import { downloadMediaUrl } from "../../../API";
import { showToast } from "../../../utils/handleToast";
import ContentLoader from "../../../Components/ContentLoader";
import { IoCloseCircle } from "react-icons/io5";

export default function Conciliation({ lan, info, customerId, batchStatus }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);

  // const [noticeId, setNoticeId] = useState([]);

  // const data = useBorrowerInfo((state) => state.data);
  // const analysis = useBorrowerInfo((state) => state.analysis);
  // const isLoading = useBorrowerInfo((state) => state.isAnalysisLoading);

  // const [analysis, setAnalysis] = useState([]);

  const toggle = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    const fetchData = async () => {
      if (!customerId) return;

      setLoading(true);

      const params = {
        lan,
      };

      if (batchStatus === "completed") params.batch_type = batchStatus;

      const analysisResponse = await getBorrowerReport(params);

      if (!analysisResponse.success) return;

      const noticeId = analysisResponse?.data?.data?.data[0]?.notice_id;

      const res = await preLitigationConciliationIntentionHistory({
        customer_id: customerId,
        notice_id: noticeId,
      });

      if (res.success) {
        setDetails(res.data);
      }

      setLoading(false);
    };

    if (isOpen) {
      fetchData();
    }

    // return () => setDetails([]);
  }, [isOpen]);

  // useEffect(() => {
  //   if (isOpen) {
  //     fetchData();
  //   }
  // }, [noticeId]);

  // const downloadFile = async (notice_id, hearing_count) => {
  //   if (!notice_id) {
  //     showToast({ variant: "danger", message: "Failed to fetch Notice Id" });
  //   }

  //   const res = await preLitigationDownloadConciliationHearing({
  //     notice_id: noticeId,
  //     hearing_count: hearing_count,
  //   });
  //   if (res.success) {
  //     downloadMediaUrl(res?.data?.pdf_data);
  //   }
  // };

  return (
    <>
      <PiScalesLight onClick={toggle} size={24} style={{ textAlign: "center", marginBottom: "3px", fill: "#599BFF" }} />

      <Offcanvas style={{ width: "28rem", paddingTop: "0.75rem" }} show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100 px-2">
            <div className="fw-600 fs-4">Conciliation </div>{" "}
            <button onClick={toggle} type="button" className="bg-white border-0">
              <IoCloseCircle fill="#999999" size={30} />
            </button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {loading ? (
            <div className="position-relative top-50">
              <ContentLoader />
            </div>
          ) : !details?.conciliation_count || details?.conciliation_count === 0 ? (
            <p className="text-muted m-0 p-5 text-center">No Data Found</p>
          ) : (
            <>
              <div className="px-3 fs-6">
                {[
                  { label: "No. of times Scheduled", value: details?.conciliation_count },
                  { label: "No. of times attended", value: details?.number_of_times },
                  { label: "Disposition", value: details?.last_response?.intention },
                ].map(({ label, value }, index) => (
                  <Fragment key={index}>
                    {(value === 0 || !!value) && (
                      <div className="d-flex justify-content-between gap-3 my-3 fs-6">
                        <p className="mb-0 text-secondary fw-medium">{label}</p>
                        <p className="mb-0 fw-bold text-black">{value}</p>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
              <div className="my-2 px-3 py-2" style={{ borderTop: "1px dashed #ddd" }} />
              {!!details?.concilation_intention_data?.length && (
                <p className="text-black fw-600 px-3 fs-5 m-0">History of Conciliation Hearing {`(${details?.concilation_intention_data?.length})`}</p>
              )}

              {!!details?.concilation_intention_data?.length && (
                <div className="fw-medium fs-6">
                  {(details?.concilation_intention_data ?? []).map((item, index) => (
                    <div key={index} style={{ borderBottom: "1px dashed #ddd" }} className="d-flex gap-3 py-4 px-3">
                      <div
                        style={{
                          textWrap: "nowrap",
                          height: "fit-content",
                        }}
                        className="border rounded d-flex flex-column align-items-center justify-content-center text-black mx-2 p-3"
                      >
                        <span style={{ lineHeight: "1em" }} className="fw-bold fs-5">
                          {item?.intention_date ? format(new Date(item?.intention_date), "dd") : ""}
                        </span>
                        <span className="tx-14"> {item?.intention_date ? format(new Date(item?.intention_date), "MMM yy") : ""}</span>
                      </div>

                      <div>
                        <p className="mb-2 f-2">
                          {item?.notice__customer_name} V/s {item?.notice__lender_name} -{" "}
                          <span className="fw-bold">
                            {index + 1} <span>Hearing</span>
                          </span>
                        </p>
                        <p className="mb-2">
                          Intention Shown : <span className="fw-medium">{item?.intention}</span>
                        </p>
                        <p className="mb-2">
                          Meeting Joined : <span className="fw-medium">{!item?.meeting_joined ? "No" : "Yes"}</span>
                        </p>
                        {!!item?.notice__notice_id && item?.conc_hearing_download_link && (
                          // <button onClick={() => downloadFile(item?.notice__notice_id, index)} className="text-primary fw-medium">
                          //   Download Conciliation Order
                          // </button>
                          <a href={item.conc_hearing_download_link} target="_blank" style={{ color: "#00a6dd" }} className="fw-semibold">
                            {" "}
                            Download Conciliation Order
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
