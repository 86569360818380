import React, { useState, useEffect } from "react";
// import { mappings, toShowParameters, toHideParameters } from "../../../../utils/file_mapping_constants/collection_batch_mapping";
import { labelMapping } from "../../../../utils/fileMappings";
import { postBatchData } from "../../../../API";
// import Excel from "../../../../Static/Images/excel.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useBatchUploadHandler } from "../../../../hooks/batchUploadHandler";
import useMappings from "../../../../hooks/useMappings";
import UploadProgressBar from "../../../../ui/UploadProgressBar";
import BatchUploadHandler from "../../../../ui/BatchUploadHandler";
import ContentLoader from "../../../../Components/ContentLoader";
import useGeneralStore from "../../../../store/generalStore";
import Steps from "../../../../ui/Steps";
import useStep from "../../../../hooks/useStep";
import SelectDropdown from "../../../../ui/forms/SelectDropdown";
import useForm from "../../../../hooks/useForm";
import ExcelFileInput from "../../../../ui/ExcelFileInput";
import { productOptions } from "../../../../utils/constants/products";

const stepValues = {
  DETAILS: "details",
  IMPORT_FILES: "import_files",
  MAP_COULUMNS: "map_columns",
};

const modeOptions = [
  { label: "Digital", value: "digital" },
  { label: "Physical", value: "physical" },
];

const stageOptions = [
  { label: "NPA", value: "NPA" },
  { label: "POT_NPA", value: "POT_NPA" },
  { label: "Charge Off", value: "Charge Off" },
];

const dpdOptions = [
  { label: "0-30", value: "0-30" },
  { label: "31-60", value: "31-60" },
  { label: "61-90", value: "61-90" },
  { label: "91-180", value: "91-180" },
  { label: "180+", value: "180+" },
];

export default function UploadNewBatch({ show, setShow, reload = () => {} }) {
  const showAlert = useGeneralStore((state) => state.open_alert);

  const { updateMappings, getMappings } = useMappings({ type: "collection" });

  // const [importPage, setImportPage] = useState(false);
  // const [IsUploading, setIsUploading] = useState(false);

  const [loading, setLoading] = useState(false);

  const [details, handleDetails, resetDetails] = useForm({
    mode: "",
    product_group: "",
    dpd: "",
  });

  const [showWarningModal, setShowWarningModal] = useState(false);

  const { activeStep, setActiveStep, steps, updateStep, resetSteps } = useStep([
    { title: "Details", completed: false, key: stepValues.DETAILS },
    { title: "Import File", completed: false, key: stepValues.IMPORT_FILES },
    { title: "Map Columns", completed: false, key: stepValues.MAP_COULUMNS },
  ]);

  // batch upload handler hook
  const {
    progressValue,
    importDisabled,
    fileName,
    batchFile,
    matchedHeaderNames,
    handleHeaderChange,
    availableHeaderNames,
    handleFileValidation,
    getUploadHeaders,
    resetBatchUpload,
    SelectSheetModal,
  } = useBatchUploadHandler({ showProgress: true });

  const closeModal = () => {
    setShow(false);
    resetBatchUpload();
    // setImportPage(false);
    setShowWarningModal(false);
    // setIsUploading(false);

    resetSteps();
    setLoading(false);
    resetDetails();
  };

  useEffect(() => {
    if (!!show) getMappings();
  }, [show]);

  //export matched columns
  const exportColumns = async () => {
    // setIsUploading(true);
    setLoading(true);

    let formData = new FormData();
    if (details?.mode) formData.append("mode", details?.mode);
    if (details?.product_group) formData.append("product_group", details?.product_group);
    if (details?.dpd) formData.append("dpd", details?.dpd);
    if (details?.stage) formData.append("stage", details?.stage);
    formData.append("batch_file", batchFile);
    formData.append("batch_name", fileName);
    formData.append("column_rename_data", JSON.stringify(getUploadHeaders()));
    const res = await postBatchData(formData);
    if (res) {
      showAlert({ variant: "success", msg: res?.message || "Imported Successfully" });
      reload();

      await updateMappings(matchedHeaderNames);
      closeModal();
    } else showAlert({ variant: "danger", msg: "Failed to import" });

    // setIsUploading(false);
    setLoading(false);
  };

  useEffect(() => {
    if (progressValue >= 100) {
      setActiveStep(stepValues.MAP_COULUMNS);
      updateStep({ stepKey: stepValues.IMPORT_FILES, completed: true });
    }
  }, [progressValue]);

  return (
    <>
      <Modal show={show} dialogClassName="modal-large" backdrop="static">
        <Modal.Header>
          <Modal.Title>Upload New Batch File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py-4">
            <Steps activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} />
          </div>

          {activeStep === stepValues.DETAILS && (
            <div className="py-5 row justify-content-center" style={{ minHeight: "300px" }}>
              <div className="col-1" />
              <div className="col-4">
                <p className="mb-1">Mode</p>
                <SelectDropdown options={modeOptions} placeholder="Choose Mode" onChange={(val) => handleDetails("mode", val)} value={details?.mode} />
              </div>

              <div className="col-4">
                <p className="mb-1">Product Group</p>
                <SelectDropdown
                  options={productOptions}
                  placeholder="Choose Product Group"
                  onChange={(val) => handleDetails("product_group", val)}
                  value={details?.product_group}
                />
              </div>

              <div className="col-1" />
              <div className="col-4">
                <p className="mb-1">Stage</p>
                <SelectDropdown options={stageOptions} placeholder="Choose Stage" onChange={(val) => handleDetails("stage", val)} value={details?.stage} />
              </div>

              <div className="col-4">
                <p className="mb-1">DPD Group</p>
                <SelectDropdown options={dpdOptions} placeholder="Choose DPD Group" onChange={(val) => handleDetails("dpd", val)} value={details?.dpd} />
              </div>
            </div>
          )}

          {activeStep === stepValues.IMPORT_FILES && (progressValue <= 0 || progressValue >= 100) && (
            <ExcelFileInput
              onChange={(e) => {
                if (e?.target?.files[0]?.size > 1e7) {
                  showAlert({ variant: "danger", msg: "File size should be lesser than 10 MBs!" });
                  return;
                }
                handleFileValidation(e);
              }}
            />
          )}

          {(activeStep === stepValues.IMPORT_FILES || activeStep === stepValues.MAP_COULUMNS) && progressValue > 0 && progressValue < 100 && (
            <UploadProgressBar progressValue={progressValue} />
          )}

          {activeStep === stepValues.MAP_COULUMNS && (
            <div className="py-5">
              <BatchUploadHandler
                availableHeaderNames={availableHeaderNames}
                matchedHeaderNames={matchedHeaderNames}
                labelMapping={labelMapping}
                handleHeaderChange={handleHeaderChange}
                automapping={true}
              />
            </div>
          )}

          {loading && <ContentLoader />}

          {/* {false && (
                        <div className="pt-3 pb-5 text-center upload-batch-popup">
                            {IsUploading && <ContentLoader />}

                            <div className="d-flex import-step align-center content-center">
                                <div className={`import-step content-center ${progressValue < 50 ? "step-active" : "step-completed"}`}>
                                    {progressValue < 100 ? (
                                        "01"
                                    ) : (
                                        <svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17 0C7.626 0 0 7.626 0 17c0 9.374 7.626 17 17 17 9.374 0 17-7.626 17-17 0-9.374-7.626-17-17-17zm8.135 13.995-8.757 8.757a2.118 2.118 0 0 1-1.503.623 2.118 2.118 0 0 1-1.502-.623l-4.508-4.507a2.124 2.124 0 1 1 3.005-3.005l3.005 3.005 7.255-7.255a2.124 2.124 0 1 1 3.005 3.005z"
                                                fill="#409E7B"
                                                fillRule="nonzero"
                                            />
                                        </svg>
                                    )}

                                    <span>Import File</span>
                                </div>
                                <div className="import-step-sep"></div>
                                <div className={`import-step content-center ${progressValue >= 100 && "step-active"}`}>
                                    02
                                    <span>Map Columns</span>
                                </div>
                            </div>

                            {importPage ? (
                                progressValue < 100 ? (
                                    <UploadProgressBar progressValue={progressValue} />
                                ) : (
                                    <BatchUploadHandler
                                        availableHeaderNames={availableHeaderNames}
                                        matchedHeaderNames={matchedHeaderNames}
                                        labelMapping={labelMapping}
                                        handleHeaderChange={handleHeaderChange}
                                        automapping={true}
                                    />
                                )
                            ) : (
                                <>
                                    <div className="import-excel-img mb-2">
                                        <img src={Excel} alt="Excel" />
                                    </div>
                                    <input
                                        type="file"
                                        name="uploadFile"
                                        id="uploadFile"
                                        accept=".xlse,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xlsb,.xml,.xla,.xlw,.xlr"
                                        style={{ opacity: "0" }}
                                        onChange={(e) => {
                                            setImportPage(true);
                                            handleFileValidation(e);
                                        }}
                                    />
                                    <br />
                                    <label htmlFor="uploadFile" className="btn btn-primary btn-lg mb-2">
                                        Select excel file from your computer
                                    </label>
                                    <p>You can upload in Excel or Binary Excel.</p>
                                </>
                            )}
                        </div>
                    )} */}
        </Modal.Body>
        <Modal.Footer>
          {/* {importPage && (
                        <Button
                            variant="primary"
                            disabled={importDisabled || progressValue < 100}
                            className="collection_batch_upload_page_footer_import"
                            onClick={() => exportColumns()}
                        >
                            Import
                        </Button>
                    )} */}
          {activeStep === stepValues.IMPORT_FILES && (
            <Button
              variant="primary"
              onClick={() => {
                setActiveStep(stepValues.DETAILS);
              }}
            >
              Prev - Details
            </Button>
          )}

          <Button variant="secondary" onClick={() => setShowWarningModal(true)}>
            Cancel
          </Button>

          {activeStep === stepValues.DETAILS && (
            <Button
              variant="primary"
              onClick={() => {
                updateStep({ stepKey: stepValues.DETAILS, completed: true });
                setActiveStep(stepValues.IMPORT_FILES);
              }}
            >
              Next - Import File
            </Button>
          )}

          {activeStep === stepValues.MAP_COULUMNS && (
            <Button
              variant="primary"
              disabled={importDisabled || progressValue < 100}
              className="collection_batch_upload_page_footer_import"
              onClick={() => exportColumns()}
            >
              Import
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Choose Sheet Modal */}
      <SelectSheetModal />

      {/* Close Warning Popup */}
      <Modal show={showWarningModal} backdrop="static" dialogClassName="modal-small resetModal">
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tab-m-body language-popup">
          <div className="pt-4 pb-4 ps-3  text-center">The data you filled out will be lost. Are you sure?</div>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => closeModal()}>
              Yes
            </Button>
            <Button variant="secondary" onClick={() => setShowWarningModal(false)}>
              No
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}
