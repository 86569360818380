import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getAlternateCustomerContacts } from "../../../../API/agentApis";
import ContentLoader from "../../../../Components/ContentLoader";
import { isEmpty } from "../../../../Functions/isEmpty";
import { maskMobileNumber } from "../../../../Functions/mask";
import useCallCustomer from "../../../../hooks/useCallCustomer";
import { showToast } from "../../../../utils/handleToast";
import useAuthStore from "../../../../store/authStore";
import useBorrowerInfo from "../store";

export default function CallAlternateNumber() {
    const userData = useAuthStore((state) => state.userData);
    const customerId = useBorrowerInfo((state) => state.customerId);

    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);

    const { callCustomer, callTypes, loading: calling } = useCallCustomer();

    const closeModal = () => {
        setShow(false);
        setSelectedContact("");
        setLoading(false);
    };

    const callAlternateNumber = async () => {
        if (isEmpty(selectedContact?.mobile_number)) {
            showToast({ message: "No Mobile Number Found", variant: "warning" });
            return;
        }

        await callCustomer({ call_type: callTypes?.AGENT, customer_mobile_number: selectedContact?.mobile_number, contact_type: "alternate" });
        closeModal();
    };

    const fetchData = async () => {
        if (!customerId) return;
        setLoading(true);
        const res = await getAlternateCustomerContacts({
            customer_id: customerId,
        });

        if (res) {
            if (res?.data?.length) {
                setDetails(res.data);
            } else setDetails([]);
        } else {
            console.log("Unable to fetch Alternate Contact Number");
            // showToast({message:'Unable to fetch Alternate Contact Number',variant:'danger'})
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!!customerId) fetchData();
        else setDetails([]);
    }, [customerId]);

    return (
        <>
            <a
                onClick={() => setShow(true)}
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "#118CEC" }}
                className="col-6 py-2 fw-medium"
            >
                Call Alternate Numbers
            </a>

            <Modal size="sm" show={show}>
                <Modal.Header>
                    <Modal.Title>Call Alternate Numbers</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ minHeight: "10rem" }}>
                    <div className="popup-content py-3">
                        {!!details?.length && !loading && (
                            <div className="legal-notices-listing">
                                {details?.map((item, index) => (
                                    <div key={index} className="item-field mb-1">
                                        <label style={{ cursor: "pointer" }} className="col-form-label d-flex align-items-center gap-2 tx-16">
                                            <input
                                                className="form-check-input m-0"
                                                style={{ width: "1.2rem", height: "1.2rem" }}
                                                type="radio"
                                                name="alt_contact_customer_radio"
                                                checked={selectedContact?.id === item?.id}
                                                onChange={() => setSelectedContact(item)}
                                            />
                                            {`${!!userData?.remove_masking ? item?.mobile_number : maskMobileNumber(item?.mobile_number)} | ${
                                                item?.name || ""
                                            } | ${item?.reference || ""}`}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        )}
                        {loading && <ContentLoader />}
                        {!loading && !details?.length && <p className="text-center my-5">No Alternate Numbers Found</p>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closeModal()}>
                        Close
                    </Button>
                    <Button disabled={!selectedContact || calling} variant="primary" onClick={() => callAlternateNumber()}>
                        Call
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
