import React from "react";
import classes from "./style.module.css";

export default function Loader() {
    return (
        <div className={classes.loader_wrapper}>
            <svg className={classes.logo} viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42 45L28 76H34L45.5 50.5L42 45Z" fill="#4295B9" />
                <path d="M53 40H47L63.5 76H69.5L53 40Z" fill="#4295B9" />
                <path d="M68 40H62L79 76H85L68 40Z" fill="#479E7B" />
                <path
                    className={classes.inner_circle}
                    d="M91.6898 86.3432C84.0401 95.6761 72.9962 101.588 60.9877 102.778C48.9792 103.968 36.9897 100.339 27.6568 92.6898C18.3239 85.0401 12.4121 73.9962 11.2219 61.9877C10.0316 49.9792 13.6605 37.9897 21.3102 28.6568L24.0466 30.8997C16.9917 39.5069 13.645 50.564 14.7427 61.6388C15.8403 72.7135 21.2925 82.8986 29.8997 89.9534C38.5068 97.0083 49.564 100.355 60.6388 99.2573C71.7135 98.1597 81.8986 92.7075 88.9534 84.1003L91.6898 86.3432Z"
                    fill="#479E7B"
                />
                <path
                    className={classes.outer_circle}
                    d="M97.0301 95.8645C91.7842 101.266 85.5117 105.564 78.5812 108.506C71.6506 111.449 64.2018 112.977 56.6724 113C49.1431 113.023 41.6851 111.541 34.7367 108.64C27.7883 105.74 21.4896 101.48 16.2109 96.1111C10.9322 90.7421 6.77988 84.3721 3.99794 77.3754C1.21601 70.3788 -0.139415 62.8967 0.0113238 55.3689C0.162063 47.841 1.81592 40.4192 4.87572 33.5395C7.93551 26.6599 12.3395 20.4612 17.8289 15.3078L21.3604 19.0695C16.3723 23.7523 12.3705 29.3849 9.59008 35.6363C6.80971 41.8877 5.30688 48.6317 5.1699 55.4722C5.03293 62.3126 6.26458 69.1114 8.79246 75.4691C11.3204 81.8268 15.0935 87.615 19.8902 92.4938C24.6868 97.3726 30.4102 101.243 36.7241 103.879C43.038 106.514 49.8149 107.861 56.6567 107.84C63.4984 107.819 70.267 106.431 76.5647 103.757C82.8624 101.083 88.5621 97.1776 93.3289 92.2697L97.0301 95.8645Z"
                    fill="#4295B9"
                />
            </svg>
        </div>
    );
}
