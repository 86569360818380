import React from "react";
import AudioPlayer from "react-h5-audio-player";
import { HiPause, HiPlay } from "react-icons/hi2";
import "react-h5-audio-player/lib/styles.css";
import "./audio.css";

export default function Audio({ url }) {
    return (
        <div className="whatsapp_preview_custom_audio_player">
            <AudioPlayer
                autoPlayAfterSrcChange={false}
                showJumpControls={false}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                autoPlay={false}
                layout="horizontal-reverse"
                style={{ background: "transparent", border: "none", boxShadow: "none", padding: "0", width: "100%" }}
                src={url}
                customIcons={{
                    play: <HiPlay style={{ fontSize: "1.5rem", color: "#998E8D" }} />,
                    pause: <HiPause style={{ fontSize: "1.5rem", color: "#998E8D" }} />,
                }}
            />
        </div>
    );
}
