import React, { memo, useCallback, useEffect, useState } from "react";
import useAgentStore from "../../store/agent/agentStore";
import useAuthStore from "../../store/authStore";
import { getAgentLeadCounts } from "../../apis";
import { IoRefresh } from "react-icons/io5";

function LeadCount() {
    const [loading, setLoading] = useState(false);
    const userData = useAuthStore((state) => state.userData);
    const addLeadCounts = useAgentStore((state) => state.addLeadCounts);

    const fetchLeadCounts = useCallback(async () => {
        setLoading(true);
        const res = await getAgentLeadCounts({ user_id: userData?.user_id });
        if (res.success) addLeadCounts(res.data);
        setLoading(false);
    }, [userData?.user_id]);

    useEffect(() => {
        fetchLeadCounts();
    }, []);

    return (
        <button
            style={{ background: "transparent", fontSize: "10px" }}
            disabled={loading}
            onClick={fetchLeadCounts}
            className="mt-1 d-flex align-items-center justify-content-center gap-2 p-2 border-0 w-100"
        >
            <IoRefresh className="tx-16 m-0" />
            {loading ? "Loading..." : "Refresh"}
        </button>
    );
}

export default memo(LeadCount);
