import React, { useRef, useState } from "react";
import loginImage from "../../Static/RawImages/login.png";
import mediusLogo from "../../Static/RawImages/medius-logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useAuthStore from "../../store/authStore";
import LandingPageRedirect from "../Components/LandingPageRedirect";
import { login } from "../../API/commonApis";

export default function Login() {
    const createUser = useAuthStore((state) => state.createUser);
    const addFeatures = useAuthStore((state) => state.addFeatures);
    const removeUser = useAuthStore((state) => state.removeUser);
    const isUserAuthenticated = useAuthStore((state) => state.isAuthenticated);

    const email = useRef(null);
    const password = useRef(null);
    const [loginErrLog, setLoginErrLog] = useState([]);
    const [loginErrLog1, setLoginErrLog1] = useState([]);

    const handelSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoginErrLog([]);
            setLoginErrLog1([]);

            if (email.current.value && password.current.value) {
                localStorage.clear();
                const formData = new FormData();
                formData.append("email", email.current.value?.toLowerCase()?.trim());
                formData.append("password", password.current.value);

                const res = await login(formData);

                let data = res.data;
                let message = res.message;

                if (res === "error") {
                    toast.error(" Invalid Credentials");
                }
                if (message === "Incorrect email ID.") {
                    setLoginErrLog(["Email Is Invalid"]);
                }
                if (message === "Incorrect password.") {
                    setLoginErrLog1(["Password Is Invalid"]);
                }
                if(!data){
                    setLoginErrLog1([message?? ""]);
                }
                if (data) {
                    if (data.role === "client") {
                        removeUser();
                        let userDetails = !!data?.is_superadmin
                            ? {
                                  email: data?.email,
                                  is_superadmin: !!data?.is_superadmin,
                                  full_name: data?.full_name,
                                  user_id: data?.user_id,
                                  client_id: data?.active_client?.client_id,
                                  logo_url: data?.active_client?.logo_url,
                                  active_client: data?.active_client,
                                  settings: { ...data?.settings },
                              }
                            : {
                                  email: data?.email,
                                  full_name: data?.full_name,
                                  user_id: data?.user_id,
                                  client_id: data?.active_client?.client_id,
                                  logo_url: data?.active_client?.logo_url,
                                  active_client: data?.active_client,
                                  settings: { ...data?.settings },
                              };

                        addFeatures(data?.features || []);
                        createUser({
                            token: data?.token,
                            role: "client",
                            userData: { ...userDetails },
                        });
                    } else {
                        setLoginErrLog(["Not A Client's Email"]);
                    }
                }
            } else {
                if (!email.current.value) {
                    setLoginErrLog(["Email Is Blank"]);
                }
                if (!password.current.value) {
                    setLoginErrLog1(["Password Is Blank"]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (isUserAuthenticated) return <LandingPageRedirect />;

    return (
        <div style={{ backgroundImage: `url(${loginImage})` }} className="login">
            <div className="container">
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick />
                <div className="row justify-content-end">
                    <div className="col-sm-6 col-md-5 col-lg-4">
                        <form onSubmit={handelSubmit}>
                            <div className="login-logo">
                                <img src={mediusLogo} alt="loginlogo" />
                            </div>

                            <div className="mb-3 row">
                                <label htmlFor="login_email" className="col-sm-12 col-form-label">
                                    Email Id
                                </label>
                                <div className="col-sm-12">
                                    <input ref={email} type="text" autoComplete="off" name="" id="login_email" className="form-control" />
                                </div>
                                {loginErrLog.length > 0 && (
                                    <div style={{ fontSize: 16 }} role="alert">
                                        <div className="text-white">{loginErrLog ? loginErrLog[0] : ""}</div>
                                    </div>
                                )}
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="login_password" className="col-sm-12 col-form-label">
                                    Password
                                </label>
                                <div className="col-sm-12">
                                    <input ref={password} type="password" autoComplete="off" name="" id="login_password" className="form-control" />
                                </div>
                                {loginErrLog1.length > 0 && (
                                    <div style={{ fontSize: 16 }} role="alert">
                                        <div className="text-white">{loginErrLog1 ? loginErrLog1[0] : ""}</div>
                                    </div>
                                )}
                            </div>
                            <div className="mb-0 row">
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-primary w-100 mt-2">
                                        Login
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
