import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import CollectionAccountDetail from "../Pages/Collection/CollectionAccountDetail";
import CollectionBatch from "./Pages/Collection/Batch";
import CollectionAccount from "./Pages/Collection/Accounts";
import DailyReport from "./Pages/Collection/DailyReport";
import LitigationAdvocates from "../Pages/Litigation/LitigationAdvocates";
import LitigationCalender from "../Pages/Litigation/LitigationCalender";
import LitigationCase from "../Pages/Litigation/LitigationCase";
import PreLitigationBatch from "./Pages/Prelitigation/LegalNotices/Batch";
import PreLitigationComplaints from "./Pages/Prelitigation/Fir/Complaints";
import PreLitigationNotices from "./Pages/Prelitigation/LegalNotices/Notices";
import Login from "./Pages/Login";
import Reports from "./Pages/AllFiles/Reports";
import AllDownloads from "./Pages/AllFiles/AllDownloads";
import Dashboard from "./Pages/Dashboard";
import PreLitigationFir from "./Pages/Prelitigation/Fir/Batch";
import useGeneralStore from "../store/generalStore";
import Sidebar from "./Components/Sidebar";
import Header from "./Components/Header";
import SetlTracker from "./Pages/Prelitigation/LegalNotices/SetlTracker";
import DailySettlementReport from "./Pages/DailySettlementReport";
import useAuthStore from "../store/authStore";
import { getClientFeature } from "../API";
import ProtectedRoute from "./ProtectedRoute";

const PanelRoutes = () => {
    const userData = useAuthStore((state) => state.userData);
    const addFeatures = useAuthStore((state) => state.addFeatures);
    const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);

    const fetchUserFeatures = async () => {
        const res = await getClientFeature({ client_id: userData?.client_id });
        if (res?.data) addFeatures(res?.data?.map((el) => el?.feature) || []);
    };

    useEffect(() => {
        if (!!userData?.client_id) fetchUserFeatures();
    }, [userData]);

    return (
        <>
            <Route exact path="/login" component={Login} />

            <aside className={`${isSidebarOpen ? "sidebar" : "sidebar sidebar-hide"}`}>
                <Sidebar />
            </aside>
            <section className={`${isSidebarOpen ? "content" : "content content-full"}`}>
                <Header />
                <div className="content-part">
                    <ProtectedRoute exact path="/" component={Dashboard} />

                    <ProtectedRoute exact path="/collection" component={<Redirect to="/collection/batch" />} />

                    <ProtectedRoute exact path="/collection/batch" component={CollectionBatch} />
                    <ProtectedRoute exact path="/collection/accounts/:id?" component={CollectionAccount} />


                    <ProtectedRoute exact path="/collection/account/:id" component={CollectionAccountDetail} />
                    <ProtectedRoute exact path="/collection/dailyreport" component={DailyReport} />
                    <ProtectedRoute exact path="/collection/settlement-tracker" component={SetlTracker} />
                    <ProtectedRoute exact path="/collection/daily-settlement-report" component={DailySettlementReport} />

                    <ProtectedRoute exact path="/prelitigation/legal" component={<Redirect to="/prelitigation/legal/batch" />} />

                    <ProtectedRoute exact path="/prelitigation/legal/batch" component={PreLitigationBatch} />
                    <ProtectedRoute exact path="/prelitigation/legal/batch/:id" component={PreLitigationBatch} />
                    <ProtectedRoute exact path="/prelitigation/legal/notices" component={PreLitigationNotices} />
                    <ProtectedRoute exact path="/prelitigation/legal/notices/:id" component={PreLitigationNotices} />
                    <ProtectedRoute exact path="/prelitigation/fir/batch" component={PreLitigationFir} />
                    <ProtectedRoute exact path="/prelitigation/fir/complaints" component={PreLitigationComplaints} />
                    <ProtectedRoute exact path="/prelitigation/fir/complaints/:id" component={PreLitigationComplaints} />

                    <ProtectedRoute exact path="/litigation" component={<Redirect to="/litigation/batch" />} />

                    <ProtectedRoute exact path="/litigation/case" component={LitigationCase} />
                    <ProtectedRoute exact path="/litigation/advocates" component={LitigationAdvocates} />
                    <ProtectedRoute exact path="/litigation/calendar" component={LitigationCalender} />

                    <ProtectedRoute exact path="/all-files/reports" component={Reports} />
                    <ProtectedRoute exact path="/all-files/all-downloads" component={AllDownloads} />
                </div>
            </section>
        </>
    );
};

export default React.memo(PanelRoutes);
// export default PanelRoutes
