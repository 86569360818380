import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { toast } from "react-toastify";
import { getNoticeTypes, legalNoticeBatchUpload } from "../../../../API/panelApis";
import Excel from "../../../../Static/Images/excel.svg";
import useGeneralStore from "../../../../store/generalStore";
import SelectDropdown from "../../../../ui/forms/SelectDropdown";

const commModeOptions = [
    { label: "Postal", value: "postal" },
    { label: "Whatsapp", value: "whatsapp" },
    { label: "Email", value: "email" },
    { label: "SMS", value: "sms" },
];

export default function BatchOnlyUploadModal({ show, setShow, getData = () => {} }) {
    const [batchFile, setBatchFile] = useState(null);
    const [communicationMode, setCommunicationMode] = useState([]);
    const [noticeType, setNoticeType] = useState("");
    const [noticeTypeOptions, setNoticeTypeOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const showAlert = useGeneralStore((state) => state.open_alert);

    const closeModal = () => {
        setShow(false);
        setBatchFile(null);
        setCommunicationMode([]);
        setNoticeType("");
    };

    const uploadBatch = async () => {
        setLoading(true);
        let formData = new FormData();
        formData.append("file", batchFile);
        formData.append("notice_type", noticeType);
        formData.append("communication_mode", JSON.stringify(communicationMode));

        try {
            const res = await legalNoticeBatchUpload(formData);
            if (res) {
                showAlert({
                    variant: "success",
                    msg: res?.message,
                });
                closeModal();
                getData();
            }
        } catch (error) {
            showAlert({
                variant: "danger",
                msg: "Sending failed",
            });
            console.log(error);
        }

        setLoading(false);
    };

    function handleFileValidation(e) {
        let filePath = e.target.value;
        let file = e.target.files[0];
        let allowedExtensions = /(\.xlse|\.xlsx|\.xlsm|\.xlsb|\.xltx|\.xltm|\.xls|\.xlt|\.xls|\.xlsb|\.xml|\.xla|\.xlw|\.xlr)$/i;
        if (!allowedExtensions.exec(filePath)) {
            toast.error("Please upload file having extensions Excel or Binary Excel.");
            return false;
        } else setBatchFile(file);
    }

    const fetchNoticeTypes = async () => {
        try {
            const res = await getNoticeTypes();
            if (res) setNoticeTypeOptions(res?.data?.map((el) => ({ label: el?.name, value: el?.type })));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchNoticeTypes();
    }, []);

    return (
        <>
            <Modal size="lg" show={show} onHide={() => closeModal()} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Upload New Batch File</Modal.Title>
                </Modal.Header>
                <Modal.Body className="tab-m-body">
                    <div className="pb-5 pt-3 d-flex flex-column align-items-center justify-content-center gap-3">
                        <div className="import-excel-img mb-2">
                            <img src={Excel} alt="Excel" />
                        </div>

                        <div className="w-50">
                            <p className="text-center">You can upload CSV or XLS or Binary file.</p>
                            <input
                                type="file"
                                name="uploadFile"
                                id="uploadFile"
                                accept=".xlse,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xlsb,.xml,.xla,.xlw,.xlr"
                                // style={{ opacity: "0" }}
                                className="form-control"
                                onChange={handleFileValidation}
                            />
                        </div>

                        <div className="w-50">
                            <p className="text-left mb-1">Type of Notice to be Issued</p>
                            <SelectDropdown options={noticeTypeOptions} placeholder="Select Notice Type" value={noticeType} onChange={setNoticeType} />
                        </div>

                        <div className="w-50">
                            <p className="text-left mb-1">Communication Mode</p>
                            <MultiSelect
                                className="bg-light-grey text-dark"
                                options={commModeOptions}
                                value={commModeOptions?.filter((el) => communicationMode?.includes(el?.value))}
                                onChange={(val) => setCommunicationMode(val?.map((el) => el.value))}
                                labelledBy="Select Communication Mode"
                            />
                        </div>

                        <button
                            className="btn btn-primary w-50 btn-lg mt-2"
                            disabled={!batchFile || !communicationMode || !noticeType || loading}
                            onClick={() => uploadBatch()}
                        >
                            {loading ? "Uploading..." : "Upload Batch"}
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" disabled={loading} onClick={() => closeModal()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
