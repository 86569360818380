import { useD3 } from "./Utils/useD3";
import { barChartD3 } from "./Utils/barChartD3";
import React from "react";

function BarChart({ data, scale, width, height }) {
    const ref = useD3(
        (svg) => {
            svg.selectAll("*").remove();
            barChartD3(data, svg, width || 500, scale || 1.2);
        },
        [data.data.length]
    );

    let heightSvg = height ? height : 370;

    return (
        <div
            className="container-bar"
            style={{
                fontFamily: "Open Sans, sans-serif",
                height: heightSvg,
                width: "100%",
                display: "flex",
            }}
        >
            <svg ref={ref}></svg>
            <div className="tooltip-bar-radial-chart"></div>
        </div>
    );
}

export default BarChart;
