import useBorrowerInfo from "./store";
import useGeneralStore from "../../../store/generalStore";
import { BsChevronLeft } from "react-icons/bs";
import Profile from "./components/profile";
import LegalAction from "./components/legal-action";
import Addresses from "./components/addresses";
import { maskString } from "../../../Functions/mask";
import useAuthStore from "../../../store/authStore";
import { isEmpty } from "../../../Functions/isEmpty";
import CallButton from "../../Components/CallButton";
import AddDisposition from "./components/add-disposition";
import OtherActions from "./components/other-actions";
import Analysis from "./components/analysis";
import LoanInfo from "./components/loan-info";
import AmountPaid from "../../Components/CustomerDetails/AmountPaid";
import { downloadMediaUrl } from "../../../API";

export default function BorrowerInfo() {
  const isOpen = useBorrowerInfo((state) => state.isOpen);
  const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);
  const userData = useAuthStore((state) => state.userData);

  const close = useBorrowerInfo((state) => state.closeBorrowerInfo);
  const data = useBorrowerInfo((state) => state.data);
  const loading = useBorrowerInfo((state) => state.isLoading);
  const borrowerDetails = data?.data;

  return (
    <div
      style={{
        inset: "0",
        zIndex: "50",
        paddingTop: "72px",
        paddingLeft: isSidebarOpen ? "178px" : "62px",
        transition: "all ease 0.5s",
        ...(isOpen
          ? { transform: "translateX(0)", opacity: "1", pointerEvents: "auto" }
          : { transform: "translateX(100%)", opacity: "0", pointerEvents: "none" }),
      }}
      className="bg-white position-fixed overflow-x-hidden overflow-y-auto"
    >
      <div className="position-sticky top-0 py-2 px-3 bg-white">
        <button onClick={close} className="btn btn-secondary ps-2 pe-4 d-flex align-items-center">
          <BsChevronLeft /> Back
        </button>
      </div>

      {loading && <p className="text-center p-5 text-muted">Loading...</p>}

      {!loading && (
        <div className="row p-3">
          <div className="col-8 row">
            <div className="col-6 pe-4">
              <div className="d-flex gap-3 mb-2">
                <div className="d-flex flex-column align-items-center gap-1">
                  <div
                    style={{
                      width: "3rem",
                      height: "3rem",
                      background: "#eee",
                    }}
                    className="rounded-circle border overflow-hidden"
                  >
                    {!!data?.client_logo && <img className="w-100 h-100 object-fit-cover" src={data?.client_logo} alt={data?.client_name ?? "Bank"} />}
                  </div>
                  <p style={{ width: "4rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} className="m-0 tx-12 fw-bold">
                    {data?.client_name ?? ""}
                  </p>
                </div>
                <div>
                  <p className="mb-0 fw-bold text-black tx-16">{borrowerDetails?.customer_name ?? ""}</p>
                  <p className="mb-0"> {!!userData?.remove_masking ? borrowerDetails?.loan_account_no : maskString(borrowerDetails?.loan_account_no)}</p>
                </div>
              </div>

              <div>
                {!isEmpty(borrowerDetails?.current_bucket) && <p className="mb-0">Bucket : {borrowerDetails?.current_bucket}</p>}
                {!isEmpty(borrowerDetails?.case_allocated_date) && <p className="mb-0">Case Allocated Date - {borrowerDetails?.case_allocated_date}</p>}
                {!isEmpty(borrowerDetails?.co_applicant_name) && <p className="mb-0">Co Applicant Name - {borrowerDetails?.co_applicant_name}</p>}
                {!isEmpty(borrowerDetails?.co_applicant_name2) && <p className="mb-0">Co Applicant Name 2 - {borrowerDetails?.co_applicant_name2}</p>}
                <AmountPaid data={borrowerDetails} type={false} />
                {!isEmpty(borrowerDetails?.settlement_letter_url) && (
                  <p className="mb-0">
                    Settlement Letter -{" "}
                    <a target="_blank" rel="noreferrer" href={downloadMediaUrl(borrowerDetails?.settlement_letter_url)}>
                      Download
                    </a>
                  </p>
                )}
                {!isEmpty(borrowerDetails?.letter_issue_date) && <p className="mb-0">Setl Letter Issued on - {borrowerDetails?.letter_issue_date}</p>}
                {!isEmpty(borrowerDetails?.email) && <p className="mb-0">Email - {borrowerDetails?.email}</p>}
              </div>

              <div
                style={{ borderTop: "1px dashed #ddd", borderBottom: "1px dashed #ddd" }}
                className="d-flex align-items-center justify-content-between gap-3 my-3 py-1"
              >
                <Profile />
                <LegalAction />
                <Addresses />
              </div>

              {/* <div className="d-flex align-items-center justify-content-between gap-2 my-3 py-1 col-md-12">
                <div className="gap-3 mb-3  col-md-6">
                  <AmountPaid data={borrowerDetails} />
                </div>
                {isEmpty(borrowerDetails?.settlement_letter_url) && (
                  <div className="gap-3 mb-3 col-md-6">
                    <span className="item-cl-left text-16 cl-gray-01">Settlement Letter</span>
                    <span className="item-cl-right text-16 cl-black">
                      <a target="_blank" rel="noreferrer" href={downloadMediaUrl(borrowerDetails?.settlement_letter_url)}>
                        Download
                      </a>
                    </span>
                  </div>
                )}

                {!isEmpty(borrowerDetails?.letter_issue_date) && (
                  <div className="gap-3 mb-3  col-md-6">
                    <span className="item-cl-left text-16 cl-gray-01">Setl Letter Issued on</span>
                    <span className="item-cl-right text-16 cl-black"> {borrowerDetails?.letter_issue_date}</span>
                  </div>
                )}
              </div> */}

              <div className="row">
                <div className="col-6 d-flex gap-1 align-items-center flex-column">
                  <CallButton customer_mobile_number={borrowerDetails?.customer_mobile_number ?? ""} styles={{ width: "100%" }} />
                  <em className="tx-12">{data?.last_call_disposition ?? ""}</em>
                </div>
                <div className="col-6 d-flex gap-1 align-items-center flex-column">
                  <button className="w-100 btn btn-primary" disabled>
                    Whatsapp
                  </button>
                  <em className="tx-12">{data?.last_whatsapp_status ?? ""}</em>
                </div>
              </div>
            </div>
            <div style={{ borderLeft: "1px dashed #ddd" }} className="col-6 pl-3">
              <LoanInfo />
            </div>

            <div className="col-12 py-4" />

            <Analysis info={data} />
          </div>

          <div className="col-4 bg-light rounded p-3">
            <div className="bg-white h-100 rounded p-3 d-flex flex-column">
              <AddDisposition />
              <div className="h-0 w-full my-4" style={{ borderTop: "1px dashed #ddd" }} />
              <OtherActions />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
