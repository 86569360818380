import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import useAuthStore from "../../store/authStore";
import { getPageTitle } from "./new-sidebar/data";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { BsCommand } from "react-icons/bs";
import { logout } from "../../API/commonApis";

const searchTrigger = () => {
    var ctrlKEvent = new KeyboardEvent("keydown", {
        key: "k",
        ctrlKey: true,
        metaKey: true,
    });

    document.dispatchEvent(ctrlKEvent);
};

function AdminHeader() {
    const removeUser = useAuthStore((state) => state.removeUser);

    const location = useLocation();

    const pageTitle = useMemo(() => getPageTitle(location.pathname), [location.pathname]);

    const logoutUser = () => {
        logout();
        removeUser();
    };

    return (
        <header
            className="d-flex align-center justify-between ps-3 pe-2 py-1 w-100 gap-2 gap-md-4"
            style={{
                position: "sticky",
                height: "4rem",
                top: "0",
                left: "0",
                background: "#f4f6f8",
                transition: "all ease 0.5s",
                zIndex: "999",
                flexWrap: "nowrap",
            }}
        >
            <h1 style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{pageTitle}</h1>

            <div className="d-flex align-items-center justify-content-end gap-4">
                <button onClick={searchTrigger} className="d-none d-md-flex align-items-center gap-2 bg-white btn border rounded px-3 py-2">
                    <HiOutlineMagnifyingGlass className="tx-16 m-0" />
                    <span style={{ fontWeight: "500" }} className="text-muted">
                        Quick Search...
                    </span>
                    <span style={{ fontWeight: "600" }} className="d-flex align-items-center gap-1 tx-12 ms-2">
                        <BsCommand className="m-0" /> + k
                    </span>
                </button>
                <Dropdown>
                    <Dropdown.Toggle style={{ width: "2.8rem", height: "2.8rem" }} className="bg-transparent border-0 p-0">
                        <div
                            style={{ width: "2.8rem", height: "2.8rem" }}
                            className="rounded-circle bg-white p-2 flex-shrink-0 d-flex align-items-center justify-content-center"
                        >
                            <svg className="w-100 object-fit-contain m-0" viewBox="0 0 162 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M69.9597 0.20166L53.629 0.403273L100.806 99.3952H117.54L69.9597 0.20166Z" fill="#3296BB" />
                                <path d="M41.129 13.5081L0 99.7984H16.7339L50 29.8388L41.129 13.5081Z" fill="#3296BB" />
                                <path d="M113.911 0L97.5806 0.201613L144.96 100H161.492L113.911 0Z" fill="#3F9E7B" />
                            </svg>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </header>
    );
}

export default AdminHeader;
