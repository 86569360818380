import React from "react";
import DateSelector from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePicker({ disabled = false, value, onChange, label = "Date", minDate = null, maxDate = null }) {
  return (
    <DateSelector
      selected={value}
      onChange={onChange}
      dateFormat="dd / MM / yyyy"
      className="form-control"
      minDate={!!minDate ? new Date(minDate) : null}
      maxDate={!!maxDate ? new Date(maxDate) : null}
      placeholderText={label}
      disabled={disabled}
      showYearDropdown
      todayButton="Today"
      wrapperClassName="w-100"
      popperPlacement="top-start"
    />
  );
}
