import React, { useState, useEffect, useMemo } from "react";
import EllipseVerticleIcon from "../../../Static/RawImages/ellipsis-vertical-icon.svg";
import NoRecord from "../../../Components/NoRecord";
import ContentLoader from "../../../Components/ContentLoader";
import { getAgentBrokenPtpLeads } from "../../../API/agentApis";
import useAuthStore from "../../../store/authStore";
import useGeneralStore from "../../../store/generalStore";
import DatePicker from "../../../ui/forms/DatePicker";
import Paging from "../../../Components/Paging";
import { format, parse } from "date-fns";
import CallButton from "../../Components/CallButton";
import useAgentStore from "../../../store/agent/agentStore";
import { findSourceOfLead } from "../../../utils/findSourceOfLead";
import useForm from "../../../hooks/useForm";
import BorrowerSearch from "../../Components/BorrowerSearch";
import CustomerDetails from "../../Components/CustomerDetails";
import IncomingCall from "../../Components/IncomingCall";
import { commaFormat } from "../../../Functions/formatNumber";
import useBorrowerInfo from "../../features/borrower-info/store";

export const dispositionOptions = [
  { label: "Wrong Number (WN)", value: "WN" },
  { label: "Switched Off (SW)", value: "SW" },
  { label: "Ringing, No Response (RNR)", value: "RNR" },
  { label: "Do Not Disturb (DND)", value: "DND" },
  { label: "Promise To Pay (PTP)", value: "PTP" },
  { label: "Broken PTP (BPTP)", value: "BPTP" },
  { label: "Dispute (DIS)", value: "DIS" },
  { label: "Surrender (SUR)", value: "SUR" },
  { label: "Fully Paid (PAID)", value: "PAID" },
  { label: "Partial Paid (P)-PAID", value: "P-PAID" },
  { label: "Call Back (CB)", value: "CB" },
  { label: "Want Settlement (SETL)", value: "SETL" },
  { label: "Language Barrier (LANG)", value: "LANG" },
  { label: "Refuse To Pay (RTP)", value: "RTP" },
  { label: "Deceased (DECEASED)", value: "DECEASED" },
  { value: "SUR", label: "Surrender (SUR)" },
  { value: "VEHICLE_LOST", label: "VEHICLE LOST (VEHICLE_LOST)" },
  { value: "VEHICLE_ALREADY_SURRENDERED", label: "VEHICLE ALREADY SURRENDERED" },
  { value: "READY_TO_SURRENDER_VEHICLE", label: "READY TO SURRENDER VEHICLE" },
];

const LIMIT_PER_PAGE = 20;

function BrokenPTP() {
  const userData = useAuthStore((state) => state.userData);
  const showAlert = useGeneralStore((state) => state.open_alert);
  const addCustomerSourceOfLeadData = useAgentStore((state) => state.addCustomerSourceOfLeadData);
  const addCustomerId = useAgentStore((state) => state.addCustomerId);
  const reloadFlag = useAgentStore((state) => state.reload_now);
  const toggleLeadCountFetchFlag = useAgentStore((state) => state.toggleLeadCountFetchFlag);
  const openBorrowerInfo = useBorrowerInfo((state) => state.openBorrowerInfo);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState("NaN");

  const [filters, handleFilters, resetFilters] = useForm({
    sort_by: "",
    borrower: "",
    date: null,
    last_disposition: "",
    client_id: "",
  });

  const handlePagination = (type, target) => {
    if (type === "directValue") setCurrentPage(parseInt(target));
  };

  const fetchData = async () => {
    setLoading(true);

    const res = await getAgentBrokenPtpLeads({
      user_id: userData?.user_id,
      page: currentPage,
      ...filters,
      sort_by: !!filters?.sort_by ? filters?.sort_by : null,
      date: !!filters?.date ? format(new Date(filters?.date), "yyyy-MM-dd") : null,
    });
    if (res) {
      setPageTotal(Math.ceil(res?.total / LIMIT_PER_PAGE));
      setData(res?.data);
    } else {
      showAlert({ variant: "danger", msg: "Unable to fetch Data" });
    }
    setLoading(false);
    toggleLeadCountFetchFlag();
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, filters, reloadFlag]);

  useEffect(() => {
    resetFilters();
  }, []);

  const lenderOptions = useMemo(() => {
    const opts = !!userData?.clients?.length ? userData?.clients?.map((el) => ({ label: el?.client_name, value: el?.client_id })) : [];
    return [{ label: "Choose", value: "" }, ...opts];
  }, [userData]);

  return (
    <>
      <div className="pageContent imp-lead-layout">
        <div className="imp-leads-content mt-0">
          <div className="table-scroll" style={{ height: "100%", minHeight: "30rem" }}>
            <table className="agent-filter-table">
              <thead>
                <tr>
                  <th className="align-top">
                    <BorrowerSearch value={filters?.borrower} onChange={(val) => handleFilters("borrower", val)} />
                  </th>

                  <th className="align-top">
                    <div className="filter-label">Total Oustanding</div>
                    <select className="form-select" value={filters?.sort_by} onChange={(e) => handleFilters("sort_by", e.target.value)}>
                      {[
                        { label: "Choose", value: "" },
                        { label: "Highest to Lowest", value: "desc" },
                        { label: "Lowest to Highest", value: "asc" },
                      ]?.map(({ label, value }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>

                  <th className="align-top">
                    <div className="filter-label">Last Disposition</div>
                    <select className="form-select" value={filters?.last_disposition} onChange={(e) => handleFilters("last_disposition", e.target.value)}>
                      {dispositionOptions.map(({ label, value }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>

                  <th className="align-top">
                    <div className="filter-label">Date</div>
                    <DatePicker label="Choose" value={filters?.date} onChange={(val) => handleFilters("date", val)} />
                  </th>

                  <th className="align-top">
                    <div className="filter-label">Lender Name</div>
                    <select className="form-select" value={filters?.client_id} onChange={(e) => handleFilters("client_id", e.target.value)}>
                      {lenderOptions?.map(({ label, value }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  !!data?.length &&
                  data?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="item-col">
                          <div className="item-text item-name">
                            <a
                              onClick={() => {
                                if (!item?.customer) {
                                  showAlert({ variant: "danger", msg: "Customer Id not Found" });
                                  return;
                                }
                                addCustomerSourceOfLeadData(findSourceOfLead(item?.source_of_lead));
                                openBorrowerInfo(item?.customer);
                              }}
                            >
                              {item?.customer_name || "----"}
                            </a>
                          </div>
                          <div className="item-state item-label">
                            {item?.state || "----"} ({item?.city || "----"})
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="item-col">
                          <div className="item-text item-price">
                            <span className="me-1 tx-16">{`₹`}</span>
                            {commaFormat(item?.pos)}
                          </div>
                          <div className="bank-name item-label">{item?.client_name || "----"}</div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <p className="mb-0">{findSourceOfLead(item?.source_of_lead) || "----"}</p>
                      </td>
                      <td className="align-middle">
                        <p className="mb-0">{!!item?.ptp_date ? format(new Date(item?.ptp_date), "dd MMM yy") : "-- --- --"}</p>
                      </td>
                      <td>
                        <div className="item-col item-col-btn">
                          <div className="item-btn">
                            <CallButton customer_mobile_number={item?.mobile_number || ""} />
                            <span>{item?.last_call_disposition || "----"}</span>
                          </div>
                          <div className="item-btn">
                            <a className={`site-btn blue-border-btn ${!item?.mobile_number && "disabled-btn"}`} href="#">
                              WhatsApp
                            </a>
                            <span>{item?.last_whatsapp_status || "----"}</span>
                          </div>
                          <div className="dropdown verticle-ellipse">
                            <a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src={EllipseVerticleIcon} />
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <button
                                  disabled={!item?.customer}
                                  onClick={() => {
                                    openBorrowerInfo(item?.customer);
                                  }}
                                  className="dropdown-item"
                                >
                                  Add Disposition
                                </button>
                              </li>
                              <li>
                                <button className="dropdown-item">Send Information</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {!loading && !!data?.length && (
          <div className="pb-3 mt-4">
            <Paging currentPage={currentPage} pageTotal={pageTotal} handlePagination={handlePagination} />
          </div>
        )}
      </div>

      {!loading && !data?.length && <NoRecord />}
      {loading && <ContentLoader />}

      <IncomingCall />
      <CustomerDetails />
    </>
  );
}

export default BrokenPTP;
