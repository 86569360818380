import React, { useState, useEffect, Fragment } from "react";
import UserIcon from "../../Static/RawImages/login.png";
import { fetchAccountData } from "../../API/agentApis";
import useGeneralStore from "../../store/generalStore";
import { format, parse } from "date-fns";
import CallButton from "./CallButton";
import ContentLoader from "../../Components/ContentLoader";
import NoRecord from "../../Components/NoRecord";
import { IconVariant } from "./iconVariants";
import AddDispForm from "./CustomerDetails/AddDispForm";
import IssueLegalNotice from "./CustomerDetails/IssueLegalNotice";
import FirCopy from "./CustomerDetails/FirCopy";
import PaymentLinkModal from "./CustomerDetails/PaymentLinkModal";
import useAgentStore from "../../store/agent/agentStore";
import WhatsappButton from "./WhatsappButton";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { isEmpty } from "../../Functions/isEmpty";
import { copyTextToClipboard } from "../../Functions/copyToClipboard";
import { toast } from "react-toastify";
import CallHistory from "./CustomerDetails/CallHistory";
import CallAlternateNumber from "./CustomerDetails/CallAlternateNumber";
import DispositionHistory from "./DispositionHistory";
import { commaFormat } from "../../Functions/formatNumber";
import { maskEmail, maskMobileNumber, maskString } from "../../Functions/mask";
import SendVerificationMsg from "./CustomerDetails/SendVerificationMsg";
import SendReminderToLender from "./CustomerDetails/SendReminderToLender";
import SendPaymentFollowup from "./CustomerDetails/SendPaymentFollowup";
import useAuthStore from "../../store/authStore";
import EmailHistory from "../../Components/EmailHistory";
import SmsHistory from "../../Components/SmsHistory";
import AddAlternateContact from "./CustomerDetails/AddAlternateContact";
import AmountPaid from "./CustomerDetails/AmountPaid";
import { downloadMediaUrl } from "../../API";
import WhatsappMsg from "../../Components/WhatsappMsg";

// SETTLEMENT PROCESS
export const settlementProcess = Object.freeze({
  INTENTION_RECEIVED: "intention_received",
  CONFIRMATION_RECEIVED: "confirmation_received",
  LETTER_RECEIVED: "letter_received",
});

function checkValidNumber(num) {
  if (isEmpty(num) || num <= 0 || isNaN(num)) return false;
  return true;
}

export default function CustomerDetails() {
  const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);
  const showAlert = useGeneralStore((state) => state.open_alert);
  const customerSourceOfLead = useAgentStore((state) => state.customer_source_of_lead);
  const removeCustomerSourceOfLeadData = useAgentStore((state) => state.removeCustomerSourceOfLeadData);
  const customerId = useAgentStore((state) => state.customer_id);
  const removeCustomerId = useAgentStore((state) => state.removeCustomerId);
  const userData = useAuthStore((state) => state.userData);

  const [show, setShow] = useState(false);
  const [showDispHistory, setShowDispHistory] = useState(false);
  const [showWhatsappHistory, setShowWhatsappHistory] = useState(false);
  const [showSmsHistory, setShowSmsHistory] = useState(false);
  const [showCallHistory, setShowCallHistory] = useState(false);
  const [showEmailHistory, setShowEmailHistory] = useState(false);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    if (!customerId) {
      showAlert({ variant: "danger", msg: "No customer id Found" });
      return;
    }
    setLoading(true);
    const res = await fetchAccountData({ customer_id: customerId });

    if (res) {
      setData(res);
    } else showAlert({ variant: "danger", msg: "Unable to fetch Customer Details" });

    setLoading(false);
  };

  useEffect(() => {
    if (!!customerId) {
      setShow(true);
      fetchData();
    } else setShow(false);
  }, [customerId]);

  useEffect(() => {
    return () => {
      removeCustomerSourceOfLeadData();
      removeCustomerId();
    };
  }, []);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          transform: !show ? "translateX(100%)" : "translateX(0%)",
          paddingTop: "78px", // 78 + 10px
          paddingRight: "10px",
          paddingLeft: isSidebarOpen
            ? "188px" // 178 + 10px
            : "72px", // 62 + 10px
          transition: "all ease 0.5s",
          height: "100vh",
          width: "100%",
          zIndex: "99",
          background: "white",
          overflow: "scroll",
        }}
      >
        <div className="d-flex align-items-center justify-content-start py-2 mb-2">
          <button className="btn btn-secondary btn-lg p-2 d-flex align-items-center justify-content-center" onClick={() => removeCustomerId()}>
            <MdOutlineArrowBackIosNew style={{ fontSize: "1rem" }} />
            <span style={{ fontSize: "0.8rem" }}>BACK</span>
          </button>
        </div>

        {!loading && !!data && (
          <div className="pageContent agentprofile-layout">
            <div className="agentprofile-content">
              <div className="agent-profile-head">
                <div className="col-left">
                  <div className="user-image">
                    <span className="img-icon">
                      <img src={!!data?.client_logo ? data?.client_logo : UserIcon} />
                    </span>
                    <span className="item-title">{data?.client_name || ""}</span>
                  </div>
                  <div className="user-data">
                    <div className="item-heading">
                      <div className="item-name">{data?.data?.customer_name || ""}</div>
                      <div className="item-number">
                        <span>
                          {!!userData?.remove_masking
                            ? data?.data?.customer_mobile_number?.slice(2)
                            : maskMobileNumber(data?.data?.customer_mobile_number?.slice(2))}
                        </span>
                        {!isEmpty(data?.alt_numbers?.alt_contact_number) && (
                          <span>
                            {!!userData?.remove_masking ? data?.alt_numbers?.alt_contact_number : maskMobileNumber(data?.alt_numbers?.alt_contact_number)}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="item-state">
                      {data?.data?.customer_state || ""} ({data?.data?.customer_city || ""})
                    </div>
                    <div className="item-utilities">
                      {!!customerSourceOfLead && (
                        <div className="item-link" style={{ color: "#3F8EF6" }}>
                          <strong>{customerSourceOfLead}</strong>
                        </div>
                      )}
                      <div className="item-date">
                        {!!data?.data?.updated_at ? format(parse("", "", new Date(data?.data?.updated_at)), "dd MMM yyyy  |  HH:mm") : "---"}
                        {/* {!!data?.data?.updated_at ? format(new Date(data?.data?.updated_at), "dd MMM yyyy  |  HH:mm") : "---"} */}
                      </div>
                    </div>

                    {!isEmpty(data?.data?.payment_link) && (
                      <div className="mb-0 tx-12 d-flex align-items-center gap-1">
                        {"Payment Link : "}
                        <a
                          href={data?.data?.payment_link}
                          target="_blank"
                          style={{
                            width: "fit-content",
                            maxWidth: "300px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            display: "inline-block",
                          }}
                          rel="noreferrer"
                        >
                          {data?.data?.payment_link}
                        </a>
                        <div
                          className="mdl-link"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (isEmpty(data?.data?.payment_link)) {
                              toast.error("No Link Found", { autoClose: 2000 });
                              return;
                            }

                            copyTextToClipboard(data?.data?.payment_link)
                              .then(() => {
                                toast.success("Copied to Clipboard", { autoClose: 2000 });
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }}
                        >
                          <svg width="23" height="23" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#727272" fillRule="evenodd">
                              <path d="M9.245 12.32a.7.7 0 1 1 1.121-.838 2.802 2.802 0 0 0 4.225.302l2.093-2.091a2.8 2.8 0 0 0-3.954-3.967l-1.205 1.197a.7.7 0 0 1-.988-.993l1.212-1.204a4.202 4.202 0 0 1 5.934 5.948l-2.101 2.1a4.203 4.203 0 0 1-6.337-.453z" />
                              <path d="M13.168 10.082a.7.7 0 1 1-1.122.839 2.802 2.802 0 0 0-4.225-.303L5.73 12.71a2.8 2.8 0 0 0 .034 3.925 2.8 2.8 0 0 0 3.919.043l1.197-1.197a.7.7 0 0 1 .99.99l-1.206 1.206a4.202 4.202 0 0 1-5.934-5.948l2.102-2.1a4.203 4.203 0 0 1 6.337.453z" />
                            </g>
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-right">
                  {/* Settlement Process */}
                  {!!data?.settlement_intention && (
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="alert alert-info text-primary py-2 border" style={{ fontWeight: "bold" }}>
                        {data?.settlement_intention === settlementProcess.INTENTION_RECEIVED
                          ? "Intends to Settle, Confirmation pending"
                          : data?.settlement_intention === settlementProcess.CONFIRMATION_RECEIVED
                          ? "Confirmation for Settlement received"
                          : data?.settlement_intention === settlementProcess.LETTER_RECEIVED
                          ? "Settlement Letter Received"
                          : data?.settlement_intention}
                      </div>
                    </div>
                  )}

                  <div className="item-col item-col-btn">
                    <div className="item-btn">
                      <CallButton customer_mobile_number={data?.data?.customer_mobile_number || ""} />
                      <span className="d-block text-12">
                        <em>{data?.last_call_disposition || ""}</em>
                      </span>
                    </div>
                    <div className="item-btn">
                      <WhatsappButton whatsapp_no={data?.data?.customer_mobile_number} />

                      <span className="d-block text-12">
                        <em>{data?.last_whatsapp_status || ""}</em>
                      </span>
                    </div>
                    <div className="dropdown verticle-ellipse mt-0">
                      <a className="site-btn blue-border-btn px-3" data-bs-toggle="dropdown" aria-expanded="false">
                        Other Actions
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <PaymentLinkModal data={data} />
                        </li>
                        <li>
                          <FirCopy data={data} />
                        </li>
                        <li>
                          <IssueLegalNotice data={data} />
                        </li>
                        <li>
                          <CallAlternateNumber customerId={customerId} />
                        </li>

                        {/* Settlement Processes */}
                        {data?.settlement_intention === settlementProcess.INTENTION_RECEIVED && (
                          <li>
                            <SendVerificationMsg data={data} />
                          </li>
                        )}
                        {data?.settlement_intention === settlementProcess.CONFIRMATION_RECEIVED && (
                          <li>
                            <SendReminderToLender data={data} />
                          </li>
                        )}
                        {data?.settlement_intention === settlementProcess.LETTER_RECEIVED && (
                          <li>
                            <SendPaymentFollowup data={data} />
                          </li>
                        )}

                        <li>
                          <AddAlternateContact lan={data?.data?.loan_account_no} client_id={data?.client_id} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="page-content bg-lightwhite p-4 rounded-4 mt-4">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-8 cl-left">
                    <div className="col-left">
                      <div className="item-cl bg-white p-3 rounded-8">
                        <h6 className="block-title mb-0 font-bold">Profile</h6>
                        <div className="row">
                          <div className="col-md-12 col-lg-6 left-col dashed-border pe-4">
                            {!isEmpty(data?.data?.email) && (
                              <div className="item-row d-flex justify-between mt-3">
                                <span className="item-cl-left text-16 cl-gray-01">Email ID</span>
                                <span className="item-cl-right text-16 cl-black">
                                  {!!userData?.remove_masking ? data?.data?.email : maskEmail(data?.data?.email)}
                                </span>
                              </div>
                            )}
                            {!isEmpty(data?.data?.gender) && (
                              <div className="item-row d-flex justify-between mt-3">
                                <span className="item-cl-left text-16 cl-gray-01">Gender</span>
                                <span className="item-cl-right text-16 cl-black">{data?.data?.gender}</span>
                              </div>
                            )}
                            {!isEmpty(data?.data?.customer_city) && (
                              <div className="item-row d-flex justify-between mt-3">
                                <span className="item-cl-left text-16 cl-gray-01">Current City</span>
                                <span className="item-cl-right text-16 cl-black">{data?.data?.customer_city}</span>
                              </div>
                            )}
                          </div>
                          <div className="col-md-12 col-lg-6 right-col ps-4">
                            {!isEmpty(data?.data?.customer_profile) && (
                              <div className="item-row d-flex justify-between mt-3">
                                <span className="item-cl-left text-16 cl-gray-01">Customer Profile</span>
                                <span className="item-cl-right text-16 cl-black">{data?.data?.customer_profile}</span>
                              </div>
                            )}
                            {!isEmpty(data?.data?.employer_name) && (
                              <div className="item-row d-flex justify-between mt-3">
                                <span className="item-cl-left text-16 cl-gray-01">Employer Name</span>
                                <span className="item-cl-right text-16 cl-black">{data?.data?.employer_name}</span>
                              </div>
                            )}
                            {!isEmpty(data?.data?.dob) && (
                              <div className="item-row d-flex justify-between mt-3">
                                <span className="item-cl-left text-16 cl-gray-01">DOB</span>
                                <span className="item-cl-right text-16 cl-black">{data?.data?.dob}</span>
                              </div>
                            )}

                            <AmountPaid data={data?.data} />

                            {!isEmpty(data?.data?.settlement_letter_url) && (
                              <div className="item-row d-flex justify-between mt-3">
                                <span className="item-cl-left text-16 cl-gray-01">Settlement Letter</span>
                                <span className="item-cl-right text-16 cl-black">
                                  <a target="_blank" rel="noreferrer" href={downloadMediaUrl(data?.data?.settlement_letter_url)}>
                                    Download
                                  </a>
                                </span>
                              </div>
                            )}

                            {!isEmpty(data?.data?.letter_issue_date) && (
                              <div className="item-row d-flex justify-between mt-3">
                                <span className="item-cl-left text-16 cl-gray-01">Setl Letter Issued on</span>
                                <span className="item-cl-right text-16 cl-black">{data?.data?.letter_issue_date}</span>
                              </div>
                            )}

                            {!isEmpty(data?.data?.case_allocated_date) && (
                              <div className="item-row d-flex justify-between mt-3">
                                <span className="item-cl-left text-16 cl-gray-01">Case Allocated Date</span>
                                <span className="item-cl-right text-16 cl-black">{data?.data?.case_allocated_date}</span>
                              </div>
                            )}
                          </div>

                          <div className="col-12 pe-4 mt-3">
                            {[
                              { name: data?.data?.co_applicant_name, mobile: data?.data?.co_applicant_mobile_number },
                              { name: data?.data?.co_applicant_name2, mobile: data?.data?.co_applicant_mobile_number2 },
                              { name: data?.data?.co_applicant_name3, mobile: data?.data?.co_applicant_mobile_number3 },
                            ].map((coBorrower, coBorrowerIndex) => (
                              <Fragment key={coBorrowerIndex}>
                                {(!isEmpty(coBorrower.name) || !isEmpty(coBorrower.mobile)) && (
                                  <div className="d-flex align-items-center justify-between mt-1 gap-3">
                                    <span className="item-cl-left text-16 cl-gray-01">Co-Borrower #{coBorrowerIndex + 1}</span>
                                    <span style={{ border: "1px dashed #d5d5d5", flex: "1" }} />
                                    <span className="item-cl-right text-16 cl-black">
                                      {coBorrower.name ?? ""}{" "}
                                      {!isEmpty(coBorrower.mobile) &&
                                        `(${!!userData?.remove_masking ? coBorrower.mobile : maskMobileNumber(coBorrower.mobile)})`}
                                    </span>
                                  </div>
                                )}
                              </Fragment>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="clear"></div>
                      <div className="row mt-4 md-gx-30">
                        <div className="col-md-12 col-lg-6">
                          <div className="item-cl bg-white rounded-8">
                            <h6 className="block-title mb-0 font-bold px-3 pt-3">Loan Outstanding</h6>
                            <div className="row">
                              <div className="col-md-12">
                                {/*  */}
                                {(checkValidNumber(data?.data?.new_settlement_amount) || checkValidNumber(data?.data?.pre_approved_sattlement_amount)) && (
                                  <div className="item-row d-flex justify-content-between gap-3 mt-3 px-3">
                                    <span className="item-cl-left text-16 cl-gray-01">Final Settlement Amount</span>
                                    <span className="item-cl-right text-16 cl-black">
                                      {`Rs. ${commaFormat(
                                        parseInt(
                                          !!data?.data?.new_settlement_amount ? data?.data?.new_settlement_amount : data?.data?.pre_approved_sattlement_amount
                                        )
                                      )}`}
                                    </span>
                                  </div>
                                )}

                                {checkValidNumber(data?.data?.pos) && (
                                  <div className="item-row d-flex justify-between mt-3 px-3">
                                    <span className="item-cl-left text-16 cl-gray-01">Total Outstanding</span>
                                    <span className="item-cl-right text-16 cl-black">{`Rs. ${roundNum(data?.data?.pos)}`}</span>
                                  </div>
                                )}
                                {/* {checkValidNumber(data?.data?.emi) && (
                                                                    <div className="item-row d-flex justify-between mt-3 px-3">
                                                                        <span className="item-cl-left text-16 cl-gray-01">EMI</span>
                                                                        <span className="item-cl-right text-16 cl-black">{`Rs. ${commaFormat(
                                                                            data?.data?.emi
                                                                        )}`}</span>
                                                                    </div>
                                                                )} */}
                                {checkValidNumber(data?.data?.minimum_due) ? (
                                  <div className="item-row d-flex justify-between mt-3 px-3">
                                    <span className="item-cl-left text-16 cl-gray-01">Minimum Due</span>
                                    <span className="item-cl-right text-16 cl-black">{`Rs. ${commaFormat(parseInt(data?.data?.minimum_due))}`}</span>
                                  </div>
                                ) : checkValidNumber(data?.data?.emi) ? (
                                  <div className="item-row d-flex justify-between mt-3 px-3">
                                    <span className="item-cl-left text-16 cl-gray-01">Minimum Due</span>
                                    <span className="item-cl-right text-16 cl-black">{`Rs. ${commaFormat(parseInt(data?.data?.emi))}`}</span>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {!isEmpty(data?.data?.last_paid_date) && (
                                  <div className="item-row d-flex justify-between mt-3 px-3">
                                    <span className="item-cl-left text-16 cl-gray-01">Last Paid Date</span>
                                    <span className="item-cl-right text-16 cl-black">{data?.data?.last_paid_date}</span>
                                  </div>
                                )}

                                {checkValidNumber(data?.data?.last_paid_amount) && (
                                  <div className="item-row d-flex justify-between mt-3 px-3">
                                    <span className="item-cl-left text-16 cl-gray-01">Last Paid Amount</span>
                                    <span className="item-cl-right text-16 cl-black">{`Rs. ${commaFormat(data?.data?.last_paid_amount)}`}</span>
                                  </div>
                                )}

                                {!isEmpty(data?.data?.due_date) && (
                                  <div className="item-row highlight-bottom-field bg-f4f6f8 p-3 mt-3">
                                    <span className="text-16 cl-black">
                                      Due Since - <strong>{data?.data?.due_date || ""}</strong>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                          <div className="item-cl bg-white rounded-8">
                            <h6 className="block-title mb-0 font-bold px-3 pt-3">History</h6>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="item-row mt-3 px-3">
                                  <ul className="icons-listing mx-0 p-0 d-flex align-items-center justify-between text-center">
                                    <li className="d-block" onClick={() => setShowCallHistory(true)}>
                                      <IconVariant icon={"ivr"} variant={data?.communication_history?.ivr_data} labelSize="0.8rem" />
                                    </li>

                                    <li className="d-block" onClick={() => setShowSmsHistory(true)}>
                                      <IconVariant icon={"sms"} variant={data?.communication_history?.sms_data} labelSize="0.8rem" />
                                    </li>
                                    <li className="d-block" onClick={() => setShowEmailHistory(true)}>
                                      <IconVariant icon={"email"} variant={data?.communication_history?.email_data} labelSize="0.8rem" />
                                    </li>
                                    <li className="d-block" onClick={() => setShowWhatsappHistory(true)}>
                                      <IconVariant icon={"whatsapp"} variant={data?.communication_history?.whatsapp_data} labelSize="0.8rem" />
                                    </li>
                                    <li className="d-block" onClick={() => setShowDispHistory(true)}>
                                      <IconVariant icon={"agent"} variant={data?.communication_history?.agent_data} labelSize="0.8rem" />
                                    </li>
                                  </ul>
                                </div>
                                <div className="item-row highlight-bottom-field bg-f4f6f8 p-3 mt-3 d-flex align-items-center justify-content-between gap-3">
                                  <span className="text-16 cl-black">
                                    {data?.data?.actual_disposition || ""} on{" "}
                                    {!!data?.data?.last_disposition_date ? format(new Date(data?.data?.last_disposition_date), "dd-MM-yyyy") : ""}
                                  </span>
                                  <span className="position-relative d-flex align-items-center justify-content-end">
                                    <span className="bg-info badge badge-info"> {data?.data?.total_calls_attempted ?? "."}</span>
                                    <span style={{ fontSize: "10px", lineHeight: "12px" }} className="ms-1">
                                      Calls <br />
                                      Attempted
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-xl-4 cl-right">
                    <div className="col-right right-sidebar h-100">
                      <AddDispForm data={data} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="profile-acc-details mt-3 accordion-style-one">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Loan & Legal Details
                      </button>
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="row md-gx-30">
                          <div className="col-md-12 col-lg-4 row-col">
                            <div className="item-col bg-f4f6f8 p-3 rounded-8">
                              <h6 className="item-title mb-0 font-bold">Loan Details</h6>
                              <div className="item-listing">
                                {!isEmpty(data?.data?.loan_account_no) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">LAN</div>
                                    <div className="item-right cl-black text-16">
                                      {!!userData?.remove_masking ? data?.data?.loan_account_no : maskString(data?.data?.loan_account_no)}
                                    </div>
                                  </div>
                                )}
                                {!isEmpty(data?.data?.loan_amount) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Total Loan Amount</div>
                                    <div className="item-right cl-black text-16">{`Rs. ${commaFormat(data?.data?.loan_amount)}`}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.data?.tenor) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Loan Tenure</div>
                                    <div className="item-righ cl-black text-16t">{data?.data?.tenor}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.data?.disbursal_date) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Disbursal Date</div>
                                    <div className="item-right cl-black text-16">{data?.data?.disbursal_date}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.data?.product_name) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Loan Taken For (Product)</div>
                                    <div className="item-right cl-black text-16">{data?.data?.product_name}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.data?.card_no) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Credit Card No.</div>
                                    <div className="item-right cl-black text-16">
                                      {!!userData?.remove_masking ? data?.data?.card_no : maskString(data?.data?.card_no)}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 col-lg-4 row-col">
                            <div className="item-col bg-f4f6f8 p-3 rounded-8">
                              <h6 className="item-title mb-0 font-bold">Loan History</h6>
                              <div className="item-listing">
                                {checkValidNumber(data?.data?.principal_outstanding) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <span className="item-left cl-gray-01 text-16">POS</span>
                                    <span className="item-right cl-black text-16">{`Rs. ${roundNum(data?.data?.principal_outstanding)}`}</span>
                                  </div>
                                )}

                                {!isEmpty(data?.data?.interest_outstanding) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Interest</div>
                                    <div className="item-right cl-black text-16">{`Rs. ${commaFormat(data?.data?.interest_outstanding)}`}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.data?.penalilty_overdue) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Penalty</div>
                                    <div className="item-right cl-black text-16">{`Rs. ${commaFormat(data?.data?.penalilty_overdue)}`}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.data?.current_bucket) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Bucket</div>
                                    <div className="item-right cl-black text-16">{data?.data?.current_bucket}</div>
                                  </div>
                                )}
                                {/* {!isEmpty(data?.data?.tenor) && (
                                                                    <div className="item-row d-flex justify-between mt-3">
                                                                        <div className="item-left cl-gray-01 text-16">Amount Paid Till Now</div>
                                                                        <div className="item-right cl-black text-16">{data?.data?.tenor}</div>
                                                                    </div>
                                                                )} */}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 col-lg-4 row-col">
                            <div className="item-col bg-f4f6f8 p-3 rounded-8">
                              <h6 className="item-title mb-0 font-bold">Legal Status</h6>
                              <div className="item-listing">
                                {!isEmpty(data?.legal_details?.legal_notices_count) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Legal Notices</div>
                                    <div className="item-right cl-black text-16">{data?.legal_details?.legal_notices_count} delivered</div>
                                  </div>
                                )}
                                {!isEmpty(data?.legal_details?.conciliation) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Conciliation</div>
                                    <div className="item-right cl-black text-16">{data?.legal_details?.conciliation}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.legal_details?.fir_status) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">FIR</div>
                                    <div className="item-righ cl-black text-16t">{data?.legal_details?.fir_status}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.legal_details?.police_station_name) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Police Station Name</div>
                                    <div className="item-right cl-black text-16">{data?.legal_details?.police_station_name}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.legal_details?.fir_number) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">FIR Number</div>
                                    <div className="item-right cl-black text-16">{data?.legal_details?.fir_number}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.legal_details?.arbritation) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Arbritation</div>
                                    <div className="item-right cl-black text-16">{data?.legal_details?.arbritation}</div>
                                  </div>
                                )}
                                {!isEmpty(data?.legal_details?.court_case) && (
                                  <div className="item-row d-flex justify-between mt-3">
                                    <div className="item-left cl-gray-01 text-16">Court Case</div>
                                    <div className="item-right cl-black text-16">{data?.legal_details?.court_case}</div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        All Address
                      </button>
                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="row md-gx-30">
                          <div className="col-md-12 col-lg-4 row-col">
                            <div className="item-col bg-f4f6f8 p-3 rounded-8 h-100">
                              <div className="item-listing">
                                <h6 className="item-title mb-2 font-bold">Current Address</h6>
                                <div className="item-desc cl-black text-16">
                                  <p>{data?.all_addresses?.current_address || ""}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 col-lg-4 row-col">
                            <div className="item-col bg-f4f6f8 p-3 rounded-8 h-100">
                              <div className="item-listing">
                                <h6 className="item-title mb-2 font-bold">Employer Address</h6>
                                <div className="item-desc cl-black text-16">
                                  <p>{data?.data?.office_address1 || ""}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 col-lg-4 row-col">
                            <div className="item-col bg-f4f6f8 p-3 rounded-8 h-100">
                              <div className="item-listing">
                                <h6 className="item-title mb-2 font-bold">Permanent Address</h6>
                                <div className="item-desc cl-black text-16">
                                  <p>{data?.all_addresses?.permanent_address || ""}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!!data?.guranters?.length && (
                    <div className="accordion-item">
                      <div className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Reference Details
                        </button>
                      </div>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="row md-gx-30">
                            {
                              !!data?.guranters?.map((item, index) => (
                                <div key={index} className="col-md-12 col-lg-4 row-col">
                                  <div className="item-col bg-f4f6f8 p-3 rounded-8 h-100">
                                    <div className="item-listing">
                                      <h6 className="item-title mb-2 font-bold">
                                        {item?.guarantor_name || "---"}
                                        <span className="cl-gray-01">(Guranter)</span>
                                      </h6>
                                      <div className="item-desc cl-black text-16">
                                        <p>{item?.address || "---"}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {!loading && !data && <NoRecord />}
        {loading && <ContentLoader />}
      </div>

      {/* communication history */}
      <DispositionHistory show={showDispHistory} setShow={setShowDispHistory} customerMobileNo={data?.data?.customer_mobile_number} />

      <WhatsappMsg
        show={showWhatsappHistory}
        setShow={setShowWhatsappHistory}
        name={data?.data?.customer_name}
        mobileNo={data?.data?.customer_mobile_number}
        isCandidate={false}
        withSendMessage={false}
        isMasked={!userData?.remove_masking}
      />
      <SmsHistory show={showSmsHistory} setShow={setShowSmsHistory} customerMobileNo={data?.data?.customer_mobile_number} />
      <CallHistory show={showCallHistory} setShow={setShowCallHistory} customerMobileNo={data?.data?.customer_mobile_number} />
      <EmailHistory show={showEmailHistory} setShow={setShowEmailHistory} customerId={customerId} isMasked={!userData?.remove_masking} />
    </>
  );
}

function roundNum(num) {
  const inNum = Number(num);
  if (!inNum) return "";
  else return String(commaFormat(parseInt(inNum)));
}
