import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import useForm from "../../../../../hooks/useForm";
import { showToast } from "../../../../../utils/handleToast";
import { getGenerateMissingNoticePdf } from "../../../../../apis";

const formDetailsDefault = {
    back_date: "",
};

const validate = (details) => {
    if (!details.back_date) return "Back date is required";
    return "";
};

export default function GenerateMissingNotices({ details }) {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formDetails, handleFormDetails, resetFormDetails] = useForm({ ...formDetailsDefault });

    const close = () => {
        setIsOpen(false);
        resetFormDetails();
    };

    const onGenerate = async () => {
        if (!details?.client?.client_id || !details?.batch_id) {
            showToast({ variant: "warning", message: "Invalid Batch" });
            return;
        }

        const error = validate(formDetails);
        if (error) {
            showToast({ variant: "warning", message: error });
            return;
        }

        setLoading(true);
        const res = await getGenerateMissingNoticePdf({
            client_id: details?.client?.client_id,
            batch_id: details?.batch_id,
            back_date: formDetails?.back_date,
        });

        if (res.success) {
            showToast({ variant: "success", message: res?.data?.message ?? "Success" });
            close();
        } else {
            showToast({ variant: "danger", message: res?.data?.message ?? "Action Failed" });
        }

        setLoading(false);
    };

    return (
        <>
            <Dropdown.Item onClick={() => setIsOpen(true)}>Generate Missing Notices</Dropdown.Item>

            <Modal size="sm" show={isOpen}>
                <Modal.Header>
                    <Modal.Title>Generate Missing Notices</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-3 px-0">
                    <div className="px-4 mb-4">
                        {[
                            ["Batch No", details?.client_batch_id],
                            ["Batch", details?.batch_name],
                            ["Notice Type", details?.notice_type],
                            ["Total Notices", details?.total_notice],
                            ["Pdf Urls", details?.pdf_urls],
                        ].map(([label, value], index) => (
                            <p key={index} className="text-muted mb-1 d-flex align-items-start gap-2">
                                <span style={{ flexShrink: "0", width: "6rem" }}>{label}</span>
                                <span>:</span>
                                <span style={{ wordBreak: "break-all" }} className="text-black">
                                    {value ?? ""}
                                </span>
                            </p>
                        ))}
                    </div>

                    <div className="px-4">
                        <label className="mb-1">Back Date</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formDetails?.back_date}
                            onChange={(e) => {
                                const val = e.target.value;
                                if (isNaN(val)) return;
                                handleFormDetails("back_date", val);
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={close}>
                        Cancel
                    </Button>
                    <Button disabled={loading} variant="primary" onClick={onGenerate}>
                        {loading ? "Please wait..." : "Generate"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
