import React from "react";
import classes from "./style.module.css";
import Audio from "./Audio";
import FileIcon from "./FileIcon";
import { downloadMediaUrl } from "../../../API";
import ImageAttachment from "./ImageAttachment";
import VideoAttachment from "./VideoAttachment";

function checkFormatType(url) {
    if (url.match(/\.(jpeg|jpg|gif|png)$/) != null) return "image";
    if (url.match(/\.(mp3|ogg|wav|aac)$/) != null) return "audio";
    if (url.match(/\.(mp4|avi|mkv|flv|wmv)$/) != null) return "video";

    return "file";
}

function getAttachmentName(url) {
    const name = url?.split("/")?.pop();
    return !!name ? name : "Attachment";
}

export default function Attachment({ file }) {
    if (!file) return null;

    const downloadUrl = !file.includes("api.themedius.ai") ? downloadMediaUrl(file) : file;
    const formatType = checkFormatType(file);

    if (formatType === "audio") return <Audio url={downloadUrl} />;
    if (formatType === "image") return <ImageAttachment url={downloadUrl} />;
    if (formatType === "video") return <VideoAttachment url={downloadUrl} />;

    return (
        <a href={downloadUrl} target="_blank" rel="noreferrer" className={classes.attachment}>
            <FileIcon url={file} /> <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{getAttachmentName(file)}</span>
        </a>
    );
}
