import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getClientProducts } from "../../../API/adminApis";
import useForm from "../../../hooks/useForm";
import useAuthStore from "../../../store/authStore";
import FilterButton from "../../../ui/buttons/FilterButton";
import DatePickers from "../../../ui/forms/DatePicker";
import SelectDropdown from "../../../ui/forms/SelectDropdown";

const filterDefaults = {
    from_date: null,
    to_date: null,
    letter_received: "",
    payment_received: "",
    product: "",
};

export default function Filter({ prevFilter, setPrevFilter }) {
    const userData = useAuthStore((state) => state.userData);

    const [show, setShow] = useState(false);
    const [filterData, handleFilterData, resetFilterData, forceUpdateFilterData] = useForm({ ...filterDefaults });

    const [products, setProducts] = useState([]);

    const getProducts = async () => {
        if (!userData?.client_id) return;
        const res = await getClientProducts({ client_id: userData?.client_id });
        if (!!res?.length) setProducts(res?.map((el) => ({ label: el, value: el })));
    };

    useEffect(() => {
        getProducts();
    }, []);

    const clearFilter = () => {
        resetFilterData();
        setPrevFilter({ ...filterDefaults });
        setShow(false);
    };

    const closeFilter = () => {
        resetFilterData();
        setShow(false);
    };

    const applyFilter = () => {
        setPrevFilter({ ...filterData });
        setShow(false);
        resetFilterData();
    };
    useEffect(() => {
        !!show && forceUpdateFilterData({ ...filterDefaults, ...prevFilter });
    }, [show]);

    return (
        <>
            <FilterButton
                onClick={() => setShow(true)}
                active={!!prevFilter && !!Object.values(prevFilter)?.some((el) => el !== "asc" && el !== "desc" && !!el)}
            />

            <Modal size="sm" show={show} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <div className="row align-items-center py-3">
                            <p className="col-5 mb-0">Product</p>
                            <div className="col-7">
                                <SelectDropdown
                                    placeholder="Choose Product"
                                    options={products}
                                    value={filterData?.product}
                                    onChange={(val) => handleFilterData("product", val)}
                                />
                            </div>
                        </div>

                        <div className="grey-bg row align-items-center py-3 px-2">
                            <p className="col-5 mb-0">Letter Received</p>
                            <div className="col-7">
                                <SelectDropdown
                                    placeholder="Choose Option"
                                    options={[
                                        { value: "yes", label: "Yes" },
                                        { value: "no", label: "No" },
                                    ]}
                                    value={filterData?.letter_received}
                                    onChange={(val) => handleFilterData("letter_received", val)}
                                />
                            </div>
                        </div>

                        <div className="row align-items-center py-3">
                            <p className="col-5 mb-0">Payment Received</p>
                            <div className="col-7">
                                <SelectDropdown
                                    placeholder="Choose Option"
                                    options={[
                                        { value: "yes", label: "Yes" },
                                        { value: "no", label: "No" },
                                    ]}
                                    value={filterData?.payment_received}
                                    onChange={(val) => handleFilterData("payment_received", val)}
                                />
                            </div>
                        </div>

                        <div className="row py-3 grey-bg px-2">
                            <label className="col-6 d-flex flex-column align-items-start justify-content-start">
                                <p className="mb-1">Date From</p>
                                <DatePickers
                                    label="Choose Date"
                                    maxDate={filterData?.to_date}
                                    value={filterData?.from_date}
                                    onChange={(val) => handleFilterData("from_date", val)}
                                />
                            </label>
                            <label className="col-6 d-flex flex-column align-items-start justify-content-start">
                                <p className="mb-1">Date To</p>
                                <DatePickers
                                    label="Choose Date"
                                    minDate={filterData?.from_date}
                                    value={filterData?.to_date}
                                    onChange={(val) => handleFilterData("to_date", val)}
                                />
                            </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => clearFilter()}>
                        Clear Filter
                    </Button>
                    <Button variant="secondary" onClick={() => closeFilter()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => applyFilter()}>
                        Apply
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
