import React, { useCallback, useEffect } from "react";
import ComplaintHeader from "../../../Components/Prelitigation/Fir/ComplaintHeader";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import Arrow from "../../../../Static/RawImages/arrow.png";
import Check from "../../../../Static/RawImages/check.png";
import Close from "../../../../Static/RawImages/close.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { fetchFIRComplaints, updateFIRComplaints, fetchWhatsappData, fetchMailData, downloadMediaUrl } from "../../../../API";
import NoRecord from "../../../../Components/NoRecord";
import { useParams } from "react-router-dom";
import getProductName from "../../../../Functions/getProductName";
import ContentLoader from "../../../../Components/ContentLoader";
import AccountsMail from "../../../../Components/AccountsHistory/AccountsMail";
import AccountsWhatsapp from "../../../../Components/AccountsHistory/AccountsWhatsapp";
import { IoCloseCircle } from "react-icons/io5";
import { toast } from "react-toastify";
import { commaFormat } from "../../../../Functions/formatNumber";
import { isEmpty } from "../../../../Functions/isEmpty";
import PoliceInquiryInitiatedModal from "../../../Components/Prelitigation/Fir/PoliceInquiryInitiatedModal";
import useGeneralStore from "../../../../store/generalStore";
import usePagination from "../../../../hooks/usePagination";
import Pagination from "../../../../Components/Pagination";

function PreLitigationComplaints() {
    const updateFetchedCount = useGeneralStore((state) => state.update_fetched_count);

    const [smLegalNotice, setLegalNotice] = useState(false);
    const [complaintData, setComplaintData] = useState([]);

    const [UnableToFetch, setUnableToFetch] = useState(false);
    const [SearchParameter, setSearchParameter] = useState({});
    const [HitReload, setHitReload] = useState(false);
    const [StorePrevFilter, setStorePrevFilter] = useState(null);
    const [pdfUrl, setPdfUrl] = useState("");
    const [ArrayIds, setArrayIds] = useState([]);
    const [compName, setCompName] = useState("");
    const [infolgShow, setInfoSmShow] = useState(false);
    const [particularData, setParticularData] = useState({});

    const [selectedComplaint, setselectedComplaint] = useState(null);
    const [details, saveNewDetails] = useState({});
    const [AccountWhatsappData, setAccountWhatsappData] = useState(null);
    const [AccountMailData, setAccountMailData] = useState(null);
    const [IsAccountMail, setIsAccountMail] = useState(false);
    const [IsAccountWhatsapp, setIsAccountWhatsapp] = useState(false);

    const [policeInquiryInitiatedModal, setPoliceInquiryInitiatedModal] = useState({
        show: false,
        complaintId: null,
    });

    const { currentPage, handleTotal, paginationCallBack, paginationProps } = usePagination({ defaultLimit: 20 });

    const { id } = useParams();

    const getFIRComplaints = async () => {
        setStorePrevFilter(SearchParameter);
        let myParams;
        if (SearchParameter.batch_id) {
            myParams = { ...SearchParameter, page: currentPage };
        } else {
            id ? (myParams = { ...SearchParameter, batch_id: id, page: currentPage }) : (myParams = { ...SearchParameter, page: currentPage });
        }
        const res = await fetchFIRComplaints(myParams);
        if (res && res.status) {
            setComplaintData(res.data);
            handleTotal(Number(res.total));
            updateFetchedCount(res.total);
            setArrayIds(
                res &&
                    res.data &&
                    res.data.length > 1 &&
                    res.data.map((item, i) => {
                        return i + 1;
                    })
            );
            setUnableToFetch(true);
        }
    };

    useEffect(() => {
        paginationCallBack(getFIRComplaints);
    }, [SearchParameter, id, currentPage]);

    const fetchParticular = async (id) => {
        let myParams = {
            complaint_id: id,
        };
        const res = await fetchFIRComplaints(myParams);
        if (res) {
            setParticularData(res.data);
            if (res.data.state && res.data.state != "None") {
                saveNewDetails((prev) => ({ ...prev, state: res.data.state }));
            }
        }
    };

    const changeParticular = (name, value) => {
        setParticularData({ ...particularData, [`${name}`]: value });
    };

    const updateComplaint = async () => {
        try {
            let myParams = {
                complaint_id: selectedComplaint,
            };
            let body = details;
            const res = await updateFIRComplaints(myParams, body);
            if (res && res.status) {
                toast.info(res.message);
                saveNewDetails({});
            } else if (res) {
                toast.error(res.message);
                saveNewDetails({});
            }
        } catch (error) {
            console.error({ error });
            saveNewDetails({});
        }
    };

    const fetchAccountHistoryData = async ({ mode = "", mobile_number = "", email = "" }) => {
        try {
            if (mode === "whatsapp") {
                setIsAccountWhatsapp(true);
                const res = await fetchWhatsappData(mobile_number);
                if (res) {
                    const resData = res.data;
                    setAccountWhatsappData(resData);
                }
            }

            if (mode === "mail") {
                setIsAccountMail(true);
                const para = { customer_mobile_number: !isEmpty(mobile_number) ? mobile_number : null, to_mail: !isEmpty(email) ? email : null, type: "fir" };
                const res = await fetchMailData(para);

                if (res) {
                    const resData = res.data;
                    setAccountMailData(resData);
                }
            }
        } catch (error) {
            if (mode === "whatsapp") {
                setAccountWhatsappData([]);
            }

            if (mode === "mail") {
                setAccountMailData([]);
            }

            console.log(error);
        }
    };

    return (
        <div>
            <ComplaintHeader
                ArrayIds={ArrayIds}
                setSearchParameter={setSearchParameter}
                StorePrevFilter={StorePrevFilter}
                HitReload={HitReload}
                setHitReload={setHitReload}
                batchId={id}
            />
            <hr className="mt-4 mb-4" />
            {complaintData && (
                <>
                    {!!complaintData?.length &&
                        complaintData?.map((val, i) => {
                            return (
                                <div key={i} className="row d-flex justify-between legal-notices-listing">
                                    <div className="col-md-4">
                                        <div className="d-flex align-start">
                                            <div>
                                                <p className="mb-2 tx-16">
                                                    <strong>
                                                        {!isEmpty(val.customer_name) ? val.customer_name : "-"}
                                                        {!isEmpty(val.lan_number) ? ` ( ${val.lan_number} )` : ""}
                                                    </strong>
                                                </p>
                                                <p>
                                                    Batch No. - <span className="text-black">{val.client_batch_id ? val.client_batch_id : "-"}</span>
                                                </p>
                                                <hr />
                                                <p>
                                                    Product - <span className="text-black">{val.prd_grp ? getProductName(val.prd_grp) : "-"}</span>
                                                    <br />
                                                </p>
                                                <p>
                                                    Total Outstanding - <span className="text-black"> {`Rs. ${commaFormat(val?.od_pos_final)}`}</span>
                                                    <br />
                                                </p>
                                                <hr />
                                                <p>
                                                    Complaint Status -{" "}
                                                    <b
                                                        style={
                                                            val.pdf_url
                                                                ? { color: "#00B74A" }
                                                                : val.is_added_missing_info === true
                                                                ? { color: "#ffa500" }
                                                                : val.is_filing_failed === false && val.is_added_missing_info === false
                                                                ? { color: "#ffc107" }
                                                                : { color: "#F93154" }
                                                        }
                                                    >
                                                        {val.pdf_url
                                                            ? "Filed"
                                                            : val.is_added_missing_info === true && val.is_filing_failed === true
                                                            ? "Info Added"
                                                            : val.is_filing_failed === false && val.is_added_missing_info === false
                                                            ? "Not Filed Yet"
                                                            : "Info Missing"}
                                                    </b>{" "}
                                                    <br />
                                                    {val.pdf_url ? (
                                                        <text>
                                                            Complaint No - <b style={{ color: "#00B74A" }}>{val.complaint_registration_id}</b>
                                                        </text>
                                                    ) : (
                                                        ""
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <td>Police Station</td>
                                                    <td>{!isEmpty(val.police_station_name) ? val.police_station_name : "-----"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Statement by {val?.batch?.client?.client_name}</td>
                                                    <td>
                                                        {!isEmpty(val?.client_statement) ? (
                                                            <div className="client_statement">{val?.client_statement} </div>
                                                        ) : (
                                                            "-----"
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="action-box">
                                            <label htmlFor="dropdown-basic" className="col-form-label mb-2">
                                                Action
                                            </label>
                                            <Dropdown className="dropdown-s2">
                                                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="w-100 text-start select-style">
                                                    Select <img src={Arrow} alt="Sort" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="select-style-dropdown">
                                                    <Dropdown.Item
                                                        disabled={!val.pdf_url}
                                                        onClick={() => {
                                                            setCompName("1");
                                                            setPdfUrl(val.pdf_url);
                                                            setLegalNotice(true);
                                                        }}
                                                    >
                                                        View Complaint Copy
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        disabled={!val.police_statement_url}
                                                        onClick={() => {
                                                            setCompName("2");
                                                            setPdfUrl(val.police_statement_url);
                                                            setLegalNotice(true);
                                                        }}
                                                    >
                                                        Statement to Commissioner
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        disabled={!val.letter_to_accused}
                                                        onClick={() => {
                                                            setCompName("3");
                                                            setPdfUrl(val.letter_to_accused);
                                                            setLegalNotice(true);
                                                        }}
                                                    >
                                                        Letter to Accused
                                                    </Dropdown.Item>

                                                    {!val.pdf_url && val.is_filing_failed !== false && val.is_added_missing_info === false && (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                fetchParticular(val.complaint_id);
                                                                setselectedComplaint(val.complaint_id);
                                                                saveNewDetails({ complaint_id: val.complaint_id });
                                                                setInfoSmShow(true);
                                                            }}
                                                        >
                                                            Fill missing Info
                                                        </Dropdown.Item>
                                                    )}

                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            setPoliceInquiryInitiatedModal({
                                                                show: true,
                                                                complaintId: val?.complaint_id,
                                                            })
                                                        }
                                                    >
                                                        Police Inquiry Initiated
                                                    </Dropdown.Item>

                                                    {/* <Dropdown.Item href="">Initiate Litigation</Dropdown.Item>
                                            <Dropdown.Item href="">Re-initiate Collection</Dropdown.Item> */}
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            {(!!val?.batch?.communication_mode?.includes("whatsapp") ||
                                                (!!val?.batch?.communication_mode?.includes("email") && !isEmpty(val?.email))) && (
                                                <>
                                                    <hr className="mt-4 mb-4" />
                                                    <p>Delivery Status</p>

                                                    <ul className="d-flex history-box">
                                                        {!!val?.batch?.communication_mode?.includes("whatsapp") && (
                                                            <li>
                                                                <p
                                                                    className="al-popup-button cursor-pointer"
                                                                    id="whatsapp"
                                                                    onClick={() => fetchAccountHistoryData({ mode: "whatsapp", mobile_number: val?.mobile })}
                                                                >
                                                                    <div>
                                                                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                                                            <g fill="#0A7AFF" fillRule="nonzero">
                                                                                <path d="M11.52 0A11.52 11.52 0 0 0 1.44 17.074L0 23.04l5.822-1.507A11.52 11.52 0 1 0 11.52 0zm0 22.08a10.56 10.56 0 0 1-5.38-1.474l-.174-.105-4.608 1.18 1.1-4.742-.101-.173a10.56 10.56 0 1 1 9.163 5.314z" />
                                                                                <path d="M7.632 13.239a14.745 14.745 0 0 0 4.905 3.842c.717.34 1.676.743 2.744.812.067.003.13.006.196.006.717 0 1.293-.248 1.763-.758.003-.002.008-.008.011-.014.167-.202.357-.383.556-.576.136-.13.274-.265.406-.403.614-.64.614-1.452-.005-2.07l-1.731-1.732c-.294-.305-.645-.466-1.014-.466s-.723.161-1.025.463l-1.031 1.031a5.48 5.48 0 0 0-.286-.15 3.561 3.561 0 0 1-.316-.172c-.94-.596-1.792-1.374-2.607-2.373-.412-.522-.688-.96-.881-1.406.27-.245.524-.5.769-.751.086-.09.176-.179.265-.268.31-.311.478-.671.478-1.037 0-.366-.164-.726-.478-1.037l-.858-.858c-.101-.1-.196-.199-.294-.3-.19-.195-.389-.397-.585-.578C8.318 4.153 7.97 4 7.6 4c-.365 0-.717.153-1.025.446L5.498 5.524a2.213 2.213 0 0 0-.66 1.416c-.054.689.073 1.42.4 2.304.505 1.368 1.265 2.639 2.394 3.995zm-2.09-6.238c.034-.383.18-.703.457-.98l1.072-1.07c.167-.162.35-.245.53-.245.175 0 .354.083.518.25.193.179.374.366.57.565l.3.305.858.858c.178.179.27.36.27.539 0 .178-.092.36-.27.538l-.268.271c-.268.27-.518.527-.795.772l-.014.014c-.24.24-.202.467-.144.64.003.008.006.014.008.023.222.532.53 1.04 1.011 1.644.864 1.066 1.774 1.892 2.777 2.529.123.08.256.144.38.207.115.058.222.112.317.173l.031.017a.602.602 0 0 0 .28.072.61.61 0 0 0 .429-.196l1.077-1.077c.167-.167.348-.256.527-.256.219 0 .397.135.51.256l1.736 1.734c.346.346.343.72-.008 1.086-.121.13-.248.253-.383.383-.202.196-.412.397-.602.625-.332.357-.726.524-1.236.524-.049 0-.1-.003-.15-.006-.944-.06-1.823-.429-2.482-.743a14.008 14.008 0 0 1-4.669-3.657c-1.074-1.294-1.797-2.497-2.275-3.788-.296-.792-.409-1.428-.363-2.007z" />
                                                                            </g>
                                                                        </svg>
                                                                        {!!val?.whatsapp_delivered ? (
                                                                            <div className="action-status status-check">
                                                                                <img src={Check} alt="Check" />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="action-status status-uncheck">
                                                                                <img src={Close} alt="Sort" />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    WhatsApp
                                                                </p>
                                                            </li>
                                                        )}
                                                        {!!val?.batch?.communication_mode?.includes("email") && !isEmpty(val?.email) && (
                                                            <li>
                                                                <p
                                                                    className="al-popup-button cursor-pointer"
                                                                    onClick={() =>
                                                                        fetchAccountHistoryData({
                                                                            mode: "mail",
                                                                            mobile_number: val?.mobile,
                                                                            email: val?.email,
                                                                        })
                                                                    }
                                                                >
                                                                    <div>
                                                                        <svg width="24" height="18" xmlns="http://www.w3.org/2000/svg">
                                                                            <g fill="#0A7AFF" fillRule="nonzero">
                                                                                <path d="M0 0v12.96a4.32 4.32 0 0 0 4.32 4.32h14.4a4.32 4.32 0 0 0 4.32-4.32V0H0zm22.08 12.96a3.36 3.36 0 0 1-3.36 3.36H4.32a3.36 3.36 0 0 1-3.36-3.36v-12h21.12v12z" />
                                                                                <path d="m20.39 3.682-.643-.71-8.227 7.42-8.18-7.411-.642.71 8.822 7.997z" />
                                                                            </g>
                                                                        </svg>
                                                                        {!!val?.email_delivered ? (
                                                                            <div className="action-status status-check">
                                                                                <img src={Check} alt="Check" />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="action-status status-uncheck">
                                                                                <img src={Close} alt="Sort" />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    Email
                                                                </p>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <hr className="mt-4 mb-4" />
                                </div>
                            );
                        })}
                    <div
                        className="al-popup"
                        //mail
                        style={
                            IsAccountMail
                                ? {
                                      transform: "translateX(0)",
                                      boxShadow: "0 2px 11px 7px rgba(0, 0, 0, 0.21)",
                                  }
                                : {
                                      transform: "translateX(100%)",
                                      boxShadow: "none",
                                  }
                        }
                    >
                        <div
                            className="al-close"
                            onClick={() => {
                                setIsAccountMail(false);
                                setAccountMailData(null);
                            }}
                        >
                            <IoCloseCircle fill="#999999" size={50} />
                        </div>
                        <div className="al-head">
                            <h2>Email ({AccountMailData ? AccountMailData.length : 0})</h2>
                        </div>
                        <AccountsMail AccountMailData={AccountMailData} />
                    </div>

                    <div
                        className="al-popup"
                        //Whatsapp
                        style={
                            IsAccountWhatsapp
                                ? {
                                      transform: "translateX(0)",
                                      boxShadow: "0 2px 11px 7px rgba(0, 0, 0, 0.21)",
                                  }
                                : {
                                      transform: "translateX(100%)",
                                      boxShadow: "none",
                                  }
                        }
                    >
                        <div
                            className="al-close"
                            onClick={() => {
                                setIsAccountWhatsapp(false);
                                setAccountWhatsappData(null);
                            }}
                        >
                            <IoCloseCircle fill="#999999" size={50} />
                        </div>
                        <div className="al-head">
                            <h2>WhatsApp ({AccountWhatsappData ? AccountWhatsappData.length : 0})</h2>
                        </div>
                        <AccountsWhatsapp AccountWhatsappData={AccountWhatsappData} />
                    </div>
                    <Pagination {...paginationProps} />
                </>
            )}
            {complaintData.length === 0 && UnableToFetch && <NoRecord />}

            {complaintData.length === 0 && !UnableToFetch && <ContentLoader />}

            <PoliceInquiryInitiatedModal
                show={policeInquiryInitiatedModal?.show}
                closeModal={() => setPoliceInquiryInitiatedModal({ show: false, complaintId: null })}
                complaintId={policeInquiryInitiatedModal?.complaintId}
            />

            {/* View Complaint Copy Popup */}
            <Modal show={smLegalNotice} dialogClassName="modal-large" onHide={() => setLegalNotice(false)} aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header>
                    <Modal.Title>{compName === "1" ? "View FIR Copy" : compName === "2" ? "Statement to Commissioner" : "Letter to Accused"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="my-4 mx-1 row">
                            <div className="col-md-12 text-center">
                                {/* <img src={Fir} alt="FIR Copy" /> */}
                                <object data={pdfUrl} type="application/pdf" width="100%" height="600">
                                    alt :{" "}
                                    <a href={!!pdfUrl ? downloadMediaUrl(pdfUrl) : ""}>
                                        Click Here To Download {compName === "1" ? "Complaint" : compName === "2" ? "Statement" : "Letter"} Copy
                                    </a>
                                </object>
                                {/* <Document
                                        file="https://s3.ap-south-1.amazonaws.com/themedius.ai/report/DPOnlineComplaint_80101012101065.pdf"
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        options={options}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                    <p>Page {pageNumber} of {numPages}</p> */}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setLegalNotice(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={infolgShow} size="lg" onHide={() => setInfoSmShow(false)}>
                <Modal.Header>
                    <Modal.Title>Fill Missing Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="py-3">
                        <div>
                            {/* Heading */}
                            <h2 className="text-center mb-3">Offender Details</h2>

                            {/* Pincode of borrower */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">Pincode of borrower</label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.zip_code ? "#fff" : "#eee",
                                    }}
                                    name="zip_code"
                                    value={particularData?.zip_code}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>

                            {/* State */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">State</label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.state ? "#fff" : "#eee",
                                    }}
                                    name="state"
                                    value={particularData?.state}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>

                            {/* District */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">District</label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.district ? "#fff" : "#eee",
                                    }}
                                    name="district"
                                    value={particularData?.district}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>

                            {/* Police Station */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">Police Station</label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.police_station_name ? "#fff" : "#eee",
                                    }}
                                    name="police_station_name"
                                    value={particularData?.police_station_name}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>

                            {/* City */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">City</label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.city ? "#fff" : "#eee",
                                    }}
                                    name="city"
                                    value={particularData?.city}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>

                            {/* House No. */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">House No.</label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.address1 ? "#fff" : "#eee",
                                    }}
                                    name="address1"
                                    value={particularData?.address1}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>
                            {/* Street Name */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">Street Name </label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.address2 ? "#fff" : "#eee",
                                    }}
                                    name="address2"
                                    value={particularData?.address2}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>

                            {/* Full Name */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">Full Name </label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.customer_name ? "#fff" : "#eee",
                                    }}
                                    name="customer_name"
                                    value={particularData?.customer_name}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>

                            {/* Borrower Mobile Number */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">Borrower Mobile Number</label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.mobile ? "#fff" : "#eee",
                                    }}
                                    name="mobile"
                                    value={particularData?.mobile}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>

                            {/* Borrower Email */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">Borrower Email</label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.email ? "#fff" : "#eee",
                                    }}
                                    name="email"
                                    value={particularData?.email}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>

                            {/* Amount */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">Amount</label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.od_pos_final ? "#fff" : "#eee",
                                    }}
                                    name="od_pos_final"
                                    value={particularData?.od_pos_final}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>

                            {/* Missing Info */}
                            <div className="row justify-content-center align-items-center mb-3">
                                <label className="col-3">Missing Info</label>
                                <div className="col-1" />
                                <input
                                    className="col-6 m-0"
                                    style={{
                                        padding: "0.675rem 0.75rem",
                                        borderRadius: "0.25rem",
                                        border: "1px solid #ced4da",
                                        background: !!particularData.missing_info ? "#fff" : "#eee",
                                    }}
                                    name="missing_info"
                                    value={particularData?.missing_info}
                                    onChange={(e) => {
                                        changeParticular(e.target.name, e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setInfoSmShow(false);
                            saveNewDetails({});
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="primary"
                        onClick={() => {
                            updateComplaint();
                            setInfoSmShow(false);
                        }}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default PreLitigationComplaints;
