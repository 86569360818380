import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
// import useAgentStore from "../../store/agent/agentStore";
// import useAuthStore from "../../store/authStore";

// =================================================

// INTRODUCTION
// ==================

// Intro & Loan Details
const introAndLoanDetails = [
    {
        question: `If someone asks for statement / details of outstanding balance`,
        answer: (
            <>
                {`Your loan account outstanding statement is given on the {{payment_link}}.`}
                <br />
                {`You can also contact.`}
                <br />
                {`Rs. {{outstanding_amount}} is still outstanding. Kindly clear payment on above link & share the receipt for our records in order to avoid legal action`}
            </>
        ),
    },
    {
        question: `If he just says Hi / Hello / random automatic reply / what /`,
        answer: `Hi, Kindly update us immediately on the payment to avoid further action.`,
    },
    {
        question: `If asks for which loan`,
        answer: `This is regarding your personal loan from {{lender_name}}`,
    },
    {
        question: `Why has my amount increased so much?`,
        answer: (
            <>
                {`It happens when you don't pay any emi for a long time, penalties for missed emi's and obviously interest is charged on the amount by the company.`}
                <br />
                {`Pay immediately to avoid increasing penalties and interests.`}
            </>
        ),
    },
    {
        question: `What to do with customers who have taken the loan from Bajaj, Axis etc.?`,
        answer: `Just go through with Smartcoin / Kreditbee / Selfin customers.`,
    },
    {
        question: `If customer asks how much amount`,
        answer: (
            <>
                {`Your loan account outstanding statement is given on the {{payment_link}}.`}
                <br />
                {`You can also contact`}
                <br />
                {`Rs. {{outstanding_amount}} is still outstanding. Kindly clear payment on above link & share the receipt for our records in order to avoid legal action.`}
            </>
        ),
    },
    {
        question: `If customer asks to speak in a different language, which you dont know`,
        answer: `We will connect to one of our members who will communicate with you regarding your loan details. `,
    },
    {
        question: `What to do when Customer hasn't received the FULL loan amount?`,
        answer: `-------------`,
    },
];

// =================================================

// POSITIVE RESPONSES
// ==================

// After Payment
const afterPayment = [
    {
        question: `if someone says i have paid or even shares payment receipt & it is confirmed on payment link that he has paid`,
        answer: `Noted, we have updated our records.`,
    },
    {
        question: `if someone says i have paid or even shares payment receipt & it is confirmed on payment link that he has NOT PAID`,
        answer: (
            <>
                {`We have checked our records, and have not found your payment. `} <br />
                {`You are advised to immediately make the payment share the payment receipt if you have paid, else, we will be constrained to proceed with legal action against you.`}
            </>
        ),
    },
    {
        question: `If FULL payment receipt is received`,
        answer: `Noted, we have updated our records.`,
    },
    {
        question: `If PART payment receipt is received`,
        answer: (
            <>
                {`You have made a part payment & we are in the receipt of the same,`} <br />
                {`We will await the remaining payment on or before {{PTP_date}}. Kindly provide us a receipt of your payment receipt for our records.`}
            </>
        ),
    },
    {
        question: `ASKING FOR NOC`,
        answer: `Contact to Customer Support`,
    },
    {
        question: `Borrower has shared old receipt with me`,
        answer: `You have shared your old receipt of the payment. We kindly request you to pay now and share us the new receipt to update our records.`,
    },
    {
        question: `Why is the paid amount not updated when the customer has already paid the amount?`,
        answer: `-------------`,
    },
    {
        question: `Customer is saying his amount is not updated even after malking payment`,
        answer: `-------------`,
    },
];

// Online Payment Options
const onlinePaymentOptions = [
    {
        question: `Can I have alternate payment options like bank details to give to the customers?`,
        answer: (
            <>
                {`In smartcoin some customers have been given specific bank details which are not the same for others.`} <br />{" "}
                {`So we can't send it to the other customers.`}
            </>
        ),
    },
    {
        question: `What if payment is failing again and again?`,
        answer: `Asked them to pay with another payment option.`,
    },
    {
        question: `if customer asks for payment link`,
        answer: `Your loan account outstanding statement is given on the {{payment_link}}.`,
    },
    {
        question: `Customer is saying he cannot PAY ONLINE - he needs to pay offline through an agent or branch`,
        answer: `-------------`,
    },
];

// Part Payments
const partPayment = [
    {
        question: `If borrower asks for less amount payment link`,
        answer: (
            <>
                {`SMARTCOIN: We cannot give you a partial payment option. I am giving you time till 5 pm tomorrow. You are required to arrange the funds and settle the entire balance. `}
                <br />
                {`In addition, please note that avoiding legal notice will have legal repercussions. So please confirm once payment has been made.`}
                <br />
                {`Legal proceedings will be initiated in court if payment is not done before {{DATE_AFTER_3_DAYS}}`}
                <br />
                {`We have already sent the legal notice to {{current_address}} & {{permanent_address}}. We will soon be dispatching to your office address in {{office_address}}`}
            </>
        ),
    },
    {
        question: `If he asks if he can make part payment`,
        answer: `You can make a 50% payment today before 5 pm, and the remaining 50% on or before 30th of this month`,
    },
    {
        question: `Why can't I make a part payment?`,
        answer: (
            <>
                {`As you haven't paid any emi from the last 2 months, you lost the option for the part payment.`}
                <br />
                {`It's advised to pay the full amount within the given time to avoid any further legal actions.`}
            </>
        ),
    },
    {
        question: `What to do when a customer wants to pay in emi's but is unable to pay?`,
        answer: (
            <>
                {`Emi's option gets closed after 3 months of the customer being a defaulter.`}
                <br />
                {`So convince them to pay the full loan. You can check through their link if they have the bank transfer option in the payment modes then you can offer them to pay in parts/instalments.`}
            </>
        ),
    },
];

// PTP in 3 days
const ptpIn3Days = [
    {
        question: `If borrower asks for more time to pay`,
        answer: (
            <>
                {`We understand and sympathise, but your loan repayment has already been delayed a lot & has been due since {due_since_months}. `}
                <br />
                {`On humanitarian grounds, we can grant you a maximum 3 days payment grace period before proceeding with a case against you.`}
            </>
        ),
    },
    {
        question: `IF borrower promises to pay within next 3 day's date`,
        answer: (
            <>
                {`As you have stated, we will await payment on or before {{date_after_3_days}}. Kindly provide us a receipt of your payment receipt for our records.`}
            </>
        ),
    },
    {
        question: `If someone says we have earlier given him time till XX Date`,
        answer: (
            <>
                {`I am giving you time until {{PTP_Date}}. You are required to arrange the funds and settle the entire balance by {{ptp_date}}. `}
                <br />
                {`In addition, please note that avoiding legal notice will have legal repercussions. So please confirm once payment has been made.`}
                <br />
                {`Legal proceedings will be initiated in court if payment is not done before {{DATE_AFTER_3_DAYS}}`}
                <br />
                {`We have already sent the legal notice to {{current_address}} & {{permanent_address}}. We will soon be dispatching to your office address in {{office_address}}`}
            </>
        ),
    },
];

// Settlement
const settlement = [
    {
        question: `If borrower asks to settle loan`,
        answer: `-------------`,
    },
    {
        question: `Why can't you settle my loan of Smart coin?`,
        answer: (
            <>
                {`Smart coin doesn't offer the settlement option for the customers, so we don't have the authority to settle your amount`}
                <br />
                {`We can only give you 2-3 days maximum for the repayment of the loan.`}
            </>
        ),
    },
];

// =================================================

// NEGATIVE RESPONSES
// ==================

// Broken PTP
const brokenPtp = [
    {
        question: `if someone has promised to pay & has broken the promise`,
        answer: (
            <>
                {`Please be advised that committing to pay the loan and then avoiding to do so is considered fraudulent by law. `}
                <br />
                {`You will be charged under applicable sections of the Indian Penal Code if payment is not made immediately and a receipt is not provided.`}
            </>
        ),
    },
    {
        question: `Everyone else who is asking for more time REPEATEDLY / not picking up calls anymore`,
        answer: (
            <>
                {`We have repeatedly reminded you to settle your outstanding, but to no avail. This reveals your fraudulent intent. `}
                <br />
                {`Please be advised that we will commence civil and criminal proceedings against you at your expense and risk if your payment is not received by today.`}
            </>
        ),
    },
    {
        question: `IF SOMEONE BREAKS PROMISE TO PAY / REFUSES TO PAY`,
        answer: (
            <>
                {`You have broken your commitment to repay the loan intentionally. Despite multiple reminders & warnings, you have still not paid, thus indicating wilful default. `}
                <br />
                <br />
                {`Please accept the following consequences as per your loan agreement`}
                <br />
                <br />
                {`Our collection executives will now be sent to your registered addresses for collection. Additionally, we will now be serving ALL legal notices to the given addresses via Registered Post. We shall notify the WILLFUL DEFAULT on your loan to CIBIL, as stipulated by the RBI`}
            </>
        ),
    },
];

// Legal / RBI Details
const legalRbiDetails = [
    {
        question: `If customer asks for case number`,
        answer: `-------------`,
    },
    {
        question: `If borrower says court case is going on`,
        answer: `-------------`,
    },
    {
        question: `If your company is registered with RBI then your company must be aware that according to RBI guidelines a borrower can take 30 days of extension in time in an emergency. So why are you not giving us the time?`,
        answer: `-------------`,
    },
];

// Not Contactable
const notContactable = [
    {
        question: `If borrower says i'm not that person, or replies randomly, or any other reply`,
        answer: (
            <>
                {`This is a registered number on an executed loan agreement which states the owner of this number has taken the loan & the number has been verified. `}
                <br />
                {`You are hereby advised to take this seriously and to immediately resolve the outstanding in order to avoid further action.`}
                <br />
                {`Kindly make the payment and send us the receipt for our records.`}
            </>
        ),
    },
    {
        question: `If borrower asks for a call back`,
        answer: <>{`We will arrange a call as per your request.`}</>,
    },
    {
        question: `if the call gets disconnected`,
        answer: `-------------`,
    },
];

// PTP beyond 3 days
const ptpBeyond3Days = [
    {
        question: `If borrower gives ANY reason & says cannot pay within 3 days`,
        answer: (
            <>
                {`We understand your concern & empathise with you. But please understand the cruciality of the situation`}
                <br />
                {`Avoiding legal notice has legal repercussions. You are advised to take this notice seriously and settle your outstanding immediately`}
                <br />
                {`You are kindly requested to arrange the funds and make the payment within the given time.`}
            </>
        ),
    },
    {
        question: `IF SOMEONE ASKS FOR MORE TIME`,
        answer: (
            <>
                {`We understand and sympathise, but your loan repayment has already been delayed a lot & has been due since {due_since_months}. `}
                <br />
                {`On humanitarian grounds, we can grant you a maximum 3 days payment grace period before proceeding with a case against you.`}
            </>
        ),
    },
    {
        question: `What to say when customers want too much time for repayment? And making extra excuses?`,
        answer: (
            <>
                {`Listen to their major reason first then make them understand that we can't give them more than 2-3 days.`}
                <br />
                {`Show them their loss with the pending loan like (CIBIL score will get lower, can lost the opportunity to take loans in future, legal action can be taken against them).`}
                <br />
                {`Rest don't entertain any other excuses.`}
            </>
        ),
    },
    {
        question: `If customer promises a PTP date which is beyond 3-7 days`,
        answer: `-------------`,
    },
];

// Refuse to Pay
const refuseToPay = [
    {
        question: `if borrower says you are harassing / torturing`,
        answer: (
            <>
                {`Please be aware that we are not harassing you. We are simply performing our duties and providing you with a reminder to avoid legal consequences.`}
                <br />
                {`Avoiding legal notice has legal repercussions. You are advised to take this notice seriously and settle your outstanding immediately`}
                <br />
                {`Kindly provide us a receipt of your payment receipt for our records within the given time.`}
            </>
        ),
    },
    {
        question: `if borrower says you can't force me / there is no such law / legal notice is invalid`,
        answer: (
            <>
                {`You have been wrongly advised. Please consult a qualified lawyer & show them the legal notices served & the consequences of your WILFUL DEFAULT`}
                <br />
                {`Avoiding legal notice has legal repercussions. You are advised to take this notice seriously and settle your outstanding immediately`}
                <br />
                {`Kindly provide us a receipt of your payment receipt for our records within the given time.`}
            </>
        ),
    },
    {
        question: `IF SOMEONE REFUSES TO PAY`,
        answer: (
            <>
                {`This is a clear case of WILFUL DEFAULT, we will now start issuing case documents & all legal notices to your addresses. Additionally, we will instruct all collection executives to follow up with you by visiting the given address with legal notices`}
                <br />
                {`What is the reason for your non-payment of loan? Confirm your intention. Please note, no clear reply would lead to this being considered as WILFUL Default.`}
            </>
        ),
    },
    {
        question: `IF SOMEONE DOESN'T GIVE ANY CLEAR REASON`,
        answer: (
            <>
                {`We have still not got any reason for Your non-payment of loan. Please note this it would be considered intentional & would amount to doing WILFUL Default.`}
            </>
        ),
    },
    {
        question: `IF HE SAYS OKAY SEND COLLECTION EXECUTIVES`,
        answer: `-------------`,
    },
    {
        question: `If borrower is Abusive`,
        answer: `-------------`,
    },
    {
        question: `Meet me face to face we will talk`,
        answer: `-------------`,
    },
];

// =================================================

// PANEL & PROCESS
// ==================

// Panel Issue
const panelIssue = [
    {
        question: `What to do when leads can't be connected with a call?`,
        answer: `-------------`,
    },
    {
        question: `What if the loan data isn't shown in the application and the customer wants to pay?`,
        answer: `-------------`,
    },
    {
        question: `Facing issue with the panel. What to do?`,
        answer: `-------------`,
    },
    {
        question: `What if I Dont know the number of the customer who is calling me - and he isnt telling me either`,
        answer: `-------------`,
    },
    {
        question: `Why am I getting calls to my contacts other than the number I have given?`,
        answer: `-------------`,
    },
];

// Process
const process = [
    {
        question: `Is Daily Report Compulsaory & what is the format to send`,
        answer: `-------------`,
    },
];

export default function ScriptFaq() {
    // const customerDetails = useAgentStore((state) => state.customer_data);
    // const userData = useAuthStore((state) => state.userData);

    const [show, setShow] = useState(false);

    const closeModal = () => {
        setShow(false);
    };
    return (
        <>
            <span className="script-faq space-px-16">
                <a onClick={() => setShow(true)}>{`FAQ`}</a>
            </span>

            <Modal className="popup-style-one script-faq-popup" size="xl" show={show}>
                <Modal.Header>
                    <Modal.Title>{`FAQ`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="popup-content">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            {["Introduction", "Positive Reponses", "Negative Responses", "Panel & Process"].map((item, index) => (
                                <li key={index} className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${index === 0 && "active"}`}
                                        id={`acc-id-${index + 1}`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#id-${index + 1}`}
                                        type="button"
                                        role="tab"
                                        aria-selected={index === 0 ? "true" : "false"}
                                    >
                                        {item}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            {/* Introduction */}
                            {/* ============= */}

                            <div className="tab-pane fade show active" id="id-1" role="tabpanel" aria-labelledby="acc-id-1">
                                <div className="d-flex">
                                    <div className="v-nav-items nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        {[
                                            // "Opening Script",
                                            "Intro / Loan Details",
                                        ].map((item, index) => (
                                            <button
                                                key={index}
                                                className={`nav-link ${index === 0 && "active"}`}
                                                id={`introduction-id-${index + 1}`}
                                                data-bs-toggle="pill"
                                                data-bs-target={`#introduction-id-cont-${index + 1}`}
                                                type="button"
                                                role="tab"
                                                aria-controls={`introduction-id-cont-${index + 1}`}
                                                aria-selected="true"
                                            >
                                                {item}
                                            </button>
                                        ))}
                                    </div>

                                    <div className="tab-content" id="v-pills-tabContent">
                                        {/* Opening Script */}
                                        {/* <div
                                            className="tab-pane fade show active"
                                            id="introduction-id-cont-1"
                                            role="tabpanel"
                                            aria-labelledby="introduction-id-1"
                                        >
                                            <div className="item-content">
                                                <p>
                                                    Hello Mr/Ms. {customerDetails?.data?.customer_name || "[NAME]"}, this is{" "}
                                                    {userData?.full_name || "[AGENT NAME]"} , calling from Conciliators {`KS Legal`} office appointed by{" "}
                                                    {customerDetails?.client_name || `[CLIENT NAME]`}
                                                </p>

                                                <p>
                                                    Before proceeding we wish to inform you that this call is being recorded for quality and training purpose.
                                                </p>

                                                <p>
                                                    Mr./Ms {customerDetails?.data?.customer_name || "[NAME]"}, you are aware that you have defaulted in payment
                                                    of your EMIs and as on <strong>{customerDetails?.due_date || `[DATE]`}</strong> an amount of{" "}
                                                    <strong>{customerDetails?.pos || `[RUPEE]`}</strong> is outstanding in your account.
                                                </p>

                                                <p>
                                                    Sir/ Ma'am, we wish to inform you that before filing Legal action, Bank has provided you this opportunity of
                                                    E-Conciliation. Conversation to be continued depending upon response from the customer.
                                                </p>

                                                <p>
                                                    CLM Name: {customerDetails?.collection_manager_name || `----`},
                                                    <br />
                                                    CLM Mobile No: {customerDetails?.collection_manager_mobile_number || `----`}
                                                </p>
                                            </div>
                                        </div> */}

                                        {/* Intro / Loan Details */}
                                        <div
                                            className="tab-pane fade show active"
                                            id="introduction-id-cont-1"
                                            role="tabpanel"
                                            aria-labelledby="introduction-id-1"
                                        >
                                            <div className="item-content">
                                                <div className="accordion accordion-style-two" id="intro_loan_details_accordian">
                                                    {introAndLoanDetails?.map(({ question, answer }, index) => (
                                                        <div key={index} className="accordion-item">
                                                            <div className="accordion-header" id={`intro-loan-details-heading${index + 1}`}>
                                                                <button
                                                                    className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#intro-loan-details-faq-${index + 1}`}
                                                                    aria-expanded="true"
                                                                    aria-controls={`intro-loan-details-faq-${index + 1}`}
                                                                >
                                                                    {question}
                                                                </button>
                                                            </div>
                                                            <div
                                                                id={`intro-loan-details-faq-${index + 1}`}
                                                                className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                aria-labelledby={`intro-loan-details-heading${index + 1}`}
                                                                data-bs-parent="#intro_loan_details_accordian"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div className="acc-item-content">
                                                                        <p>{answer}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Positive Responses */}
                            {/* ============= */}

                            <div className="tab-pane fade" id="id-2" role="tabpanel" aria-labelledby="acc-id-2">
                                <div className="tab-pane fade show active" id="id-two" role="tabpanel" aria-labelledby="acc-id-two">
                                    <div className="d-flex">
                                        <div className="v-nav-items nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            {["After Payment", "Online Payment options", "Part Payment", "PTP in 3 days", "Settlement"].map((item, index) => (
                                                <button
                                                    key={index}
                                                    className={`nav-link ${index === 0 && "active"}`}
                                                    id={`positive-responses-id-${index + 1}`}
                                                    data-bs-toggle="pill"
                                                    data-bs-target={`#positive-responses-id-cont-${index + 1}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`positive-responses-id-cont-${index + 1}`}
                                                    aria-selected={index === 0 ? "true" : "false"}
                                                >
                                                    {item}
                                                </button>
                                            ))}
                                        </div>

                                        <div className="tab-content" id="v-pills-tabContent">
                                            {/* After Payment */}

                                            <div
                                                className="tab-pane fade show active"
                                                id="positive-responses-id-cont-1"
                                                role="tabpanel"
                                                aria-labelledby="positive-responses-id-1"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="positive_responses_after_payment">
                                                            {afterPayment?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div
                                                                        className="accordion-header"
                                                                        id={`positive-responses-after-payment-heading${index + 1}`}
                                                                    >
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#positive-responses-after-payment-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`positive-responses-after-payment-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`positive-responses-after-payment-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`positive-responses-after-payment-heading${index + 1}`}
                                                                        data-bs-parent="#positive_responses_after_payment"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Online Payment Options */}

                                            <div
                                                className="tab-pane fade"
                                                id="positive-responses-id-cont-2"
                                                role="tabpanel"
                                                aria-labelledby="positive-responses-id-2"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="positive_responses_online_payment_options">
                                                            {onlinePaymentOptions?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div
                                                                        className="accordion-header"
                                                                        id={`positive-responses-online-payment-options-heading${index + 1}`}
                                                                    >
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#positive-responses-online-payment-options-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`positive-responses-online-payment-options-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`positive-responses-online-payment-options-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`positive-responses-online-payment-options-heading${index + 1}`}
                                                                        data-bs-parent="#positive_responses_online_payment_options"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Part Payment */}

                                            <div
                                                className="tab-pane fade"
                                                id="positive-responses-id-cont-3"
                                                role="tabpanel"
                                                aria-labelledby="positive-responses-id-3"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="positive_responses_part_payment">
                                                            {partPayment?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div
                                                                        className="accordion-header"
                                                                        id={`positive-responses-part-payment-heading${index + 1}`}
                                                                    >
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#positive-responses-part-payment-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`positive-responses-part-payment-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`positive-responses-part-payment-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`positive-responses-part-payment-heading${index + 1}`}
                                                                        data-bs-parent="#positive_responses_part_payment"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* PTP in 3 days */}

                                            <div
                                                className="tab-pane fade"
                                                id="positive-responses-id-cont-4"
                                                role="tabpanel"
                                                aria-labelledby="positive-responses-id-4"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="positive_responses_ptp_in_3_days">
                                                            {ptpIn3Days?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div
                                                                        className="accordion-header"
                                                                        id={`positive-responses-ptp-in-3-days-heading${index + 1}`}
                                                                    >
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#positive-responses-ptp-in-3-days-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`positive-responses-ptp-in-3-days-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`positive-responses-ptp-in-3-days-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`positive-responses-ptp-in-3-days-heading${index + 1}`}
                                                                        data-bs-parent="#positive_responses_ptp_in_3_days"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Settlement */}

                                            <div
                                                className="tab-pane fade"
                                                id="positive-responses-id-cont-5"
                                                role="tabpanel"
                                                aria-labelledby="positive-responses-id-5"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="positive_responses_settlement">
                                                            {settlement?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div className="accordion-header" id={`positive-responses-settlement-heading${index + 1}`}>
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#positive-responses-settlement-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`positive-responses-settlement-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`positive-responses-settlement-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`positive-responses-settlement-heading${index + 1}`}
                                                                        data-bs-parent="#positive_responses_settlement"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Negative Responses */}
                            {/* ============= */}

                            <div className="tab-pane fade" id="id-3" role="tabpanel" aria-labelledby="acc-id-3">
                                <div className="tab-pane fade show active" id="id-two" role="tabpanel" aria-labelledby="acc-id-two">
                                    <div className="d-flex">
                                        <div className="v-nav-items nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            {["Broken PTP", "Legal / RBI Details", "Not Contactable", "PTP beyond 3 days", "Refuse To Pay"].map(
                                                (item, index) => (
                                                    <button
                                                        key={index}
                                                        className={`nav-link ${index === 0 && "active"}`}
                                                        id={`negative-responses-id-${index + 1}`}
                                                        data-bs-toggle="pill"
                                                        data-bs-target={`#negative-responses-id-cont-${index + 1}`}
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`negative-responses-id-cont-${index + 1}`}
                                                        aria-selected={index === 0 ? "true" : "false"}
                                                    >
                                                        {item}
                                                    </button>
                                                )
                                            )}
                                        </div>

                                        <div className="tab-content" id="v-pills-tabContent">
                                            {/* Broken PTP */}

                                            <div
                                                className="tab-pane fade show active"
                                                id="negative-responses-id-cont-1"
                                                role="tabpanel"
                                                aria-labelledby="negative-responses-id-1"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="negative_responses_broken_ptp">
                                                            {brokenPtp?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div className="accordion-header" id={`negative-responses-broken-ptp-heading${index + 1}`}>
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#negative-responses-broken-ptp-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`negative-responses-broken-ptp-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`negative-responses-broken-ptp-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`negative-responses-broken-ptp-heading${index + 1}`}
                                                                        data-bs-parent="#negative_responses_broken_ptp"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Legal / RBI Details */}

                                            <div
                                                className="tab-pane fade"
                                                id="negative-responses-id-cont-2"
                                                role="tabpanel"
                                                aria-labelledby="negative-responses-id-2"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="negative_responses_legal_rbi_details">
                                                            {legalRbiDetails?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div
                                                                        className="accordion-header"
                                                                        id={`negative-responses-legal-rbi-details-heading${index + 1}`}
                                                                    >
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#negative-responses-legal-rbi-details-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`negative-responses-legal-rbi-details-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`negative-responses-legal-rbi-details-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`negative-responses-legal-rbi-details-heading${index + 1}`}
                                                                        data-bs-parent="#negative_responses_legal_rbi_details"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Not Contactable */}

                                            <div
                                                className="tab-pane fade"
                                                id="negative-responses-id-cont-3"
                                                role="tabpanel"
                                                aria-labelledby="negative-responses-id-3"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="negative_responses_not_contactable">
                                                            {notContactable?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div
                                                                        className="accordion-header"
                                                                        id={`negative-responses-not-contactable-heading${index + 1}`}
                                                                    >
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#negative-responses-not-contactable-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`negative-responses-not-contactable-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`negative-responses-not-contactable-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`negative-responses-not-contactable-heading${index + 1}`}
                                                                        data-bs-parent="#negative_responses_not_contactable"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* PTP beyond 3 days */}

                                            <div
                                                className="tab-pane fade"
                                                id="negative-responses-id-cont-4"
                                                role="tabpanel"
                                                aria-labelledby="negative-responses-id-4"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="negative_responses_ptp_beyond_3_days">
                                                            {ptpBeyond3Days?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div
                                                                        className="accordion-header"
                                                                        id={`negative-responses-ptp-beyond-3-days-heading${index + 1}`}
                                                                    >
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#negative-responses-ptp-beyond-3-days-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`negative-responses-ptp-beyond-3-days-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`negative-responses-ptp-beyond-3-days-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`negative-responses-ptp-beyond-3-days-heading${index + 1}`}
                                                                        data-bs-parent="#negative_responses_ptp_beyond_3_days"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Refuse To Pay */}

                                            <div
                                                className="tab-pane fade"
                                                id="negative-responses-id-cont-5"
                                                role="tabpanel"
                                                aria-labelledby="negative-responses-id-5"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="negative_responses_refuse_to_pay">
                                                            {refuseToPay?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div
                                                                        className="accordion-header"
                                                                        id={`negative-responses-refuse-to-pay-heading${index + 1}`}
                                                                    >
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#negative-responses-refuse-to-pay-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`negative-responses-refuse-to-pay-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`negative-responses-refuse-to-pay-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`negative-responses-refuse-to-pay-heading${index + 1}`}
                                                                        data-bs-parent="#negative_responses_refuse_to_pay"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Panel & Process */}
                            {/* ============= */}

                            <div className="tab-pane fade" id="id-4" role="tabpanel" aria-labelledby="acc-id-4">
                                <div className="tab-pane fade show active" id="id-two" role="tabpanel" aria-labelledby="acc-id-two">
                                    <div className="d-flex">
                                        <div className="v-nav-items nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            {["Panel Issue", "Process"].map((item, index) => (
                                                <button
                                                    key={index}
                                                    className={`nav-link ${index === 0 && "active"}`}
                                                    id={`panel-process-id-${index + 1}`}
                                                    data-bs-toggle="pill"
                                                    data-bs-target={`#panel-process-id-cont-${index + 1}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`panel-process-id-cont-${index + 1}`}
                                                    aria-selected={index === 0 ? "true" : "false"}
                                                >
                                                    {item}
                                                </button>
                                            ))}
                                        </div>

                                        <div className="tab-content" id="v-pills-tabContent">
                                            {/* Panel Issue */}

                                            <div
                                                className="tab-pane fade show active"
                                                id="panel-process-id-cont-1"
                                                role="tabpanel"
                                                aria-labelledby="panel-process-id-1"
                                            >
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="panel_process_panel_issue">
                                                            {panelIssue?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div className="accordion-header" id={`panel-process-panel-issue-heading${index + 1}`}>
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#panel-process-panel-issue-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`panel-process-panel-issue-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`panel-process-panel-issue-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`panel-process-panel-issue-heading${index + 1}`}
                                                                        data-bs-parent="#panel_process_panel_issue"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Process */}

                                            <div className="tab-pane fade" id="panel-process-id-cont-2" role="tabpanel" aria-labelledby="panel-process-id-2">
                                                <div className="item-content">
                                                    <div className="listing-content">
                                                        <div className="accordion accordion-style-two" id="panel_process_process">
                                                            {process?.map(({ question, answer }, index) => (
                                                                <div key={index} className="accordion-item">
                                                                    <div className="accordion-header" id={`panel-process-process-heading${index + 1}`}>
                                                                        <button
                                                                            className={`accordion-button ${index !== 0 && "collapsed"}`}
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#panel-process-process-faq-${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls={`panel-process-process-faq-${index + 1}`}
                                                                        >
                                                                            {question}
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        id={`panel-process-process-faq-${index + 1}`}
                                                                        className={`accordion-collapse collapse ${index === 0 && "show"}`}
                                                                        aria-labelledby={`panel-process-process-heading${index + 1}`}
                                                                        data-bs-parent="#panel_process_process"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div className="acc-item-content">
                                                                                <p>{answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closeModal()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
