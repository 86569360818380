import React, { useCallback, useEffect, useState } from "react";
import { fetchRecentReports, proceedToCompressedFile } from "../../../API";
import NoRecord from "../../../Components/NoRecord";
import ContentLoader from "../../../Components/ContentLoader";
import Paging from "../../../Components/Paging";
import dateFormat from "../../../Functions/getCorrentDateFormat";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useGeneralStore from "../../../store/generalStore";

function AllDownloads() {
    const updateFetchedCount = useGeneralStore((state) => state.update_fetched_count);

    const [recentReports, setRecentReports] = useState([]);
    const [pageTotal, setPageTotal] = useState("NaN");
    const [currentPage, setCurrentPage] = useState(1);
    const [UnableToFetch, setUnableToFetch] = useState(false);
    const [smShow, setSmShow] = useState(false);
    const [reportId, setReportId] = useState(null);
    const [email, setEmail] = useState(null);

    const getReadyReport = useCallback(async () => {
        const res = await fetchRecentReports({ page: currentPage });
        if (res) {
            setRecentReports(res.data);
            setPageTotal(Math.ceil(res?.total / 20));
            updateFetchedCount(res.total);
        }
    }, [currentPage]);

    const handlePagination = (type, target) => {
        if (type === "directValue") setCurrentPage(parseInt(target));
    };

    useEffect(() => {
        getReadyReport();
    }, [getReadyReport]);

    const proceedToDownload = useCallback(async (id, mail, sendToEmail = false) => {
        const res = await proceedToCompressedFile({
            report_id: id,
            email: mail ? mail : null,
        });
        if (res && !sendToEmail && res?.data.compressed_file_url) {
            document.getElementById("downloadLink").href = res.data.compressed_file_url;
            document.getElementById("downloadLink").click();
        }
    }, []);

    function checkValidEmail(email_id) {
        return !!String(email_id)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    return (
        <>
            <div className="all-downloads">
                <h2 className="tx-18">Recent reports List</h2>
                {recentReports && (
                    <>
                        {recentReports.length > 0 && (
                            <>
                                <table className="table table-hover table-small mt-3 mb-5 ">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" className="text-center">
                                                S.No.
                                            </th>
                                            <th scope="col" className="text-start">
                                                Name of report
                                            </th>
                                            {/* <th scope="col" className="text-center">
                                                Batches
                                            </th> */}
                                            {/* <th scope="col" className="text-center">
                                                Period
                                            </th> */}
                                            <th scope="col" className="text-center" style={{ whiteSpace: "nowrap" }}>
                                                Requested on
                                            </th>
                                            {/* <th scope="col" className="text-center">
                                                Link to download
                                            </th> */}
                                            <th scope="col" className="text-center">
                                                File Link
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recentReports.map((val, i) => (
                                            <tr key={i}>
                                                <td valign="middle" className="text-center">
                                                    {(currentPage - 1) * 20 + i + 1}
                                                </td>
                                                <td valign="middle" className="text-start">
                                                    <p className="mb-0">
                                                        <strong className="text-black">{val.report_name ? val.report_name : "-"}</strong>
                                                        <br />
                                                        <span
                                                            className="text-muted tx-12 line-h1"
                                                            style={{
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            {val.report_message ? val.report_message : "-"}
                                                            <br />
                                                        </span>
                                                    </p>
                                                </td>
                                                {/* <td valign="middle" className="text-center">
                                                        {val.batches ? val.batches : "-"}
                                                    </td> */}
                                                {/* <td valign="middle" className="text-center">
                                                        {val.period ? val.period : "-"}
                                                    </td> */}
                                                <td valign="middle" className="text-center">
                                                    <p className="mb-0 text-muted">{val.requested_on ? dateFormat(val.requested_on) : "-"}</p>
                                                </td>
                                                {val.status === "Generating..." ? (
                                                    <td valign="middle" className="text-center" style={{ whiteSpace: "nowrap" }}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="15"
                                                            height="15"
                                                            className="me-1 blink"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <g fill="none">
                                                                <g fill="#E02020">
                                                                    <g>
                                                                        <g>
                                                                            <g transform="translate(-1065.000000, -296.000000) translate(1065.000000, 293.000000) translate(0.000000, 0.000000) translate(0.000000, 3.000000)">
                                                                                <circle cx="10" cy="10" r="10" opacity=".16" fill="#ffc107" />
                                                                                <circle cx="10" cy="10" r="6" fill="#ffc107" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                        <strong className="text-warning">Generating..</strong>
                                                    </td>
                                                ) : val.status === "Not Generated" ? (
                                                    <td valign="middle" className="text-center" style={{ whiteSpace: "nowrap" }}>
                                                        <strong className="text-danger">Not Generated</strong>
                                                    </td>
                                                ) : val.status === "Generated" ? (
                                                    <td valign="middle" className="text-center">
                                                        {/* Download */}
                                                        <button
                                                            className="btn btn-primary btn-sm me-3"
                                                            disabled={val.status !== "Generated"}
                                                            onClick={() => {
                                                                proceedToDownload(val?.report_id);
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                style={{ width: "1.2rem", height: "1.2rem", margin: "0" }}
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="#fff"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                                                />
                                                            </svg>
                                                        </button>

                                                        {/* Email */}
                                                        <button
                                                            className="btn btn-secondary btn-sm"
                                                            disabled={val.status !== "Generated"}
                                                            onClick={() => {
                                                                setSmShow(true);
                                                                setReportId(val?.report_id);
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                style={{ width: "1.2rem", height: "1.2rem", margin: "0" }}
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="#505050"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                ) : (
                                                    ""
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Paging currentPage={currentPage} pageTotal={pageTotal} handlePagination={handlePagination} />
                            </>
                        )}
                    </>
                )}

                {!recentReports && UnableToFetch && <NoRecord />}

                {!recentReports && !UnableToFetch && <ContentLoader />}
            </div>

            <a href="" id="downloadLink" className="d-none" target="_blank"></a>
            <Modal size="sm" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Header>
                    <Modal.Title>Enter Your Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="my-4">
                            <div className="mb-2 row">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputPassword"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="abc@gmail.com"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSmShow(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        disabled={!checkValidEmail(email)}
                        onClick={() => {
                            proceedToDownload(reportId, email, true);
                            setSmShow(false);
                        }}
                    >
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AllDownloads;
