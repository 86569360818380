export default function AnalysisCard({ icon: Icon, title = "", content, onClick = () => {}, tag = "", isLoading = false }) {
    const tagStatus = tag?.toLowerCase() === "positive" ? "Positive" : tag?.toLowerCase() === "negative" ? "Negative" : "No Response";
    return (
        <div style={{ background: "#F5F6F8", cursor: "pointer" }} onClick={onClick} className="rounded p-3 d-flex gap-2">
            <span style={{ width: "20px", height: "20px" }}>
                <Icon style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </span>

            <div className="ms-1 flex-grow-1">
                <div className="d-flex justify-content-between gap-3">
                    <p className="m-0 fw-bold tx-16 text-black">{title}</p>

                    {!isLoading && !!tagStatus && (
                        <span
                            className={`badge ${
                                tagStatus === "Positive" ? "bg-success" : tagStatus === "Negative" ? "bg-danger" : "bg-info"
                            } tx-12 d-flex align-items-center`}
                        >
                            {tagStatus}
                        </span>
                    )}
                </div>

                {isLoading && <em className="text-muted mt-2">Loading...</em>}
                {!isLoading && !!content && <div className="mt-2">{content}</div>}
            </div>
        </div>
    );
}
