import React from "react";

function NoRecord() {
    return (
        <>
            <div className="full-screen error-text w-100 h-100 mt-5 pt-5">
                <div className="d-flex w-100 mt-5 pt-5 align-center justify-center">
                    <div className="w-100 text-center">
                        <svg width="378" height="295" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                                <path d="M183.18 293.29H34.385v-45.315h155.5v38.615c0 3.7-3 6.7-6.705 6.7z" fill="#BCBCBC" fillRule="nonzero" />
                                <path
                                    d="M34.31 293.29H20.305c-3.945 0-7.17-3.225-7.17-7.17V49.075a3.634 3.634 0 0 1 3.625-3.625h17.55a3.634 3.634 0 0 1 3.625 3.625v240.59c.005 1.995-1.63 3.625-3.625 3.625z"
                                    fill="#BCBCBC"
                                    fillRule="nonzero"
                                />
                                <path
                                    d="M180.505 247.975v27.355c0 2.675-2.19 4.87-4.87 4.87H5.08c-2.675 0-4.87-2.19-4.87-4.87V37.165c0-2.675 2.19-4.87 4.87-4.87h32.855"
                                    stroke="#0E0E0E"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M362.82 215.715 229.355 173.41c4.595-7.65 8-15.565 10.41-23.705l131.01 39.85a4.537 4.537 0 0 1 3.01 5.645l-5.325 17.5c-.715 2.385-3.26 3.74-5.64 3.015zM181.665 2.785l45.84 45.84h-45.84z"
                                    fill="#BCBCBC"
                                    fillRule="nonzero"
                                />
                                <path
                                    d="M218.23 187.79v58.99c0 .655-.54 1.195-1.195 1.195h-177.9c-.655 0-1.195-.54-1.195-1.195V1.27c0-.655.54-1.195 1.195-1.195H172.39"
                                    stroke="#0E0E0E"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path stroke="#231F20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M218.23 45.91v27.88" />
                                <path stroke="#0E0E0E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="m172.39.075 45.84 45.835h-45.84z" />
                                <circle fill="#BCBCBC" fillRule="nonzero" cx="162.55" cy="130.53" r="79.22" />
                                <circle stroke="#0E0E0E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" cx="162.55" cy="130.53" r="79.22" />
                                <circle fill="#F4F4F4" fillRule="nonzero" cx="162.55" cy="130.53" r="64.495" />
                                <circle stroke="#0E0E0E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" cx="162.55" cy="130.53" r="64.495" />
                                <path
                                    d="m364.82 208.215-132.095-39.85c4.085-8.64 7.17-17.365 8.435-26.76l131.615 40.455a4.537 4.537 0 0 1 3.01 5.645l-5.325 17.5c-.715 2.38-3.26 3.735-5.64 3.01z"
                                    stroke="#0E0E0E"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <circle stroke="#0E0E0E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" cx="145.075" cy="126.575" r="10.405" />
                                <circle fill="#0E0E0E" fillRule="nonzero" cx="145.075" cy="126.575" r="7.645" />
                                <circle fill="#FFF" fillRule="nonzero" cx="148.52" cy="123.295" r="1.36" />
                                <circle stroke="#0E0E0E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" cx="196.94" cy="126.575" r="10.405" />
                                <circle fill="#0E0E0E" fillRule="nonzero" cx="196.94" cy="126.575" r="7.645" />
                                <circle fill="#FFF" fillRule="nonzero" cx="198.945" cy="123.295" r="1.36" />
                                <path
                                    d="M153.34 169.155c12.7-9.39 25.4-9.39 38.1 0M125.455 111.805c12.105-2.755 20.535-6.235 24.13-10.68M192.285 102.68c10.495 6.625 18.92 10.135 24.605 9.54"
                                    stroke="#0E0E0E"
                                    strokeWidth="3.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path d="m114.4 114.52-5.505 11.755c-3.375 11.71 14.39 10.985 10.835 0l-5.33-11.755z" fill="#BCBCBC" fillRule="nonzero" />
                                <path
                                    d="m112.2 113.16-5.505 11.755c-3.375 11.71 14.39 10.985 10.835 0l-5.33-11.755zM50.67 18.79h84M50.67 35.05h46.5M50.67 51.315h62.5M50.67 232.29h61.465M50.67 215.29h30.735"
                                    stroke="#0E0E0E"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                        </svg>
                        <h1>Record Not Found</h1>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NoRecord;
