import { toast } from "react-hot-toast";

export const hideAllToast = () => {
    toast.remove();
};

export const showToast = ({ message, variant, duration = 3000, id }) => {
    if (["success", "danger", "info", "warning"].includes(variant)) {
        const messageString = typeof message === "string" ? message : JSON.stringify(message);
        const toastId = toast.custom(
            <div>
                <div
                    className={`md-alert alert alert-dismissible ${
                        variant === "success" ? "alert-success" : variant === "danger" ? "alert-danger" : variant === "info" ? "alert-primary" : "alert-warning"
                    } fade show`}
                    role="alert"
                >
                    {variant === "success" && (
                        <svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M20.188 12.75h4.939a3.187 3.187 0 0 1 3.187 3.667l-1.466 9.561a3.185 3.185 0 0 1-3.175 2.71H11.688a1.063 1.063 0 0 1-1.063-1.063V15.937c0-.148.031-.295.092-.431l4.25-9.563c.17-.383.55-.63.97-.63a4.25 4.25 0 0 1 4.25 4.25v3.187zM12.75 26.563h10.935c.53.006.983-.38 1.062-.905l1.466-9.561a1.063 1.063 0 0 0-1.074-1.222h-6.014a1.063 1.063 0 0 1-1.063-1.063v-4.25c0-.948-.62-1.751-1.478-2.025l-3.834 8.626v10.4zm-2.125 0V17H8.5c-.587 0-1.063.476-1.063 1.063V25.5c0 .587.476 1.063 1.063 1.063h2.125zm1.063 2.125H8.5A3.188 3.188 0 0 1 5.312 25.5v-7.438A3.188 3.188 0 0 1 8.5 14.876h3.188c.586 0 1.062.476 1.062 1.063v11.687c0 .587-.476 1.063-1.063 1.063z"
                                fill="#FFF"
                                fillRule="evenodd"
                            />
                        </svg>
                    )}
                    {variant === "danger" && (
                        <svg width="31" height="31" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#FFF" fillRule="evenodd">
                                <path d="M15.5 26.156c-5.885 0-10.656-4.77-10.656-10.656 0-5.885 4.77-10.656 10.656-10.656 5.885 0 10.656 4.77 10.656 10.656 0 5.885-4.77 10.656-10.656 10.656zm0-1.937a8.719 8.719 0 1 0 0-17.438 8.719 8.719 0 0 0 0 17.438z" />
                                <path d="M7.966 9.336a.969.969 0 1 1 1.37-1.37l13.698 13.698a.969.969 0 0 1-1.37 1.37L7.966 9.336z" />
                            </g>
                        </svg>
                    )}
                    {variant === "info" && (
                        <svg width="31" height="31" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#FFF" fillRule="evenodd">
                                <path d="M15.5 26.156c-5.885 0-10.656-4.77-10.656-10.656 0-5.885 4.77-10.656 10.656-10.656 5.885 0 10.656 4.77 10.656 10.656 0 5.885-4.77 10.656-10.656 10.656zm0-1.937a8.719 8.719 0 1 0 0-17.438 8.719 8.719 0 0 0 0 17.438z" />
                                <path d="M14.531 11.625a.969.969 0 1 1 1.938 0V15.5a.969.969 0 1 1-1.938 0v-3.875zM15.5 18.406a.969.969 0 1 1 0 1.938.969.969 0 0 1 0-1.938z" />
                            </g>
                        </svg>
                    )}
                    {variant === "warning" && (
                        <svg width="31" height="31" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#FFF" fillRule="evenodd">
                                <path d="M13.012 7.117a2.908 2.908 0 0 1 4.976 0L26.2 20.828a2.906 2.906 0 0 1-2.496 4.36H7.284a2.906 2.906 0 0 1-2.477-4.373l8.205-13.698zm-6.535 14.68a.969.969 0 0 0 .818 1.453h16.4a.969.969 0 0 0 .836-1.44L16.328 8.117a.969.969 0 0 0-1.655-.002L6.477 21.797z" />
                                <path d="M14.531 12.594a.969.969 0 1 1 1.938 0v3.875a.969.969 0 1 1-1.938 0v-3.875zM15.5 18.406a.969.969 0 1 1 0 1.938.969.969 0 0 1 0-1.938z" />
                            </g>
                        </svg>
                    )}{" "}
                    {messageString}
                    <button type="button" onClick={() => toast.remove(toastId)}>
                        <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.53 2.661c-3.547-3.548-9.32-3.548-12.869 0-3.548 3.548-3.548 9.321 0 12.87 3.548 3.548 9.321 3.548 12.87 0 3.547-3.549 3.547-9.322 0-12.87zm-2.846 10.023a.7.7 0 0 1-.99 0l-2.598-2.598-2.723 2.722a.7.7 0 1 1-.99-.99l2.723-2.722-2.599-2.599a.7.7 0 1 1 .99-.99l2.599 2.599 2.475-2.475a.7.7 0 1 1 .99.99l-2.475 2.475 2.598 2.599a.7.7 0 0 1 0 .99z"
                                fill="#FFF"
                                fillRule="nonzero"
                                fillOpacity=".5"
                            />
                        </svg>
                    </button>
                </div>
            </div>,
            {
                duration: !!duration ? duration : 2000,
                id: !!id ? id : undefined,
            }
        );
    }
};
