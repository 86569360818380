import React from "react";
import classes from "./style.module.css";

export default function PageError() {
    const onClick = () => {
        window.location.reload();
    };

    return (
        <div className={classes.container}>
            <h1 className={classes.glitch}>Oops!</h1>
            <p className="text-center mb-3">Something went wrong!</p>
            <button onClick={onClick} className="btn btn-secondary">
                Try Agian!
            </button>
        </div>
    );
}
