import React, { useState } from "react";
import ContentLoader from "../ContentLoader";
import { IoCloseCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import { isEmpty } from "../../Functions/isEmpty";
import { format } from "date-fns";

function AccountSms(props) {
    const data = props.AccountMailData;
    const [IsAccountMailDetail, setIsAccountMailDetail] = useState(false);

    return !props.loading ? (
        <>
            {!!data && !!data?.length ? (
                data?.map((val, i) => (
                    <React.Fragment key={i}>
                        <div className="al-mail-content">
                            <div className="mail-user d-flex align-center">
                                <div className="user-avtar">{val.from_mail ? val.from_mail.substr(0, 1) : ""}</div>
                                <div className="user-details">
                                    <p className="text-muted mb-0">
                                        {val.from_mail ? val.from_mail : ""}
                                        <br />
                                        {!isEmpty(val?.created_on) ? format(new Date(val?.created_on), "do MMM yyyy") : ""}
                                    </p>
                                </div>
                            </div>
                            <div className="mail-info">
                                <div className="text-black">
                                    <strong>{val.subject ? val.subject.substr(0, 52) : ""}...</strong>
                                </div>
                                <p>{val.body ? val.body.substr(0, 100) : ""}…</p>
                                <p className="text-end mb-0">
                                    <a onClick={() => setIsAccountMailDetail(true)}>View Detail</a>
                                </p>
                            </div>
                            <hr />
                        </div>
                        <div
                            className="al-popup"
                            //Mail Detail
                            style={
                                IsAccountMailDetail
                                    ? {
                                          transform: "translateX(0)",
                                          boxShadow: "0 2px 11px 7px rgba(0, 0, 0, 0.21)",
                                      }
                                    : {
                                          transform: "translateX(100%)",
                                          boxShadow: "none",
                                      }
                            }
                        >
                            <div className="al-close" onClick={() => setIsAccountMailDetail(false)}>
                                <IoCloseCircle fill="#999999" size={50} />
                            </div>
                            <div className="al-head">
                                <h2 className="tx-16 mail-detail-head">
                                    <div className="d-flex align-start">
                                        <Link onClick={() => setIsAccountMailDetail(false)}>
                                            <svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
                                                <g fill="#1A1A1A" fillRule="evenodd">
                                                    <path d="M25.5 15.938a1.063 1.063 0 0 1 0 2.124h-17a1.062 1.062 0 1 1 0-2.125h17z" />
                                                    <path d="m10.003 17 5.623 5.624a1.063 1.063 0 0 1-1.502 1.502l-6.375-6.375a1.063 1.063 0 0 1 0-1.502l6.375-6.375a1.063 1.063 0 0 1 1.502 1.502L10.003 17z" />
                                                </g>
                                            </svg>
                                        </Link>
                                        <span>{val.subject ? val.subject.substr(0, 52) : ""}...</span>
                                    </div>
                                </h2>
                            </div>
                            <div className="al-mail-content al-mail-content-details">
                                <div className="mail-user d-flex align-center mb-4">
                                    <div className="user-avtar">{val.from_mail ? val.from_mail.substr(0, 1) : ""}</div>
                                    <div className="user-details">
                                        <p className="mb-0">
                                            {val.from_mail ? val.from_mail : ""}
                                            <br />
                                            {val.subject ? val.subject.substr(0, 52) : ""}...
                                        </p>
                                    </div>
                                </div>
                                <div className="mail-info">
                                    <div dangerouslySetInnerHTML={{ __html: val?.body }} />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ))
            ) : (
                <div className="fs-5 fw-bold">No Data Found</div>
            )}
        </>
    ) : (
        <>
            <ContentLoader />
        </>
    );
}

export default AccountSms;
