import React, { useEffect, useState } from "react";
import ContentLoader from "../../../Components/ContentLoader";
import NoRecord from "../../../Components/NoRecord";
import DailyReportHeader from "../../Components/DailyReport/Header";
import useGeneralStore from "../../../store/generalStore";
import { Dropdown } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { getDailyReportFiles } from "../../../API/panelApis";
import { format } from "date-fns";
import useAuthStore from "../../../store/authStore";
import FailedPaymentListUpload from "../../Components/DailyReport/FailedPaymentListUpload";
import UploadPaymentList from "../../Components/DailyReport/UploadPaymentList";
import { compactFormat } from "../../../Functions/formatNumber";
import { downloadMediaUrl } from "../../../API";
import usePagination from "../../../hooks/usePagination";
import Pagination from "../../../Components/Pagination";

function DailyReport() {
    const showAlert = useGeneralStore((state) => state.open_alert);
    const userData = useAuthStore((state) => state.userData);
    const features = useAuthStore((state) => state.features);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});

    const [showUploadFailedPaymentList, setShowUploadFailedPaymentList] = useState(false);
    const [showUploadPaymentList, setShowUploadPaymentList] = useState(false);

    const { currentPage, handleTotal, paginationCallBack, paginationProps } = usePagination({ defaultLimit: 20 });

    function download(dataurl, filename) {
        const link = document.createElement("a");
        link.href = downloadMediaUrl(dataurl);
        link.target = "_blank";
        link.rel = "noreferer";
        if (!!filename) link.download = filename;
        link.click();
        link.remove();
    }

    const fetchData = async () => {
        setLoading(true);

        const res = await getDailyReportFiles({
            client_id: userData?.client_id,
            page: currentPage,
            date_from: !!filters?.date_from ? format(new Date(filters?.date_from), "yyyy-MM-dd") : null,
            date_to: !!filters?.date_to ? format(new Date(filters?.date_to), "yyyy-MM-dd") : null,
        });
        if (res) {
            setData(res?.data);
            handleTotal(Number(res?.total));
        } else {
            showAlert({ variant: "danger", msg: "Unable to fetch data" });
        }

        setLoading(false);
    };

    useEffect(() => {
        paginationCallBack(fetchData);
    }, [currentPage, filters]);

    return (
        <>
            <DailyReportHeader filters={filters} setFilters={setFilters} />

            {!!data.length && (
                <>
                    <div style={{ overflow: "hidden" }}>
                        {data?.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <p className="mb-0 tx-16">
                                            <strong>{!!item?.date ? format(new Date(item?.date), "dd MMM yyyy") : ""}</strong>
                                        </p>

                                        {features?.includes("payment_scrapping") && (
                                            <>
                                                <hr className="w-75 mt-2 mb-3" />

                                                <p className="mb-2">
                                                    <span>Collection</span>
                                                    <span className="fw-bold"> - Rs. {compactFormat(item?.collected_today || 0)}</span>
                                                </p>
                                                <p className="mb-2">
                                                    <span>Accounts Paid</span>
                                                    <span className="fw-bold"> - {item?.collected_today_cnt || 0}</span>
                                                </p>
                                            </>
                                        )}
                                    </div>

                                    <div className="col-md-6">
                                        <table
                                            style={{ marginTop: "0", borderRadius: "0.75rem", overflow: "hidden", width: "fit-content" }}
                                            className="table table-bordered table-striped mx-auto"
                                        >
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle">Allocation</td>
                                                    <td className="align-middle fw-bold">{compactFormat(item?.total_allocation || 0)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle">Collection Till Date</td>
                                                    <td className="align-middle fw-bold">{compactFormat(item?.total_collected || 0)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle">Resolution Till Date</td>
                                                    <td className="align-middle fw-bold">{`${item?.resolution_percent || 0} %`}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-md-3">
                                        <p className="mb-1">Action</p>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="primary"
                                                className="w-100 text-start d-flex align-items-center justify-content-between select-style"
                                            >
                                                Select <FiChevronDown />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="select-style-dropdown">
                                                <Dropdown.Item
                                                    disabled={!item?.payment_summary_url}
                                                    onClick={() => download(item?.payment_summary_url, item?.payment_summary_url?.split("/")?.pop())}
                                                >
                                                    Download Summary
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    disabled={!item?.calling_mis_url}
                                                    onClick={() => download(item?.calling_mis_url, item?.calling_mis_url?.split("/")?.pop())}
                                                >
                                                    Download Legal Calling MIS
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    disabled={!item?.payment_list_url}
                                                    onClick={() => download(item?.payment_list_url, item?.payment_list_url?.split("/")?.pop())}
                                                >
                                                    Download List of Payments
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => setShowUploadPaymentList(true)}>Upload Payment List</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setShowUploadFailedPaymentList(true)}>Upload Failed Payment List</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <hr />
                            </React.Fragment>
                        ))}
                    </div>
                    <Pagination {...paginationProps} />
                </>
            )}

            {!loading && !data?.length && <NoRecord />}

            {loading && <ContentLoader />}

            <FailedPaymentListUpload show={showUploadFailedPaymentList} setShow={setShowUploadFailedPaymentList} />

            <UploadPaymentList show={showUploadPaymentList} setShow={setShowUploadPaymentList} />
        </>
    );
}

export default DailyReport;
