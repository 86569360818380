import { apiGET, apiPOST, apiPUT } from "../utils/apiHandler";

export const login = (body) => apiPOST("/account/api/login/", body);
export const logout = () => apiPOST("/account/api/logout/");
export const agentLogout = (params) => apiGET("/dashboard/api/agent_logout/", params);
export const sendAgentOTP = (body) => apiPOST("account/api/send_login_otp/", body);

export const whatsappLogin = (body = { sender_phone_no: null, agent_exotel_number: null }) => apiPOST("/dashboard/api/hit_whatsapp_campaign_api/", body);

export const whatsappSendMessage = (body = { sender_phone_no: null, data: [], msg_limit_per_minute: 5 }) =>
  apiPOST("/dashboard/api/hit_whatsapp_campaign_status_api/", body);

export const getNoticeTypes = (params) => apiGET("/pre_litigation/api/notice-types/", params);
