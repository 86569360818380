import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { postSendReminderToLender } from "../../../API";
import { isEmpty } from "../../../Functions/isEmpty";
import useAuthStore from "../../../store/authStore";
import { showToast } from "../../../utils/handleToast";

function SendReminderToLender({ data }) {
    const userData = useAuthStore((state) => state.userData);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [settlementAmount, setSettlementAmount] = useState(0);
    const [noOfInstallments, setNoOfInstallments] = useState(1);

    const closeModal = () => {
        setShow(false);
        setLoading(false);
        setSettlementAmount(0);
        setNoOfInstallments(1);
    };

    const sendReminder = async () => {
        setLoading(true);

        if (!userData?.full_name) {
            showToast({ variant: "warning", message: "Agent name not found" });
            return;
        }

        if (!data?.data?.customer_id) {
            showToast({ variant: "warning", message: "Customer not found" });
            return;
        }

        if (!data?.data?.customer_mobile_number) {
            showToast({ variant: "warning", message: "Mobile number not found" });
            return;
        }

        if (!data?.client_name) {
            showToast({ variant: "warning", message: "Client name not found" });
            return;
        }

        if (!settlementAmount) {
            showToast({ variant: "warning", message: "Settlement Amount cannot be empty" });
            return;
        }

        const eachSetlAmount = !!noOfInstallments ? Number(Number(settlementAmount) / noOfInstallments).toFixed(2) : 0;
        const settlementEmis = [...Array(noOfInstallments).keys()]?.map((no) => ({ emi_no: no + 1, amount: eachSetlAmount }));

        const res = await postSendReminderToLender({
            agent_name: userData?.full_name,
            client_name: data?.client_name,
            customer_id: data?.data?.customer_id,
            mobile_number: data?.data?.customer_mobile_number,
            settlement_amount: settlementAmount,
            settlement_emi_data: settlementEmis,
        });

        if (res) {
            showToast({ variant: "success", message: res?.message });
            closeModal();
        } else {
            showToast({ variant: "danger", message: "Failed to send" });
        }

        setLoading(false);
    };

    return (
        <>
            <a className="dropdown-item" onClick={() => setShow(true)}>
                Send Reminder To Lender
            </a>

            <Modal className="popup-style-one settlement-popup" size="md" show={show}>
                <Modal.Header>
                    <Modal.Title>Send Reminder To Lender</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="popup-content">
                        <table style={{ border: "none" }} className="table w-100 mt-0">
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }} className="py-1 px-0 text-left">
                                        Total Outstanding :
                                    </td>
                                    <td style={{ border: "none" }} className="py-1 px-0 text-left">
                                        <strong>{!!data?.data?.pos ? `Rs. ${data?.data?.pos}` : ""}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ border: "none" }} className="py-1 px-0 text-right">
                                        Pre Approved Amount :
                                    </td>
                                    <td style={{ border: "none" }} className="py-1 px-0 text-right">
                                        <strong>
                                            {!isEmpty(data?.data?.pre_approved_sattlement_amount) ? `Rs. ${data?.data?.pre_approved_sattlement_amount}` : ""}
                                        </strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="pt-2">
                            <p className="mb-1">Final Settlement Amount</p>
                            <input
                                type="text"
                                className="form-control mb-1"
                                placeholder="Enter Amount"
                                value={settlementAmount}
                                onChange={(e) => (!isNaN(e.target.value) ? setSettlementAmount(e.target.value) : {})}
                            />
                            <div className="filter-note text-14 text-danger text-start">
                                Amount must be in range {!!data?.data?.pos ? data?.data?.pos : ""} to{" "}
                                {!!data?.data?.pre_approved_sattlement_amount ? data?.data?.pre_approved_sattlement_amount : ""}
                            </div>
                        </div>

                        <div className="mt-4">
                            <p className="mb-2">Number of Installments</p>
                            <div className="d-flex align-items-center justify-content-start gap-3">
                                {[1, 2, 3].map((item, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            border: "1px solid",
                                            borderRadius: "0.5rem",
                                            width: "2.5rem",
                                            height: "2.5rem",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            backgroundColor: noOfInstallments === item ? "#EFFBFE" : "transparent",
                                            borderColor: noOfInstallments === item ? "#4498B8" : "#dedede",
                                            color: noOfInstallments === item ? "#4498B8" : "#000",
                                        }}
                                        onClick={() => setNoOfInstallments(item)}
                                    >
                                        {item}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>

                    <Button disabled={loading || !settlementAmount} onClick={sendReminder} variant="primary">
                        {loading ? "Sending..." : "Send"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SendReminderToLender;
