import { Fragment, useEffect, useState } from "react";
import AnalysisCard from "./analysis-card";
import useBorrowerInfo from "../../store";
import { FaPhone } from "react-icons/fa6";
import { Offcanvas } from "react-bootstrap";
import "./style.call-record.css";
import { IoIosArrowForward } from "react-icons/io";
import { getCampaignsCallRecord } from "../../../../../apis";
import moment from "moment";
import { IoCloseCircle } from "react-icons/io5";
import ContentLoader from "../../../../../Components/ContentLoader";
import CallDetails from "./call-details";

export default function CallResponse() {
  const analysis = useBorrowerInfo((state) => state.analysis);

  const customerId = analysis?.customer_id;

  const [isOpen, setIsOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [callInView, setCallInView] = useState({ sid: null, status: null });

  const toggle = () => setIsOpen((prev) => !prev);
  const toggleDetails = () => setShowDetails((prev) => !prev);

  const showCallDetail = (sid, status) => (setCallInView({ sid, status }), toggleDetails());

  return (
    <>
      <AnalysisCard onClick={toggle} title="Call Response" tag={analysis?.call_ai_tag} content={analysis?.call_ai_disposition} icon={FaPhone} />
      <CallRecord showCallDetail={showCallDetail} isOpen={isOpen} toggle={toggle} customerId={customerId} />
      {showDetails && (
        <CallDetails
          show={showDetails}
          toggleShow={toggleDetails}
          sid={callInView.sid}
          callStatus={callInView.status}
          backdropClassName="opacity-0"
          placement="end"
          style={{ width: "25rem" }}
        />
      )}
    </>
  );
}

const dateStyle = {
  padding: "5px 24px 5px 0",
  fontWeight: "500",
  borderRight: "3px solid #e0e0e0",
  fontSize: "0.95rem",
};

function CallRecord({ showCallDetail, isOpen, toggle, customerId }) {
  const [details, setDetails] = useState(null);
  const seenDates = new Set();
  const seenDatesMissed = new Set();
  const seenDatesConnected = new Set();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getCampaignsCallRecord({
        customer: customerId,
      });

      if (res.success) {
        setDetails(res.data?.data);
      }
    };
    if (customerId && isOpen) fetchData();

    // return () => setDetails(null);
  }, [customerId, isOpen]);

  function filterByStatus(data, status) {
    if (data?.length > 0) {
      if (status === "missed") {
        return data.filter((item) => item.status === status).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } else if (status === "completed") {
        return data.filter((item) => item.status === "completed").sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } else {
        return data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }
    }
    return [];
  }

  const checkDate = (dateString) => {
    const givenDate = moment(dateString);
    const today = moment();
    const tomorrow = moment().add(1, "days");
    if (givenDate.isSame(today, "day")) {
      return "Today";
    } else if (givenDate.isSame(tomorrow, "day")) {
      return "Tomorrow";
    } else {
      return givenDate.format("DD MMM YYYY");
    }
  };

  return (
    <Offcanvas style={{ width: "28rem", paddingTop: "0.75rem" }} show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
      <Offcanvas.Header>
        <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100 px-2">
          <div className="fw-600 fs-4">Call Record {details?.attempted_length && `(${details.attempted_length})`} </div>{" "}
          <button onClick={toggle} type="button" className="bg-white border-0">
            <IoCloseCircle fill="#999999" size={30} />
          </button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!details ? (
          <div className="position-relative top-50">
            <ContentLoader />
          </div>
        ) : !details.attempted_length ? (
          <div className="d-flex justify-content-center align-items-center w-100 py-5">No data Found</div>
        ) : (
          <div className="call_record_box mt-1">
            <div className="counter_box">
              <div className="font_box">
                <h4 className="h4 fw-bolder m-0 my-1 p-0">{details?.attempted_length}</h4>
                <p className="fw-normal text-black fs-6">Attempted</p>
              </div>
              <div className="font_box">
                <h4 className="h4 fw-bolder m-0 p-0 my-1">{details?.connected_length}</h4>
                <p className="fw-normal text-black fs-6">Connected</p>
              </div>
              <div className="font_box">
                <h4 className="h4 fw-bolder m-0 p-0 my-1">{details?.missed_length}</h4>
                <p className="fw-normal text-black fs-6">Missed</p>
              </div>
            </div>
            <div className="tabbing_box">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ background: "white", margin: "0.5rem 0" }}>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    All
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Connected
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Missed
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div className="coll_coter_deta">
                    {filterByStatus(details?.list, "")?.map((val, i) => {
                      let date = new Date(val?.created_at);
                      date.getHours() >= 19 && date.setHours(18);
                      const displayDate = checkDate(val?.created_at);
                      const dateHeader = !seenDates.has(displayDate) ? (
                        <h2 className="my-3 fs-6" key={displayDate}>
                          {displayDate}
                        </h2>
                      ) : null;
                      seenDates.add(displayDate);
                      return (
                        <Fragment key={i}>
                          {dateHeader}
                          {val?.status === "busy" && (
                            <div className="date_font">
                              <p>
                                <span style={dateStyle}>{moment(date).format("hh:mm A")}</span>{" "}
                                <span className="bus" style={{ fontWeight: "bold", fontSize: "0.94rem" }}>
                                  busy
                                </span>
                              </p>
                            </div>
                          )}
                          {(val?.status === "no-answer" || val?.status === "missed") && (
                            <div className="date_font">
                              <p>
                                <span style={dateStyle}>{moment(date).format("hh:mm A")}</span>{" "}
                                <span className="no" style={{ fontWeight: "bold", fontSize: "0.94rem" }}>
                                  no answer
                                </span>
                              </p>
                            </div>
                          )}
                          {val?.status === "completed" && (
                            <div className="date_font" role="button" onClick={() => showCallDetail(val.call_sid, val?.status)}>
                              <p>
                                <span style={dateStyle}>{moment(date).format("hh:mm A")}</span>{" "}
                                <span className="ans" style={{ fontWeight: "bold", fontSize: "0.94rem" }}>
                                  Connected
                                </span>
                              </p>
                              <IoIosArrowForward className="icon" />
                            </div>
                          )}
                          {(val?.status === "failed-no-attempt" || val?.status === "failed") && (
                            <div className="date_font">
                              <p>
                                <span style={dateStyle}>{moment(date).format("hh:mm A")}</span>{" "}
                                <span className="yellow" style={{ fontWeight: "bold", fontSize: "0.94rem" }}>
                                  attempt failed
                                </span>
                              </p>
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div className="coll_coter_deta">
                    {filterByStatus(details?.list, "completed")?.map((val, index) => {
                      const displayDate = checkDate(val?.created_at);
                      const dateHeader = !seenDatesMissed.has(displayDate) ? (
                        <h2 className="my-3 fs-6" key={displayDate}>
                          {displayDate}
                        </h2>
                      ) : null;
                      seenDatesMissed.add(displayDate);
                      return (
                        <Fragment key={index}>
                          {dateHeader}
                          {val?.status === "busy" && (
                            <div className="date_font">
                              <p>
                                <span style={dateStyle}>{moment(val?.created_at).format("hh:mm A")}</span>{" "}
                                <span className="bus" style={{ fontWeight: "bold", fontSize: "0.94rem" }}>
                                  busy
                                </span>
                              </p>
                            </div>
                          )}
                          {val?.status === "no-answer" && (
                            <div className="date_font">
                              <p>
                                <span style={dateStyle}>{moment(val?.created_at).format("hh:mm A")}</span>{" "}
                                <span className="no-answer" style={{ fontWeight: "bold", fontSize: "0.94rem" }}>
                                  no answer
                                </span>
                              </p>
                            </div>
                          )}
                          {val?.status === "completed" && (
                            <div className="date_font" role="button" onClick={() => showCallDetail(val.call_sid, val?.status)}>
                              <p>
                                <span style={dateStyle}>{moment(val?.created_at).format("hh:mm A")}</span>{" "}
                                <span className="ans" style={{ fontWeight: "bold", fontSize: "0.94rem" }}>
                                  Connected
                                </span>
                              </p>
                              <IoIosArrowForward className="icon" />
                            </div>
                          )}
                          {(val?.status === "failed-no-attempt" || val?.status === "failed") && (
                            <div className="date_font">
                              <p>
                                <span style={dateStyle}>{moment(val?.created_at).format("hh:mm A")}</span>{" "}
                                <span className="yellow" style={{ fontWeight: "bold", fontSize: "0.94rem" }}>
                                  attempt failed
                                </span>
                              </p>
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div className="coll_coter_deta">
                    {filterByStatus(details?.list, "missed")?.map((val, index) => {
                      const displayDate = checkDate(val?.created_at);
                      const dateHeader = !seenDatesConnected.has(displayDate) ? (
                        <h2 className="my-3 fs-6" key={displayDate}>
                          {displayDate}
                        </h2>
                      ) : null;
                      seenDatesConnected.add(displayDate);
                      return (
                        <Fragment key={index}>
                          {dateHeader}
                          <div className="date_font">
                            <p>
                              <span style={dateStyle}>{moment(val?.created_at).format("hh:mm A")} </span>{" "}
                              <span className="no" style={{ fontWeight: "bold", fontSize: "0.94rem" }}>
                                no answer
                              </span>
                            </p>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
