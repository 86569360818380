// validate Email
export const isValidEmail = (email) => {
    return !!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

// validate number
export const isNumber = (num) => !!String(num).match(/^\d+$/);

// validate phone number
export const isValidMobileNumber = (mobile) => {
    if (!mobile) return false;
    let mobileStr = mobile?.toString();
    
    if(mobileStr.length == 10 || mobileStr.length == 12){

        if(mobileStr?.length == 12){
            if(mobileStr[0] != '9' || mobileStr[1] != '1') return false;
        }
    
        if(isNumber(mobileStr)) return true;

    }

    return false;
};
