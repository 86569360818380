import React, { useEffect } from "react";
import { toast, Toaster, useToasterStore } from "react-hot-toast";

const TOAST_LIMIT = 1;

export default function ToastWrapper() {
    const { toasts } = useToasterStore();

    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
            .forEach((t) => toast.remove(t.id)); // Dismiss – Use toast.dismiss(t.id) for exit animation
    }, [toasts]);

    return <Toaster containerStyle={{ top: 0, left: 0, bottom: 0, right: 0 }} />;
}
