import React from "react";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Accordion from "react-bootstrap/Accordion";

import Hamburger from "../../Static/RawImages/hamburger.png";
import Logo from "../../Static/RawImages/logo.png";
import { DEFAULT_PAGE } from "../../utils/PanelConstants";
import useGeneralStore from "../../store/generalStore";
import useAuthStore from "../../store/authStore";
import { panelFeatures } from "./ControlledNavigation";

const isActive = (details, allowed = []) => !!allowed?.includes(details?.key);

function Sidebar() {
    const features = useAuthStore((state) => state.features);
    const toggleSidebar = useGeneralStore((state) => state.toggle_sidebar);

    const location = useLocation();

    const checkIfActive = (target) => location.pathname?.toString()?.indexOf(target?.toString()) > -1;

    return (
        <>
            <div className="logo">
                <Link to={DEFAULT_PAGE}>
                    <img src={Logo} alt="logo" />
                </Link>
            </div>

            <div className="menu-toggle" onClick={() => toggleSidebar()}>
                <img src={Hamburger} alt="Hamburger" />
            </div>

            <div className="menu">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <div className="accordion" id="accordionMenu">
                            {isActive(panelFeatures.DASHBOARD, features) && (
                                // {!userSpecific?.hide_dashboard && (
                                <div className="accordion-item no-submenu">
                                    <h2 className="accordion-header" id="headingOne">
                                        <div
                                            className="accordion-button nav-link"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <Link to="/" className="nav-link">
                                                <div className="m-icon">
                                                    <svg width="23" height="19" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M0 .95v10.197c0 2.203 1.88 3.99 4.2 3.99h4.07l-.7 2.66H6.066v.886h10.266v-.886h-1.502l-.7-2.66H18.2c2.32 0 4.2-1.787 4.2-3.99V.95H0zm13.87 16.847H8.53l.7-2.66h3.94l.7 2.66zm7.597-6.65c0 1.714-1.463 3.103-3.267 3.103h-14c-1.804 0-3.267-1.39-3.267-3.103v-9.31h20.534v9.31z"
                                                            fill="#505050"
                                                            fillRule="nonzero"
                                                        />
                                                    </svg>
                                                </div>
                                                <span
                                                    className="menu-text"
                                                    style={location.pathname?.toString() === "/" ? { fontWeight: "bold" } : { fontWeight: "normal" }}
                                                >
                                                    Dashboard
                                                </span>
                                            </Link>
                                        </div>
                                    </h2>
                                </div>
                            )}
                            {isActive(panelFeatures.COLLECTION, features) && (
                                // {!userSpecific?.hide_collection && (
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <div
                                            // className={useRouteMatch("/collection") ? "accordion-button" : "accordion-button collapsed"}
                                            className={checkIfActive("/collection") ? "accordion-button" : "accordion-button collapsed"}
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            <a
                                                className="nav-link"
                                                style={
                                                    checkIfActive("/collection")
                                                        ? { fontWeight: "bold", color: "#000000" }
                                                        : { fontWeight: "normal", color: "#505050" }
                                                }
                                            >
                                                <div className="m-icon" data-toggle="tooltip" data-placement="right" title="Collection">
                                                    <Tooltip title="Collection" placement="right">
                                                        <svg width="23" height="23" xmlns="http://www.w3.org/2000/svg">
                                                            <g fill="#505050" fillRule="nonzero">
                                                                <path d="M11.2 0C5.014 0 0 5.014 0 11.2c0 6.186 5.014 11.2 11.2 11.2 6.186 0 11.2-5.014 11.2-11.2A11.2 11.2 0 0 0 11.2 0zm0 21.467C5.53 21.467.933 16.87.933 11.2.933 5.53 5.53.933 11.2.933c5.67 0 10.267 4.597 10.267 10.267A10.267 10.267 0 0 1 11.2 21.467z" />
                                                                <path d="m11.727 10.7.57-4.993c.6.155 1.175.399 1.703.724l.467-.808a6.949 6.949 0 0 0-2.068-.854l.201-1.684-.933-.108-.192 1.69h-.042c-1.782 0-3.462 1.498-3.462 3.084 0 1.442.905 2.595 2.753 3.514l-.625 5.46a5.175 5.175 0 0 1-1.834-.756l-.094-.056-.466.803.093.056c.67.439 1.42.738 2.207.882L9.8 19.315l.933.108.192-1.69h.042c1.95 0 3.966-1.241 3.966-3.322 0-1.694-.844-2.674-3.206-3.71zM8.904 7.752c0-1.045 1.237-2.109 2.469-2.146l-.537 4.662c-1.293-.714-1.932-1.54-1.932-2.516zm2.128 9.049.583-5.133C13.617 12.6 14 13.337 14 14.41c0 1.619-1.717 2.361-2.968 2.389z" />
                                                            </g>
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                                <span className="menu-text">Collection</span>
                                            </a>
                                        </div>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className={checkIfActive("/collection") ? "accordion-collapse" : "accordion-collapse collapse"}
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionMenu"
                                    >
                                        <div className="accordion-body">
                                            <div className="sidebar_submenu ">
                                                <ul className="nav flex-column">
                                                    {isActive(panelFeatures.COLLECTION_BATCH, features) && (
                                                        <li className={`nav-item ${checkIfActive("/collection/batch") && "active"}`}>
                                                            <Link className="nav-link" to="/collection/batch">
                                                                <div className="m-icon">
                                                                    <Tooltip title="Batch" placement="right">
                                                                        <svg width="23" height="19" xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M7.933.876A2.333 2.333 0 0 0 5.6 3.21v.466h-.467A2.333 2.333 0 0 0 2.8 6.01v.466h-.275A2.53 2.53 0 0 0 0 9.001v9.609h14.275a2.53 2.53 0 0 0 2.525-2.525v-.275h.467a2.333 2.333 0 0 0 2.333-2.334v-.466h.467a2.333 2.333 0 0 0 2.333-2.334v-9.8H7.933zm7.934 15.209c0 .879-.713 1.591-1.592 1.591H.933V9.001c0-.879.713-1.591 1.592-1.591h13.342v8.675zm2.8-2.609a1.4 1.4 0 0 1-1.4 1.4H16.8v-8.4H3.733V6.01a1.4 1.4 0 0 1 1.4-1.4h13.534v8.866zm2.8-2.8a1.4 1.4 0 0 1-1.4 1.4H19.6v-8.4H6.533V3.21a1.4 1.4 0 0 1 1.4-1.4h13.534v8.866z"
                                                                                fill="#505050"
                                                                                fillRule="nonzero"
                                                                            />
                                                                        </svg>
                                                                    </Tooltip>
                                                                </div>
                                                                <span className="menu-text">Batch</span>
                                                            </Link>
                                                        </li>
                                                    )}

                                                    <li className={`nav-item ${checkIfActive("/collection/accounts") && "active"}`}>
                                                            <Link className="nav-link" to="/collection/accounts">
                                                                <div className="m-icon">
                                                                    <Tooltip title="Batch" placement="right">
                                                                        <svg width="23" height="19" xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M7.933.876A2.333 2.333 0 0 0 5.6 3.21v.466h-.467A2.333 2.333 0 0 0 2.8 6.01v.466h-.275A2.53 2.53 0 0 0 0 9.001v9.609h14.275a2.53 2.53 0 0 0 2.525-2.525v-.275h.467a2.333 2.333 0 0 0 2.333-2.334v-.466h.467a2.333 2.333 0 0 0 2.333-2.334v-9.8H7.933zm7.934 15.209c0 .879-.713 1.591-1.592 1.591H.933V9.001c0-.879.713-1.591 1.592-1.591h13.342v8.675zm2.8-2.609a1.4 1.4 0 0 1-1.4 1.4H16.8v-8.4H3.733V6.01a1.4 1.4 0 0 1 1.4-1.4h13.534v8.866zm2.8-2.8a1.4 1.4 0 0 1-1.4 1.4H19.6v-8.4H6.533V3.21a1.4 1.4 0 0 1 1.4-1.4h13.534v8.866z"
                                                                                fill="#505050"
                                                                                fillRule="nonzero"
                                                                            />
                                                                        </svg>
                                                                    </Tooltip>
                                                                </div>
                                                                <span className="menu-text">Accounts</span>
                                                            </Link>
                                                        </li>

                                                    {isActive(panelFeatures.SETTLEMENT_TRACKER, features) && (
                                                        // {!!userData?.active_client?.collection_active && (
                                                        <li className={`nav-item ${checkIfActive("/collection/settlement-tracker") && "active"}`}>
                                                            <Link className="nav-link" to="/collection/settlement-tracker">
                                                                <div className="m-icon">
                                                                    <svg
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 54 54"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M30.5 12.25C30.2236 12.25 30 12.4736 30 12.75V13.25C30 13.5264 30.2236 13.75 30.5 13.75H31.4062L21.5635 25.6436C21.3418 25.9111 20.9219 25.877 20.7461 25.5762L17.9619 20.8027C17.9229 20.7373 17.873 20.6846 17.8154 20.6445C17.7803 20.6191 17.7422 20.5996 17.7031 20.585C17.5088 20.5146 17.2764 20.5664 17.1348 20.748L10.2148 29.6445C10.0459 29.8633 10.085 30.1768 10.3027 30.3467L10.6973 30.6533C10.8105 30.7412 10.9482 30.7734 11.0791 30.7529C11.2012 30.7344 11.3174 30.6709 11.3994 30.5654L16.957 23.4199C17.0391 23.3154 17.1514 23.2539 17.2686 23.2344C17.3408 23.2217 17.416 23.2256 17.4873 23.2451C17.6064 23.2783 17.7148 23.3555 17.7842 23.4746L20.5332 28.1875C20.708 28.4873 21.1279 28.5215 21.3496 28.2539L32.5303 14.7441L32.6777 15.6299C32.708 15.8096 32.8301 15.9512 32.9883 16.0137C33.0264 16.0283 33.0654 16.0391 33.1064 16.0449C33.1543 16.0508 33.2041 16.0498 33.2539 16.041L33.7471 15.959C33.9375 15.9268 34.085 15.791 34.1406 15.6191C34.165 15.5459 34.1719 15.4658 34.1582 15.3838L33.7051 12.668C33.665 12.4268 33.4561 12.25 33.2119 12.25H30.5Z"
                                                                            fill="black"
                                                                        />
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M44 22C44 27.9697 41.623 33.3838 37.7627 37.3477L39.8613 39.4463C40.2266 39.209 40.6328 39.0586 41.0488 38.9961C41.957 38.8604 42.916 39.1416 43.6162 39.8418L52.3447 48.5703C53.5166 49.7422 53.5166 51.6416 52.3447 52.8135L52.0947 53.0635C50.9238 54.2344 49.0234 54.2344 47.8525 53.0635L39.123 44.334C38.2061 43.417 38.0068 42.0518 38.5264 40.9404L36.3027 38.7168C32.457 42.0107 27.4609 44 22 44C9.84961 44 0 34.1504 0 22C0 9.84961 9.84961 0 22 0C34.1504 0 44 9.84961 44 22ZM40 22C40 31.9414 31.9414 40 22 40C12.0586 40 4 31.9414 4 22C4 12.0586 12.0586 4 22 4C31.9414 4 40 12.0586 40 22Z"
                                                                            fill="black"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <span>Settlement Tracker</span>
                                                            </Link>
                                                        </li>
                                                    )}

                                                    {isActive(panelFeatures.DAILY_SETL_REPORT, features) && (
                                                        // {!!userData?.active_client?.collection_active && (
                                                        <li className={`nav-item ${checkIfActive("/collection/daily-settlement-report") && "active"}`}>
                                                            <Link className="nav-link" to="/collection/daily-settlement-report">
                                                                <div className="m-icon">
                                                                    <svg
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 54 54"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M30.5 12.25C30.2236 12.25 30 12.4736 30 12.75V13.25C30 13.5264 30.2236 13.75 30.5 13.75H31.4062L21.5635 25.6436C21.3418 25.9111 20.9219 25.877 20.7461 25.5762L17.9619 20.8027C17.9229 20.7373 17.873 20.6846 17.8154 20.6445C17.7803 20.6191 17.7422 20.5996 17.7031 20.585C17.5088 20.5146 17.2764 20.5664 17.1348 20.748L10.2148 29.6445C10.0459 29.8633 10.085 30.1768 10.3027 30.3467L10.6973 30.6533C10.8105 30.7412 10.9482 30.7734 11.0791 30.7529C11.2012 30.7344 11.3174 30.6709 11.3994 30.5654L16.957 23.4199C17.0391 23.3154 17.1514 23.2539 17.2686 23.2344C17.3408 23.2217 17.416 23.2256 17.4873 23.2451C17.6064 23.2783 17.7148 23.3555 17.7842 23.4746L20.5332 28.1875C20.708 28.4873 21.1279 28.5215 21.3496 28.2539L32.5303 14.7441L32.6777 15.6299C32.708 15.8096 32.8301 15.9512 32.9883 16.0137C33.0264 16.0283 33.0654 16.0391 33.1064 16.0449C33.1543 16.0508 33.2041 16.0498 33.2539 16.041L33.7471 15.959C33.9375 15.9268 34.085 15.791 34.1406 15.6191C34.165 15.5459 34.1719 15.4658 34.1582 15.3838L33.7051 12.668C33.665 12.4268 33.4561 12.25 33.2119 12.25H30.5Z"
                                                                            fill="black"
                                                                        />
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M44 22C44 27.9697 41.623 33.3838 37.7627 37.3477L39.8613 39.4463C40.2266 39.209 40.6328 39.0586 41.0488 38.9961C41.957 38.8604 42.916 39.1416 43.6162 39.8418L52.3447 48.5703C53.5166 49.7422 53.5166 51.6416 52.3447 52.8135L52.0947 53.0635C50.9238 54.2344 49.0234 54.2344 47.8525 53.0635L39.123 44.334C38.2061 43.417 38.0068 42.0518 38.5264 40.9404L36.3027 38.7168C32.457 42.0107 27.4609 44 22 44C9.84961 44 0 34.1504 0 22C0 9.84961 9.84961 0 22 0C34.1504 0 44 9.84961 44 22ZM40 22C40 31.9414 31.9414 40 22 40C12.0586 40 4 31.9414 4 22C4 12.0586 12.0586 4 22 4C31.9414 4 40 12.0586 40 22Z"
                                                                            fill="black"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <span>Daily SETL Report</span>
                                                            </Link>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {isActive(panelFeatures.PRELITIGATION, features) && (
                                <div className={`accordion-item ${checkIfActive("/prelitigation") && "active"}`}>
                                    <h2 className="accordion-header" id="headingThree">
                                        <div
                                            className={checkIfActive("/prelitigation") ? "accordion-button" : "accordion-button collapsed"}
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            <a
                                                className="nav-link"
                                                style={
                                                    checkIfActive("/prelitigation")
                                                        ? { fontWeight: "bold", color: "#000000" }
                                                        : { fontWeight: "normal", color: "#505050" }
                                                }
                                            >
                                                <div className="m-icon">
                                                    <Tooltip title="Pre-Litigation" placement="right">
                                                        <svg width="17" height="22" xmlns="http://www.w3.org/2000/svg">
                                                            <g fill="#505050" fillRule="nonzero">
                                                                <path d="M0 .437v20.695h10.733c3.35 0 6.067-2.51 6.067-5.605V.437H0zm15.867 15.09c0 2.62-2.299 4.743-5.134 4.743h-9.8V1.3h14.934v14.227z" />
                                                                <path d="M4.667 6.473h7.467v1H4.667zM4.667 9.922h7.467v1H4.667zM4.667 13.371h5.6v1h-5.6z" />
                                                            </g>
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                                <span className="menu-text">Pre-Litigation</span>
                                            </a>
                                        </div>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className={checkIfActive("/prelitigation") ? "accordion-collapse" : "accordion-collapse collapse"}
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionMenu"
                                    >
                                        <ul className="accordion-body">
                                            <Accordion defaultActiveKey="1" className="level-two">
                                                {isActive(panelFeatures.PRELITIGATION_NOTICES, features) && (
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>
                                                            <li className={`nav-item ${checkIfActive("/prelitigation/legal") && "active"}`}>
                                                                <a className="nav-link">
                                                                    <div className="m-icon">
                                                                        <Tooltip title="Legal Notices" placement="right">
                                                                            <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M12.343 2.743c-.46.009-.919.053-1.372.132V0h-.457A10.862 10.862 0 0 0 3.9 2.286 11.333 11.333 0 0 0 0 8.229v.457l3.2.772a9.6 9.6 0 1 0 9.143-6.715zm-7.886.247a9.957 9.957 0 0 1 5.6-2.076v9.459l-9.033-2.45A10.117 10.117 0 0 1 4.457 2.99zm7.886 18.039A8.686 8.686 0 0 1 4.078 9.696l6.893 1.874V3.8a8.398 8.398 0 0 1 1.372-.142 8.686 8.686 0 0 1 0 17.372z"
                                                                                    fill="#505050"
                                                                                    fillRule="nonzero"
                                                                                />
                                                                            </svg>
                                                                        </Tooltip>
                                                                    </div>
                                                                    <span className="menu-text">Legal Notices</span>
                                                                </a>
                                                            </li>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="sidebar_submenu ">
                                                                {isActive(panelFeatures.PRELITIGATION_NOTICES_BATCH, features) && (
                                                                    <li className={`nav-item ${checkIfActive("/prelitigation/legal/batch") && "active"}`}>
                                                                        <Link className="nav-link" to="/prelitigation/legal/batch">
                                                                            <div className="m-icon">
                                                                                <Tooltip title="Batch" placement="right">
                                                                                    <svg width="23" height="19" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M7.933.876A2.333 2.333 0 0 0 5.6 3.21v.466h-.467A2.333 2.333 0 0 0 2.8 6.01v.466h-.275A2.53 2.53 0 0 0 0 9.001v9.609h14.275a2.53 2.53 0 0 0 2.525-2.525v-.275h.467a2.333 2.333 0 0 0 2.333-2.334v-.466h.467a2.333 2.333 0 0 0 2.333-2.334v-9.8H7.933zm7.934 15.209c0 .879-.713 1.591-1.592 1.591H.933V9.001c0-.879.713-1.591 1.592-1.591h13.342v8.675zm2.8-2.609a1.4 1.4 0 0 1-1.4 1.4H16.8v-8.4H3.733V6.01a1.4 1.4 0 0 1 1.4-1.4h13.534v8.866zm2.8-2.8a1.4 1.4 0 0 1-1.4 1.4H19.6v-8.4H6.533V3.21a1.4 1.4 0 0 1 1.4-1.4h13.534v8.866z"
                                                                                            fill="#505050"
                                                                                            fillRule="nonzero"
                                                                                        />
                                                                                    </svg>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <span className="menu-text">Batch</span>
                                                                        </Link>
                                                                    </li>
                                                                )}

                                                                {isActive(panelFeatures.PRELITIGATION_NOTICES_NOTICES, features) && (
                                                                    <li className={`nav-item ${checkIfActive("/prelitigation/legal/notices") && "active"}`}>
                                                                        <Link className="nav-link" to="/prelitigation/legal/notices">
                                                                            <div className="m-icon">
                                                                                <Tooltip title="Notices" placement="right">
                                                                                    <svg width="28" height="20" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g fill="#505050" fillRule="nonzero">
                                                                                            <path d="M5.8 6.56c.405 2.143 1.905 3.53 3.82 3.53 1.915 0 3.385-1.35 3.82-3.525.07-.332.405-2.014.425-2.422a3.626 3.626 0 0 0-1.105-2.765A4.502 4.502 0 0 0 9.596.118a4.498 4.498 0 0 0-3.151 1.289 3.559 3.559 0 0 0-1.07 2.745c.025.414.385 2.21.425 2.409zm1.375-4.502a3.474 3.474 0 0 1 2.433-.98c.917 0 1.794.354 2.432.98.563.551.861 1.299.825 2.066 0 .261-.255 1.544-.41 2.285-.265 1.306-1.15 2.73-2.835 2.73S7 7.659 6.78 6.386c-.15-.746-.39-2.028-.41-2.294-.04-.755.251-1.491.805-2.033zM15.73 12.332l-6.11-.78-6.075.766c-1.5.28-3.135 1.273-3.345 2.85L0 19.115h19.385l-.24-3.757c-.105-1.554-1.375-2.684-3.415-3.026zM.925 18.165l.265-2.874c.145-1.107 1.42-1.833 2.5-2.038l5.925-.74 5.955.755c.74.123 2.47.584 2.575 2.147l.18 2.75H.925zM16.965 8.408c.335 1.744 1.575 2.85 3.155 2.85 1.58 0 2.795-1.097 3.155-2.85.055-.266.325-1.596.34-1.928a2.943 2.943 0 0 0-.895-2.242 3.73 3.73 0 0 0-2.612-1.052 3.73 3.73 0 0 0-2.613 1.052 2.89 2.89 0 0 0-.87 2.228c.02.356.285 1.686.34 1.942zm1.26-3.49a2.705 2.705 0 0 1 1.887-.757c.711 0 1.392.272 1.888.756.422.412.646.97.62 1.544 0 .19-.195 1.164-.325 1.79-.1.476-.555 2.095-2.175 2.095-1.305 0-2-1.078-2.17-2.094-.12-.585-.31-1.592-.325-1.796a1.975 1.975 0 0 1 .595-1.539h.005zM22 19.115h6l-.055-3.572c-.105-1.287-1.15-2.228-2.83-2.513l-4.935-.565-.12.95 4.91.56c.565.095 1.885.447 2 1.634l.12 2.556H22v.95z" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <span className="menu-text">Notices</span>
                                                                        </Link>
                                                                    </li>
                                                                )}
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )}
                                                {isActive(panelFeatures.PRELITIGATION_FIR, features) && (
                                                    // {!userSpecific?.hide_fir && (
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>
                                                            <li className={`nav-item ${checkIfActive("/prelitigation/fir") && "active"}`}>
                                                                <a className="nav-link">
                                                                    <div className="m-icon">
                                                                        <Tooltip title="FIR" placement="right">
                                                                            <svg
                                                                                width="27px"
                                                                                height="22px"
                                                                                viewBox="0 0 27 22"
                                                                                version="1.1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                    <g
                                                                                        id="Accounts_LEFT_MENU-Copy"
                                                                                        transform="translate(-16.000000, -359.000000)"
                                                                                        fill="#505050"
                                                                                        fillRule="nonzero"
                                                                                    >
                                                                                        <g id="Group-11" transform="translate(0.000000, 288.000000)">
                                                                                            <g id="police" transform="translate(16.000000, 71.000000)">
                                                                                                <g id="Group" transform="translate(14.280000, 0.000000)">
                                                                                                    <path
                                                                                                        d="M2.95897809,1.34178997 C0.353600604,2.90421726 -0.489550698,6.28081845 1.06888062,8.88619593 C2.63130791,11.4915734 6.0079091,12.3387207 8.61328658,10.7762934 C11.2186641,9.2138661 12.0658113,5.83726492 10.503384,3.23188744 C8.94495273,0.626509953 5.56435557,-0.220637326 2.95897809,1.34178997 Z M8.1005691,9.91882149 C5.96947662,11.1938099 3.20586812,10.5027856 1.92623954,8.37131649 C0.651476459,6.23984733 1.34237859,3.47575034 3.47347106,2.20076194 C5.60456353,0.920907177 8.36817203,1.61193142 9.64780062,3.74826695 C10.9225637,5.8797361 10.2316616,8.64383309 8.1005691,9.91882149 Z"
                                                                                                        id="Shape"
                                                                                                    ></path>
                                                                                                </g>
                                                                                                <g id="Group" transform="translate(0.000000, 8.820000)">
                                                                                                    <path
                                                                                                        d="M4.54384486,0.437950627 C1.6310529,1.2930089 -0.0391076418,4.34564683 0.815950627,7.26243438 C1.6710089,10.1752263 4.72764243,11.8453869 7.64043438,10.9903286 C10.5532263,10.1352703 12.2233869,7.07863681 11.3683286,4.16584486 C10.5132703,1.2530529 7.45663681,-0.417107642 4.54384486,0.437950627 Z M7.25283783,9.9258002 C4.86832578,10.6265548 2.37188765,9.2591101 1.67113309,6.87459805 C0.97037853,4.490086 2.3378232,1.98878152 4.72233525,1.29289331 C7.1068473,0.597005096 9.60328543,1.95958341 10.30404,4.34409546 C11.0047946,6.72860752 9.63734989,9.22991199 7.25283783,9.9258002 Z"
                                                                                                        id="Shape"
                                                                                                    ></path>
                                                                                                </g>
                                                                                                <path
                                                                                                    d="M12.7134,19.0848 C12.1464,19.0848 11.7096,18.8496 11.5542,18.4506 C11.3148,17.8416 11.8188,17.0898 12.705,16.7412 C12.9822,16.632 13.2762,16.5732 13.5534,16.5732 C14.1246,16.5732 14.5572,16.8084 14.7126,17.2074 C14.952,17.8164 14.448,18.5682 13.5618,18.9168 C13.2846,19.026 12.9906,19.0848 12.7134,19.0848 Z M13.5576,16.9932 C13.335,16.9932 13.0914,17.0394 12.8604,17.1318 C12.2178,17.3838 11.7978,17.9172 11.9448,18.2952 C12.0834,18.6522 12.7806,18.7698 13.4064,18.522 C14.049,18.27 14.469,17.7366 14.322,17.3586 C14.2338,17.136 13.9398,16.9932 13.5576,16.9932 Z"
                                                                                                    id="Shape"
                                                                                                ></path>
                                                                                                <path
                                                                                                    d="M14.616,18.2658 C14.1204,18.2658 13.7256,18.06 13.5576,17.7198 C13.272,17.1318 13.7172,16.3422 14.574,15.9264 C14.8974,15.771 15.2334,15.687 15.5526,15.687 C16.0482,15.687 16.443,15.8928 16.611,16.2372 C16.8966,16.8252 16.4514,17.6148 15.5946,18.0306 C15.2754,18.1818 14.9352,18.2658 14.616,18.2658 Z M15.5568,16.107 C15.3006,16.107 15.0234,16.1742 14.7588,16.3044 C14.1372,16.6068 13.7592,17.1696 13.9356,17.535 C14.1036,17.8794 14.805,17.9424 15.4098,17.6484 C16.0314,17.346 16.4094,16.7832 16.233,16.4178 C16.1406,16.2204 15.8844,16.107 15.5568,16.107 Z"
                                                                                                    id="Shape"
                                                                                                ></path>
                                                                                                <path
                                                                                                    d="M16.1322,16.968 C15.876,16.968 15.6576,16.8882 15.4896,16.7328 C15.0066,16.2876 15.1368,15.393 15.7836,14.6916 C16.4052,14.0196 17.3292,13.8096 17.7954,14.238 C18.2784,14.6832 18.1482,15.5778 17.5014,16.2792 C17.0982,16.7076 16.5858,16.968 16.1322,16.968 Z M17.1486,14.4228 C16.8126,14.4228 16.4052,14.637 16.0902,14.9772 C15.6198,15.4854 15.477,16.149 15.7752,16.4262 C16.065,16.6908 16.737,16.4892 17.1906,15.9936 C17.661,15.4854 17.8038,14.8218 17.5056,14.5488 C17.4174,14.4648 17.2956,14.4228 17.1486,14.4228 Z"
                                                                                                    id="Shape"
                                                                                                ></path>
                                                                                                <path
                                                                                                    d="M17.4342,15.1746 C17.2704,15.1746 17.1234,15.1368 16.989,15.057 C16.422,14.7252 16.3548,13.8222 16.8378,13.0032 C17.1906,12.3984 17.7492,12.012 18.2574,12.012 C18.4212,12.012 18.5682,12.0498 18.7026,12.1296 C19.2696,12.4614 19.3326,13.3644 18.8538,14.1834 C18.5052,14.784 17.9466,15.1746 17.4342,15.1746 Z M18.2574,12.4278 C17.9004,12.4278 17.4762,12.7428 17.199,13.2132 C16.8504,13.8096 16.8504,14.49 17.199,14.6958 C17.5434,14.8974 18.1482,14.553 18.4884,13.9692 C18.837,13.3728 18.837,12.6924 18.4884,12.4908 C18.4254,12.4488 18.3456,12.4278 18.2574,12.4278 Z"
                                                                                                    id="Shape"
                                                                                                ></path>
                                                                                                <g id="Group" transform="translate(7.560000, 14.700000)">
                                                                                                    <path
                                                                                                        d="M5.0694,0.6636 L2.7384,0.441 C2.7384,0.441 2.6922,0.4494 1.6338,2.1042 C0.5208,3.7212 0.529199061,3.7716 0.529199061,3.7716 L1.6464,5.8296 C3.7968,4.9014 5.0526,3.003 5.0694,0.6636 Z"
                                                                                                        id="Path"
                                                                                                    ></path>
                                                                                                    <path
                                                                                                        d="M1.6464,6.0396 C1.5708,6.0396 1.4994,5.9976 1.4616,5.9304 L0.3444,3.8724 C0.336,3.8556 0.3276,3.8346 0.3234,3.8178 C0.2982,3.7128 0.294,3.6834 1.4574,1.9866 C2.5578,0.2646 2.5872,0.2562 2.6922,0.2352 C2.7132,0.231 2.7342,0.231 2.7552,0.231 L5.0862,0.4536 C5.1954,0.4662 5.2752,0.5544 5.2752,0.6636 C5.2584,3.1038 3.9648,5.0568 1.7262,6.0228 C1.701,6.0312 1.6758,6.0396 1.6464,6.0396 Z M0.7686,3.7716 L1.7346,5.5566 C3.6456,4.662 4.7712,2.9652 4.851,0.8526 L2.8308,0.6594 C2.7342,0.798 2.4738,1.176 1.8102,2.2176 C1.1088,3.2382 0.8568,3.6288 0.7686,3.7716 Z"
                                                                                                        id="Shape"
                                                                                                    ></path>
                                                                                                </g>
                                                                                                <g id="Group" transform="translate(15.120000, 8.400000)">
                                                                                                    <path
                                                                                                        d="M6.3294,4.1958 L5.9598,1.8858 C5.9598,1.8858 5.9388,1.8438 4.0698,1.239 C2.226,0.5712 2.1798,0.5922 2.1798,0.5922 L0.4662,2.1882 C1.9068,4.032 4.0572,4.7712 6.3294,4.1958 Z"
                                                                                                        id="Path"
                                                                                                    ></path>
                                                                                                    <path
                                                                                                        d="M4.8342,4.5948 L4.8342,4.5948 C3.0576,4.5948 1.449,3.7842 0.2982,2.3142 C0.231,2.2302 0.2394,2.1042 0.3192,2.0328 L2.0328,0.4368 C2.0496,0.4242 2.0622,0.4116 2.0832,0.4032 C2.1126,0.3906 2.1462,0.3822 2.1798,0.3822 C2.226,0.3822 2.3184,0.3822 4.1412,1.0416 C6.0858,1.6716 6.0984,1.6968 6.1446,1.7892 C6.1572,1.8102 6.1614,1.8312 6.1656,1.8522 L6.5352,4.1622 C6.552,4.2714 6.4848,4.3722 6.3798,4.3974 C5.8632,4.5318 5.3424,4.5948 4.8342,4.5948 Z M0.7518,2.205 C1.8102,3.4776 3.2508,4.1748 4.8342,4.1748 L4.8342,4.1748 C5.2458,4.1748 5.67,4.1286 6.09,4.0362 L5.7666,2.0328 C5.607,1.974 5.1744,1.8186 3.9984,1.4364 C2.8308,1.0122 2.394,0.8694 2.2344,0.819 L0.7518,2.205 Z"
                                                                                                        id="Shape"
                                                                                                    ></path>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </Tooltip>
                                                                    </div>
                                                                    <span className="menu-text">FIR</span>
                                                                </a>
                                                            </li>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="sidebar_submenu ">
                                                                {isActive(panelFeatures.PRELITIGATION_FIR_BATCH, features) && (
                                                                    <li className={`nav-item ${checkIfActive("/prelitigation/fir/batch") && "active"}`}>
                                                                        <Link className="nav-link" to="/prelitigation/fir/batch">
                                                                            <div className="m-icon">
                                                                                <Tooltip title="Batch" placement="right">
                                                                                    <svg width="23" height="18" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M7.933 0A2.333 2.333 0 0 0 5.6 2.333V2.8h-.467A2.333 2.333 0 0 0 2.8 5.133V5.6h-.275A2.53 2.53 0 0 0 0 8.125v9.608h14.275A2.53 2.53 0 0 0 16.8 15.21v-.276h.467A2.333 2.333 0 0 0 19.6 12.6v-.467h.467A2.333 2.333 0 0 0 22.4 9.8V0H7.933zm7.934 15.209c0 .879-.713 1.591-1.592 1.591H.933V8.125c0-.88.713-1.592 1.592-1.592h13.342v8.676zm2.8-2.609a1.4 1.4 0 0 1-1.4 1.4H16.8V5.6H3.733v-.467a1.4 1.4 0 0 1 1.4-1.4h13.534V12.6zm2.8-2.8a1.4 1.4 0 0 1-1.4 1.4H19.6V2.8H6.533v-.467a1.4 1.4 0 0 1 1.4-1.4h13.534V9.8z"
                                                                                            fill="#505050"
                                                                                            fillRule="nonzero"
                                                                                        />
                                                                                    </svg>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <span className="menu-text">Batch</span>
                                                                        </Link>
                                                                    </li>
                                                                )}
                                                                {isActive(panelFeatures.PRELITIGATION_FIR_COMPLAINTS, features) && (
                                                                    <li className={`nav-item ${checkIfActive("/prelitigation/fir/complaints") && "active"}`}>
                                                                        <Link className="nav-link" to="/prelitigation/fir/complaints">
                                                                            <div className="m-icon">
                                                                                <Tooltip title="Complaints" placement="right">
                                                                                    <svg width="18" height="23" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g fillRule="nonzero" fill="none">
                                                                                            <path
                                                                                                d="M14.933 2.8V0H0v19.6h2.8v2.8h8.867a6.067 6.067 0 0 0 6.066-6.067V2.8h-2.8zm-14 15.867V.933H14V2.8H2.8v15.867H.933zM16.8 16.333a5.133 5.133 0 0 1-5.133 5.134H3.733V3.733H16.8v12.6z"
                                                                                                fill="#505050"
                                                                                            />
                                                                                            <path
                                                                                                fill="#505050"
                                                                                                d="M7 8.4h6.533v1H7zM7 12.133h6.533v1H7zM7 15.867h4.667v1H7z"
                                                                                            />
                                                                                        </g>
                                                                                    </svg>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <span className="menu-text">Complaints</span>
                                                                        </Link>
                                                                    </li>
                                                                )}
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )}
                                            </Accordion>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </li>

                    {isActive(panelFeatures.ALL_FILES, features) && <hr className="ms-5" /> }

                    <li className="nav-item">
                        <div className="accordion" id="accordionMenuNew">
                            {isActive(panelFeatures.ALL_FILES, features) && (
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <div
                                            className={checkIfActive("/all-files") ? "accordion-button" : "accordion-button collapsed"}
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            <a
                                                className="nav-link"
                                                style={
                                                    checkIfActive("/all-files")
                                                        ? { fontWeight: "bold", color: "#000000" }
                                                        : { fontWeight: "normal", color: "#505050" }
                                                }
                                            >
                                                <div className="m-icon">
                                                    <Tooltip title="All Files" placement="right">
                                                        <svg width="23" height="19" viewBox="0 0 23 19" xmlns="http://www.w3.org/2000/svg">
                                                            <g fill="#000" fillRule="nonzero">
                                                                <path d="M10.124 2.82 8.244 0H0v15.04c0 1.777 1.81 3.76 4.23 3.76h14.1a4.23 4.23 0 0 0 4.23-4.23V2.82H10.124zM21.62 14.57a3.29 3.29 0 0 1-3.29 3.29H4.23c-1.852 0-3.29-1.518-3.29-2.82V.94h6.796l1.88 2.82H21.62v10.81z" />
                                                                <path d="M7.99 10.34a2.82 2.82 0 0 0 4.45 2.298l2.266 2.266.663-.663-2.265-2.265A2.82 2.82 0 1 0 7.99 10.34zm2.82-1.88a1.88 1.88 0 1 1 0 3.76 1.88 1.88 0 0 1 0-3.76z" />
                                                            </g>
                                                        </svg>
                                                    </Tooltip>
                                                </div>
                                                <span className="menu-text">All Files</span>
                                            </a>
                                        </div>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className={checkIfActive("/all-files") ? "accordion-collapse" : "accordion-collapse collapse"}
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionMenuNew"
                                    >
                                        <div className="accordion-body">
                                            <div className="sidebar_submenu ">
                                                <ul className="nav flex-column">
                                                    {isActive(panelFeatures.ALL_FILES_VIEW_FILES, features) && (
                                                        <li className={`nav-item ${checkIfActive("/all-files/reports") && "active"}`}>
                                                            <Link className="nav-link" to="/all-files/reports">
                                                                <div className="m-icon">
                                                                    <Tooltip title="View Files" placement="right">
                                                                        <svg width="23" height="19" viewBox="0 0 23 19" xmlns="http://www.w3.org/2000/svg">
                                                                            <g fill="#000" fillRule="nonzero">
                                                                                <path d="M10.124 2.82 8.244 0H0v15.04c0 1.777 1.81 3.76 4.23 3.76h14.1a4.23 4.23 0 0 0 4.23-4.23V2.82H10.124zM21.62 14.57a3.29 3.29 0 0 1-3.29 3.29H4.23c-1.852 0-3.29-1.518-3.29-2.82V.94h6.796l1.88 2.82H21.62v10.81z" />
                                                                                <path d="m7.656 10.913 3.483 3.483 3.84-6.641-.818-.47-3.224 5.579-2.613-2.618z" />
                                                                            </g>
                                                                        </svg>
                                                                    </Tooltip>
                                                                </div>
                                                                <span className="menu-text">View Files</span>
                                                            </Link>
                                                        </li>
                                                    )}

                                                    {isActive(panelFeatures.ALL_FILES_DOWNLOAD_FILES, features) && (
                                                        <li className={`nav-item ${checkIfActive("/all-files/all-downloads") && "active"}`}>
                                                            <Link className="nav-link" to="/all-files/all-downloads">
                                                                <div className="m-icon">
                                                                    <Tooltip title="Download Files" placement="right">
                                                                        <svg width="23" height="19" viewBox="0 0 23 19" xmlns="http://www.w3.org/2000/svg">
                                                                            <g fill="#505050" fillRule="nonzero">
                                                                                <path d="M10.124 2.82 8.244 0H0v14.57a4.23 4.23 0 0 0 4.23 4.23h14.1a4.23 4.23 0 0 0 4.23-4.23V2.82H10.124zM21.62 14.57a3.29 3.29 0 0 1-3.29 3.29H4.23a3.29 3.29 0 0 1-3.29-3.29V.94h6.796l1.88 2.82H21.62v10.81z" />
                                                                                <path d="m8.324 10.476-.668.668 3.624 3.619 3.624-3.62-.668-.667-2.486 2.491V7.52h-.94v5.447z" />
                                                                            </g>
                                                                        </svg>
                                                                    </Tooltip>
                                                                </div>
                                                                <span className="menu-text">Download Files</span>
                                                            </Link>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </li>
                </ul>
            </div>

            {/* Admin menu Below */}
        </>
    );
}

export default Sidebar;
