import { commaFormat } from "../../../Functions/formatNumber";
import { isEmpty } from "../../../Functions/isEmpty";
import { flattenedDispositionOptions } from "./data";

function checkValidNumber(num) {
    if (isEmpty(num) || num <= 0 || isNaN(num)) return false;
    return true;
}

function roundNum(num) {
    const inNum = Number(num);
    if (!inNum) return "";
    else return String(commaFormat(parseInt(inNum)));
}

function getValueIfObject(value, key) {
    try {
        if (!!value) {
            let obj = JSON.parse(value);
            if (typeof obj == "object" && obj !== null && obj.hasOwnProperty(key)) {
                return obj[key];
            }
        }
    } catch (error) {
        console.log("Invalid Object");
    }

    return "";
}

function parseJson(json) {
    try {
        return JSON.parse(json);
    } catch (e) {
        return { data: JSON.stringify(json) };
    }
}

// create a function which will convert snake case to normal case
function snakeToNormal(str) {
    return str
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

const createDispOptions = (disps = []) => flattenedDispositionOptions.filter(({ value }) => disps.includes(value));

export { checkValidNumber, roundNum, createDispOptions, getValueIfObject, parseJson, snakeToNormal };

// Online Case Status
const reasons = {
    need_more_time: "Need More Time",
    dispute_fraud: "Dispute / Fraud",
    borrower_deceased: "Borrower Deceased",
    wilful_default: "Wilful Default",
};

const getReason = (reason) => reasons[reason] ?? reason;

const nonPayReasons = {
    reason_job_loss: "Job Loss",
    reason_medical_reason: "Medical Reason",
    reason_financial_difficulty: "Financial Difficulty",
};

const getNonPayReason = (reason) => nonPayReasons[reason] ?? reason;

const paymentModeOptions = {
    online_payment: "Online Payment",
    cash_deposit: "Cash Deposit",
};

const getPaymentMode = (mode) => paymentModeOptions[mode] ?? mode;

const paidTypeOptions = {
    full_paid: "Full Paid",
    part_paid: "Part Paid",
};

const getPaidType = (type) => paidTypeOptions[type] ?? type;

const paymentPlans = {
    pay_in_one_shot: "Pay in One Shot",
    pay_in_two_parts: "Pay in Two Parts",
};

const getPaymentPlan = (plan) => paymentPlans[plan] ?? plan;

export { getReason, getNonPayReason, getPaymentMode, getPaidType, getPaymentPlan };
