import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { format } from "date-fns";
import { commaFormat } from "../../../Functions/formatNumber";

function AmountPaid({ data, type = true }) {
  const [show, setShow] = useState(false);

  const closeModal = () => {
    setShow(false);
  };

  if (!data?.actual_amount_paid?.length) return null;

  return (
    <>
      {type ? (
        <>
          <div className="item-row d-flex justify-between mt-3">
            <span className="item-cl-left text-16 cl-gray-01">Amount Paid</span>
            <span onClick={() => setShow(true)} style={{ cursor: "pointer" }} className="item-cl-right text-16 cl-black text-primary">
              Click to View
            </span>
          </div>
        </>
      ) : (
        <>
          <p className="mb-0">
            Amount Paid - {" "}
            <a onClick={() => setShow(true)} className="text-primary">
              Click to View
            </a>
          </p>
        </>
      )}

      <Modal size="md" show={show}>
        <Modal.Header>
          <Modal.Title>Amount Paid</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <p className="mb-4">
            Borrower Name : <strong className="text-black">{data?.borrower_details?.customer_name ?? ""}</strong>
          </p>

          <div>
            <table className="mt-0 table table-bordered border table-sm">
              <thead className="thead-light">
                <tr>
                  <th className="px-3 py-1 text-left align-middle">Date</th>
                  <th className="px-3 py-1 text-center align-middle">Loan Acc. No.</th>
                  <th className="px-3 py-1 text-end align-middle">Amount Paid</th>
                </tr>
              </thead>
              <tbody>
                {(data?.actual_amount_paid ?? []).map((item, index) => (
                  <tr key={index}>
                    <td className="px-3 py-1 text-left align-middle">{!!item?.payment_date ? format(new Date(item?.payment_date), "dd-MM-yyyy") : ""}</td>
                    <td className="px-3 py-1 text-center align-middle">{item?.loan_account_no ?? ""}</td>
                    <td className="px-3 py-1 text-end align-middle">{commaFormat(item?.amount_paid)}</td>
                  </tr>
                ))}
                <tr>
                  <td className="px-3 py-1 text-left align-middle">
                    <strong>Total</strong>
                  </td>
                  <td />
                  <td className="px-3 py-1 text-end align-middle">
                    <strong>{commaFormat((data?.actual_amount_paid ?? []).reduce((acc, curr) => curr.amount_paid + acc, 0))}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AmountPaid;
