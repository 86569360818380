import { Fragment, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { parseJson, snakeToNormal } from "../../../Agent/features/borrower-info/helpers";
import { getGupshupConversation } from "../../../API";
import { getBorrowerReport } from "../../../apis";
import WhatsappMsg from "../../../Components/WhatsappMsg";
import ContentLoader from "../../../Components/ContentLoader";
import { IoCloseCircle } from "react-icons/io5";

export default function WhatsappResponse({ lan, mobileNo, batchStatus }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showChats, setShowChats] = useState(false);

  const [analysis, setAnalysis] = useState(null);

  const fetchDetails = async () => {
    const params = {
      lan,
    };

    if (batchStatus === "completed") params.batch_type = batchStatus;

    const analysisResponse = await getBorrowerReport(params);
    if (analysisResponse.success) {
      setAnalysis(analysisResponse?.data?.data?.data[0] || {});
    }
  };

  useEffect(() => {
    if (isOpen) fetchDetails();
  }, [isOpen]);

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <>
      <div className="d-flex align-items-center  justify-content-center" onClick={toggle}>
        <svg style={{ fill: "#0A7AFF" }} width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="nonzero">
            <path d="M11.52 0A11.52 11.52 0 0 0 1.44 17.074L0 23.04l5.822-1.507A11.52 11.52 0 1 0 11.52 0zm0 22.08a10.56 10.56 0 0 1-5.38-1.474l-.174-.105-4.608 1.18 1.1-4.742-.101-.173a10.56 10.56 0 1 1 9.163 5.314z" />
            <path d="M7.632 13.239a14.745 14.745 0 0 0 4.905 3.842c.717.34 1.676.743 2.744.812.067.003.13.006.196.006.717 0 1.293-.248 1.763-.758.003-.002.008-.008.011-.014.167-.202.357-.383.556-.576.136-.13.274-.265.406-.403.614-.64.614-1.452-.005-2.07l-1.731-1.732c-.294-.305-.645-.466-1.014-.466s-.723.161-1.025.463l-1.031 1.031a5.48 5.48 0 0 0-.286-.15 3.561 3.561 0 0 1-.316-.172c-.94-.596-1.792-1.374-2.607-2.373-.412-.522-.688-.96-.881-1.406.27-.245.524-.5.769-.751.086-.09.176-.179.265-.268.31-.311.478-.671.478-1.037 0-.366-.164-.726-.478-1.037l-.858-.858c-.101-.1-.196-.199-.294-.3-.19-.195-.389-.397-.585-.578C8.318 4.153 7.97 4 7.6 4c-.365 0-.717.153-1.025.446L5.498 5.524a2.213 2.213 0 0 0-.66 1.416c-.054.689.073 1.42.4 2.304.505 1.368 1.265 2.639 2.394 3.995zm-2.09-6.238c.034-.383.18-.703.457-.98l1.072-1.07c.167-.162.35-.245.53-.245.175 0 .354.083.518.25.193.179.374.366.57.565l.3.305.858.858c.178.179.27.36.27.539 0 .178-.092.36-.27.538l-.268.271c-.268.27-.518.527-.795.772l-.014.014c-.24.24-.202.467-.144.64.003.008.006.014.008.023.222.532.53 1.04 1.011 1.644.864 1.066 1.774 1.892 2.777 2.529.123.08.256.144.38.207.115.058.222.112.317.173l.031.017a.602.602 0 0 0 .28.072.61.61 0 0 0 .429-.196l1.077-1.077c.167-.167.348-.256.527-.256.219 0 .397.135.51.256l1.736 1.734c.346.346.343.72-.008 1.086-.121.13-.248.253-.383.383-.202.196-.412.397-.602.625-.332.357-.726.524-1.236.524-.049 0-.1-.003-.15-.006-.944-.06-1.823-.429-2.482-.743a14.008 14.008 0 0 1-4.669-3.657c-1.074-1.294-1.797-2.497-2.275-3.788-.296-.792-.409-1.428-.363-2.007z" />
          </g>
        </svg>
      </div>

      <Offcanvas style={{ width: "28rem", paddingTop: "0.75rem" }} show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100 px-2">
            <div className="fw-600 fs-4">WhatsApp</div>
            <button onClick={toggle} type="button" className="bg-white border-0">
              <IoCloseCircle fill="#999999" size={30} />
            </button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!analysis ? (
            <div className="position-relative top-50">
              <ContentLoader />
            </div>
          ) : (
            <>
              <div className="row border m-3 rounded-2 p-1" style={{ boxShadow: "inset 0 0 10px #cbcacabd" }}>
                <a className="col-6 text-center p-2 fw-bold text-white rounded-2 fs-6" style={{ background: "#009EC1" }}>
                  Summary
                </a>
                <a onClick={() => setShowChats(true)} className="col-6 text-center p-2 rounded-2 text-black fs-6 fw-medium">
                  Chats
                </a>
              </div>

              <div>
                {!!analysis?.whatsapp_disposition && (
                  <div className="m-3 mt-4 rounded-5 bg-body-tertiary px-2 py-3">
                    {Object.entries(parseJson(analysis?.whatsapp_disposition))?.map(([key, value], i) => (
                      <Fragment key={i}>
                        {!!value && (
                          <p className="text-black px-3 mb-3">
                            <strong className="fs-5 fw-bold my-2 d-inline-block">{snakeToNormal(key)}</strong>
                            <br />
                            <span className="text-black fs-6">{value}</span>
                          </p>
                        )}
                      </Fragment>
                    ))}
                  </div>
                )}
                {!analysis.whatsapp_disposition && <p className="my-5 text-center text-black">Summary Not Found</p>}
              </div>

              <WhatsappMsg
                show={showChats}
                setShow={setShowChats}
                name={analysis?.customer_name}
                mobileNo={mobileNo}
                isCandidate={false}
                withSendMessage={false}
                isMasked={true}
              />
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
