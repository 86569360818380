import React, { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "react-bootstrap/Modal";
import ContentLoader from "../../../Components/SmallLoader";
import {
    getBulkReports,
    downloadBulkReports,
    fetchCities,
    fetchProductNew,
    fetchClientBatch,
    fetchStates,
    fetchDistrict,
    fetchPoliceStation,
    crossCheckReport,
} from "../../../API";
import Paging from "../../../Components/Paging";
import { format } from "date-fns";
import Excel from "../../../Static/Images/excel.svg";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import useAuthStore from "../../../store/authStore";
import SelectDropdown from "../../../ui/forms/SelectDropdown";
import { getGeneratedLegalNoticeTemplates } from "../../../API/panelApis";

function Reports() {
    const userData = useAuthStore((state) => state.userData);
    const features = useAuthStore((state) => state.features);

    const [reports, setReports] = useState([]);
    const [mode, setMode] = useState("pre_litigation");
    const [noticeType, setNoticeType] = useState("");
    const [subProcess, setSubProcess] = useState("legal_notice");
    const [city, setCity] = useState("");
    const [type, setType] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [product, setProduct] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
    const [filterDone, setFilterDone] = useState(false);
    const [loader, setLoader] = useState(false);
    const [hoverMsg, setHoverMsg] = useState(false);
    const [downSize, setDownSize] = useState("500");
    const [selectedReport, setSelectedReport] = useState([]);
    const [collectionReport, setCollectionReport] = useState([]);
    const [selectedReportCheckBox, setSelectedReportCheckBox] = useState({});
    const [totalReport, setTotalReport] = useState(0);
    const [pageTotal, setPageTotal] = useState("NaN");
    const [currentPage, setCurrentPage] = useState(1);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [policeStations, setPoliceStations] = useState([]);
    const [batch, setBatch] = useState("");
    const [addressType, setAddressType] = useState("");
    const [SearchParameter, setSearchParameter] = useState(null);
    const [StorePrevFilter, setStorePrevFilter] = useState(null);
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [policeStation, setPoliceStation] = useState("");

    const [noticeTypes, setNoticeTypes] = useState([]);

    const [disposition, setDisposition] = useState([]);
    const [date, setDate] = useState(null);
    const [to, setTo] = useState(null);
    const [file, setFile] = useState(null);
    const [disp, setDisp] = useState([]);
    const [cfile, setCfile] = useState("");
    const [checkCross, setCheckCross] = useState("");
    const [multiBatch, setMultiBatch] = useState([]);
    const [optBtch, setOptBtch] = useState([]);
    const [opt, setOpt] = useState([]);

    const [collectionRes, setCollectionRes] = useState([]);
    const [dataCollectionCross, setdataCollectionRes] = useState([]);
    const [postType, setPostType] = useState("");

    const history = useHistory();

    let reportSet = [];

    const downloadBulkReport = useCallback(async (reportArr, size) => {
        let isFile = cfile === "byUpload" ? "1" : "0";

        // let isFile = subProcess === "cross_check_report" ? "1" : "0";
        let body = {
            pdf_urls: reportArr,
            // pdf_urls: selectAll ? [...collectionRes] : reportArr,
            size: size,
            select_all: selectAll ? true : cfile === "byFilter" ? true : false,
            mode: mode,
            sub_process: subProcess,
            city: city,
            type: type,
            product: product,
            client_id: userData?.client_id,
            batch_id: batch ? [batch] : [],
            month: month,
            notice_type: noticeType,
            year: year,
            police_station: policeStation,
            district: district,
            state: state,
            format: flow,
            download_all: selectAll,
            filter_type: checkCross,
            address_type: addressType,
            download_cross_report_data: selectAll ? dataCollectionCross : collectionReport,
            from: !!date ? format(new Date(date), "yyyy-MM-dd") : null,
            to: !!to ? format(new Date(to), "yyyy-MM-dd") : null,
            disposition: !!disp ? disp : null,
            post_type: postType,
        };

        const res = await downloadBulkReports(body, isFile);

        history.push("/all-files/all-downloads");
    });

    const fetchBulkReports = useCallback(
        async (SearchParameter) => {
            if (pageTotal === "NaN") {
                setLoader(false);
            } else {
                setLoader(true);
            }
            setStorePrevFilter(SearchParameter);
            const para = {
                ...SearchParameter,
                page: currentPage,
                size: 50,
            };
            const res = await getBulkReports(para);
            if (res && para.sub_process !== "cross_check_report") {
                setReports(res.data);
                setTotalReport(res.total);
                handleInitPagination(res);
                setFilterDone(true);
                setLoader(false);
            } else if (res && para.sub_process === "cross_check_report") {
                setCollectionRes(res.data);
                setTotalReport(res.total);
                handleInitPagination(res);
                setFilterDone(true);
                setLoader(false);
            }
        },
        [SearchParameter, currentPage]
    );
    useEffect(() => {
        if (pageTotal !== "NaN") {
            fetchBulkReports(StorePrevFilter);
        }
    }, [currentPage]);

    const fetchNoticeTypes = async () => {
        try {
            const res = await getGeneratedLegalNoticeTemplates({ client_id: userData?.client_id });
            if (res) {
                setNoticeTypes(
                    !!res?.data?.all_templates?.length
                        ? res?.data?.all_templates
                              ?.filter(({ type }) => !!res?.data?.generated_templates?.includes(type))
                              ?.map(({ name, type }) => ({ label: name, value: type }))
                        : []
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchNoticeTypes();
    }, []);

    const getCities = useCallback(async (process) => {
        const res = await fetchCities(process);

        if (res) {
            setCities(res.payload);
        }
    }, []);

    const getStates = useCallback(async () => {
        const res = await fetchStates(userData?.client_id, batch);

        if (res) {
            setStates(res.data);
        }
    }, []);

    const getDistrict = useCallback(async () => {
        const res = await fetchDistrict(userData?.client_id, batch);

        if (res) {
            setDistricts(res.data);
        }
    }, []);

    const getPoliceStation = useCallback(async () => {
        const res = await fetchPoliceStation(userData?.client_id, batch);

        if (res) {
            setPoliceStations(res.data);
        }
    }, []);

    const handleInitPagination = (res) => {
        let total = res.total;
        let totalPage = Math.ceil(total / 50);
        setPageTotal(totalPage);
    };
    const handlePagination = (type, target) => {
        if (type === "directValue") {
            setCurrentPage(parseInt(target));
        }
    };

    const [products, setProducts] = useState([]);

    const getProducts = useCallback(async (prc) => {
        const res = await fetchProductNew(prc);
        if (res) {
            setProducts(res);
        }
    }, []);

    // useEffect(()=>{
    //     fetchBulkReports();
    // },[fetchBulkReports])
    const [smShow, setSmShow] = useState(false);
    const [flow, setFlow] = useState("consolidated");

    const [optionsBatch, setOptionsBatch] = useState([]);

    const getBatches = async ({ process, notice_type }) => {
        try {
            const res = await fetchClientBatch(userData?.client_id, process, notice_type);

            let options_arr = [];

            if (res) {
                options_arr = res?.batch_instances?.map((item) => ({ value: item?.notice__batch__batch_id, label: item?.notice__batch__batch_name }));
            }
            setOptBtch([...options_arr]);
            setOptionsBatch([...options_arr]);
        } catch (error) {
            console.log(error);
        }
    };

    const submitAction = async (e) => {
        e.preventDefault();
        if (!subProcess) return;

        if (cfile === "byUpload") {
            try {
                let formData = new FormData();
                // formData.append("disposition", !!disp ? disp : null);
                // formData.append("is_file", "1");
                formData.append("file", !!file ? file : null);
                formData.append("select_all", true);
                formData.append("mode", mode);
                formData.append("sub_process", subProcess);
                formData.append("notice_type", noticeType);
                formData.append("size", "1");
                formData.append("client_id", userData?.client_id || null);
                // formData.append("from", !!date ? format(new Date(date), "yyyy-MM-dd") : null);
                // formData.append("to", !!to ? format(new Date(to), "yyyy-MM-dd") : null);
                let params = {
                    is_file: "2",
                };

                const res = await crossCheckReport(formData, params);

                if (res) {
                    setCollectionRes(res.data);
                    setTotalReport(res.total);
                    handleInitPagination(res);
                    setFilterDone(true);
                    setLoader(false);
                    // setCfile("");
                    setdataCollectionRes(res.download_cross_report_data);
                    // history.push("/all-files/all-downloads");
                }
            } catch (error) {
                console.log(error);
                toast.error("Error while uploading");
                setLoader(false);
            }
        } else {
            let params = {
                mode: !!mode ? mode : null,
                sub_process: !!subProcess ? subProcess : null,
                notice_type: !!noticeType ? noticeType : null,
                batch_id: batch ? batch : opt ? opt : null,
                city: !!city ? city : null,
                product: !!product ? product : null,
                type: !!type ? type : null,
                month: !!month ? month : null,
                year: !!year ? year : null,
                address_type: !!addressType ? addressType : null,
                state: !!state ? state : null,
                district: !!district ? district : null,
                police_station: !!policeStation ? policeStation : null,
                from: !!date ? format(new Date(date), "yyyy-MM-dd") : null,
                to: !!to ? format(new Date(to), "yyyy-MM-dd") : null,
                disposition: !!disp ? disp : null,
                post_type: postType,
                client_id: userData?.client_id || null,
            };
            setSearchParameter({ ...params });
            fetchBulkReports({ ...params });
            setLoader(true);
        }
    };

    useEffect(() => {
        if (!!reports.length && selectedReport.length === reports.length) {
            setSelectAll(true);
            setSelectAllCheckBox(true);
        } else if (!!collectionRes.length && selectedReport.length === collectionRes.length) {
            setSelectAll(true);
            setSelectAllCheckBox(true);
        } else {
            setSelectAll(false);
            setSelectAllCheckBox(false);
        }
    }, [selectedReport]);

    const Disp = [
        { label: "CB", value: "CB" },
        { label: "PAID", value: "PAID" },
        { label: "PTP", value: "PTP" },
        { label: "DIS", value: "DIS" },
        { label: "SETL", value: "SETL" },
    ];

    const selectDisHandler = (client) => {
        let clients = client.map((item) => item.value);
        setDisp(clients);
        setDisposition(client);
    };
    const selectMultiHandler = (batch) => {
        let batches = batch.map((item) => item.value);
        setOpt(batches);
        setMultiBatch(batch);
    };

    function isNumber(val) {
        let regex = new RegExp("^[0-9]+$");
        return regex.test(val);
    }

    const handleDownloadSize = (val) => {
        if (!val) {
            setDownSize(0);
            return;
        }
        if (!isNumber(val) || val < 0) return;
        setDownSize(Number(val));
    };

    useEffect(() => {
        setBatch("");
        !!subProcess && !!noticeType && getBatches({ process: subProcess, notice_type: noticeType });
    }, [subProcess, noticeType]);

    return (
        <>
            <div className="report-page">
                <div className="row">
                    <div className="col-lg-3 col-md-4">
                        <form className="pe-3 report-page-left" onSubmit={submitAction}>
                            {/* Select Notices to Download */}

                            <div className="mb-3 row">
                                <p className="mb-1">Select Notices to Download</p>
                                <SelectDropdown
                                    placeholder="Select"
                                    value={cfile}
                                    onChange={(val) => {
                                        setCfile(val);
                                        setCheckCross(val);
                                    }}
                                    options={[
                                        { label: "Upload File of Loan Accounts", value: "byUpload" },
                                        { label: "Select Batch or Notice Type", value: "byFilter" },
                                    ]}
                                />
                            </div>

                            {cfile === "byUpload" && (
                                <div>
                                    <input
                                        id="File_Upload_cross_check"
                                        type="file"
                                        onChange={(e) => setFile(e.target.files[0])}
                                        required
                                        accept=".xlse,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xlsb,.xml,.xla,.xlw,.xlr"
                                        style={{ display: "none" }}
                                    />
                                </div>
                            )}

                            {cfile === "byFilter" && (
                                <>
                                    {/* Mode */}
                                    <div className="mb-3 row">
                                        <p className="mb-1">
                                            Mode <span className="text-danger">*</span>
                                        </p>
                                        <SelectDropdown
                                            placeholder="Select Mode"
                                            value={mode}
                                            onChange={setMode}
                                            options={
                                                !features?.includes("hide_all_file_filters")
                                                    ? [
                                                          { label: "Pre Litigation", value: "pre_litigation" },
                                                          { label: "Litigation", value: "litigation" },
                                                          { label: "Collection", value: "collection" },
                                                      ]
                                                    : [{ label: "Pre Litigation", value: "pre_litigation" }]
                                            }
                                        />
                                    </div>

                                    {/* Sub Process */}
                                    <div className="mb-3 row">
                                        <p className="mb-1">
                                            Sub Process <span className="text-danger">*</span>
                                        </p>
                                        <SelectDropdown
                                            placeholder="Select Sub Process"
                                            value={subProcess}
                                            onChange={(val) => {
                                                setSubProcess(val);
                                                if (val !== "cross_check_report") {
                                                    setFile(null);
                                                    setDate(null);
                                                    setTo(null);
                                                    setDisposition([]);
                                                    getCities(val);
                                                    getProducts(val);
                                                    // getBatches({ process: val });
                                                }
                                                // else {
                                                //     getBatches({ process: "collection" });
                                                // }
                                            }}
                                            options={
                                                mode === "pre_litigation" && !features?.includes("hide_all_file_filters")
                                                    ? [
                                                          { label: "Legal Notice", value: "legal_notice" },
                                                          { label: "Legal Letter", value: "letter_to_accused" },
                                                          { label: "FIR", value: "fir" },
                                                          { label: "Police Statement", value: "police_statement" },
                                                          { label: "POD", value: "pod" },
                                                      ]
                                                    : mode === "pre_litigation"
                                                    ? [{ label: "Legal Notice", value: "legal_notice" }]
                                                    : mode === "collection"
                                                    ? [
                                                          { label: "Daily Report", value: "daily_report" },
                                                          { label: "Cross Check Report", value: "cross_check_report" },
                                                      ]
                                                    : mode === "litigation"
                                                    ? [{ label: "Cases", value: "cases" }]
                                                    : []
                                            }
                                        />
                                    </div>

                                    {subProcess !== "fir" && subProcess !== "letter_to_accused" && subProcess !== "police_statement" && (
                                        <div className="mb-3 row">
                                            <p className="mb-1">
                                                Notice Type <span className="text-danger">*</span>
                                            </p>
                                            <SelectDropdown
                                                placeholder="Select Notice Type"
                                                value={noticeType}
                                                onChange={setNoticeType}
                                                options={noticeTypes}
                                            />
                                        </div>
                                    )}

                                    {(mode === "collection" && subProcess === "cross_check_report" && (
                                        <div>
                                            <div className="mb-2 row">
                                                <label className="col-md-12 col-form-label">Batch</label>
                                                <MultiSelect
                                                    options={optBtch}
                                                    value={multiBatch}
                                                    hasSelectAll={false}
                                                    onChange={(val) => selectMultiHandler(val)}
                                                    placeholder="Select Clients"
                                                />
                                            </div>
                                            <div className="mb-2 row">
                                                <label className="col-md-12 col-form-label">From </label>
                                                <div className="col-md-12">
                                                    <input type="date" className="form-control" onChange={(e) => setDate(e.target.value)} value={date} />
                                                </div>
                                            </div>

                                            <div className="mb-2 row">
                                                <label className="col-md-12 col-form-label">To </label>
                                                <div className="col-md-12">
                                                    <input type="date" className="form-control" onChange={(e) => setTo(e.target.value)} value={to} />
                                                </div>
                                            </div>

                                            <div className="mb-2 row">
                                                <label className="col-md-12 col-form-label">Disposition</label>
                                                <MultiSelect
                                                    options={Disp}
                                                    value={disposition}
                                                    hasSelectAll={false}
                                                    onChange={(val) => selectDisHandler(val)}
                                                    placeholder="Select Clients"
                                                />
                                            </div>
                                        </div>
                                    )) || (
                                        <>
                                            <div className="mb-3 row">
                                                <p className="mb-1">Batch</p>
                                                <SelectDropdown
                                                    placeholder="Select Batch"
                                                    value={batch}
                                                    onChange={(val) => {
                                                        setBatch(val);
                                                        if (subProcess === "fir" || subProcess === "letter_to_accused" || subProcess === "police_statement") {
                                                            getStates();
                                                            getDistrict();
                                                            getPoliceStation();
                                                        }
                                                    }}
                                                    options={optionsBatch}
                                                />
                                            </div>

                                            {!features?.includes("hide_all_file_filters") && (
                                                <>
                                                    {subProcess !== "fir" && subProcess !== "letter_to_accused" && subProcess !== "police_statement" && (
                                                        <div className="mb-3 row">
                                                            <p className="mb-1">Type</p>
                                                            <SelectDropdown
                                                                placeholder="Select Type"
                                                                value={type}
                                                                onChange={setType}
                                                                options={[
                                                                    { label: "Applicant", value: "applicant" },
                                                                    { label: "Co Applicant", value: "co_applicant" },
                                                                    { label: "Current Address", value: "current" },
                                                                    { label: "Permanent Address", value: "permanent" },
                                                                    { label: "Office Address", value: "office" },
                                                                    { label: "Co Borrower Address", value: "co_borrower" },
                                                                ]}
                                                            />
                                                        </div>
                                                    )}

                                                    {subProcess !== "fir" && subProcess !== "letter_to_accused" && subProcess !== "police_statement" && (
                                                        <div className="mb-3 row">
                                                            <p className="mb-1">City</p>
                                                            <SelectDropdown
                                                                placeholder="Select City"
                                                                value={city}
                                                                onChange={setCity}
                                                                options={cities?.map((cit) => ({ label: cit, value: cit }))}
                                                            />
                                                        </div>
                                                    )}

                                                    {subProcess !== "fir" && subProcess !== "letter_to_accused" && subProcess !== "police_statement" && (
                                                        <div className="mb-3 row">
                                                            <p className="mb-1">Product</p>
                                                            <SelectDropdown
                                                                placeholder="Select Product"
                                                                value={product}
                                                                onChange={setProduct}
                                                                options={products?.map((val) => ({ label: val, value: val }))}
                                                            />
                                                        </div>
                                                    )}

                                                    {(subProcess === "fir" || subProcess === "letter_to_accused" || subProcess === "police_statement") && (
                                                        <div className="mb-3 row">
                                                            <p className="mb-1">State</p>
                                                            <SelectDropdown
                                                                placeholder="Select State"
                                                                value={state}
                                                                onChange={setState}
                                                                options={states?.map((val) => ({ label: val, value: val }))}
                                                            />
                                                        </div>
                                                    )}

                                                    {(subProcess === "fir" || subProcess === "letter_to_accused" || subProcess === "police_statement") && (
                                                        <div className="mb-3 row">
                                                            <p className="mb-1">District</p>
                                                            <SelectDropdown
                                                                placeholder="Select District"
                                                                value={district}
                                                                onChange={setDistrict}
                                                                options={districts?.map((val) => ({ label: val, value: val }))}
                                                            />
                                                        </div>
                                                    )}

                                                    {(subProcess === "fir" || subProcess === "letter_to_accused" || subProcess === "police_statement") && (
                                                        <div className="mb-3 row">
                                                            <p className="mb-1">Police Station</p>
                                                            <SelectDropdown
                                                                placeholder="Select Police Station"
                                                                value={policeStation}
                                                                onChange={setPoliceStation}
                                                                options={policeStations?.map((val) => ({ label: val, value: val }))}
                                                            />
                                                        </div>
                                                    )}

                                                    <div className="mb-3 row">
                                                        <p className="mb-1">Year</p>
                                                        <SelectDropdown
                                                            placeholder="Select Year"
                                                            value={year}
                                                            onChange={setYear}
                                                            options={[
                                                                { label: "2021", value: "2021" },
                                                                { label: "2022", value: "2022" },
                                                                { label: "2023", value: "2023" },
                                                                { label: "2024", value: "2024" },
                                                                { label: "2025", value: "2025" },
                                                            ]}
                                                        />
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <p className="mb-1">Month</p>
                                                        <SelectDropdown
                                                            placeholder="Select Month"
                                                            value={month}
                                                            onChange={setMonth}
                                                            options={[
                                                                { label: "January", value: "1" },
                                                                { label: "February", value: "2" },
                                                                { label: "March", value: "3" },
                                                                { label: "April", value: "4" },
                                                                { label: "May", value: "5" },
                                                                { label: "June", value: "6" },
                                                                { label: "July", value: "7" },
                                                                { label: "August", value: "8" },
                                                                { label: "September", value: "9" },
                                                                { label: "October", value: "10" },
                                                                { label: "November", value: "11" },
                                                                { label: "December", value: "12" },
                                                            ]}
                                                        />
                                                    </div>

                                                    {(subProcess !== "fir" || subProcess !== "letter_to_accused" || subProcess !== "police_statement") && (
                                                        <div className="mb-3 row">
                                                            <p className="mb-1">Address Type</p>
                                                            <SelectDropdown
                                                                placeholder="Select Address Type"
                                                                value={addressType}
                                                                onChange={setAddressType}
                                                                options={[
                                                                    { label: "Unique", value: "unique" },
                                                                    { label: "Duplicate", value: "duplicate" },
                                                                ]}
                                                            />
                                                        </div>
                                                    )}

                                                    <div className="mb-3 row">
                                                        <p className="mb-1">Post Type</p>
                                                        <SelectDropdown
                                                            placeholder="Select Post Type"
                                                            value={postType}
                                                            onChange={setPostType}
                                                            options={[
                                                                { label: "Speed Post", value: "speed_post" },
                                                                { label: "Registry", value: "registry" },
                                                            ]}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                            <div className="mb-2 mt-4 row">
                                <div className="col-md-12">
                                    {cfile === "byFilter" && (
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="w-100 btn-lg"
                                            disabled={!subProcess}
                                            // disabled={!noticeType || !subProcess}
                                        >
                                            Search
                                        </Button>
                                    )}
                                    {cfile === "byUpload" && (
                                        <Button variant="primary" type="submit" className="w-100 btn-lg" disabled={!file || !subProcess}>
                                            Upload
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-lg-9 col-md-8">
                        {(cfile === "byUpload" && !filterDone && (
                            // File upload Case
                            <div className="d-flex align-items-center justify-content-center flex-column gap-2" style={{ paddingTop: "64px" }}>
                                <div className="import-excel-img mb-2">
                                    <img src={Excel} alt="Excel" />
                                </div>

                                <br />
                                <label htmlFor="File_Upload_cross_check" className="btn btn-primary btn-lg mb-2">
                                    {!!file ? "Change Selected Excel File" : "Select Excel File from your Computer"}
                                </label>
                                <p>You can upload in Excel or Binary Excel.</p>
                                {!!file && <p className="m-0">{file?.name}</p>}
                            </div>
                        )) || (
                            // Not File Upload Case
                            <div className="report-page-right">
                                <div className="row mb-3">
                                    <div className="col-md-7">
                                        {filterDone ? (
                                            <div>
                                                <p className="mb-2 tx-16">
                                                    <strong>{totalReport} Record Found</strong>
                                                </p>
                                            </div>
                                        ) : !loader ? (
                                            <p className="mb-2 tx-16 fs-5 text-center">
                                                <strong>Please filter the reports</strong>
                                            </p>
                                        ) : null}
                                    </div>

                                    <div className="col-md-5 text-end">
                                        {reports.length > 0 || collectionRes.length > 0 ? (
                                            <button
                                                className="btn btn-outline-primary btn-lg"
                                                onClick={() => {
                                                    if (reportSet.length > 0) {
                                                        setSelectedReport(reportSet);
                                                    }
                                                    if (flow === "consolidated" && process !== "collection" && subProcess !== "cross_check_report") {
                                                        setSmShow(true);
                                                    } else {
                                                        downloadBulkReport(selectedReport, 1);
                                                    }
                                                }}
                                            >
                                                <svg width="16" height="17" xmlns="http://www.w3.org/2000/svg">
                                                    <g fill="#000" fillRule="evenodd">
                                                        <path d="M.202 11.702a.7.7 0 0 1 1.401 0v2.1a.7.7 0 0 0 .7.7h9.806a.7.7 0 0 0 .7-.7v-2.1a.7.7 0 0 1 1.401 0v2.1a2.1 2.1 0 0 1-2.1 2.1H2.303a2.1 2.1 0 0 1-2.102-2.1v-2.1z" />
                                                        <path d="M9.513 7.706a.7.7 0 0 1 .99.99l-2.801 2.8a.7.7 0 0 1-.99 0l-2.802-2.8a.7.7 0 1 1 .99-.99l2.306 2.306 2.307-2.306z" />
                                                        <path d="M6.506 1.2a.7.7 0 0 1 1.4 0v9.802a.7.7 0 0 1-1.4 0V1.2z" />
                                                    </g>
                                                </svg>
                                                Download
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        {reports.length > 0 || collectionRes.length > 0 ? (
                                            <>
                                                <Checkbox
                                                    className="me-2"
                                                    color="primary"
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                    checked={selectAllCheckBox}
                                                    onChange={(e) => {
                                                        setSelectAllCheckBox(e.target.checked);
                                                        if (e.target.checked) {
                                                            if (reports.length > 0) {
                                                                setSelectedReport(reports);
                                                                let temp = {};
                                                                reports.map((_, index) => {
                                                                    temp[index] = true;
                                                                });
                                                                setSelectedReportCheckBox(temp);
                                                            }
                                                            if (collectionRes.length > 0) {
                                                                setSelectedReport(collectionRes);
                                                                let temp = {};
                                                                collectionRes.map((_, index) => {
                                                                    temp[index] = true;
                                                                });
                                                                setSelectedReportCheckBox(temp);
                                                            }
                                                        } else {
                                                            setSelectedReport([]);
                                                            let temp = {};
                                                            reports.map((_, index) => {
                                                                temp[index] = false;
                                                            });
                                                            setSelectedReportCheckBox(temp);
                                                        }
                                                    }}
                                                />
                                                <label className="col-form-label">
                                                    <strong>Select All</strong>
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div className="report-pdf">
                                    {loader ? (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <ContentLoader />
                                        </div>
                                    ) : (
                                        <div className="row">
                                            {reports &&
                                                reports.length > 0 &&
                                                reports.map((val, index) => {
                                                    let v = val?.split("/");
                                                    return (
                                                        <div className="col-xl-4 col-lg-6" key={index}>
                                                            <div className="report-pdf-box d-flex align-center">
                                                                <Checkbox
                                                                    className="me-2"
                                                                    color="primary"
                                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                                    id="indiCheck"
                                                                    checked={!!selectedReportCheckBox?.[index]}
                                                                    onChange={(e) => {
                                                                        setSelectedReportCheckBox((prev) => ({ ...prev, [index]: e.target.checked }));
                                                                        if (e.target.checked) {
                                                                            setSelectedReport((prev) => [...prev, val]);
                                                                        } else {
                                                                            setSelectedReport((prev) => [...prev.filter((el) => el !== val)]);
                                                                        }
                                                                    }}
                                                                />
                                                                <svg width="28" height="36" viewBox="0 0 28 36" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fillRule="nonzero" fill="none">
                                                                        <path fill="#C9C9C9" d="M15.933 25.691 13.67.091H.01v23.374z" />
                                                                        <path fill="#E8E6E6" d="M27.39 23.465V7.883l-5.565-2.226L19.6.09h-5.928l.036 25.6z" />
                                                                        <path fill="#C9C9C9" d="M19.6 7.883V.09l7.79 7.792z" />
                                                                        <path fill="#FC0F1A" d="M.01 23.465h27.381v12.243H.01z" />
                                                                        <g fill="#FFF">
                                                                            <path d="M8.587 26.939c1.355 0 2.134.666 2.134 1.846 0 1.241-.78 1.96-2.134 1.96h-.961v1.491H6.279V26.94h2.308zm-.961 2.747h.893c.59 0 .93-.295.93-.855 0-.553-.34-.833-.93-.833h-.893v1.688zM13.893 26.939c1.665 0 2.815 1.074 2.815 2.648 0 1.567-1.165 2.65-2.868 2.65h-2.225v-5.298h2.278zm-.931 4.222h.961c.825 0 1.415-.635 1.415-1.566 0-.938-.62-1.582-1.476-1.582h-.9v3.148zM18.956 27.998v1.226h2.377v1.06h-2.377v1.952H17.61V26.94h3.928v1.06h-2.58z" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                                <div
                                                                    className="ms-2"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        position: "relative",
                                                                        overflowX: "hidden",
                                                                        paddingRight: "10px",
                                                                    }}
                                                                    onMouseEnter={() => {
                                                                        setHoverMsg(index);
                                                                    }}
                                                                    onMouseLeave={() => setHoverMsg(-1)}
                                                                    onDoubleClick={() => {
                                                                        document.getElementById("repo").href = val;
                                                                        document.getElementById("repo").click();
                                                                    }}
                                                                >
                                                                    <p className="mb-0 tx-16 pdf-name">{v[5]}</p>
                                                                    <p className="mb-0 tx-12 pdf-time-stamp d-none">12 Nov 21 | 09:20 PM</p>
                                                                    {index === hoverMsg ? (
                                                                        <span
                                                                            className="text-primary "
                                                                            style={{
                                                                                position: "absolute",
                                                                                fontSize: "12px",
                                                                                top: "-20px",
                                                                                left: "30px",
                                                                                border: "1px solid #d2d2d2",
                                                                                borderRadius: "5px",
                                                                                background: "#d2d2d2",
                                                                                padding: "0 4px",
                                                                            }}
                                                                        >
                                                                            Double click to download
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>

                                                                <a href="" id="repo" target="_blank" className="d-none"></a>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            {!!collectionRes?.length &&
                                                collectionRes.map((val, index) => {
                                                    let v = val?.split("/");
                                                    // let v = val?.name_to_show?.split(/(?:_|.mp3)+/);
                                                    return (
                                                        <div className="col-xl-4 col-lg-6" key={index}>
                                                            <div className="report-pdf-box d-flex align-center">
                                                                <Checkbox
                                                                    className="me-2"
                                                                    color="primary"
                                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                                    id="indiCheck"
                                                                    checked={!!selectedReportCheckBox?.[index]}
                                                                    onChange={(e) => {
                                                                        setSelectedReportCheckBox((prev) => ({ ...prev, [index]: e.target.checked }));
                                                                        if (e.target.checked) {
                                                                            setSelectedReport((prev) => [...prev, val]);
                                                                            // setSelectedReport((prev) => [...prev, val.url]);
                                                                            if (!!dataCollectionCross[index])
                                                                                setCollectionReport((prev) => [...prev, dataCollectionCross[index]]);
                                                                            // setCollectionReport((prev) => [...prev, v[0]]);
                                                                        } else {
                                                                            setSelectedReport((prev) => [...prev.filter((el) => el !== val)]);
                                                                            // setSelectedReport((prev) => [...prev.filter((el) => el !== val.url)]);
                                                                            setCollectionReport((prev) => [
                                                                                ...prev.filter((el) => el !== dataCollectionCross[index]),
                                                                            ]);
                                                                            // setCollectionReport((prev) => [...prev.filter((el) => el !== v), v[0]]);
                                                                        }
                                                                    }}
                                                                />
                                                                <svg width="28" height="36" viewBox="0 0 28 36" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fillRule="nonzero" fill="none">
                                                                        <path fill="#C9C9C9" d="M15.933 25.691 13.67.091H.01v23.374z" />
                                                                        <path fill="#E8E6E6" d="M27.39 23.465V7.883l-5.565-2.226L19.6.09h-5.928l.036 25.6z" />
                                                                        <path fill="#C9C9C9" d="M19.6 7.883V.09l7.79 7.792z" />
                                                                        <path fill="#FC0F1A" d="M.01 23.465h27.381v12.243H.01z" />
                                                                        <g fill="#FFF">
                                                                            <path d="M8.587 26.939c1.355 0 2.134.666 2.134 1.846 0 1.241-.78 1.96-2.134 1.96h-.961v1.491H6.279V26.94h2.308zm-.961 2.747h.893c.59 0 .93-.295.93-.855 0-.553-.34-.833-.93-.833h-.893v1.688zM13.893 26.939c1.665 0 2.815 1.074 2.815 2.648 0 1.567-1.165 2.65-2.868 2.65h-2.225v-5.298h2.278zm-.931 4.222h.961c.825 0 1.415-.635 1.415-1.566 0-.938-.62-1.582-1.476-1.582h-.9v3.148zM18.956 27.998v1.226h2.377v1.06h-2.377v1.952H17.61V26.94h3.928v1.06h-2.58z" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                                <div
                                                                    className="ms-2"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        position: "relative",
                                                                        overflow: "hidden",
                                                                        paddingRight: "10px",
                                                                    }}
                                                                    onMouseEnter={() => {
                                                                        setHoverMsg(index);
                                                                    }}
                                                                    onMouseLeave={() => setHoverMsg(-1)}
                                                                    onDoubleClick={() => {
                                                                        document.getElementById("repo").href = val;
                                                                        document.getElementById("repo").click();
                                                                    }}
                                                                >
                                                                    <p className="mb-0 tx-16 pdf-name">{v[5]}</p>
                                                                    {/* <p className="mb-0 tx-12 pdf-time-stamp d-none">12 Nov 21 | 09:20 PM</p> */}
                                                                    {index === hoverMsg ? (
                                                                        <span
                                                                            className="text-primary "
                                                                            style={{
                                                                                position: "absolute",
                                                                                fontSize: "12px",
                                                                                top: "-20px",
                                                                                left: "30px",
                                                                                border: "1px solid #d2d2d2",
                                                                                borderRadius: "5px",
                                                                                background: "#d2d2d2",
                                                                                padding: "0 4px",
                                                                            }}
                                                                        >
                                                                            Double click to download
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>

                                                                <a href="" id="repo" target="_blank" className="d-none"></a>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    )}
                                    <Paging currentPage={currentPage} pageTotal={pageTotal} handlePagination={handlePagination} />
                                </div>
                                {filterDone && totalReport === 0 ? <c /> : ""}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Filter */}
            <Modal size="sm" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Header>
                    <Modal.Title>Download Options</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="mb-4">
                        <div className="mt-3">
                            <label style={{ cursor: "pointer" }} className="col-12 d-flex align-items-center justify-content-start gap-2 mb-3">
                                <input
                                    checked={flow === "consolidated" ? true : false}
                                    className="form-check-input ml-0"
                                    style={{ width: "1.2rem", height: "1.2rem" }}
                                    type="radio"
                                    name="gridRadios"
                                    value="consolidated"
                                    onChange={() => setFlow("consolidated")}
                                />
                                {"Consolidated - (Multiple Notices in One PDF)"}
                            </label>
                            <label style={{ cursor: "pointer" }} className="col-12 d-flex align-items-center justify-content-start gap-2 mb-3">
                                <input
                                    checked={flow === "seperate" ? true : false}
                                    className="form-check-input ml-0"
                                    type="radio"
                                    style={{ width: "1.2rem", height: "1.2rem" }}
                                    name="gridRadios"
                                    value="seperate"
                                    onChange={() => setFlow("seperate")}
                                />
                                {"Separate - (Every Notice in a seperate PDF)"}
                            </label>
                        </div>

                        {flow === "consolidated" && (
                            <div>
                                <hr />
                                <div className="mb-3 row mt-4 align-items-start">
                                    <div className="col-8">
                                        <p>What is the maximum size of the file you have to download?</p>
                                    </div>
                                    <div className="col-4">
                                        <div className="d-inline-block">
                                            <div className="d-flex bg-white" style={{ border: "1px solid #ced4da", borderRadius: ".25rem" }}>
                                                <input
                                                    type="text"
                                                    value={downSize}
                                                    onChange={(e) => handleDownloadSize(e.target.value)}
                                                    className="pe-0 text-left"
                                                    style={{
                                                        maxWidth: "100px",
                                                        border: "0",
                                                        borderRadius: ".25rem",
                                                        outline: "0",
                                                        padding: "0.675rem 0.75rem",
                                                    }}
                                                />
                                                <span className="ps-0 text-muted" style={{ padding: "0.675rem 0.75rem", borderRadius: ".25rem" }}>
                                                    Mb
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSmShow(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            downloadBulkReport(selectedReport, downSize);
                            setSmShow(false);
                        }}
                    >
                        Download
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Reports;
