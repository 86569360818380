import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import useForm from "../../../hooks/useForm";
import FilterButton from "../../../ui/buttons/FilterButton";
import DatePicker from "../../../ui/forms/DatePicker";

const filterDefaults = {
    date_from: null,
    date_to: null,
};

export default function Filter({ prevFilter, setPrevFilter }) {
    const [show, setShow] = useState(false);
    const [filterData, handleFilterData, resetFilterData, forceResetFilterData] = useForm({ ...filterDefaults });

    const clearFilter = () => {
        resetFilterData();
        setPrevFilter({ ...filterDefaults });
        setShow(false);
    };

    const closeFilter = () => {
        resetFilterData();
        setShow(false);
    };

    const applyFilter = () => {
        setPrevFilter({ ...filterData });
        setShow(false);
        resetFilterData();
    };

    useEffect(() => {
        !!show && forceResetFilterData({ ...filterDefaults, ...prevFilter });
    }, [show]);

    return (
        <>
            <FilterButton
                onClick={() => setShow(true)}
                active={!!prevFilter && !!Object.values(prevFilter)?.some((el) => el !== "asc" && el !== "desc" && !!el)}
            />

            <Modal size="sm" show={show} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-4 d-flex flex-column gap-3">
                        <div className="row">
                            <label className="col-6 d-flex flex-column align-items-start justify-content-start">
                                <p className="mb-1">Date From</p>
                                <DatePicker
                                    label="Choose Date"
                                    maxDate={filterData?.date_to}
                                    value={filterData?.date_from}
                                    onChange={(val) => handleFilterData("date_from", val)}
                                />
                            </label>
                            <label className="col-6 d-flex flex-column align-items-start justify-content-start">
                                <p className="mb-1">Date To</p>
                                <DatePicker
                                    label="Choose Date"
                                    minDate={filterData?.date_from}
                                    value={filterData?.date_to}
                                    onChange={(val) => handleFilterData("date_to", val)}
                                />
                            </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => clearFilter()}>
                        Clear Filter
                    </Button>
                    <Button variant="secondary" onClick={() => closeFilter()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => applyFilter()}>
                        Apply
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
