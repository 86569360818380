export const compactFormat = (number, showZero = false) => {
    if (number === undefined || number === null || number === "None" || number === "") return "";

    if (showZero && String(number) === "0") return "0";
    if (!showZero && String(number) === "0") return "";

    //  k, L, Cr
    const formatter = Intl.NumberFormat("en-IN", { notation: "compact", compactDisplay: "short" });
    return formatter.format(number).replace("T", "k");
};

export const commaFormat = (number, showZero = false) => {
    if (number === undefined || number === null || number === "None" || number === "") return "";

    if (showZero && String(number) === "0") return "0";
    if (!showZero && String(number) === "0") return "";

    const formatter = Intl.NumberFormat("en-IN");
    return formatter.format(parseInt(number));
};
