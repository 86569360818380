import React, { useEffect, useMemo, useState } from "react";
import classes from "./style.module.css";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { CgHashtag } from "react-icons/cg";
import { FiChevronRight } from "react-icons/fi";
import { sidebarData } from "../new-sidebar/data";
import { Link } from "react-router-dom";

function SearchAny() {
    const [search, setSearch] = useState("");
    const [show, setShow] = useState(false);

    const closeSearch = () => {
        setSearch("");
        setShow(false);
    };

    const onKeyDown = (e) => {
        if (!!show && e.key === "Escape") {
            closeSearch();
            e.preventDefault();
        }

        if ((e.metaKey || e.ctrlKey) && (e.which === 75 || e.key === "k" || e.key === "K") && !show) {
            setShow(true);
            e.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [show]);

    const results = useMemo(() => {
        if (search === "" || search === undefined || search === null) return sidebarData;

        let result = [];
        for (const item of sidebarData) {
            const items = item.items.filter((el) => `${el?.label}${el?.href}${el?.tags?.join("")}`.toLowerCase().includes(search?.trim()?.toLowerCase()));
            if (!!items.length) result.push({ ...item, items });
        }
        return result;
    }, [sidebarData, search]);

    if (!show) return <></>;
    return (
        <>
            <div onClick={() => closeSearch()} className={classes.backdrop} />

            <div className={classes.screen}>
                <div className={classes.container}>
                    <div className={classes.search_container}>
                        <HiOutlineMagnifyingGlass className={classes.search_icon} />

                        <input type="text" placeholder="Search Pages" autoFocus value={search} onChange={(e) => setSearch(e.target.value)} />

                        <button tabIndex="-1" onClick={closeSearch} className={classes.escape_btn}>
                            ESC
                        </button>
                    </div>

                    <div className={classes.results_container}>
                        {!!results?.length && (
                            <div className={classes.results}>
                                {results?.map((item, index) => (
                                    <div key={index} className={classes.category_item}>
                                        <p className={classes.main_title}>{item.label}</p>
                                        <div className={classes.item_wrapper}>
                                            {item?.items?.map((el, indx) => (
                                                <Link to={el?.href} tabIndex="0" key={`${index}_${indx}`} onClick={closeSearch} className={classes.item}>
                                                    <div className={classes.page}>
                                                        <span className={classes.hash}>
                                                            <CgHashtag />
                                                        </span>
                                                        <p className={classes.item_title}>{el.title}</p>

                                                        <span className={classes.arrow}>
                                                            <FiChevronRight />
                                                        </span>
                                                    </div>
                                                    <div className={classes.page_info}>
                                                        {el?.tags?.map((tag, tagIndex) => (
                                                            <span key={`${tagIndex}-tag-${index}`}>{tag}</span>
                                                        ))}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {!results?.length && (
                            <div className={classes.not_found}>
                                No Matches Found
                                <br />
                                <span className={classes.sad_emoji}>
                                    <HiOutlineEmojiSad />
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchAny;
