// Preletigation Legal Notice Batch Upload Mappings

export const toShowParameters = [
    "lan_number",
    "customer_name",
    "state",
    "zip_code",
    "prd_grp",
    "emi_amount",
    "od_pos_final",
    "bkt_grp",
    "mobile",
    "address_type",
    "address1",
    "clm_name",
    "clm_contact_no",
    "email",
    "city",
    "od_future_amount",
    "legal_case_id",
    "customer_office_address",
    "customer_permanent_address",
    "loan_tenure",
    "npa_date",
    "camp_address",
    "camp_time",
    "camp_date",
    "concilator_name",
    "pursuable_amount",
    "clm_email_id",
    "language",
];

export const toHideParameters = [
    "alt_mobile_1",
    "alt_mobile_2",
    "address2",
    "address3",
    "address4",
    "alt_email_1",
    "alt_email_2",
    "section_138",
    "company_name",
    "reg_number",
    "chassis_no",
    "eng_no",
    "model",
    "closure_date",
    "agreement_date",
    "applicant_type",
    "final_btk",
    "od_pos_in_cr",
    "branch",
    "region",
    "remarks",
    "bank_ifsc_code",
    "cheque_bank",
    "cheque_account_no",
    "cheque_amount",
    "cheque_returned_date",
    "cheque_bounce_reason",
    "cheque_no",
    "cheque_issued_date",
    "cheque_bank_address",
    "cheque_submitted_date",
    "cheque_submitted_bank",
    "cheque_submitted_bank_address",
    "cheque_customer_name",
    "co_borrower_name",
    "co_address",
    "company_pan",
    "co_pan",
    "co_mail",
    "co_contact",
    "co_borrower2_name",
    "co_borrower_2_address",
    "co_borrower_2_pan",
    "co_borrower_2_contact",
    "co_borrower_2_mail",
    "co_borrower3_name",
    "co_borrower_3_address",
    "co_borrower_3_pan",
    "co_borrower_3_contact",
    "co_borrower_3_mail",
    "co_borrower_4_name",
    "co_borrower_4_address",
    "co_borrower_4_pan",
    "co_borrower_4_contact",
    "co_borrower_4_mail",
    "co_borrower_5_name",
    "co_borrower_5_address",
    "co_borrower_5_pan",
    "co_borrower_5_contact",
    "co_borrower_5_mail",
    "co_borrower_6_name", 
    "co_borrower_7_name" ,
    "co_borrower_8_name" ,
    "co_borrower_9_name" ,
    "co_borrower_10_name", 
    "co_borrower_6_address", 
    "co_borrower_7_address" ,
    "co_borrower_8_address" ,
    "co_borrower_9_address" ,
    "co_borrower_10_address", 
    "guarantor_name",
    "guarantor_address",
    "guarantor_2_name",
    "guarantor_2_address",
    "arbitration",
    "lender_name",
];

export const labelMapping = {
    camp_address: "Camp Address",
    camp_time: "Camp Time",
    camp_date: "Camp Date",
    concilator_name: "Conciliator Name",
    pursuable_amount: "Pursuable Amount",
    clm_email_id: "Collection Manager Mail ID",
    alt_mobile_1: "2nd Contact",
    alt_mobile_2: "3rd Contact",
    alt_email_1: "Email ID 2",
    alt_email_2: "Email ID 3",
    email: "Email ID 1",
    emi_amount: "EMI Amount",
    od_future_amount: "Foreclosure Amount",
    company_name: "Company Name",
    lan_number: "Loan Account No.",
    account: "Accounts",
    applicant_type: "Applicant type",
    language: "Language",
    customer_name: "Customer Name",
    address_type: "Address type",
    address1: "Address 1",
    address2: "Address 2",
    address3: "Address 3",
    address4: "Address 4",
    city: "City",
    state: "State",
    zip_code: "Zip Code",
    mobile: "Primary Contact",
    final_btk: "Final Bkt",
    dpd: "DPD",
    od_pos_final: "Loan Amount",
    od_pos_in_cr: "ODPos in CR",
    prd_grp: "Product",
    branch: "Branch",
    region: "Region",
    bkt_grp: "Due Since / BKT",
    remarks: "Remarks",
    legal_case_id: "Legal Case ID",
    customer_office_address: "Customer office address",
    customer_permanent_address: "Customer permanent address",
    loan_tenure: "Loan Tenure",
    clm_name: "Collection Manager Name",
    clm_contact_no: "Collection Manager Contact No",
    co_borrower_name: "Co Borrower Name",
    co_address: "Co Borrower Address",
    reg_number: "Vehicle Number",
    chassis_no: "Chassis Number",
    eng_no: "Engine Number",
    model: "Model",
    closure_date: "Closure date",
    agreement_date: "Disbursal Date",
    bank_ifsc_code: "Customer IFSC Code",
    cheque_bank: "Cheque Bank",
    cheque_account_no: "Cheque Account No.",
    cheque_amount: "Cheque Amount",
    cheque_returned_date: "Cheque Returned Date",
    cheque_bounce_reason: "Cheque Bounce Reason",
    cheque_no: "Cheque No.",
    cheque_issued_date: "Cheque Issued Date",
    cheque_bank_address: "Cheque Bank Address",
    cheque_submitted_date: "Cheque Submitted Date",
    cheque_submitted_bank: "Cheque Submitted Bank",
    cheque_submitted_bank_address: "Cheque Submitted Bank Address",
    cheque_customer_name: "Cheque Customer Name",
    section_138: "Sec 138 NIA Status",
    company_pan: "Company PAN",
    npa_date: "NPA Date",
    co_pan: "Co Borrower PAN",
    co_mail: "Co Borrower Email",
    co_contact: "Co Borrower Contact No.",
    co_borrower2_name: "Co Borrower 2 Name",
    co_borrower_2_address: "Co Borrower 2 Address",
    co_borrower_2_pan: "Co Borrower 2 PAN",
    co_borrower_2_contact: "Co Borrower 2 Contact No.",
    co_borrower_2_mail: "Co Borrower 2 Mail",
    co_borrower3_name: "Co Borrower 3 Name",
    co_borrower_3_address: "Co Borrower 3 Address",
    co_borrower_3_pan: "Co Borrower 3 PAN",
    co_borrower_3_contact: "Co Borrower 3 Contact No.",
    co_borrower_3_mail: "Co Borrower 3 Email",
    co_borrower_4_name: "Co Borrower 4 Name",
    co_borrower_4_address: "Co Borrower 4 Address",
    co_borrower_4_pan: "Co Borrower 4 PAN",
    co_borrower_4_contact: "Co Borrower 4 Contact No.",
    co_borrower_4_mail: "Co Borrower 4 Email",
    co_borrower_5_name: "Co Borrower 5 Name",
    co_borrower_5_address: "Co Borrower 5 Address",
    co_borrower_5_pan: "Co Borrower 5 PAN",
    co_borrower_5_contact: "Co Borrower 5 Contact No.",
    co_borrower_5_mail: "Co Borrower 5 Email",
    co_borrower_6_name: "Co Borrower 6 Name",
    co_borrower_7_name: "Co Borrower 7 Name",
    co_borrower_8_name: "Co Borrower 8 Name",
    co_borrower_9_name: "Co Borrower 9 Name",
    co_borrower_10_name: "Co Borrower 10 Name",

    co_borrower_6_address: "Co Borrower 6 Address",
    co_borrower_7_address: "Co Borrower 7 Address",
    co_borrower_8_address: "Co Borrower 8 Address",
    co_borrower_9_address: "Co Borrower 9 Address",   
    co_borrower_10_address: "Co Borrower 10 Address",

    co_borrower_6_pan: "Co Borrower 6 PAN",
    co_borrower_6_contact: "Co Borrower 6 Contact No.",
    co_borrower_6_mail: "Co Borrower 6 Email",
    guarantor_name: "Guarantor Name",
    guarantor_address: "Guarantor Address",
    guarantor_2_name: "Guarantor Name 2",
    guarantor_2_address: "Guarantor Address 2",
    arbitration: "Arbitration Status",
    notice_type: "Notice Type",
    lender_name: "Lender Name",
    card_no: "Card Number",
    casa_id: "Casa Id",
    lein_amount: "Lein Amount",
    recall_notice_date: "Recall Notice Date",
    irregularity_notice_date: "Irregularity Notice Date",
    margin_notice_date: "Margin Call Notice Date",
    charges_as_on_date: "Charges as on Date",
    auction_date: "Auction Date",
    cust_id: "Customer ID",
    due_date: "Due Date",
    dd_number: "DD No.",
    excess_beneficiary_account_number: "Other Loan acc no.",
    excess_amount: "Excess amount",
    last_paid_date: "Last Paid Date",
    last_paid_amount: "Last Paid Amount",

    description_of_jewels: "Description of Jewels",
    fineness_in_carats: "Fineness in Carats",
    article_count: "No. of Articles",
    net_weight: "Net Weight",
    rate_per_gram: "Rate per gram",
    appraised_value: "Appraised Value",
    highest_bid_amount: "Highest Bid Amount",
    lan_closure_date: "LAN Closure Date",
    gold_loan_outstanding: "Gold Loan Outstanding",
    card_account_no: "Card Account No",
};

export const mappings = {
    lan_number: [
        "lanno",
        "loanno",
        "loanid",
        "loannumber",
        "lan",
        "loanaccountno",
        "loanaccountnumber",
        "loannoaccountno",
        "agmtno",
        "accountnumber",
        "accountreference",
        "loancode",
        "accno",
    ],
    customer_name: [
        "customername",
        "cname",
        "custname",
        "customer",
        "name",
        "nameofcustomer",
        "borrowername",
        "contactname",
        "customertobedebited",
        "nameofborrower",
    ],
    state: ["state", "currentstate", "custstate", "commstate"],
    zip_code: ["zipcode", "currentpincode", "pincode", "custpin", "commpin"],
    prd_grp: ["prdgrp", "productcategory1", "product", "productgroup", "productname"],
    company_name: ["entityname", "entity", "clientname", "companyname", "workplacename"],
    applicant_type: ["applicanttype"],
    email: ["email", "mail", "emailid", "mailid", "companymailid", "currentemail", "customercurrentemailid", "customeremailid"],
    address_type: ["addresstype"],
    address1: [
        "address1",
        "add1",
        "customerresiadd",
        "address",
        "currentaddress",
        "companyaddress",
        "currentaddrline1",
        "residenceaddress",
        "customercurrentaddress",
        "custadd1",
        "customeraddress1withpincode"
    ],
    address2: ["address2", "add2", "currentaddrline2", "custadd2"],
    address3: ["address3", "add3", "custadd2"],
    address4: ["address4", "add4"],
    city: ["city", "currentcity", "custcity"],
    mobile: ["mobile", "customerm", "contactno", "contactnumber", "companycontactno", "currentmobile", "phone", "customercurrentcontact", "customermobileno"],
    final_btk: ["finalbktjan21", "bucket"],
    dpd: ["openingdpd", "dpd"],
    od_pos_final: ["odposfinal", "netdisbamount", "totaldue", "amountfinanced", "loanamount", "disbursementamount"],
    od_pos_in_cr: ["odposincr"],
    od_future_amount: [
        "principalos",
        "bckt",
        "tos",
        "overprin",
        "principaloutstanding",
        "totaloutstanding",
        "totaloutstandingonclosuredateinr",
        "totaloutstandingonclosuredate",
        "osamount",
        "claimamount"
    ],
    branch: ["branch"],
    region: ["region", "regionterritory"],
    bkt_grp: ["bktgrp", "dpdgrp"],
    emi_amount: ["emiamountrs", "emiamount", "emiamounts", "fixedemi", "emi"],
    remarks: ["remarks"],
    alt_email_1: ["altemail1", "2ndemailid"],
    alt_email_2: ["altemail2", "3rdemailid"],
    alt_mobile_1: ["altmobile1", "2ndcontactno", "permanentmobile", "altmobilenumber", "altmobile"],
    alt_mobile_2: ["altmobile2", "3rdcontactno"],
    legal_case_id: ["legalcaseid", "referenceno", "refno"],
    account: ["account1", "accounts1"],
    customer_office_address: ["customerofficeaddress", "officeaddress", "borrowerofficeaddress", "officeadd", "workplaceaddress"],
    customer_permanent_address: ["customerpermanentaddress", "permanentadd", "permenantadd", "permanentaddrline1", "permanentaddress"],
    loan_tenure: ["loantenure", "tenure", "tenureofloan"],
    clm_name: ["clmname", "collectionmanagername", "manager", "collectionofficername"],
    clm_contact_no: ["clmcontactrno", "clmcontactno", "clmcontact", "clmno", "managermobileno", "collectionofficermobilenumber"],
    co_borrower_name: ["coborrowername", "coborrowername1", "coapplicant1"],
    co_address: ["coborroweradd,", "coborroweradd", "coborroweraddress", "coapplicant1address"],
    reg_number: ["regno,", "regnumber", "registerno", "registernumber", "vechicleno", "vehiclenumber", "registrationnumber"],
    chassis_no: ["chassisno,", "chassisnumber"],
    eng_no: ["engno,", "engnumber", "engineno", "enginenumber"],
    model: ["model", "modelname"],
    closure_date: ["closuredate", "lanclosuredate"],
    agreement_date: ["agreementdate", "disbursal", "disbursaldata", "disburseddate", "loandisbursementdate", "dateldisburseddate"],
    bank_ifsc_code: ["customerifsc", "customerifsccode", "ifsccode", "ifsc"],
    cheque_bank: ["chequebank", "bank", "bankname"],
    cheque_account_no: ["chequeaccountno", "customerdebitac", "customerdebitaccount"],
    cheque_amount: ["chequeamount", "bouncedamount"],
    cheque_returned_date: ["chequereturneddate", "dateoftxn"],
    cheque_bounce_reason: ["chequebouncereason", "reasondescription"],
    cheque_no: ["chequeno", "chequenumber"],
    cheque_issued_date: ["chequeissueddate"],
    cheque_bank_address: ["chequebankaddress"],
    cheque_submitted_date: ["chequesubmitteddate"],
    cheque_submitted_bank: ["chequesubmittedbank"],
    cheque_submitted_bank_address: ["chequesubmittedbankaddress"],
    cheque_customer_name: ["chequecustomername"],
    section_138: ["sec138niastatus", "sec138"],
    company_pan: ["companypan"],
    npa_date: ["npadate"],
    co_pan: ["coborrowerpanno"],
    co_mail: ["coborrowermailid"],
    co_contact: ["coborrowercontactno"],
    co_borrower2_name: ["coborrowername2"],
    co_borrower_2_address: ["coborrower2address"],
    co_borrower_2_pan: ["coborrower2panno"],
    co_borrower_2_contact: ["coborrower2contactno"],
    co_borrower_2_mail: ["coborrower2mailid"],
    co_borrower3_name: ["coborrowername3"],
    co_borrower_3_address: ["coborrower3address"],
    co_borrower_3_pan: ["coborrower3panno"],
    co_borrower_3_contact: ["coborrower3contactno"],
    co_borrower_3_mail: ["coborrower3mailid"],
    co_borrower_4_name: ["coborrowername4"],
    co_borrower_4_address: ["coborrower4address"],
    co_borrower_4_pan: ["coborrower4panno"],
    co_borrower_4_contact: ["coborrower4contactno"],
    co_borrower_4_mail: ["coborrower4mailid"],
    co_borrower_5_name: ["coborrowername5"],
    co_borrower_5_address: ["coborrower5address"],
    co_borrower_5_pan: ["coborrower5panno"],
    co_borrower_5_contact: ["coborrower5contactno"],
    co_borrower_5_mail: ["coborrower5mailid"],

    co_borrower_6_name: ["coborrowername6"],
    co_borrower_7_name: ["coborrowername7"],
    co_borrower_8_name: ["coborrowername8"],
    co_borrower_9_name: ["coborrowername9"],
    co_borrower_10_name: ["coborrowername10"],
 
    co_borrower_6_address: ["coborrower6address"],
    co_borrower_7_address: ["coborrower7address"],
    co_borrower_8_address: ["coborrower8address"],
    co_borrower_9_address: ["coborrower9address"],
    co_borrower_10_address: ["coborrower10address"],

    co_borrower_6_pan: ["coborrower6panno"],
    co_borrower_6_contact: ["coborrower6contactno"],
    co_borrower_6_mail: ["coborrower6mailid"],

    guarantor_name: ["guarantorname1"],
    guarantor_address: ["guarantoraddress"],
    guarantor_2_name: ["guarantorname2"],
    guarantor_2_address: ["guarantoraddress"],
    arbitration: ["arbitrationstatus"],
    camp_address: ["campaddress", "visitaddress"],
    camp_time: ["camptime", "visittiming"],
    camp_date: ["campdate", "visitdate"],
    concilator_name: ["conciliatorname"],
    pursuable_amount: ["pursuableamount", "pursuable", "totaloverdue"],
    clm_email_id: ["clmemailid", "clmemail", "managermailid"],
    notice_type: ["noticetype"],
    lender_name: ["lendername", "lender"],
    language: ["lang", "language", "languageofnotice"],
    card_no: ["cardno", "cardnumber"],
    casa_id: ["casaid"],
    lein_amount: ["leinamount", "lein"],

    recall_notice_date: ["recallletterdate"],
    irregularity_notice_date: ["irregularityletterdate"],
    margin_notice_date: ["margincalldate"],
    charges_as_on_date: ["chargesasondate","claimamountason"],
    auction_date: ["auctiondate"],
    cust_id: ["customerid", "custid"],
    due_date: ["duedate"],
    dd_number: ["ddno"],
    excess_beneficiary_account_number: ["savingsaccountotherloanlccountnumber"],
    excess_amount: ["excessamountinrs", "excessamount"],
    last_paid_date: ["lastpaiddate"],
    last_paid_amount: ["lastpaidamount"],
    description_of_jewels: ["descriptionofjewels"],
    fineness_in_carats: ["finenessincarats"],
    article_count: ["noofarticles"],
    net_weight: ["netweight"],
    rate_per_gram: ["ratepergram"],
    appraised_value: ["appraisedvalue"],
    highest_bid_amount: ["highestbidamount"],
    lan_closure_date: ["lanclosuredate"],
    gold_loan_outstanding: ["goldloanoutstanding"],
    card_account_no: ["cardaccountno"],
};
