import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import { getNoticeTypes } from "../../../../API/agentApis";
import { getNoticePdfLink, postSendLegalNotice } from "../../../../API";
import { copyTextToClipboard } from "../../../../Functions/copyToClipboard";
import SelectDropdown from "../../../../ui/forms/SelectDropdown";
import { isEmpty } from "../../../../Functions/isEmpty";
import { showToast } from "../../../../utils/handleToast";
import useBorrowerInfo from "../store";

const communicationModeOptions = [
    { label: "Whatsapp", value: "whatsapp" },
    { label: "SMS", value: "sms" },
    { label: "Email", value: "email" },
];

export default function LegalNotice() {
    const data = useBorrowerInfo((state) => state.data);

    const [show, setShow] = useState(false);

    const [communicationModes, setCommunicationModes] = useState([]);
    const [noticeTypesOptions, setNoticeTypesOptions] = useState([]);
    const [noticeType, setNoticeType] = useState("");
    const [pdfLink, setPdfLink] = useState("");
    const [pdfLinkLoading, setPdfLinkLoading] = useState(false);

    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setShow(false);
        setNoticeType("");
    };

    useEffect(() => {
        if (!!show) {
            setCommunicationModes(["email", "whatsapp", "sms"]);
        }
    }, [show]);

    const fetchNoticeTypes = async () => {
        const res = await getNoticeTypes();
        if (res) setNoticeTypesOptions(res?.data?.map(({ name, type }) => ({ label: name, value: type })));
    };

    useEffect(() => {
        fetchNoticeTypes();
    }, []);

    const fetchNoticePdfLink = async () => {
        if (!data?.data?.customer_id || !noticeType) {
            setPdfLink("");
            return;
        }

        setPdfLinkLoading(true);

        const res = await getNoticePdfLink({
            customer_id: data?.data?.customer_id,
            notice_type: noticeType,
        });

        if (!!res) {
            setPdfLink(res?.data);
        } else setPdfLink("");

        setPdfLinkLoading(false);
    };

    useEffect(() => {
        fetchNoticePdfLink();
    }, [noticeType]);

    const sendNotice = async () => {
        setLoading(true);

        if (!data?.data?.customer_id) {
            showToast({ variant: "warning", message: "Customer Not Found" });
            return;
        }

        if (!noticeType) {
            showToast({ variant: "warning", message: "Please select a notice type" });
            return;
        }

        const res = await postSendLegalNotice({
            customer_id: data?.data?.customer_id,
            notice_type: noticeType,
            communication_channels: communicationModes,
        });

        if (!!res) {
            showToast({ variant: "success", message: res?.message });
        } else {
            showToast({ variant: "danger", message: "Failed to send legal notice" });
        }
        setLoading(false);
    };

    return (
        <>
            <a
                onClick={() => setShow(true)}
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "#118CEC" }}
                className="col-6 py-2 fw-medium"
            >
                Legal Notice
            </a>

            <Modal show={show} size="sm">
                <Modal.Header>
                    <Modal.Title>Send Legal Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pt-3">
                        {(!!pdfLink || pdfLinkLoading) && (
                            <div className="pb-3 d-flex align-items-center justify-content-between gap-3">
                                <div className="d-flex align-items-center justify-content-start gap-1">
                                    <svg className="flex-shrink-0" width="23" height="23" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="#727272" fillRule="evenodd">
                                            <path d="M9.245 12.32a.7.7 0 1 1 1.121-.838 2.802 2.802 0 0 0 4.225.302l2.093-2.091a2.8 2.8 0 0 0-3.954-3.967l-1.205 1.197a.7.7 0 0 1-.988-.993l1.212-1.204a4.202 4.202 0 0 1 5.934 5.948l-2.101 2.1a4.203 4.203 0 0 1-6.337-.453z" />
                                            <path d="M13.168 10.082a.7.7 0 1 1-1.122.839 2.802 2.802 0 0 0-4.225-.303L5.73 12.71a2.8 2.8 0 0 0 .034 3.925 2.8 2.8 0 0 0 3.919.043l1.197-1.197a.7.7 0 0 1 .99.99l-1.206 1.206a4.202 4.202 0 0 1-5.934-5.948l2.102-2.1a4.203 4.203 0 0 1 6.337.453z" />
                                        </g>
                                    </svg>

                                    {!pdfLinkLoading && (
                                        <a
                                            href={pdfLink}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="m-0"
                                            style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "21rem", textOverflow: "ellipsis" }}
                                        >
                                            {pdfLink}
                                        </a>
                                    )}

                                    {pdfLinkLoading && (
                                        <a className="m-0" style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "21rem", textOverflow: "ellipsis" }}>
                                            Loading...
                                        </a>
                                    )}
                                </div>
                                <button
                                    disabled={!pdfLink}
                                    onClick={() => {
                                        if (isEmpty(pdfLink)) {
                                            toast.error("No Link Found", { autoClose: 2000 });
                                            return;
                                        }

                                        copyTextToClipboard(pdfLink)
                                            .then(() => {
                                                toast.success("Copied to Clipboard", { autoClose: 2000 });
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            });
                                    }}
                                    className="btn btn-secondary btn-sm flex-shrink-0"
                                >
                                    <FiCopy />
                                    Copy
                                </button>
                            </div>
                        )}
                        <div className="mb-2">
                            <div className="my-2 w-75">
                                <p className="mb-1">Notice Type</p>
                                <SelectDropdown
                                    className="bg-light-grey text-dark"
                                    options={noticeTypesOptions}
                                    value={noticeType}
                                    onChange={setNoticeType}
                                    placeholder="Select Notice Type"
                                />
                            </div>
                        </div>

                        <div className="mb-4 mt-3 row">
                            <label htmlFor="number" className="col-lg-12 col-form-label pb-0">
                                Communication Mode
                            </label>
                            <div className="col-12 d-flex items-center justify-content-start gap-4 mt-1">
                                {communicationModeOptions.map((item) => (
                                    <label key={item.value} className="col-form-label pt-0">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={communicationModes.includes(item.value)}
                                            onChange={(e) =>
                                                setCommunicationModes((prev) =>
                                                    e.target.checked ? [...new Set([...prev, item.value])] : prev.filter((el) => el !== item.value)
                                                )
                                            }
                                        />{" "}
                                        {item.label}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closeModal()}>
                        Close
                    </Button>
                    <Button disabled={loading} onClick={() => sendNotice()} variant="primary">
                        {loading ? "Sending..." : "Send"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

// function MobileNumberMaskedInput({ value, onChange = () => {}, name = "" }) {
//     const [displayValue, setDisplayValue] = useState("");
//     const [isChanged, setIsChanged] = useState(false);

//     useEffect(() => {
//         if (!isChanged) {
//             setDisplayValue(maskMobileNumber(value));
//             setIsChanged(true);
//         }
//     }, [value]);

//     const handleChange = (event) => {
//         const number = event.target.value;

//         // extract only the last 5 digits
//         const last5Digits = number.replace(/\D/g, "").slice(-5);

//         // mask the rest of the digits with asterisks
//         const maskedValue = number.slice(0, -5).replace(/./g, "*") + last5Digits;

//         setDisplayValue(maskedValue);

//         // extract the full unmasked value
//         const unmaskedValue = number.replace(/\D/g, "");
//         onChange(unmaskedValue);
//     };

//     return <input name={name} type="text" className="form-control" value={displayValue} onChange={handleChange} />;
// }

// const DivInput = ({ value, onChange, ...divProps }) => {
//     const [displayValue, setDisplayValue] = useState(value);

//     const handleChange = (event) => {
//         const { value } = event.target;
//         setDisplayValue(value);
//         onChange(value);
//     };

//     return (
//         <div {...divProps} contentEditable suppressContentEditableWarning onBlur={() => setDisplayValue(value)} onInput={handleChange}>
//             {displayValue}
//         </div>
//     );
// };
