import { Fragment, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import useBorrowerInfo from "../store";
import { isEmpty } from "../../../../Functions/isEmpty";

export default function LegalAction() {
    const [isOpen, setIsOpen] = useState(false);

    const data = useBorrowerInfo((state) => state.data);
    const legalDetails = data?.legal_details;

    const toggle = () => setIsOpen((prev) => !prev);

    return (
        <>
            <a onClick={toggle} style={{ color: "#118CEC" }} className="fw-medium py-2">
                Legal Action
            </a>

            <Offcanvas show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Legal Action</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {[
                        ["Legal Notices", !isEmpty(legalDetails?.legal_notices_count) ? `${legalDetails?.legal_notices_count} delivered` : ""],
                        ["Conciliation", !isEmpty(legalDetails?.conciliation) ? legalDetails?.conciliation : ""],
                        ["FIR", !isEmpty(legalDetails?.fir_status) ? legalDetails?.fir_status : ""],
                        ["Police Station Name", !isEmpty(legalDetails?.police_station_name) ? legalDetails?.police_station_name : ""],
                        ["FIR Number", !isEmpty(legalDetails?.fir_number) ? legalDetails?.fir_number : ""],
                        ["Arbritation", !isEmpty(legalDetails?.arbritation) ? legalDetails?.arbritation : ""],
                        ["Court Case", !isEmpty(legalDetails?.court_case) ? legalDetails?.court_case : ""],
                    ].map(([label, value], index) => (
                        <Fragment key={index}>
                            {!!value && (
                                <div className="d-flex justify-content-between gap-3 mb-3">
                                    <p className="mb-0">{label}</p>
                                    <p className="mb-0 fw-bold text-black">{value}</p>
                                </div>
                            )}
                        </Fragment>
                    ))}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
