import React, { useState } from "react";
import classes from "./style.module.css";

export default function ImageAttachment({ url = "" }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    return (
        <a href={url} target="_blank" rel="noreferrer">
            {loading && <div className={classes.image_loading} />}
            {!loading && error && <div className={classes.image_error} />}

            {!error && (
                <img
                    style={{
                        position: loading ? "absolute" : "relative",
                        opacity: loading ? 0 : 1,
                    }}
                    onError={() => {
                        setError(true);
                        setLoading(false);
                    }}
                    onLoad={() => setLoading(false)}
                    className={classes.chat_img}
                    src={url}
                    loading="lazy"
                    alt="attachment"
                />
            )}
        </a>
    );
}
