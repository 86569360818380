import React from "react";
import { useRouteMatch } from "react-router-dom";

export default function SubMenuLink({ children, target = "/match", icon, label = "", subMenu = false, labelText = "" }) {
    let key = label?.replace(/\s/g, "");

    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id={`headingAdmin${key}`}>
                <div
                    className="accordion-button collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapseAdmin${key}`}
                    aria-expanded="false"
                    aria-controls={`collapseAdmin${key}`}
                >
                    <a
                        className="nav-link d-flex align-items-start gap-1"
                        style={useRouteMatch(target) ? { fontWeight: "bold", color: "#000000" } : { fontWeight: "normal", color: "#505050" }}
                    >
                        <div style={{ width: "1.5rem", float: "left", height: "1.5rem", textAlign: "center", marginRight: "0.5rem" }}>{icon}</div>
                        <span className="menu-text" style={{ lineHeight: "1.3rem", whiteSpace: "normal" }}>
                            {!!labelText ? labelText : label}
                        </span>
                    </a>
                </div>
            </h2>

            <div
                id={`collapseAdmin${key}`}
                className="accordion-collapse collapse"
                aria-labelledby={`headingAdmin${key}`}
                data-bs-parent={`#accordionAdmin${subMenu ? key : ""}`}
            >
                <div className="accordion-body">
                    <div className="sidebar_submenu ">
                        <ul className="nav flex-column">{children}</ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
