import React from "react";
import useCallCustomer from "../../hooks/useCallCustomer";

export default function CallButton({ customer_mobile_number = "", styles }) {
    const { callButtonText, callCustomer, callTypes, loading } = useCallCustomer();

    return (
        <button
            disabled={!customer_mobile_number || loading}
            style={!!styles ? styles : { width: "6rem" }}
            className={`site-btn text-center blue-btn px-1 ${(!customer_mobile_number || loading) && "disabled-btn"}`}
            onClick={() => callCustomer({ call_type: callTypes?.AGENT, customer_mobile_number })}
        >
            {callButtonText}
        </button>
    );
}
