import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import { DEFAULT_PAGE, panelPages } from "../../utils/PanelConstants";

const redirectToLanding = (landing = "/") => {
    let landingRoute = DEFAULT_PAGE;

    for (let i = 0; i < panelPages?.length; i++) {
        const { key, route } = panelPages[i];

        if (landing === key) {
            landingRoute = route;
            break;
        }
    }

    return landingRoute;
};

export default function LandingPageRedirect() {
    const userData = useAuthStore((state) => state.userData);
    const history = useHistory();

    useEffect(() => {
        history.push(redirectToLanding(userData?.settings?.landing_page));
    }, [userData?.settings?.landing_page]);

    return <></>;
}
