import React from "react";
import { Redirect, Route } from "react-router-dom";
import useAuthStore from "../store/authStore";
import ControlledNavigation from "./Components/ControlledNavigation";
import ErrorBoundary from "../Components/ErrorBoundary";

function ProtectedRoute({ component: Component, ...restOfProps }) {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const userRole = useAuthStore((state) => state.role);

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                isAuthenticated && userRole === "client" ? (
                    <ErrorBoundary>
                        <ControlledNavigation component={Component} />
                    </ErrorBoundary>
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
}

export default ProtectedRoute;
