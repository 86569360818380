import { useState } from "react";

/*

    const initialData = [
        { 
            title: "Step #1", 
            completed: false, 
            key: "unique_key",
            is_disabled: false (optional)
        },
        .....
    ]

*/

export default function useStep(initialData = []) {
    const [steps, setSteps] = useState(initialData);
    const [activeStep, setActiveStep] = useState(initialData[0]?.key || null);

    const updateStep = ({ stepKey, completed = true }) => {
        setSteps((prevSteps) => {
            return prevSteps.map((step) => {
                if (step.key === stepKey) {
                    return { ...step, completed };
                }

                return step;
            });
        });
    };

    const updateMultipleStep = (stepKeys = []) => {
        /*
            stepKeys = [
                { key: 'step1', completed: true },
                { key: 'step2', completed: false },
                ...
            ]
        */

        setSteps((prevSteps) => {
            return prevSteps.map((step) => {
                const stepKey = stepKeys.find((stepKey) => stepKey.key === step.key);
                if (stepKey) {
                    return { ...step, completed: stepKey.completed };
                }

                return step;
            });
        });
    };

    const resetSteps = () => {
        setSteps(initialData);
        setActiveStep(initialData[0]?.key || null);
    };

    return { steps, updateStep, updateMultipleStep, resetSteps, activeStep, setActiveStep };
}
