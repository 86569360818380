import { Fragment, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import AnalysisCard from "./analysis-card";
import { format } from "date-fns";
import { getNonPayReason, getPaidType, getPaymentMode, getPaymentPlan, getReason } from "../../../Agent/features/borrower-info/helpers";
import { getBorrowerReport } from "../../../apis";
import { GrInProgress } from "react-icons/gr";
import ContentLoader from "../../../Components/ContentLoader";
import { IoCloseCircle } from "react-icons/io5";

export default function OnlineCaseStatus({ lan, batchStatus }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [analysis, setAnalysis] = useState([]);

  const fetchDetails = async () => {
    setIsLoading(true);

    const params = {
      lan,
    };

    if (batchStatus === "completed") params.batch_type = batchStatus;

    const analysisResponse = await getBorrowerReport(params);
    if (analysisResponse.success) {
      setAnalysis(analysisResponse?.data?.data?.data[0]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) fetchDetails();

    // return () => setAnalysis([]);
  }, [isOpen]);

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <>
      {/* <AnalysisCard
                
                title="Online Case Status"
                isLoading={isLoading}
                tag={analysis?.online_tag}
                content={analysis?.online_disposition}
                icon={(props) => (
                    <svg
                        {...props}
                        style={{ width: "20px", objectFit: "contain" }}
                        fill="currentColor"
                        viewBox="0 0 576 530"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M69 448.536C69 433.625 81.0883 421.536 96 421.536H315C329.912 421.536 342 433.625 342 448.536V472.536H385C399.912 472.536 412 484.625 412 499.536V529.536H0V499.536C0 484.625 12.0883 472.536 27 472.536H69V448.536ZM96 436.536H315C321.627 436.536 327 441.909 327 448.536V462.536H84V448.536C84 441.909 89.3726 436.536 96 436.536Z"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M110.732 275.772C89.3407 254.8 89.0013 220.458 109.973 199.067C130.946 177.677 165.288 177.337 186.678 198.309L195.789 207.241L299.064 101.905L289.953 92.9728C268.562 72.0007 268.223 37.6587 289.195 16.2678C310.167 -5.12299 344.509 -5.46242 365.9 15.5097L484.397 131.687C505.788 152.659 506.127 187.001 485.155 208.392C468.033 225.856 442 229.288 421.378 218.548L401.538 238.785L562.558 396.654C580.108 413.859 580.386 442.034 563.18 459.583C545.975 477.132 517.8 477.411 500.251 460.205L339.23 302.336L314.827 327.227C325.972 347.632 323.055 373.728 305.933 391.192C284.961 412.583 250.619 412.922 229.228 391.95L110.732 275.772ZM303.612 312.954L402.686 211.902L307.716 118.791L208.642 219.843L303.612 312.954ZM512.852 447.352L351.832 289.483L388.936 251.638L549.957 409.507C560.407 419.753 560.573 436.531 550.327 446.982C540.081 457.432 523.303 457.598 512.852 447.352Z"
                        />
                    </svg>
                )}
            /> */}

      <svg xmlns="http://www.w3.org/2000/svg" onClick={toggle} style={{ height: "26" }} viewBox="0 0 64 64" id="Law">
        <path
          fill="#599bff"
          d="M33.16 61.69H5.37a1.5 1.5 0 0 1-1.5-1.5V54a1.5 1.5 0 0 1 1.5-1.5H22a1.5 1.5 0 0 1 0 3H6.87v3.23h24.79v-3.27h-2.14a1.5 1.5 0 0 1 0-3h3.64a1.5 1.5 0 0 1 1.5 1.5v6.23a1.5 1.5 0 0 1-1.5 1.5Z"
          className="color222222 svgShape"
        ></path>
        <path
          fill="#599bff"
          d="M29.52 55.46A1.5 1.5 0 0 1 28 54v-4.77H10.51V54a1.5 1.5 0 0 1-3 0v-6.27A1.5 1.5 0 0 1 9 46.23h20.52a1.5 1.5 0 0 1 1.5 1.5V54a1.5 1.5 0 0 1-1.5 1.46zm-6.74-18.31a1.5 1.5 0 0 1-1.06-2.56l16.21-16.22a1.5 1.5 0 1 1 2.13 2.12L23.84 36.71a1.53 1.53 0 0 1-1.06.44zm-10.3-10.3a1.45 1.45 0 0 1-1.06-.44 1.49 1.49 0 0 1 0-2.12L27.64 8.07a1.5 1.5 0 1 1 2.12 2.12L13.54 26.41a1.47 1.47 0 0 1-1.06.44z"
          className="color222222 svgShape"
        ></path>
        <path
          fill="#599bff"
          d="M40.2 22.13a1.45 1.45 0 0 1-1.06-.44l-1.21-1.2a1.5 1.5 0 0 1 2.13-2.12l.14.14 2-2L31.62 5.93l-2 2 6.1 6.1a1.51 1.51 0 0 1 0 2.12 1.49 1.49 0 0 1-2.12 0L26.44 9a1.49 1.49 0 0 1 0-2.12l4.11-4.12a1.56 1.56 0 0 1 2.13 0l12.7 12.7a1.52 1.52 0 0 1 .44 1.06 1.5 1.5 0 0 1-.44 1.06l-4.12 4.12a1.48 1.48 0 0 1-1.06.43zM19.86 42.47A1.49 1.49 0 0 1 18.8 42L6.1 29.33a1.49 1.49 0 0 1 0-2.12l4.12-4.12a1.49 1.49 0 0 1 2.12 0l1.2 1.2a1.5 1.5 0 1 1-2.12 2.12l-.14-.14-2 2 10.58 10.58 2-2-5.39-5.38a1.51 1.51 0 0 1 0-2.12 1.49 1.49 0 0 1 2.12 0L25 35.79a1.5 1.5 0 0 1 0 2.12L20.92 42a1.51 1.51 0 0 1-1.06.47zM55.9 56.69a4.2 4.2 0 0 1-3-1.24l-25-24.92a1.47 1.47 0 0 1-.45-1.06 1.5 1.5 0 0 1 .44-1.06l3.87-3.86a1.49 1.49 0 0 1 2.12 0l25 24.91a4.24 4.24 0 0 1 0 6 4.2 4.2 0 0 1-2.98 1.23zM31.08 29.47 55 53.33a1.27 1.27 0 0 0 1.74 0 1.24 1.24 0 0 0 .36-.87 1.23 1.23 0 0 0-.36-.87l-24-23.86z"
          className="color222222 svgShape"
        ></path>
      </svg>

      <Offcanvas style={{ width: "28rem", paddingTop: "0.75rem" }} show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100 px-2">
            <div className="fw-600 fs-4">Online Case Status</div>
            <button onClick={toggle} type="button" className="bg-white border-0">
              <IoCloseCircle fill="#999999" size={30} />
            </button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {isLoading ? (
            <div className="position-relative top-50">
              <ContentLoader />
            </div>
          ) : analysis?.online_response_filled ? (
            <div className="pt-1">
              {!!analysis?.online_response_filled && (
                <div className="px-3 d-flex flex-column gap-3 m-3 fs-6">
                  <div className="d-flex justify-content-between gap-3">
                    <p className="mb-0 text-secondary fw-medium">Status</p>
                    <p className="mb-0 fw-medium">
                      {analysis?.online_response_filled?.submission_date ? (
                        <span style={{ color: "darkgreen" }} className="fw-600">
                          Filled Completely
                        </span>
                      ) : (
                        <span className="text-danger fw-600">Incomplete</span>
                      )}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between gap-3 fs-6">
                    <p className="mb-0 text-secondary fw-medium">Disposition</p>
                    <p className="mb-0 fw-bold">{getDisposition(analysis?.online_response_filled?.intention)}</p>
                  </div>
                </div>
              )}

              <div style={{ background: "#F5F6F8" }} className="p-3 rounded d-flex flex-column gap-3 m-3 fs-6 py-6 ">
                {[
                  {
                    label: "1st Pay Date",
                    value: !!analysis?.online_response_filled?.pay_date_1 ? format(new Date(analysis?.online_response_filled?.pay_date_1), "dd MMM yyyy") : "",
                  },
                  {
                    label: "2nd Pay Date",
                    value: !!analysis?.online_response_filled?.pay_date_2 ? format(new Date(analysis?.online_response_filled?.pay_date_2), "dd MMM yyyy") : "",
                  },
                  {
                    label: "Grace Period",
                    value: !!analysis?.online_response_filled?.grace_period
                      ? format(new Date(analysis?.online_response_filled?.grace_period), "dd MMM yyyy")
                      : "",
                  },
                  {
                    label: "Paid Date",
                    value: !!analysis?.online_response_filled?.paid_date ? format(new Date(analysis?.online_response_filled?.paid_date), "dd MMM yyyy") : "",
                  },
                  { label: "Amount Paid", value: analysis?.online_response_filled?.amount_paid },
                  { label: "Payment Plan", value: getPaymentPlan(analysis?.online_response_filled?.payment_plan) },
                  { label: "Reason", value: getReason(analysis?.online_response_filled?.reason) },
                  {
                    label: "Non Pay Reason",
                    value: getNonPayReason(analysis?.online_response_filled?.non_payment_reason),
                  },
                  { label: "Submission Date", value: analysis?.online_response_filled?.submission_date ?? "" },
                  { label: "Paid Type", value: getPaidType(analysis?.online_response_filled?.paid_payment_type) },
                  { label: "Payment Mode", value: getPaymentMode(analysis?.online_response_filled?.payment_mode) },
                  { label: "Transaction Ref", value: analysis?.online_response_filled?.transaction_ref_no },
                  { label: "IP Address", value: analysis?.online_response_filled?.ip_address },
                  {
                    label: "PTP Amount",
                    value:
                      (analysis?.online_response_filled?.intention?.includes("yesWillPay") &&
                        (analysis?.online_response_filled?.ptp_amount ? `Rs. ${analysis?.online_response_filled?.ptp_amount}` : null)) ||
                      null,
                  },
                  {
                    label: "PTP Date",
                    value: (analysis?.online_response_filled?.intention?.includes("yesWillPay") && analysis?.online_response_filled?.ptp_date) || null,
                  },
                ].map(({ label, value }, index) => (
                  <Fragment key={index}>
                    {!!value && (
                      <div className="d-flex justify-content-between gap-3 fs-6">
                        <p className="mb-0 text-secondary fw-medium">{label}</p>
                        <p className="mb-0 fw-600 text-black">{value}</p>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          ) : (
            <p className="text-center py-5">No data Found</p>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function getDisposition(intention) {
  if (!intention) return "";

  return intention.includes("refuseToPay__needMoreTime")
    ? "Promise to Pay"
    : intention.includes("yesWillPay__requestSettlement")
    ? "Settlement"
    : intention.includes("yesWillPay")
    ? "Promise To Pay"
    : intention.includes("alreadyPaid_fullPaid_thankyou")
    ? "Already Paid"
    : intention.includes("alreadyPaid")
    ? "Partial Paid"
    : intention.includes("refuseToPay")
    ? "Refuse To Pay"
    : intention;
}
