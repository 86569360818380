import React from "react";
import { useState, useEffect } from "react";
import Excel from "../../Static/Images/excel.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { VscLoading } from "react-icons/vsc";
import { MultiSelect } from "react-multi-select-component";
import { postPreLitiFIRBatchData } from "../../API";
import axios from "axios";

// import StateArray from "./StaticData";
import { useForm } from "react-hook-form";
import { mappings, toShowParameters, labelMapping } from "../../utils/file_mapping_constants/preletigation_fir_batch_mapping";
import AxiosCalls from "../../AxiosCalls";
import { injectStyle } from "react-toastify/dist/inject-style";
import { toast } from "react-toastify";
import SmallLoader from "../../Components/SmallLoader";
import { useBatchUploadHandler } from "../../hooks/batchUploadHandler";
import UploadProgressBar from "../../ui/UploadProgressBar";
import BatchUploadHandler from "../../ui/BatchUploadHandler";
import BatchFilterModal from "./FirHeader/BatchFilterModal";
import FilterButton from "../../ui/buttons/FilterButton";
import SortButton from "../../ui/buttons/SortButton";
import { format } from "date-fns";
import useAuthStore from "../../store/authStore";
import useClientStore from "../../store/clientStore";
import getBaseUrl from "../../apis/getBaseUrl";

const commModeOptions = [
    { label: "Postal", value: "postal" },
    { label: "Whatsapp", value: "whatsapp" },
    { label: "Email", value: "email" },
    { label: "SMS", value: "sms" },
];

function PrelitigationBatchHeader({ HitReload, setHitReload, setSearchParameter }) {
    const clientToken = useAuthStore((state) => state.token);
    const userData = useAuthStore((state) => state.userData);

    // mapping
    const removeMapping = useClientStore((state) => state.removeMapping);
    const addMapping = useClientStore((state) => state.addMapping);

    const [importPage, setImportPage] = useState(false);

    const [StateArray, setStateArray] = useState([]);
    const [DistrictArray, setDistrictArray] = useState([]);
    const [PoliceStationArray, setPoliceStationArray] = useState([]);
    const [IsUploading, setIsUploading] = useState(false);
    const [commicationMode, setCommicationMode] = useState([]);
    const [isHidden, setIsHidden] = useState(0);
    const [firstStepData, setFirstStepData] = useState();
    const [pdfDownloadButton, setPdfDownloadButton] = useState(null);
    const [checkPdfResponse, setCheckPdfResponse] = useState({});
    const [displayOtpField, setDisplayOtpField] = useState(false);
    const [otpTimeOut, setOtpTimeOut] = useState(false);
    const [complaintId, setComplaintId] = useState("");
    const [fileFirSuccessful, setFileFirSuccessful] = useState(false);

    const [SortAsc, setSortAsc] = useState(true);

    // filter
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        search: "",
        batch_no: "",
        date_from: null,
        date_to: null,
        specific_date: null,
    });
    const [searchBy, setSearchBy] = useState("");

    const filterMapping = () => ({
        file: filterData?.search || null,
        batch_name: null,
        client_batch_id: filterData?.batch_no || null,
        batch_status: null,
        from_date: !!filterData?.date_from ? format(new Date(filterData?.date_from), "yyyy-MM-dd") : "",
        to_date: !!filterData?.date_to ? format(new Date(filterData?.date_to), "yyyy-MM-dd") : "",
        specific_date: !!filterData?.specific_date ? format(new Date(filterData?.specific_date), "yyyy-MM-dd") : "",
    });

    useEffect(() => {
        // apply filter actiins
        setSearchParameter({ ...filterMapping() });
    }, [filterData]);

    //Modal
    const [firlgShow, setFirSmShow] = useState(false);
    const [isBorrowerEmailRequired, setIsBorrowerEmailRequired] = useState(false);

    //react Hook form
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
        getValues,
        setValue,
    } = useForm({ mode: "onSubmit" });

    let count = 0;
    let otpTime = 0;

    //Modal
    const [lgShow, setLgShow] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);

    // batch upload handler hook
    const {
        progressValue,
        importDisabled,
        fileName,
        batchFile,
        matchedHeaderNames,
        handleHeaderChange,
        availableHeaderNames,
        handleFileValidation,
        getUploadHeaders,
        resetBatchUpload,
        SelectSheetModal,
    } = useBatchUploadHandler({ showProgress: true });

    useEffect(() => {
        if (!!lgShow) addMapping(mappings);
    }, [lgShow]);

    //export matched columns
    const exportColumns = async () => {
        try {
            let _commicationMode = commicationMode;
            _commicationMode = _commicationMode.map((val) => val.value);

            let formData = new FormData();

            formData.append("batch_file", batchFile);
            formData.append("batch_name", fileName);
            formData.append("communication_mode", JSON.stringify(_commicationMode));
            formData.append("column_rename_data", JSON.stringify(getUploadHeaders()));

            const res = await postPreLitiFIRBatchData(formData);
            // const res = true
            if (res) {
                removeMapping();

                toast.success("imported successfully");
                resetBatchUpload();
                setCommicationMode([]);
                setImportPage(false);
                setIsUploading(false);
                setLgShow(false);
                setHitReload(!HitReload);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getStates();
        setInitialData();
    }, []);

    const setInitialData = () => {
        setValue("complainant_full_name", userData?.full_name);
        setValue("complainant_email", userData?.email);
        setValue("complainant_house_no", "2075-2089");
        setValue("complainant_street_name", "Adani Western Heights");
        setValue("complainant_area", "Near DN Nagar Metro Station, Andheri West");
        setValue("complainant_city", "Mumbai");
        setValue("complainant_pincode", "400053");
        // setValue("complainant_email", email);
    };

    const onSubmit = (data) => {
        setIsHidden(1);
        setFirstStepData({ ...firstStepData, data });
        if (isHidden == 1) {
            finalApiCall({ ...firstStepData, data });
            // setIsHidden(2);
            // setPdfDownloadButton(true);
        }
    };

    const finalApiCall = async (final_data) => {
        try {
            if (
                // final_data.data.state == "Maharashtra" &&
                final_data.data.borrower_city.toUpperCase() == "MUMBAI" ||
                final_data.data.borrower_city.toUpperCase() == "BOMBAY"
            ) {
                if (displayOtpField) {
                    if (otpTimeOut) {
                        setDisplayOtpField(false);
                        injectStyle();
                        toast.error("Session Timeout, Please Try Again !", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    } else {
                        checkForOtp(final_data.data.otp);
                    }
                } else {
                    const res = await axios.post(AxiosCalls.fileSingleFir, final_data.data);
                    if (res.data.data.status_code == 200) {
                        // setIsHidden(2);
                        setDisplayOtpField(true);
                        setComplaintId(res.data.complaint_id);
                        setTimeout(() => setOtpTimeOut(true), 240000);
                    }
                }
            } else {
                const res = await axios.post(AxiosCalls.fileSingleFir, final_data.data);

                if (res.data.data.status_code == 200) {
                    // setIsHidden(2);
                    checkPDFStatus(res.data.complaint_id);
                }
            }
        } catch (error) {
            setApiCalled(false);
            injectStyle();
            toast.error("Something Went Wrong Please Try Again !", {
                position: toast.POSITION.TOP_CENTER,
            });
            console.log(error);
        }
    };

    const checkForOtp = async (otp) => {
        const otpData = { complaint_id: complaintId, otp: otp };

        try {
            const res = await axios.post(`${getBaseUrl().baseUrl}/fir/api/update_otp_for_single_fir/`, { complaint_id: complaintId, otp: otp });

            if (res.status == 200) {
                checkPDFStatus(complaintId);
            }
        } catch {
            setApiCalled(false);
            injectStyle();
            toast.error("Invalid Otp !", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };
    const checkPDFStatus = (complaint_id) => {
        setApiCalled(true);
        setIsHidden(2);
        try {
            fetch(getBaseUrl().baseUrl + AxiosCalls.checkPdfUrl + complaint_id, { headers: { Authorization: `Token ${clientToken}` } })
                .then((response) => response.json())
                .then((data) => {
                    if (count <= 240000 && data.data.fir_registered == "null") {
                        setTimeout(() => {
                            checkPDFStatus(complaint_id);
                            count = count + 15000;
                        }, 15000);
                    } else if (data.data.fir_registered == "Error") {
                        setIsHidden(2);
                        setApiCalled(false);
                    } else if (data.data.fir_registered == "Success") {
                        count = 240001;
                        if (data.data.pdf_url != null) {
                            setPdfDownloadButton(data.data.pdf_url);
                            setCheckPdfResponse(data.data);
                            setFileFirSuccessful(true);
                            setTimeout(() => {
                                setApiCalled(false);
                            }, 500);

                            //axios.get(data.data.pdf_url);
                        } else {
                            injectStyle();
                            toast.error("Something Went Wrong Please Try Again !", {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        }

                        setApiCalled(false);
                    }
                })
                .catch((err) => {
                    setApiCalled(false);
                    console.log(err);
                });
        } catch (error) {
            setApiCalled(false);
            console.error({ error });
        }
    };
    const getStates = () => {
        try {
            fetch(`${getBaseUrl().baseUrl}/fir/api/get_states/`)
                .then((response) => response.json())
                .then((data) => {
                    setStateArray(data.data);
                });
        } catch (error) {
            console.error({ error });
        }
    };
    const getDistricts = (state_name) => {
        let state_id = "";
        StateArray.map((element) => {
            if (element.state_name == state_name) {
                state_id = element.state_id;
            }
        });
        try {
            fetch(`${getBaseUrl().baseUrl}/fir/api/get_districts/?state_id=${state_id}`)
                .then((response) => response.json())
                .then((data) => {
                    setDistrictArray(data.data);
                });
        } catch (error) {
            console.error({ error });
        }
    };
    const getPoliceStation = (website_id) => {
        let district_id = "";
        DistrictArray.map((element) => {
            if (element.website_id == website_id) {
                district_id = element.district_id;
            }
        });
        try {
            fetch(`${getBaseUrl().baseUrl}/fir/api/get_police_stations/?district_id=${district_id}`)
                .then((response) => response.json())
                .then((data) => {
                    setPoliceStationArray(data.data);
                });
        } catch (error) {
            console.error({ error });
        }
    };

    const handleChangeBorrowerCity = (newValue) => {
        if (newValue.toUpperCase() == "MUMBAI" || newValue.toUpperCase() == "BOMBAY") {
            setIsBorrowerEmailRequired(true);
            let district_id = "";
            DistrictArray.map((element) => {
                if (element.district_name == "MUMBAI" || element.district_name == "BOMBAY") {
                    district_id = element.website_id;
                    setValue("district", district_id);
                }
            });
        } else {
            setIsBorrowerEmailRequired(false);
        }
    };

    return (
        <div className="row d-flex d-wrap justify-between filter-section">
            <div className="col-md-12 col-lg-4 d-flex align-center">
                <FilterButton onClick={() => setShowFilter(true)} active={!!filterData && !!Object.values(filterData)?.some((el) => !!el)} />
                <SortButton
                    onClick={() => {
                        setSortAsc(!SortAsc);
                        let sort = SortAsc ? "asc" : "dsc";
                        setSearchParameter({ ...filterMapping(), sort });
                    }}
                    label="Sort By Batch"
                    active={!SortAsc}
                />
            </div>

            <div className="col-md-12 col-lg-8 pt-3 pt-lg-0 text-end">
                <button className="btn btn-outline-primary btn-lg" onClick={() => setFirSmShow(true)}>
                    File Single FIR
                </button>
                <button className="btn btn-outline-primary btn-lg ms-2 d-none">
                    <svg width="16" height="17" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#000" fillRule="evenodd">
                            <path d="M.202 11.702a.7.7 0 0 1 1.401 0v2.1a.7.7 0 0 0 .7.7h9.806a.7.7 0 0 0 .7-.7v-2.1a.7.7 0 0 1 1.401 0v2.1a2.1 2.1 0 0 1-2.1 2.1H2.303a2.1 2.1 0 0 1-2.102-2.1v-2.1z" />
                            <path d="M9.513 7.706a.7.7 0 0 1 .99.99l-2.801 2.8a.7.7 0 0 1-.99 0l-2.802-2.8a.7.7 0 1 1 .99-.99l2.306 2.306 2.307-2.306z" />
                            <path d="M6.506 1.2a.7.7 0 0 1 1.4 0v9.802a.7.7 0 0 1-1.4 0V1.2z" />
                        </g>
                    </svg>
                    Download Bulk Batch File
                </button>
                <button className="btn btn-primary btn-lg ms-2" onClick={() => setLgShow(true)}>
                    <svg width="14" height="17" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#ffffff" fillRule="evenodd">
                            <path d="M.903 8.201a.7.7 0 0 1 1.4 0v5.601a.7.7 0 0 0 .701.7h8.405a.7.7 0 0 0 .7-.7v-5.6a.7.7 0 0 1 1.4 0v5.6a2.1 2.1 0 0 1-2.1 2.1H3.004a2.1 2.1 0 0 1-2.101-2.1v-5.6zM7.206 2.19 4.9 4.497a.7.7 0 0 1-.99-.99L6.71.706a.7.7 0 0 1 .99 0l2.802 2.8a.7.7 0 1 1-.99.99L7.206 2.19z" />
                            <path d="M6.506 1.2a.7.7 0 0 1 1.4 0v9.102a.7.7 0 0 1-1.4 0V1.2z" />
                        </g>
                    </svg>
                    Upload New Batch File
                </button>
            </div>

            {/* Filter Modal */}

            <BatchFilterModal
                show={showFilter}
                close={() => setShowFilter(false)}
                prevFilter={filterData}
                setPrevFilter={setFilterData}
                searchByPrev={searchBy}
                setSearchByPrev={setSearchBy}
            />

            {/*Upload New Batch File*/}
            <Modal show={lgShow} onHide={() => setLgShow(false)} dialogClassName="modal-large" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">Upload New Batch File</Modal.Title>
                </Modal.Header>
                <Modal.Body className="tab-m-body">
                    <div className="pt-0 pb-5 text-center upload-batch-popup">
                        {IsUploading ? (
                            <Modal.Title id="example-custom-modal-styling-title">
                                <h1
                                    style={{
                                        height: "300px",
                                        display: "grid",
                                        placeItems: "center",
                                    }}
                                >
                                    {" "}
                                    <span>
                                        <VscLoading className="uploader" /> Uploading
                                    </span>{" "}
                                </h1>
                            </Modal.Title>
                        ) : (
                            <>
                                <div className="pt-4 d-flex import-step align-center content-center">
                                    {/* className="import-step step-active content-center"  */}
                                    <div className={`import-step content-center ${progressValue < 50 ? "step-active" : "step-completed"}`}>
                                        {progressValue < 100 ? (
                                            "01"
                                        ) : (
                                            <svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17 0C7.626 0 0 7.626 0 17c0 9.374 7.626 17 17 17 9.374 0 17-7.626 17-17 0-9.374-7.626-17-17-17zm8.135 13.995-8.757 8.757a2.118 2.118 0 0 1-1.503.623 2.118 2.118 0 0 1-1.502-.623l-4.508-4.507a2.124 2.124 0 1 1 3.005-3.005l3.005 3.005 7.255-7.255a2.124 2.124 0 1 1 3.005 3.005z"
                                                    fill="#409E7B"
                                                    fillRule="nonzero"
                                                />
                                            </svg>
                                        )}

                                        <span>Import File</span>
                                    </div>
                                    <div className="import-step-sep"></div>
                                    <div className={`import-step content-center ${progressValue >= 100 && "step-active"}`}>
                                        02
                                        <span>Map Columns</span>
                                    </div>
                                </div>

                                {importPage ? (
                                    progressValue < 100 ? (
                                        <UploadProgressBar progressValue={progressValue} />
                                    ) : (
                                        <BatchUploadHandler
                                            availableHeaderNames={availableHeaderNames}
                                            matchedHeaderNames={matchedHeaderNames}
                                            upfrontColumns={toShowParameters}
                                            labelMapping={labelMapping}
                                            handleHeaderChange={handleHeaderChange}
                                            automapping={false}
                                        />
                                    )
                                ) : (
                                    <div className="w-75 m-auto">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <form className="mt-2 text-start">
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <label htmlFor="Batch" className="col-form-label pt-0">
                                                                Communication Mode
                                                            </label>
                                                            <MultiSelect
                                                                className="bg-light-grey text-dark"
                                                                options={commModeOptions}
                                                                value={commicationMode}
                                                                onChange={setCommicationMode}
                                                                labelledBy="Select Communication Mode"
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="import-excel-img mb-2">
                                                    <img src={Excel} alt="Excel" />
                                                </div>
                                                <input
                                                    type="file"
                                                    name="uploadFile"
                                                    id="uploadFile"
                                                    accept=".xlse,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xlsb,.xml,.xla,.xlw,.xlr"
                                                    style={{ opacity: "0" }}
                                                    disabled={!commicationMode.length}
                                                    onChange={(e) => {
                                                        setImportPage(true);
                                                        handleFileValidation(e);
                                                        // handleFileValidation(e, mappings);
                                                    }}
                                                />
                                                <label
                                                    htmlFor="uploadFile"
                                                    style={!!commicationMode.length ? {} : { background: "#F1F8E9", border: "#607D8B", color: "#607D8B" }}
                                                    className="btn btn-primary btn-lg mb-2"
                                                >
                                                    Select excel file from your computer
                                                </label>
                                                <p>You can upload only CSV or XLS file to import your inventory 134.</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {importPage && (
                        <Button
                            variant="primary"
                            disabled={importDisabled || progressValue < 100}
                            className="collection_batch_upload_page_footer_import"
                            onClick={() => {
                                exportColumns();
                                setIsUploading(true);
                            }}
                        >
                            Import
                        </Button>
                    )}
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setImportPage(false);
                            setIsUploading(false);
                            resetBatchUpload();
                            setCommicationMode([]);
                            setLgShow(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            {/**/}

            <SelectSheetModal />

            {/*File Single FIR*/}
            <Modal
                show={firlgShow}
                onHide={() => {
                    //  setFirSmShow(false)
                }}
                dialogClassName="modal-large"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title id="example-custom-modal-styling-title">File Single FIR</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="tab-m-body">
                        <div className="pt-0 pb-3  upload-batch-popup">
                            {/* Step 1 */}
                            <div className="pt-4 d-flex import-step align-center content-center">
                                <div className={`import-step content-center ${isHidden == 0 ? "step-active" : "step-completed"}`}>
                                    01<span>Step 1</span>
                                </div>
                                <div className="import-step-sep"></div>
                                <div className={`import-step content-center ${isHidden == 1 ? "step-active" : isHidden == 2 ? "step-completed" : "false"}`}>
                                    02<span>Step 2</span>
                                </div>
                                <div className="import-step-sep"></div>
                                <div className={`import-step content-center ${isHidden == 2 ? "step-active" : "false"}`}>
                                    03<span>Step 3</span>
                                </div>
                            </div>
                            {isHidden == 0 ? (
                                <div className="w-75 m-auto">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8">
                                            <h2 className="text-center mb-4">Offender Details</h2>

                                            {/* <div className="mb-2 row">
                                            <label htmlFor="from" className="col-md-5 col-form-label">State</label>
                                            <div className="col-md-7">
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div> */}
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Pincode of borrower{" "}
                                                </label>
                                                <div className="col-md-7">
                                                    <input
                                                        placeholder="Pin Code"
                                                        {...register("borrower_pincode", {
                                                            required: "PinCode is required",
                                                            pattern: {
                                                                value: /^[1-9][0-9]{5}$/,
                                                                message: "invalid PinCode",
                                                            },
                                                        })}
                                                        name="borrower_pincode"
                                                        type="text"
                                                        maxLength={6}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div style={{ marginLeft: "250px", color: "red" }}>
                                                    {errors.borrower_pincode && errors.borrower_pincode.message}
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    State
                                                </label>
                                                <div className="col-md-7">
                                                    {
                                                        <select
                                                            {...register("state", {
                                                                onChange: (e) => {
                                                                    if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "") {
                                                                        getDistricts(e.target.value);
                                                                    } else {
                                                                        setDistrictArray([]);
                                                                    }
                                                                },
                                                            })}
                                                            id="inputState"
                                                            className="form-select"
                                                            required
                                                        >
                                                            <option value="">Select State</option>
                                                            {StateArray.map((stateName, index) => {
                                                                return (
                                                                    <option
                                                                        // selected={stateName.selected}
                                                                        disabled={!stateName.is_active}
                                                                        key={index}
                                                                        value={stateName.state_name}
                                                                    >
                                                                        {stateName.state_name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    }
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    District
                                                </label>
                                                <div className="col-md-7">
                                                    {
                                                        <select
                                                            {...register("district", {
                                                                onChange: (e) => {
                                                                    if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "") {
                                                                        getPoliceStation(e.target.value);
                                                                    } else {
                                                                        setPoliceStationArray([]);
                                                                    }
                                                                },
                                                            })}
                                                            id="inputState"
                                                            className="form-select"
                                                            required
                                                        >
                                                            <option value="">Select District</option>
                                                            {DistrictArray.map((stateName, index) => {
                                                                return (
                                                                    <option
                                                                        // selected={stateName.selected}
                                                                        key={index}
                                                                        value={stateName.website_id}
                                                                    >
                                                                        {stateName.district_name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    }
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Police Station
                                                </label>
                                                <div className="col-md-7">
                                                    {
                                                        <select
                                                            {...register("police_station")}
                                                            id="inputState"
                                                            className="form-select"
                                                            required={PoliceStationArray.length === 0 ? false : true}
                                                        >
                                                            <option value="">Select Police Station</option>
                                                            {PoliceStationArray.map((stateName, index) => {
                                                                return (
                                                                    <option
                                                                        // selected={stateName.selected}
                                                                        key={index}
                                                                        value={stateName.website_id}
                                                                    >
                                                                        {stateName.police_station_name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    }
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    City
                                                </label>
                                                <div className="col-md-7">
                                                    <input
                                                        placeholder="City"
                                                        {...register("borrower_city", {
                                                            pattern: {
                                                                value: /^[a-zA-Z\s]*$/,
                                                                message: "special character is not allowed",
                                                            },
                                                            onChange: (e) => {
                                                                handleChangeBorrowerCity(e.target.value);
                                                            },
                                                        })}
                                                        name="borrower_city"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    House No.
                                                </label>
                                                <div className="col-md-7">
                                                    <input
                                                        placeholder="House No."
                                                        {...register("borrower_house_no")}
                                                        name="borrower_house_no"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Street Name
                                                </label>
                                                <div className="col-md-7">
                                                    <input
                                                        placeholder="Street Name"
                                                        {...register("borrower_street_name")}
                                                        name="borrower_street_name"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Area
                                                </label>
                                                <div className="col-md-7">
                                                    <input
                                                        placeholder="Area"
                                                        {...register("borrower_area", {
                                                            pattern: {
                                                                value: /^[a-zA-Z\s]*$/,
                                                                message: "special character is not allowed",
                                                            },
                                                        })}
                                                        name="borrower_area"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div style={{ marginLeft: "250px", color: "red" }}>{errors.borrower_area && errors.borrower_area.message}</div>
                                            </div>

                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Product
                                                </label>
                                                <div className="col-md-7">
                                                    <input
                                                        placeholder="Product"
                                                        {...register("product")}
                                                        name="product"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Full Name
                                                </label>
                                                <div className="col-md-7">
                                                    <input
                                                        placeholder="Full Name"
                                                        {...register("borrower_full_name", {
                                                            required: "Full Name is required",
                                                            pattern: {
                                                                value: /^[a-zA-Z\s]*$/,
                                                                message: "special character is not allowed",
                                                            },
                                                        })}
                                                        name="borrower_full_name"
                                                        required
                                                        // type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div style={{ marginLeft: "250px", color: "red" }}>
                                                    {errors.borrower_full_name && errors.borrower_full_name.message}
                                                </div>
                                            </div>
                                            {/* <div className="mb-2 row">
                        <label htmlFor="from" className="col-md-5 col-form-label">
                          Borrower Email
                        </label>
                        <div className="col-md-7">
                          <input
                            placeholder="Complainant Email"
                            {...register("email")}
                            name="email"
                            required
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div> */}
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Borrower Mobile Number
                                                </label>
                                                <div className="col-md-7">
                                                    <input
                                                        {...register("borrower_mobile_number", {
                                                            required: "Mobile Number is required",
                                                            pattern: {
                                                                value: /^[6789]\d{9}$/,
                                                                message: "invalid Mobile number",
                                                            },
                                                        })}
                                                        name="borrower_mobile_number"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="9422233555"
                                                        required
                                                    />
                                                </div>
                                                <div style={{ marginLeft: "250px", color: "red" }}>
                                                    {errors.borrower_mobile_number && errors.borrower_mobile_number.message}
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Borrower Email
                                                </label>
                                                <div className="col-md-7">
                                                    <input
                                                        {...register("borrower_email", {
                                                            // required: "Email is required",
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "invalid email address",
                                                            },
                                                        })}
                                                        name="borrower_email"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="abcde@mail.com"
                                                        required={isBorrowerEmailRequired}
                                                    />
                                                </div>
                                                <div style={{ marginLeft: "250px", color: "red" }}>
                                                    {errors.borrower_email && errors.borrower_email.message}
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Borrower Gender
                                                </label>
                                                <div className="col-md-7">
                                                    {
                                                        <select
                                                            {...register("borrower_gender")}
                                                            id="borrower_gender"
                                                            name="borrower_gender"
                                                            className="form-select"
                                                        >
                                                            <option selected value="male">
                                                                Male
                                                            </option>
                                                            <option value="female">Female</option>

                                                            {/*  <option
                                selected={stateName.selected}
                                key={index}
                              >
                                {stateName.name}
                              </option> */}
                                                        </select>
                                                    }
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Company Name
                                                </label>
                                                <div className="col-md-7">
                                                    <input
                                                        placeholder="Company name"
                                                        {...register("company_name")}
                                                        name="company_name"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Amount{" "}
                                                </label>
                                                <div className="col-md-7">
                                                    <input placeholder="Amount" {...register("amount")} name="amount" type="number" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">
                                                    Complaint Text
                                                </label>
                                                <div className="col-md-7">
                                                    {/* <input type="text" className="form-control" placeholder="First name" {...register("First name", {required: true, maxLength: 80})} /> */}
                                                    {/* <input ref={register('complainText')} required placeholder="First name" name="complainText" type="text" className="form-control" /> */}
                                                    <textarea
                                                        type="text"
                                                        rows="4"
                                                        cols="50"
                                                        name="complaint_text"
                                                        className="form-control"
                                                        placeholder="Complaint Text"
                                                        {...register("complaint_text")}
                                                    />
                                                </div>
                                            </div>
                                            {/*  <div className="mb-2 row">
                                                <label htmlFor="from" className="col-md-5 col-form-label">Number for OTP</label>
                                                <div className="col-md-7">
                                                    <input ref={register} name='otp' required type="text" className="form-control" />
                                                </div>
                                            </div>      */}
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {/* Step 2 */}
                            {isHidden == 1 ? (
                                <>
                                    {/*  <div className="pt-4 d-flex import-step align-center content-center">
                                        <div className="import-step content-center step-active">01<span>Step 1</span></div>
                                        <div className="import-step-sep"></div>
                                        <div className="import-step content-center false">02<span>Step 2</span></div>
                                    </div> */}
                                    <div className="w-75 m-auto">
                                        {apiCalled ? (
                                            <div style={{ marginLeft: "400px" }}>
                                                <SmallLoader />
                                            </div>
                                        ) : null}
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <h2 className="text-center">Client / Complainant Details</h2>
                                                <form className="mt-3">
                                                    <div className="mb-2 row">
                                                        <label htmlFor="from" className="col-md-5 col-form-label">
                                                            Complainant Full Name
                                                        </label>
                                                        <div className="col-md-7">
                                                            <input
                                                                {...register("complainant_full_name", {
                                                                    required: "Complainant Full Name is required",
                                                                    pattern: {
                                                                        value: /^[a-zA-Z\s]*$/,
                                                                        message: "special character is not allowed",
                                                                    },
                                                                })}
                                                                name="complainant_full_name"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Complainant Full Name"
                                                                disabled={displayOtpField}
                                                                required
                                                            />
                                                        </div>
                                                        <div style={{ marginLeft: "250px", color: "red" }}>
                                                            {errors.complainant_full_name && errors.complainant_full_name.message}
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 row">
                                                        <label htmlFor="from" className="col-md-5 col-form-label">
                                                            Complainant Email
                                                        </label>
                                                        <div className="col-md-7">
                                                            <input
                                                                {...register("complainant_email", {
                                                                    required: "Complainant Email is required",
                                                                    pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                        message: "invalid email address",
                                                                    },
                                                                })}
                                                                name="complainant_email"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Complainant Email"
                                                                disabled={displayOtpField}
                                                                required
                                                            />
                                                        </div>
                                                        <div style={{ marginLeft: "250px", color: "red" }}>
                                                            {errors.complainant_email && errors.complainant_email.message}
                                                        </div>
                                                    </div>
                                                    {/* <div className="mb-2 row">
                            <label
                              htmlFor="from"
                              className="col-md-5 col-form-label"
                            >
                              Client Address
                            </label>
                            <div className="col-md-7">
                              <input {...register("client_address", { required: "Client Address is required" })} name='client_address' type="text" className="form-control" required />
                            </div>
                            <div style={{ marginLeft: '235px' }}>
                              {errors.client_address && errors.client_address.message}
                            </div>
                          </div> */}
                                                    <div className="mb-2 row">
                                                        <label htmlFor="from" className="col-md-5 col-form-label">
                                                            Complainant House No.
                                                        </label>
                                                        <div className="col-md-7">
                                                            <input
                                                                placeholder="Complainant House No,"
                                                                {...register("complainant_house_no")}
                                                                name="complainant_house_no"
                                                                type="text"
                                                                className="form-control"
                                                                disabled={displayOtpField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 row">
                                                        <label htmlFor="from" className="col-md-5 col-form-label">
                                                            Complainant Street Name
                                                        </label>
                                                        <div className="col-md-7">
                                                            <input
                                                                placeholder="Complainant Street Name"
                                                                {...register("complainant_street_name", {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z\s]*$/,
                                                                        message: "special character is not allowed",
                                                                    },
                                                                })}
                                                                name="complainant_street_name"
                                                                type="text"
                                                                className="form-control"
                                                                disabled={displayOtpField}
                                                            />
                                                        </div>
                                                        <div style={{ marginLeft: "250px", color: "red" }}>
                                                            {errors.complainant_street_name && errors.complainant_street_name.message}
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 row">
                                                        <label htmlFor="from" className="col-md-5 col-form-label">
                                                            Complainant Area
                                                        </label>
                                                        <div className="col-md-7">
                                                            <input
                                                                placeholder="Complainant Area"
                                                                {...register("complainant_area")}
                                                                name="complainant_area"
                                                                type="text"
                                                                className="form-control"
                                                                disabled={displayOtpField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 row">
                                                        <label htmlFor="from" className="col-md-5 col-form-label">
                                                            Complainant City
                                                        </label>
                                                        <div className="col-md-7">
                                                            <input
                                                                placeholder="Complainant City"
                                                                {...register("complainant_city")}
                                                                name="complainant_city"
                                                                type="text"
                                                                className="form-control"
                                                                disabled={displayOtpField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 row">
                                                        <label htmlFor="from" className="col-md-5 col-form-label">
                                                            Complainant Pincode
                                                        </label>
                                                        <div className="col-md-7">
                                                            <input
                                                                placeholder="Complainant Pincode"
                                                                {...register("complainant_pincode", {
                                                                    required: "Complainant Pincode is required",
                                                                    pattern: {
                                                                        value: /^[1-9][0-9]{5}$/,
                                                                        message: "invalid pincode",
                                                                    },
                                                                })}
                                                                name="complainant_pincode"
                                                                required
                                                                maxLength={6}
                                                                // type="number"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div style={{ marginLeft: "250px", color: "red" }}>
                                                            {errors.complainant_pincode && errors.complainant_pincode.message}
                                                        </div>
                                                    </div>
                                                    <div className="mb-2 row">
                                                        <label htmlFor="from" className="col-md-5 col-form-label">
                                                            Complainant Mobile Number
                                                        </label>
                                                        <div className="col-md-7">
                                                            <input
                                                                placeholder="Complainant Mobile Number"
                                                                {...register("number_for_otp", {
                                                                    required: " Complainant Mobile Number is required",
                                                                    pattern: {
                                                                        value: /^[6789]\d{9}$/,
                                                                        message: "invalid Mobile number",
                                                                    },
                                                                })}
                                                                name="number_for_otp"
                                                                required
                                                                maxLength={10}
                                                                // minLength={10}
                                                                // type="number"
                                                                className="form-control"
                                                                disabled={displayOtpField}
                                                            />
                                                        </div>
                                                        <div style={{ marginLeft: "250px", color: "red" }}>
                                                            {errors.number_for_otp && errors.number_for_otp.message}
                                                        </div>
                                                    </div>
                                                    {displayOtpField ? (
                                                        <div className="mb-2 row">
                                                            <label htmlFor="from" className="col-md-5 col-form-label">
                                                                OTP
                                                            </label>
                                                            <div className="col-md-7">
                                                                <input
                                                                    {...register("otp", {
                                                                        required: "OTP Number is required",
                                                                    })}
                                                                    name="otp"
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Please Enter OTP"
                                                                    required
                                                                />
                                                            </div>
                                                            <div style={{ marginLeft: "250px", color: "red" }}>{errors.otp && errors.otp.message}</div>
                                                        </div>
                                                    ) : null}
                                                    {/*  <div className="mb-2 row">
                            <label
                              htmlFor="from"
                              className="col-md-5 col-form-label"
                            >
                              Detail 3
                            </label>
                            <div className="col-md-7">
                              <input type="text" className="form-control" />
                            </div>
                          </div> */}
                                                    {/* <div className="mb-2 row">
                            <label
                              htmlFor="from"
                              className="col-md-5 col-form-label"
                            >
                              Detail 4{" "}
                            </label>
                            <div className="col-md-7">
                              <input type="text" className="form-control" />
                            </div>
                          </div> */}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}

                            {/* <div className="pt-4 d-flex import-step align-center content-center">
                <div className="import-step content-center step-completed">
                  01<span>Step 1</span>
                </div>
                <div className="import-step-sep"></div>
                <div className="import-step content-center step-completed">
                  02<span>Step 2</span>
                </div>
                <div className="import-step-sep"></div>
                <div className="import-step content-center step-active">
                  03<span>Step 3</span>
                </div>
              </div> */}

                            {isHidden == 2 ? (
                                <>
                                    {apiCalled ? (
                                        <div style={{ marginLeft: "400px" }}>
                                            <SmallLoader />
                                        </div>
                                    ) : (
                                        <>
                                            {fileFirSuccessful ? (
                                                <div className="w-75 m-auto">
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-8">
                                                            <h2 className="text-center">Acknowledgement</h2>
                                                            <p className="text-center">
                                                                Your FIR against {checkPdfResponse.customer_name ? checkPdfResponse.customer_name : ""} is filed
                                                            </p>
                                                            <p className="text-center">
                                                                Your Complaint Registered ID is{" "}
                                                                {checkPdfResponse.complaint_registration_id ? checkPdfResponse.complaint_registration_id : ""}
                                                            </p>
                                                            <form className="mt-3">
                                                                <div className="mb-2 text-center">
                                                                    <a
                                                                        href={pdfDownloadButton}
                                                                        className="btn btn-primary btn-lg mb-2 ms-2 me-2"
                                                                        style={{ display: "inline-block" }}
                                                                        // onClick={() => {
                                                                        //   if (
                                                                        //     pdfDownloadButton !== null &&
                                                                        //     pdfDownloadButton !== ""
                                                                        //   ) {

                                                                        //   }
                                                                        // }}
                                                                    >
                                                                        View FIR Copy
                                                                    </a>
                                                                    <p>
                                                                        <strong>Police Station:</strong>{" "}
                                                                        {checkPdfResponse.police_station_name ? checkPdfResponse.police_station_name : ""}
                                                                    </p>
                                                                    <a
                                                                        href={
                                                                            checkPdfResponse.police_statement_url ? checkPdfResponse.police_statement_url : null
                                                                        }
                                                                        className="btn btn-primary btn-lg mb-2 ms-2 me-2"
                                                                        // className={`btn btn-primary btn-lg mb-2 ms-2 me-2${
                                                                        //   isHidden == 0
                                                                        //     ? "step-active"
                                                                        //     : "step-completed"
                                                                        // }`}
                                                                        style={{ display: "inline-block" }}
                                                                    >
                                                                        View Statement to Comissioner
                                                                    </a>
                                                                    <p>
                                                                        <strong>Sent on:</strong> police@police.com <br />
                                                                        <strong>Status:</strong>{" "}
                                                                        {checkPdfResponse.police_statement_delivered &&
                                                                        checkPdfResponse.police_statement_delivered == true ? (
                                                                            <span className="text-success">Delivered</span>
                                                                        ) : (
                                                                            <span className="text-error">Not Delivered</span>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="w-75 m-auto">
                                                    <div className="row justify-content-center">
                                                        <div
                                                            className="col-lg-8"
                                                            style={{
                                                                color: "red",
                                                                alignSelf: "center",
                                                                marginLeft: 250,
                                                            }}
                                                        >
                                                            Your request has not completed. Please try again
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* {
              pdfDownloadButton != null && pdfDownloadButton != ""  ? (
                <a href={pdfDownloadButton}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setFirSmShow(false);
                      setPdfDownloadButton(null);
                      reset();
                      setIsHidden(0);
                    }}
                    disabled={
                      pdfDownloadButton == null || pdfDownloadButton == ""
                    }
                  >
                    Download PDF
                  </Button>
                </a>
              ) : null
            } */}
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setFirSmShow(false);
                                setDisplayOtpField(false);
                                setPdfDownloadButton(null);
                                setIsHidden(0);
                                reset();

                                setTimeout(() => {
                                    setInitialData();
                                }, 500);
                            }}
                            disabled={apiCalled}
                        >
                            Cancel
                        </Button>
                        {isHidden == 1 ? (
                            <>
                                <Button
                                    onClick={() => {
                                        setDisplayOtpField(false);
                                        setOtpTimeOut(false);
                                        setIsHidden(0);
                                    }}
                                    variant="primary"
                                    disabled={apiCalled}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    /*  disabled={apiCalled} */
                                >
                                    {displayOtpField ? "Submit Otp" : "File Single FIR"}
                                </Button>
                            </>
                        ) : null}

                        {isHidden == 0 ? (
                            <>
                                <Button type="submit" variant="primary" disabled={apiCalled}>
                                    Next
                                </Button>
                            </>
                        ) : null}
                        {isHidden == 2 ? (
                            <>
                                {fileFirSuccessful ? (
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            setFirSmShow(false);
                                            setDisplayOtpField(false);
                                            setPdfDownloadButton(null);
                                            setIsHidden(0);
                                            reset();
                                            setTimeout(() => {
                                                setInitialData();
                                            }, 500);
                                            setFileFirSuccessful(false);
                                        }}
                                        disabled={apiCalled}
                                    >
                                        Done
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => {
                                            setDisplayOtpField(false);
                                            setOtpTimeOut(false);
                                            setIsHidden(0);
                                        }}
                                        variant="primary"
                                        disabled={apiCalled}
                                    >
                                        Try Again
                                    </Button>
                                )}
                            </>
                        ) : null}
                    </Modal.Footer>
                </form>
            </Modal>
            {/**/}
        </div>
    );
}

export default PrelitigationBatchHeader;
