import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SelectDropdown from "../../../ui/forms/SelectDropdown";
import DatePicker from "../../../ui/forms/DatePicker";
import getBaseUrl from "../../../apis/getBaseUrl";

const searchByModes = { DATE_BETWEEN: "date_between", SPECIFIC_DATE: "specific" };

const filterDefaults = {
    search: "",
    batch_no: "",
    mobile_no: "",
    complaint_no: "",
    state: "",
    district: "",
    police_station: "",
    status: "",
    date_from: null,
    date_to: null,
    specific_date: null,
};

const statusOptions = [
    { label: "FIR Filed", value: "filed" },
    { label: "FIR Not Filed", value: "not_filed_yet" },
    { label: "FIR Info Missing", value: "info_missing" },
    { label: "FIR Info Added", value: "info_added" },
];

export default function ComplaintsFilterModal({ show, close, prevFilter, setPrevFilter, searchByPrev, setSearchByPrev }) {
    const [filter, setFilter] = useState({ ...filterDefaults });
    const [searchBy, setSearchBy] = useState("");

    // API States
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [policeStation, setPoliceStation] = useState([]);

    // handler
    const handleFilter = (name, val) => setFilter((prev) => ({ ...prev, [name]: val }));
    const updateSearchBy = (type) => {
        setSearchBy(type);
        setFilter((prev) => ({ ...prev, date_from: null, date_to: null, specific_date: null }));
    };

    const clearFilter = () => {
        setFilter({ ...filterDefaults });
        setPrevFilter({ ...filterDefaults });
        setSearchBy("");
    };

    const closeFilter = () => {
        close();
    };

    const applyFilter = () => {
        setPrevFilter({ ...filter });
        setSearchByPrev(searchBy);
        closeFilter();
    };

    useEffect(() => {
        if (!!show) {
            setFilter({ ...prevFilter });
            setSearchBy(searchByPrev);
        }
    }, [show]);

    // API Calls
    const getStates = () => {
        try {
            fetch(`${getBaseUrl().baseUrl}/fir/api/get_states/`)
                .then((response) => response.json())
                .then((data) => {
                    setStates(data.data);
                });
        } catch (error) {
            console.error({ error });
        }
    };

    useEffect(() => {
        getStates();
    }, []);

    // Get Districts
    const getDistricts = () => {
        const state_id = states?.find((el) => el?.state_name?.toString() === filter?.state?.toString())?.state_id;
        try {
            fetch(`${getBaseUrl().baseUrl}/fir/api/get_districts/?state_id=${state_id}`)
                .then((response) => response.json())
                .then((data) => {
                    setDistricts(data.data);
                });
        } catch (error) {
            console.error({ error });
        }
    };

    useEffect(() => {
        !!filter.state ? getDistricts() : setDistricts([]);
    }, [filter.state]);

    // Get Police Station

    const getPoliceStation = () => {
        const district_id = districts?.find((el) => el?.district_name?.toString() === filter?.district?.toString())?.district_id;

        try {
            fetch(`${getBaseUrl().baseUrl}/fir/api/get_police_stations/?district_id=${district_id}`)
                .then((response) => response.json())
                .then((data) => {
                    setPoliceStation(data.data);
                });
        } catch (error) {
            console.error({ error });
        }
    };

    useEffect(() => {
        !!filter.state && !!filter.district ? getPoliceStation() : setPoliceStation([]);
    }, [filter.state, filter.district]);

    return (
        <Modal size="sm" show={show} onHide={close} backdrop="static">
            <Modal.Header>
                <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body onKeyDown={(e) => e.key === "Enter" && applyFilter()}>
                {/* Search Filter */}
                <div className="mb-2 row modal-search">
                    <div className="col-md-12 pt-3 pb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by customer name / police station"
                            value={filter?.search}
                            onChange={(e) => handleFilter("search", e.target.value)}
                        />
                    </div>
                </div>

                <div className="grey-bg mb-2">
                    {/* Search by Batch Number */}
                    <div className="mb-2 row">
                        <label htmlFor="search_by_batch_number" className="col-md-8 col-form-label pt-2 mt-1">
                            Search by Batch Number
                        </label>
                        <div className="col-md-4">
                            <input
                                id="search_by_batch_number"
                                type="text"
                                className="form-control"
                                placeholder="Batch No."
                                value={filter?.batch_no}
                                onChange={(e) => handleFilter("batch_no", e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Search by Mobile Number */}
                    <div className="mb-2 row">
                        <label htmlFor="search_by_mobile_number" className="col-md-8 col-form-label pt-2 mt-1">
                            Mobile No.
                        </label>
                        <div className="col-md-4">
                            <input
                                id="search_by_mobile_number"
                                type="text"
                                className="form-control"
                                placeholder="Mobile No."
                                value={filter?.mobile_no}
                                onChange={(e) => handleFilter("mobile_no", e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Search by Complaint Number */}
                    <div className="mb-2 row">
                        <label htmlFor="search_by_complaint_number" className="col-md-8 col-form-label pt-2 mt-1">
                            Complaint No.
                        </label>
                        <div className="col-md-4">
                            <input
                                id="search_by_complaint_number"
                                type="text"
                                className="form-control"
                                placeholder="Complaint No."
                                value={filter?.complaint_no}
                                onChange={(e) => handleFilter("complaint_no", e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="grey-bg mb-2" style={{ position: "relative", zIndex: "3" }}>
                    {/* State */}
                    <div className="mb-2 row">
                        <label className="col-sm-5 col-form-label">State</label>
                        <div className="col-sm-7">
                            <SelectDropdown
                                options={[...states?.filter((el) => !!el?.is_active)?.map((val) => ({ label: val?.state_name, value: val?.state_name }))]}
                                value={filter?.state}
                                onChange={(val) => handleFilter("state", val)}
                                placeholder="Select State"
                            />
                        </div>
                    </div>

                    {/* District */}
                    <div className="mb-2 row">
                        <label className="col-sm-5 col-form-label">District</label>
                        <div className="col-sm-7">
                            <SelectDropdown
                                options={[...districts?.map((val) => ({ label: val?.district_name, value: val?.district_name }))]}
                                value={filter?.district}
                                disabled={!filter?.state}
                                onChange={(val) => handleFilter("district", val)}
                                placeholder="Select District"
                            />
                        </div>
                    </div>

                    {/* Police Station */}
                    <div className="mb-2 row">
                        <label className="col-sm-5 col-form-label">Police Station</label>
                        <div className="col-sm-7">
                            <SelectDropdown
                                options={[...policeStation?.map((val) => ({ label: val?.police_station_name, value: val?.police_station_name }))]}
                                value={filter?.police_station}
                                disabled={!filter?.state || !filter?.district}
                                onChange={(val) => handleFilter("police_station", val)}
                                placeholder="Select Police Station"
                            />
                        </div>
                    </div>
                </div>

                {/* Status */}
                <div className="mb-2 row" style={{ position: "relative", zIndex: "2" }}>
                    <label className="col-sm-5 col-form-label">Status</label>
                    <div className="col-sm-7">
                        <SelectDropdown options={statusOptions} value={filter?.status} onChange={(val) => handleFilter("status", val)} placeholder="Select" />
                    </div>
                </div>

                {/* Search By Date */}
                <div className="grey-bg" style={{ zIndex: "0" }}>
                    <div className="mb-2 row">
                        <label className="col-sm-5 col-form-label">Search by Date</label>
                    </div>
                    <div className="mb-2 row">
                        <label htmlFor="date_between" className="col-md-4 col-form-label">
                            <input
                                className="form-check-input ml-0"
                                type="radio"
                                name="search_by_date_type"
                                id="date_between"
                                checked={searchBy === searchByModes?.DATE_BETWEEN}
                                onChange={() => updateSearchBy(searchByModes?.DATE_BETWEEN)}
                            />{" "}
                            Date Between
                        </label>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6 ps-0 datepicker datepicker-small datepicker-small-white">
                                    <DatePicker
                                        disabled={searchBy !== searchByModes?.DATE_BETWEEN}
                                        value={filter?.date_from}
                                        maxDate={filter?.date_to}
                                        onChange={(val) => handleFilter("date_from", val)}
                                        label="From"
                                    />
                                </div>
                                <div className="col-md-6 ps-0 datepicker datepicker-small datepicker-small-white">
                                    <DatePicker
                                        disabled={searchBy !== searchByModes?.DATE_BETWEEN}
                                        value={filter?.date_to}
                                        minDate={filter?.date_from}
                                        onChange={(val) => handleFilter("date_to", val)}
                                        label="To"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <label htmlFor="specific_date" className="col-md-6 col-form-label">
                            <input
                                className="form-check-input ml-0"
                                type="radio"
                                name="search_by_date_type"
                                id="specific_date"
                                checked={searchBy === searchByModes?.SPECIFIC_DATE}
                                onChange={() => updateSearchBy(searchByModes?.SPECIFIC_DATE)}
                            />{" "}
                            Specific Date
                        </label>
                        <div className="col-md-6 datepicker">
                            <div className="col-md-12 datepicker datepicker-small datepicker-small-white w-100">
                                <DatePicker
                                    disabled={searchBy !== searchByModes?.SPECIFIC_DATE}
                                    value={filter?.specific_date}
                                    onChange={(val) => handleFilter("specific_date", val)}
                                    label="Specific date"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={clearFilter}>
                    Clear Filter
                </Button>
                <Button variant="secondary" onClick={closeFilter}>
                    Close
                </Button>
                <Button variant="primary" onClick={applyFilter}>
                    Apply
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
