export const dispositions = [
  { name: "Wrong Number", short: "WN", value: "WN" },
  { name: "Switched Off", short: "SW", value: "SW" },
  { name: "Ringing, No Response", short: "RNR", value: "RNR" },
  { name: "Do Not Disturb", short: "DND", value: "DND" },
  { name: "Promise To Pay", short: "PTP", value: "PTP" },
  { name: "Broken PTP", short: "BPTP", value: "BPTP" },
  { name: "Dispute", short: "DIS", value: "DIS" },
  { name: "Surrender", short: "SUR", value: "SUR" },
  { name: "Fully Paid", short: "PAID", value: "PAID" },
  { name: "Partial Paid", short: "P-PAID", value: "P-PAID" },
  { name: "Call Back", short: "CB", value: "CB" },
  { name: "Want Settlement", short: "SETL", value: "SETL" },
  { name: "Language Barrier", short: "LANG", value: "LANG" },
  { name: "Refuse To Pay", short: "RTP", value: "RTP" },
  { name: "Deceased", short: "DECEASED", value: "DECEASED" },
  { name: "Surrender", short: "SUR", value: "SUR" },
  { value: "VEHICLE_LOST", label: "VEHICLE LOST (VEHICLE_LOST)" },
  { value: "VEHICLE_ALREADY_SURRENDERED", label: "VEHICLE ALREADY SURRENDERED" },
  { value: "READY_TO_SURRENDER_VEHICLE", label: "READY TO SURRENDER VEHICLE" },
];

export function getDisposition(disposition) {
  if (disposition === "WN") return "Wrong Number (WN)";
  if (disposition === "SW") return "Switched Off (SW)";
  if (disposition === "RNR") return "Ringing, No Response (RNR)";
  if (disposition === "DND") return "Do Not Disturb (DND)";
  if (disposition === "PTP") return "Promise To Pay (PTP)";
  if (disposition === "BPTP") return "Broken PTP (BPTP)";
  if (disposition === "DIS") return "Dispute (DIS)";
  if (disposition === "SUR") return "Surrender (SUR)";
  if (disposition === "PAID") return "Fully Paid (PAID)";
  if (disposition === "P-PAID") return "Partial Paid (P-PAID)";
  if (disposition === "CB") return "Call Back (CB)";
  if (disposition === "SETL") return "Want Settlement (SETL)";
  if (disposition === "LANG") return "Language Barrier (LANG)";
  if (disposition === "RTP") return "Refuse To Pay (RTP)";
  if (disposition === "DECEASED") return "Deceased (DECEASED)";
  if (disposition === "ITP") return "Interest To Pay (ITP)";
  if (disposition === "SUR") return "Surrender (SUR)";

  return disposition;
}
