import React from "react";
import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { fetchFIRComplaints } from "../../../../API";
import ComplaintsFilterModal from "../../../../Components/Pre-Litigation/FirComplaints/ComplaintsFilterModal";
import { format } from "date-fns";
import { BsFileEarmarkPlus } from "react-icons/bs";
import FilterButton from "../../../../ui/buttons/FilterButton";
import SortButton from "../../../../ui/buttons/SortButton";
import FileMissingInfo from "./FileMissingInfo";
import useGeneralStore from "../../../../store/generalStore";

function ComplaintHeader({ setSearchParameter, HitReload, setHitReload, batchId }) {
    const showAlert = useGeneralStore((state) => state.open_alert);
    const [SortAsc, setSortAsc] = useState(true);

    // filter
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        search: "",
        batch_no: "",
        mobile_no: "",
        complaint_no: "",
        state: "",
        district: "",
        police_station: "",
        status: "",
        date_from: null,
        date_to: null,
        specific_date: null,
    });
    const [searchBy, setSearchBy] = useState("");

    const [showFillMissingInfoModal, setShowFillMissingInfoModal] = useState(false);
    const [missingInfoComplaints, setMissingInfoComplaints] = useState([]);
    const [missingInfoComplaintsLoading, setMissingInfoComplaintsLoading] = useState(false);

    const filterMapping = () => ({
        customer_name: filterData?.search || "",
        mobile_number: filterData?.mobile_no || "",
        // complaint_id: "",
        client_batch_id: filterData?.batch_no || "",
        // overdue: '',
        // product: '',
        status: filterData?.status || "",
        // amount_from: '',
        // amount_to: '',
        from_date: !!filterData?.date_from ? format(new Date(filterData?.date_from), "yyyy-MM-dd") : "",
        to_date: !!filterData?.date_to ? format(new Date(filterData?.date_to), "yyyy-MM-dd") : "",
        specific_date: !!filterData?.specific_date ? format(new Date(filterData?.specific_date), "yyyy-MM-dd") : "",
        state: filterData?.state || "",
        district: filterData?.district || "",
        police_station: filterData?.police_station || "",
        complaint_number: filterData?.complaint_no || "",
    });

    useEffect(() => {
        // apply filter actiins
        setSearchParameter({ ...filterMapping() });
    }, [filterData]);

    const fetchMissingInfoComplaints = async () => {
        if (!batchId) {
            showAlert({ variant: "warning", msg: "Batch not Found" });
            return;
        }

        setMissingInfoComplaintsLoading(true);

        const res = await fetchFIRComplaints({
            batch_id: batchId,
            status: "info_missing",
            size: 20,
        });

        if (res) {
            setMissingInfoComplaints([...res?.data]);
        } else showAlert({ variant: "danger", msg: "Unable to fetch complaints" });

        setMissingInfoComplaintsLoading(false);
    };

    return (
        <div className="row d-flex d-wrap justify-between filter-section">
            <div className="col-md-12 col-lg-6 d-flex align-center">
                <FilterButton onClick={() => setShowFilter(true)} active={!!filterData && !!Object.values(filterData)?.some((el) => !!el)} />
                <SortButton
                    onClick={() => {
                        setSortAsc(!SortAsc);
                        let sort = SortAsc ? "asc" : "dsc";
                        setSearchParameter({ ...filterMapping(), sort });
                    }}
                    label="Sort By Batch"
                    active={!SortAsc}
                />
            </div>

            <div className="col-md-12 col-lg-6 pt-3 pt-lg-0 text-end">
                <Dropdown className="bulk-action">
                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="w-100 text-start select-style-two">
                        Select Option for Action
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="select-style-dropdown-two">
                        <Dropdown.Item
                            disabled={!batchId}
                            onClick={() => {
                                fetchMissingInfoComplaints();
                                setShowFillMissingInfoModal(true);
                            }}
                        >
                            <BsFileEarmarkPlus style={{ marginRight: "0.5rem", fontSize: "1.1rem" }} />
                            Fill missing Info
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {/* Filter Modal */}
            <ComplaintsFilterModal
                show={showFilter}
                close={() => setShowFilter(false)}
                prevFilter={filterData}
                setPrevFilter={setFilterData}
                searchByPrev={searchBy}
                setSearchByPrev={setSearchBy}
            />

            <FileMissingInfo
                show={showFillMissingInfoModal}
                setShow={setShowFillMissingInfoModal}
                missingInfoDetails={missingInfoComplaints}
                detailsLoading={missingInfoComplaintsLoading}
                reload={() => setHitReload(!HitReload)}
            />
        </div>
    );
}

export default ComplaintHeader;
