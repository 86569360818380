import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const store = (set) => ({
    isAuthenticated: false,
    token: null,
    userData: null,
    role: null,
    features: [],
    profileCompleted: false,
    updateProfileCompleted: (status) => set(() => ({ profileCompleted: !!status })),
    addFeatures: (features = []) => set(() => ({ features })),
    createUser: ({ token, userData, role }) => set(() => ({ userData, token, role, isAuthenticated: true })),
    removeUser: () => set(() => ({ userData: null, token: null, isAuthenticated: false, role: null, features: [], profileCompleted: false })),
});

const useAuthStore = create(devtools(persist(store, { name: "user" })));

export default useAuthStore;
