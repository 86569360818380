import React, { useEffect, useRef, useState } from "react";
import PrelitigationFirBatchHeader from "../../../../Components/Headers/PrelitigationFirBatchHeader";
import ContentLoader from "../../../../Components/ContentLoader";
import {
  fetchFirBatches,
  fileFirMissing,
  fetchFIRDownloadTrackingReport,
  downloadMediaUrl,
  startServingToBorrowerCampaign,
  downloadNotWorkingBatchFile,
  downloadFirCopiesZip,
} from "../../../../API";
import NoRecord from "../../../../Components/NoRecord";
import FirBatchItem from "../../../../Components/Pre-Litigation/FirBatchItem";
import useGeneralStore from "../../../../store/generalStore";
import { getFirAnalysisReport, stateBreakup } from "../../../../API/panelApis";
import usePagination from "../../../../hooks/usePagination";
import Pagination from "../../../../Components/Pagination";
import StateBreakup from "../../../Components/Prelitigation/LegalNotices/Batch/StateBreakup";

function PreLitigationFir() {
  const updateFetchedCount = useGeneralStore((state) => state.update_fetched_count);
  const [batchData, setBatchData] = useState([]);
  const [HitReload, setHitReload] = useState(false);
  const [ArrayIds, setArrayIds] = useState([]);
  const [UnableToFetch, setUnableToFetch] = useState(false);
  const [searchParameter, setSearchParameter] = useState("");

  const showAlert = useGeneralStore((state) => state.open_alert);

  const stateBreakupModalRef = useRef(null);

  const { currentPage, handleTotal, limitPerPage, paginationCallBack, paginationProps } = usePagination({ defaultLimit: 20 });

  const getData = async () => {
    try {
      const res = await fetchFirBatches({
        ...searchParameter,
        page: currentPage,
        size: limitPerPage,
      });
      if (res) {
        setArrayIds(
          res.data.map((item, i) => {
            return i + 1;
          })
        );
        setBatchData(res.data);
        handleTotal(Number(res.total));
        updateFetchedCount(res.total);
        setUnableToFetch(true);
      }
    } catch (error) {
      setUnableToFetch(true);
      console.log(error);
    }
  };

  useEffect(() => {
    paginationCallBack(getData);
  }, [HitReload, searchParameter]);

  const fileFir = async (btchId, val) => {
    try {
      let params = {
        batch_id: btchId,
        file_missing: val,
      };
      const res = await fileFirMissing(params);
      if (res) {
        showAlert({
          variant: "info",
          msg: res,
        });
      }
    } catch (error) {
      showAlert({
        variant: "danger",
        msg: error.toString(),
      });
    }
  };

  const ServingToBorrower = async (val, btchId) => {
    try {
      let params = {
        client_id: val,
        batch_id: btchId,
      };
      const res = await startServingToBorrowerCampaign(params);
      if (res) {
        showAlert({
          variant: "info",
          msg: res.message,
        });
      }
    } catch (error) {
      showAlert({
        variant: "danger",
        msg: error.toString(),
      });
    }
  };

  const downloadNot_WorkingBatchFile = async (val, btchId) => {
    const link = document.createElement("a");
    link.href = downloadNotWorkingBatchFile(val, btchId);
    link.target = "_blank";
    link.click();
  };

  function download(dataurl, filename) {
    const link = document.createElement("a");
    link.href = downloadMediaUrl(dataurl);
    if (!!filename) link.download = filename;
    link.click();
  }

  const downloadTrackingReport1 = async (clientId, batchId) => {
    try {
      let params1 = {
        client_id: clientId,
        batch_id: batchId,
      };
      let res = await fetchFIRDownloadTrackingReport(params1);

      if (res) {
        showAlert({
          variant: "info",
          msg: res?.message || "FIR Report created successfully.",
        });
        download(res?.data?.file_url, res?.data?.file_url.split("/").at(-1));
      }
    } catch (error) {
      console.log(error);
      showAlert({
        variant: "danger",
        msg: error.toString(),
      });
    }
  };

  const downloadFirAnalysisReport = async ({ client_id, batch_id }) => {
    try {
      let res = await getFirAnalysisReport({ client_id, batch_id });

      if (res) {
        showAlert({
          variant: "info",
          msg: res?.message || "FIR Analysis Report Downloaded successfully.",
        });

        download(res?.data?.file_url, res?.data?.file_url.split("/").at(-1));
      }
    } catch (error) {
      console.log(error);
      showAlert({
        variant: "danger",
        msg: error.toString(),
      });
    }
  };

  const downloadUploadedData = (url) => {
    if (!url) return;
    showAlert({
      variant: "info",
      msg: "Report created successfully.",
    });
    download(url);
  };

  const downloadFir_copies = async (client_id, batch_id) => {
    try {
      let res = await downloadFirCopiesZip({ client_id, batch_id });
      if (res?.data) {
        showAlert({
          variant: "info",
          msg: "FIR copies Downloaded successfully.",
        });
        download(res?.data);
      } else {
        showAlert({
          variant: "info",
          msg: "No FIR copies found.",
        });
      }
    } catch (error) {
      console.log(error);
      showAlert({
        variant: "danger",
        msg: error.toString(),
      });
    }
  };

  const viewStateBreakup = async (batch_id) => {
    try {
      let res = await stateBreakup({ batch_id });

      if (res?.data && res.data.length > 0) {
        stateBreakupModalRef.current.showModal(res.data);
      } else {
        showAlert({
          variant: "info",
          msg: "No Data found.",
        });
      }
    } catch (error) {
      console.log(error);
      showAlert({
        variant: "danger",
        msg: error.toString(),
      });
    }
  };

  return (
    <div>
      <PrelitigationFirBatchHeader setSearchParameter={setSearchParameter} HitReload={HitReload} setHitReload={setHitReload} />
      <hr className="mt-4 mb-4" />

      {/* Batch Details List */}

      {!!batchData?.length && (
        <>
          {batchData?.map((val, index) => (
            <FirBatchItem
              key={index}
              data={val}
              downloadUploadedData={downloadUploadedData}
              downloadTrackingReport1={downloadTrackingReport1}
              downloadFirAnalysisReport={downloadFirAnalysisReport}
              fileFir={fileFir}
              servingToBorrower={ServingToBorrower}
              downloadNotWorkingBatchFile={downloadNot_WorkingBatchFile}
              downloadFirCopies={downloadFir_copies}
              viewStateBreakup={viewStateBreakup}
            />
          ))}

          <StateBreakup ref={stateBreakupModalRef} />
          <Pagination {...paginationProps} />
        </>
      )}

      {batchData.length === 0 && UnableToFetch && <NoRecord />}

      {batchData.length === 0 && !UnableToFetch && <ContentLoader />}
    </div>
  );
}

export default PreLitigationFir;
