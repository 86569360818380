import useGeneralStore from "../store/generalStore";

const format_excel = /(\.xlse|\.xlsx|\.xlsm|\.xlsb|\.xltx|\.xltm|\.xls|\.xlt|\.xls|\.xlsb|\.xml|\.xla|\.xlw|\.xlr)$/i;
const format_image = /(\.jpg|\.jpeg|\.png)$/i;

export const fileFormats = {
    EXCEL: "excel",
    IMAGE: "image",
};

export const acceptFileFormats = {
    EXCEL: ".xlse,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xlsb,.xml,.xla,.xlw,.xlr",
    IMAGE: "image/png, image/jpg, image/jpeg",
};

export default function useCheckFileFormat() {
    const showAlert = useGeneralStore((state) => state.open_alert);

    function checkFileFormat(e, format = fileFormats?.EXCEL) {
        let filePath = e.target.value;

        if (!format) {
            showAlert({ variant: "warning", msg: "Pls Specify File Type" });
            return false;
        }

        // check for excel
        if (format === fileFormats?.EXCEL && !format_excel.exec(filePath)) {
            showAlert({ variant: "warning", msg: "Please upload file having extensions Excel or Binary Excel." });
            return false;
        }

        // check for image jpg & png
        if (format === fileFormats?.IMAGE && !format_image.exec(filePath)) {
            showAlert({ variant: "warning", msg: "Please upload image in .jpg or .jpeg or .png format only." });
            return false;
        }

        return true;
    }

    return { checkFileFormat };
}
