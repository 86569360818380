import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ContentLoader from "../../../../Components/ContentLoader";
import { useBatchUploadHandler } from "../../../../hooks/batchUploadHandler";
import BatchUploadHandler from "../../../../ui/BatchUploadHandler";
import UploadProgressBar from "../../../../ui/UploadProgressBar";
import Excel from "../../../../Static/Images/excel.svg";
import { updateModelData } from "../../../../API/panelApis";
import useAuthStore from "../../../../store/authStore";
import useGeneralStore from "../../../../store/generalStore";
import useMappings from "../../../../hooks/useMappings";
import MultiSelectDropDown from "../../../../ui/forms/MultiSelectDropDown";
import { labelMapping as labelMappingCompleteObj } from "../../../../utils/fileMappings";

const labelMappingOptions = Object.keys(labelMappingCompleteObj).map((el) => ({ label: labelMappingCompleteObj[el], value: el }));

const labelMappingDefault = [
  "loan_account_no",
  "principal_outstanding",
  "pos",
  "payment_link",
  "email",
  "customer_address1",
  "permanent_address",
  "office_address1",
  "pre_approved_sattlement_amount",
  "customer_profile",
  "workplace_name",
  "customer_alt_contact_no",
  "ref_name_1",
  "ref_num_1",
  "ref_name_2",
  "ref_num_2",
  "ref_name_3",
  "ref_num_3",
  "ref_name_4",
  "ref_num_4",
  "client_code",
  "minimum_due",
  "card_no",
  "co_applicant_name",
  "co_applicant_mobile_number",
  "co_applicant_name2",
  "co_applicant_mobile_number2",
  "co_applicant_name3",
  "co_applicant_mobile_number3",
];

export default function UpdateBatchData({ show, setShow }) {
  const userData = useAuthStore((state) => state.userData);
  const showAlert = useGeneralStore((state) => state.open_alert);

  const [importPage, setImportPage] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);

  const [labelMappingArr, setLabelMappingArr] = useState([...labelMappingDefault]);

  const { updateMappings, getMappings } = useMappings({ type: "collection" });

  // batch upload handler hook
  const {
    progressValue,
    importDisabled,
    batchFile,
    matchedHeaderNames,
    handleHeaderChange,
    availableHeaderNames,
    handleFileValidation,
    getUploadHeaders,
    resetBatchUpload,
    SelectSheetModal,
  } = useBatchUploadHandler({ showProgress: true });

  const closeModal = () => {
    setShow(false);
    resetBatchUpload();
    setImportPage(false);
    setLabelMappingArr([...labelMappingDefault]);
  };

  const labelMapping = useMemo(
    () =>
      labelMappingArr.reduce((acc, el) => {
        acc[el] = labelMappingCompleteObj[el];
        return acc;
      }, {}),
    [labelMappingArr]
  );

  useEffect(() => {
    if (!!show) getMappings();
  }, [show]);

  //export matched columns
  const exportColumns = async () => {
    const finalMapping = getUploadHeaders();

    const filteredMapping = {};
    const labelMappingKeys = Object.keys(labelMapping);
    for (const [key, value] of Object.entries(finalMapping)) {
      if (labelMappingKeys?.includes(value)) filteredMapping[key] = value;
    }

    if (!Object.values(filteredMapping)?.includes("loan_account_no")) {
      showAlert({ msg: "Loan Account No. is Mandatory", variant: "warning" });
      return;
    }

    setIsUploading(true);

    let formData = new FormData();
    formData.append("client_id", userData?.client_id);
    formData.append("file", batchFile);
    formData.append("column_rename_data", JSON.stringify(filteredMapping));
    const res = await updateModelData(formData);
    if (res) {
      await updateMappings(matchedHeaderNames);
      showAlert({ variant: "success", msg: res?.message });
      closeModal();
    } else showAlert({ variant: "danger", msg: "Failed to update" });
    setIsUploading(false);
  };

  return (
    <>
      <Modal show={show} dialogClassName="modal-large" backdrop="static">
        <Modal.Header>
          <Modal.Title>Update Batch Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <div className="pt-3 pb-3 text-center upload-batch-popup">
              {IsUploading && <ContentLoader />}

              <div className="d-flex import-step align-center content-center">
                {/* className="import-step step-active content-center"  */}
                <div className={`import-step content-center ${progressValue < 50 ? "step-active" : "step-completed"}`}>
                  {progressValue < 100 ? (
                    "01"
                  ) : (
                    <svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17 0C7.626 0 0 7.626 0 17c0 9.374 7.626 17 17 17 9.374 0 17-7.626 17-17 0-9.374-7.626-17-17-17zm8.135 13.995-8.757 8.757a2.118 2.118 0 0 1-1.503.623 2.118 2.118 0 0 1-1.502-.623l-4.508-4.507a2.124 2.124 0 1 1 3.005-3.005l3.005 3.005 7.255-7.255a2.124 2.124 0 1 1 3.005 3.005z"
                        fill="#409E7B"
                        fillRule="nonzero"
                      />
                    </svg>
                  )}

                  <span>Import File</span>
                </div>
                <div className="import-step-sep"></div>
                <div className={`import-step content-center ${progressValue >= 100 && "step-active"}`}>
                  02
                  <span>Map Columns</span>
                </div>
              </div>
              {/*  */}

              {importPage ? (
                progressValue < 100 ? (
                  <UploadProgressBar progressValue={progressValue} />
                ) : (
                  <>
                    <div className="bg-light shadow rounded p-3 w-75 mb-4 mx-auto">
                      <div className="text-start">
                        <p className="mb-1">Choose Columns</p>
                        <div style={{ maxWidth: "100%" }}>
                          <MultiSelectDropDown options={labelMappingOptions} value={labelMappingArr} onChange={setLabelMappingArr} />
                        </div>
                      </div>
                    </div>

                    <BatchUploadHandler
                      availableHeaderNames={availableHeaderNames}
                      matchedHeaderNames={matchedHeaderNames}
                      labelMapping={labelMapping}
                      automapping={true}
                      handleHeaderChange={handleHeaderChange}
                    />
                  </>
                )
              ) : (
                <>
                  <div className="import-excel-img mb-2">
                    <img src={Excel} alt="Excel" />
                  </div>
                  <input
                    type="file"
                    name="uploadFile"
                    id="uploadFile"
                    accept=".xlse,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xlsb,.xml,.xla,.xlw,.xlr"
                    style={{ opacity: "0" }}
                    onChange={(e) => {
                      if (e?.target?.files[0]?.size > 1e7) {
                        e.target.value = "";
                        showAlert({ variant: "danger", msg: "File size should be lesser than 10 MBs!" });
                        return;
                      }
                      setImportPage(true);
                      handleFileValidation(e);
                    }}
                  />
                  <br />
                  <label htmlFor="uploadFile" className="btn btn-primary btn-lg mb-2">
                    Select excel file from your computer
                  </label>
                  <p>You can upload in Excel or Binary Excel.</p>
                </>
              )}

              {progressValue >= 100 && (
                <div style={{ maxWidth: "800px" }} className="alert alert-warning py-2 mx-auto mt-5">
                  <strong>* Loan Account No.</strong> is Mandatory
                </div>
              )}
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          {importPage && (
            <Button
              variant="primary"
              disabled={importDisabled || progressValue < 100}
              className="collection_batch_upload_page_footer_import"
              onClick={() => exportColumns()}
            >
              Update
            </Button>
          )}
          <Button variant="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <SelectSheetModal />
    </>
  );
}
