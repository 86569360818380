import { useCallback, useRef, useState } from "react";

export const limits = [10, 20, 30, 50, 100];

const getLimit = (limit) => (!limits.includes(Number(limit)) ? limits[1] : limit);

export default function usePagination({ customLimit = false, defaultLimit = 20 }) {
  const [limitPerPage, setLimitPerPage] = useState(getLimit(defaultLimit));
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const prevPageRef = useRef(1);

  const handlePagination = useCallback(
    (page) => {
      setCurrentPage(page <= 1 ? 1 : page > pageTotal ? pageTotal : page);
    },
    [pageTotal]
  );

  const handleTotal = useCallback(
    (count) => {
      const totalItems = Number(count);
      if (isNaN(totalItems) || totalItems < 0) return;
      const limit = customLimit ? limitPerPage : defaultLimit;
      setPageTotal(Math.ceil(totalItems / limit));
      setTotal(totalItems);
    },
    [customLimit, defaultLimit, limitPerPage]
  );

  const resetPagination = useCallback(() => {
    setCurrentPage(1);
  }, []);

  const handleLimitPerPage = useCallback((limit) => {
    setLimitPerPage(getLimit(limit));
  }, []);

  const paginationCallBack = useCallback(
    (cb = () => {}) => {
      // HOW IT WORKS:
      // =============
      // intial rendering => call cb only once
      // when page changes => call cb
      // when any cb dependancy changes => call cb after resetting pagination

      if (prevPageRef.current !== currentPage) {
        prevPageRef.current = currentPage;
        cb();
        return;
      }

      if (currentPage !== 1) {
        resetPagination();
        return;
      }

      cb();
    },
    [currentPage]
  );

  return {
    currentPage,
    total,
    limitPerPage,
    handleTotal,
    resetPagination,
    paginationCallBack,
    paginationProps: {
      limitPerPage,
      handleLimitPerPage,
      handlePagination,
      currentPage,
      pageTotal,
      customLimit,
    },
  };
}
